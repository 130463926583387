import { Select, MenuItem, FormControl, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import "../../index.css";
import { Box } from "@mui/system";

interface Props {
  dropdownValue: any;
  control: any;
  nameOfSelect: string;
  resetField: any;
}

const dropDownStyle = {
  color: "#495057",
  border: "none",
  outline: "none",
  fontSize: "14px",
  width: "240px",
  maxWidth: "240px",
  backgroundColor: "transparent",
};

const SelectGrantTypeForEwcIssuance = (props: Props) => {
  const { dropdownValue, control, nameOfSelect, resetField } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={"space-between"}
      mt={1.5}
    >
      <Typography variant="subtitle1" mt={0} marginRight={2}>
        {"Grant Type"}
        <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
      </Typography>
      <Controller
        name={nameOfSelect}
        control={control}
        rules={{
          required: false,
        }}
        defaultValue={"pre-authorised"}
        render={({ field: { onChange, value } }) => (
          <FormControl variant="outlined">
            <Select
              value={value}
              onChange={(e: any) => {
                onChange(e);

                resetField("dynamicCredentialRequest");
                resetField("userPin");
              }}
              name={nameOfSelect}
              style={{
                ...dropDownStyle,
                height: "32px",
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: "auto",
                  },
                },
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              {dropdownValue?.map((option: any) => (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default SelectGrantTypeForEwcIssuance;
