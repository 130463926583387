import { HttpService } from "../../service/HTTPService";
import { addRefreshAuthToAuthProvider } from "react-admin";
import { refreshAuth } from "./refreshAuth";
import { LocalStorageService } from "../../service/localStorageService";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";
import { HttpServiceForDigitalWallet } from "../../service/digitalWalletHttpService";
import { HttpServiceForDigitalWalletEwc } from "../../service/digitalWalletEwcHttpService";
import { OnBoardingHttpService } from "../../service/onboardingService";

export const myAuthProvider = {
  login: async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<any> => {
    try {
      const res = await HttpService.login(username, password);
      const token = res.data;
      LocalStorageService.updateToken(token);

      const organisationFeaturesRes =
        await OnBoardingHttpService.getOrganisationFeatures();
      useDigitalWalletStore
        .getState()
        .updateOrgFeatures(organisationFeaturesRes.data.configuredFeatures);

      const adminDetailsRes = await HttpService.getOrganisationAdminDetails();
      LocalStorageService.updateUser(adminDetailsRes.data);

      const avatarImage = await HttpService.getAdminAvatarImage();
      LocalStorageService.updateProfilePic(avatarImage);

      const digitalWalletRes =
        await HttpServiceForDigitalWallet.getDigitalWallet();
      useDigitalWalletStore
        .getState()
        .updateDigitalWalletDeploymentStatus(digitalWalletRes.statusStr);

      const digitalWalletEwcRes =
        await HttpServiceForDigitalWalletEwc.getDigitalWallet();
      useDigitalWalletStore
        .getState()
        .updateDigitalWalletDeploymentStatusForEWC(
          digitalWalletEwcRes.statusStr
        );

      const redirectPage = () => {
        if (organisationFeaturesRes.data.configuredFeatures.gettingStarted) {
          return "/start";
        } else if (
          organisationFeaturesRes.data.configuredFeatures.dataAgreements
        ) {
          return "/dataagreement";
        } else if (
          organisationFeaturesRes.data.configuredFeatures.managedData
        ) {
          return "/personaldata";
        } else if (
          organisationFeaturesRes.data.configuredFeatures.digitalWalletAries
        ) {
          return "/aries-cloud-agent";
        } else if (
          organisationFeaturesRes.data.configuredFeatures.digitalWalletOid4vc
        ) {
          return "/oid4vc";
        } else if (
          organisationFeaturesRes.data.configuredFeatures.manageUsers
        ) {
          return "/configuration";
        } else if (
          organisationFeaturesRes.data.configuredFeatures.privacydashboard
        ) {
          return "/privacydashboard";
        } else if (organisationFeaturesRes.data.configuredFeatures.account) {
          return "/manageadmin";
        } else return "/webhooks";
      };
      return { redirectTo: redirectPage(), data: res.data };
    } catch (error: any) {
      throw (
        error.response?.data?.errorDescription ||
        error.response?.data?.Message ||
        error
      );
    }
  },

  checkError: (error: any): Promise<any> => Promise.resolve(),
  checkAuth: () => {
    const isLoggedIn =
      LocalStorageService.getAccessToken() !== undefined ? true : false;
    const currentPath = window.location.pathname;
    if (isLoggedIn || currentPath === "/onboarding") {
      return Promise.resolve();
    } else return Promise.reject({ redirectTo: "/login", message: false });
  },
  logout: () => {
    return HttpService.logout()
      .then((res) => {})
      .catch((error) => {});
  },
  getIdentity: (): Promise<any> => {
    try {
      const { lastVisited, name, email } = LocalStorageService.getUser();
      return Promise.resolve({ lastVisited, name, email });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: (params: any): Promise<any> => Promise.resolve(),
};

export const authProvider = addRefreshAuthToAuthProvider(
  myAuthProvider,
  refreshAuth
);
