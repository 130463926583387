import { Select, MenuItem, FormControl } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "../../index.css";
import { Box } from "@mui/system";

interface Props {
  dropdownValue: any;
  control: any;
  nameOfSelect: string;
  valueKey: string; // Key to use as the value for MenuItem
  displayKey: string; // Key to use as the display text for MenuItem
  digitalWallet?: any;
  selectedValue: string;
  disabled?: boolean;
}

const dropDownStyle = {
  color: "#495057",
  border: "none",
  outline: "none",
  fontSize: "14px",
  width: "100%",
  backgroundColor: "transparent",
};

const CommonDropdownWithDefaultSelectValue = (props: Props) => {
  const {
    dropdownValue,
    control,
    nameOfSelect,
    valueKey,
    displayKey,
    digitalWallet,
    selectedValue,
    disabled
  } = props;
  const { t } = useTranslation("translation");

  return (
    <Controller
      name={nameOfSelect}
      control={control}
      rules={{
        required: true,
      }}
      defaultValue={selectedValue || ""}
      render={({ field: { onChange, value } }) => (
        <FormControl variant="outlined" fullWidth>
          <Select
            value={value}
            disabled={digitalWallet?.statusStr === "Deployed" || disabled}
            onChange={onChange}
            name={nameOfSelect}
            displayEmpty
            style={{
              ...dropDownStyle,
              height: "32px",
            }}
            inputProps={{ "aria-label": "Without label" }}
            renderValue={(selected) => {
              const selectedItem = dropdownValue?.find(
                (item: any) => item[valueKey] === selected
              );
              return selectedItem ? (
                selectedItem[displayKey]
              ) : (
                <em>{t("common.select")}</em>
              );
            }}
          >
            {dropdownValue?.map((option: any) =>
              option?.disabled ? (
                <Box sx={{ cursor: "not-allowed" }}>
                  <MenuItem
                    key={option[displayKey]}
                    value={option[valueKey]}
                    disabled={option?.disabled}
                    className={
                      "css-j2ut2o-MuiButtonBase-root-MuiMenuItem-root Mui-disabled"
                    }
                    sx={{ cursor: "not-allowed" }}
                  >
                    {option[displayKey]}
                  </MenuItem>
                </Box>
              ) : (
                <MenuItem
                  key={option[displayKey]}
                  value={option[valueKey]}
                  disabled={option?.disabled}
                >
                  {option[displayKey]}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default CommonDropdownWithDefaultSelectValue;
