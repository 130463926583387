import React, { ReactNode } from 'react';
import { Layout } from 'react-admin';
import MyAppBar from './MyAppBar';
import MyMenu from './MyMenu';
import { OrganizationDetailsCRUDProvider } from '../providers/organizationDetailsCRUDProvider';
import '../../index.css';

interface CustomLayoutProps {
  children: ReactNode;
}

export const MyLayout: React.FC<CustomLayoutProps> = (props) => {
  return (
    <OrganizationDetailsCRUDProvider>
      <Layout {...props} appBar={MyAppBar} menu={MyMenu} className="custom-layout">
        <div className="custom-content">
          {props.children}
        </div>
      </Layout>
    </OrganizationDetailsCRUDProvider>
  );
};

