import * as React from "react";
import { Dispatch, SetStateAction } from "react";

import { Drawer, Typography, Button, Box, Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import DefaultBanner from "../../assets/OrganisationDefaultBanner.jpeg";
import DefaultLogo from "../../assets/OrganisationDefaultLogo.png";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  BannerContainer,
} from "./modalStyle";

import { useTranslation } from "react-i18next";
import { useSSIStore } from "../../store/ssiStore";
import { OrganizationDetailsCRUDContext } from "../../contexts/organizationDetailsCrud";
import { DataAttributeCard } from "../issuanceHistory/dataAttributeCard";
import { DataAgreementPolicyCard } from "../issuanceHistory/dataAgreementPolicyCard";
import { DataAgreementPolicyCardForEWC } from "../issuanceHistory/dataAgreementPolicyCardForEWC";
import { DataAttributeCardEWC } from "../issuanceHistory/dataAttributeCardEWC";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  selectedData: any;
  ssi: string;
}

export default function ViewExchangeRecordModal(props: Props) {
  const { t } = useTranslation("translation");
  const { open, setOpen, headerText, selectedData, ssi } = props;
  const { organisationDetails, logoImageBase64, coverImageBase64 }: any =
    React.useContext(OrganizationDetailsCRUDContext);

  const [blurPersonalData, setBlurPersonalData] = React.useState(true);
  const selectedDaRecord: any = useSSIStore((state) =>
    ssi === "issuanceHistory" || ssi === "issuanceHistoryEWC"
      ? state.dataAgreementForIssuanceHistory
      : state.dataAgreementForVerificationsHistory
  );

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <Container>
          <HeaderContainer>
            <Box pl={2} style={{ overflow: "hidden" }}>
              <Typography
                style={{
                  color: "#F3F3F6",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {headerText}: {selectedDaRecord?.purpose}
              </Typography>
              <Typography color="#F3F3F6">{selectedData?.id}</Typography>
            </Box>
            <CloseIcon
              onClick={() => setOpen(false)}
              sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
            />
          </HeaderContainer>
          <BannerContainer>
            <Box
              style={{ height: "150px", width: "100%" }}
              component="img"
              alt="Banner"
              src={
                coverImageBase64
                  ? `data:image/jpeg;charset=utf-8;base64,${coverImageBase64}`
                  : DefaultBanner
              }
            />
            <Box
              style={{
                position: "absolute",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                background: "#000000bf",
                right: "10px",
                top: "90px",
                cursor: "pointer",
              }}
              onClick={() => {
                setBlurPersonalData(!blurPersonalData);
              }}
            >
              {blurPersonalData ? (
                <VisibilityOffOutlinedIcon style={{ color: "white" }} />
              ) : (
                <VisibilityOutlinedIcon style={{ color: "white" }} />
              )}
            </Box>
          </BannerContainer>
          <Box sx={{ marginBottom: "60px" }}>
            <Avatar
              src={
                logoImageBase64
                  ? `data:image/jpeg;charset=utf-8;base64,${logoImageBase64}`
                  : DefaultLogo
              }
              style={{
                position: "absolute",
                marginLeft: 50,
                marginTop: "-65px",
                width: "110px",
                height: "110px",
                border: "solid white 6px",
              }}
            />
          </Box>
          <DetailsContainer>
            <Box p={1.5}>
              <Typography variant="h6" fontWeight="bold">
                {organisationDetails.name}
              </Typography>
              <Typography color="#9F9F9F">
                {organisationDetails.location}
              </Typography>
              <Typography variant="subtitle1" mt={3}>
                {t("common.overView")}
              </Typography>
              <Typography
                variant="subtitle2"
                color="#9F9F9F"
                mt={1}
                sx={{ wordWrap: "breakWord" }}
              >
                {organisationDetails.description}
              </Typography>

              <Typography color="grey" mt={3} variant="subtitle1">
                {selectedDaRecord?.purpose?.toUpperCase()}
              </Typography>

              {ssi === "issuanceHistoryEWC" ? (
                <DataAttributeCardEWC
                  selectedData={selectedData}
                  blurPersonalData={blurPersonalData}
                  ssi={ssi}
                />
              ) : (
                <DataAttributeCard
                  selectedData={selectedData}
                  blurPersonalData={blurPersonalData}
                  ssi={ssi}
                />
              )}

              {ssi === "issuanceHistoryEWC" ? (
                <DataAgreementPolicyCardForEWC />
              ) : (
                <DataAgreementPolicyCard
                  selectedData={selectedData}
                  setOpenViewExchangeRecordModal={setOpen}
                  ssi={ssi}
                />
              )}
            </Box>
          </DetailsContainer>
          <FooterContainer>
            <Button
              onClick={() => setOpen(false)}
              style={buttonStyle}
              sx={{
                marginLeft: "15px",
                color: "black",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              variant="outlined"
            >
              {t("common.close")}
            </Button>
          </FooterContainer>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
