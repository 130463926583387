import { TextareaAutosize, Typography } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButtons from "./buttonsContainer";
import { styled } from "@mui/material/styles";

const InputWithTypoGridBox = styled("div")(({ theme }) => ({
  display: "grid",
  width: "100%",
}));

const OtherEventNotifications = () => {
  const { t } = useTranslation("translation");

  const { handleSubmit, formState, reset, register } = useForm<any>({
    mode: "onChange",
  });

  useEffect(() => {
    let defaultValues = {
      eventDetails: "",
    };

    reset({ ...defaultValues });
  }, []);

  const onSubmit = (createdData: any) => {
    console.log("createdData", createdData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography color="black" variant="subtitle1" fontWeight="bold">
        {t("notifications.eventNotification")}
      </Typography>
      <InputWithTypoGridBox>
        <Typography color="black" variant="subtitle2" mb={1} mt={1}>
          {t("notifications.enterEventDetails")}:
        </Typography>

        <TextareaAutosize
          aria-label="empty textarea"
          placeholder={t("notifications.pleaseTypeAtleastFiveCharacters")}
          style={{
            border: "none",
            borderBottom: "1px solid #bdbdbd",
            resize: "vertical",
            width: "100%",
            outline: "none",
            height: "100px",
            fontFamily: "Inter,Helvetica,Arial,sans-serif",
            fontSize:"14px"
          }}
          {...register("eventDetails", {
            required: true,
            minLength: 5,
          })}
        />
      </InputWithTypoGridBox>
      <SubmitButtons
        handleSubmit={handleSubmit(onSubmit)}
        clearValues={() => reset({ eventDetails: "" })}
        disable={!formState.isValid}
        buttonName1={t("notifications.clear")}
        buttonName2={t("notifications.notify")}
      />
    </form>
  );
};

export default OtherEventNotifications;
