export const getIconColor = (
  record: any,
  fieldType: string,
  selectedDropdownValue: any,
  digitalWalletDeploymentStatus: any
) => {
  if (record.active === false) {
    if (selectedDropdownValue[record.id] === record.version) {
      return "#FF0C10"; // Red color
    } else if (selectedDropdownValue[record.id] === undefined) {
      return "#FF0C10";
    } else if (selectedDropdownValue[record.id] !== record.version) {
      return "#4D4D4F";
    }
    return "#FF0C10";
  } else if (record.active === true && fieldType === "qr") {
    // custom qr icon
    if (record.methodOfUse === "data_using_service") {
      return "#4D4D4F";
    } else {
      return "#B9B9B9";
    }
  } else if (record.active === true && fieldType === "editOrDelete") {
    // custom edit or delete icon
    return "#4D4D4F";
  } else return "#4D4D4F";
};
