import { DIGITALWALLETEWCENDPOINTS } from "../settings/digitalWalletEwcSetting";
import { getAuthenticatedHeaders, httpClient } from "./HTTPService";
import {
  convertIssuanceHistoryForEWCForClient,
  convertVerificationHistoryForEWCForClient,
} from "./adapter";

export const HttpServiceForDigitalWalletEwc = {
  deployDigitalWallet: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .post(DIGITALWALLETEWCENDPOINTS.deployDigitalWallet(), payload, config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  getDigitalWallet: async (): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(DIGITALWALLETEWCENDPOINTS.getDigitalWallet(), config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  getOrganisationalIdentifier: async (): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(DIGITALWALLETEWCENDPOINTS.getOrganisationalIdentifier(), config)
      .then((res) => {
        return res.data;
      });
  },
  issueCredentialForEWC: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.post(
      DIGITALWALLETEWCENDPOINTS.issueCredentialForEWC(),
      payload,
      config
    );
  },
  updateIssueCredentialForEWC: async (
    payload: any,
    id: string | undefined
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.put(
      DIGITALWALLETEWCENDPOINTS.updateIssueCredentialForEWC(id),
      payload,
      config
    );
  },
  listIssueCredentialHistoryRecordsForEwc: async (
    offsetValue: number,
    pageSize: number,
    id: any
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(
        DIGITALWALLETEWCENDPOINTS.listIssueCredentialHistoryRecordsForEwc(
          offsetValue,
          pageSize,
          id
        ),
        config
      )
      .then((res) => {
        const CredentialHistoryRecords: any = res.data;
        return convertIssuanceHistoryForEWCForClient(CredentialHistoryRecords);
      });
  },
  deleteIssuanceHistoryForEWC: async (id: string | undefined): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.delete(
      DIGITALWALLETEWCENDPOINTS.deleteIssuanceHistoryForEWC(id),
      config
    );
  },
  listIssueVerificationHistoryRecordsForEwc: async (
    offsetValue: number,
    pageSize: number,
    id: any
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(
        DIGITALWALLETEWCENDPOINTS.listIssueVerificationHistoryRecordsForEwc(
          offsetValue,
          pageSize,
          id
        ),
        config
      )
      .then((res) => {
        const records: any = res.data;
        return convertVerificationHistoryForEWCForClient(records);
      });
  },
  sendVeificationForEWC: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.post(
      DIGITALWALLETEWCENDPOINTS.sendVeificationForEWC(),
      payload,
      config
    );
  },
  deleteVerificationHistoryForEWC: async (
    id: string | undefined
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.delete(
      DIGITALWALLETEWCENDPOINTS.deleteVerificationHistoryForEWC(id),
      config
    );
  },
  createCredentialDefinitin: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.post(
      DIGITALWALLETEWCENDPOINTS.createCredentialDefinitin(),
      payload,
      config
    );
  },
  updateCredentialDefinitin: async (payload: any, id: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.put(
      DIGITALWALLETEWCENDPOINTS.updateCredentialDefinitin(id),
      payload,
      config
    );
  },
  listCredentialDefinitin: async (
    offsetValue: number,
    pageSize: number
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(
        DIGITALWALLETEWCENDPOINTS.listCredentialDefinitin(
          offsetValue,
          pageSize
        ),
        config
      )
      .then((res) => {
        const data: any = res.data;
        return data;
      });
  },
  deleteCredentialDefinionForEWC: async (
    id: string | undefined
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.delete(
      DIGITALWALLETEWCENDPOINTS.deleteCredentialDefinionForEWC(id),
      config
    );
  },
  listIssuePresentationDefinitionForEwc: async (
    offsetValue: number,
    pageSize: number
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(
        DIGITALWALLETEWCENDPOINTS.listIssuePresentationDefinitionForEwc(
          offsetValue,
          pageSize
        ),
        config
      )
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  createPresentationDefinitionForEWC: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.post(
      DIGITALWALLETEWCENDPOINTS.createPresentationDefinitionForEWC(),
      payload,
      config
    );
  },
  deletePresentationDefinitinoForEWC: async (
    id: string | undefined
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.delete(
      DIGITALWALLETEWCENDPOINTS.deletePresentationDefinitinoForEWC(id),
      config
    );
  },
  updatePresentationDefinitionForEWC: async (
    payload: any,
    id: any
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.put(
      DIGITALWALLETEWCENDPOINTS.updatePresentationDefinitionForEWC(id),
      payload,
      config
    );
  },
  listCredentialsEwc: async (
    offsetValue: number,
    pageSize: number
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(
        DIGITALWALLETEWCENDPOINTS.listCredentialsEwc(offsetValue, pageSize),
        config
      )
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  listVerificationsEwc: async (
    offsetValue: number,
    pageSize: number
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(
        DIGITALWALLETEWCENDPOINTS.listVerificationsEwc(offsetValue, pageSize),
        config
      )
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  deleteCredentialForEWC: async (id: string | undefined): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.delete(
      DIGITALWALLETEWCENDPOINTS.deleteCredentialForEWC(id),
      config
    );
  },
  deleteVerificationForEWC: async (id: string | undefined): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.delete(
      DIGITALWALLETEWCENDPOINTS.deleteVerificationForEWC(id),
      config
    );
  },
  receiveCredentialForEWC: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .post(
        DIGITALWALLETEWCENDPOINTS.receiveCredentialForEWC(),
        payload,
        config
      )
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  receiveCredentialWithUserPinForEWC: async (
    payload: any,
    id: any
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .put(
        DIGITALWALLETEWCENDPOINTS.receiveCredentialWithUserPinForEWC(id),
        payload,
        config
      )
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  receiveDefferedCredential: async (id: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .put(DIGITALWALLETEWCENDPOINTS.receiveDefferedCredential(id), "", config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  readCredentialForEWC: async (id: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(DIGITALWALLETEWCENDPOINTS.readCredentialForEWC(id), config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  receiveVerificationForEWC: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .post(
        DIGITALWALLETEWCENDPOINTS.receiveVerificationForEWC(),
        payload,
        config
      )
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  filterVerificationForEWC: async (id: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .post(DIGITALWALLETEWCENDPOINTS.filterVerificationForEWC(id), "", config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  sendVerifications: async (payload: any, id: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .post(DIGITALWALLETEWCENDPOINTS.sendVerifications(id), payload, config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  getStatistics: async (): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(DIGITALWALLETEWCENDPOINTS.getStatistics(), config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
};
