import { Typography, Button, Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { styled } from "@mui/material/styles";

import { useOnBoardingStore } from "../../store/onBoardingStore";

import { OnBoardingHttpService } from "../../service/onboardingService";
import { HttpService } from "../../service/HTTPService";
import { LocalStorageService } from "../../service/localStorageService";
import {
  AddOrgPayload,
  RegisterAdminPayload,
  addBillingAddressPayload,
  addBillingSubscribePlanpayload,
  addBillingpaymentSourcePayload,
} from "../../utils/serviceAgreementUtils";
import { useNavigate } from "react-router-dom";
import LoaderComponent from "../LoaderComponent";
import "../../index.css";
const buttonStyle = {
  height: 30,
  width: 150,
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  marginTop: "20px",
  justifyContent: "space-between",
}));

interface Props {
  setError: Dispatch<SetStateAction<string>>;
  setOpenSnackBar: Dispatch<SetStateAction<boolean>>;
  handleBack: any;
}

const ServiceAgreement = (props: Props) => {
  const { t } = useTranslation("translation");
  const { setError, setOpenSnackBar, handleBack } = props;
  const [urlSource, setUrlSource] = useState<any>();
  const [openLoader, setOpenLoader] = useState(false);
  const [serviceAgreementResponse, setServiceAgreementResponse] =
    useState<any>();

  const clearOnboardingStore = useOnBoardingStore((state) => state.clearStore);

  const navigate = useNavigate();
  const onbordingStore = useOnBoardingStore((state) => state);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeHeight, setIframeHeight] = useState<number>(200);

 const handleResizeIframe = useCallback(() => {
  if (iframeRef.current && iframeRef.current.contentWindow?.document.body) {
    const headerElement = iframeRef.current.contentDocument?.querySelector(
      ".iframe-header"
    ) as HTMLElement | null;
    const headerHeight = headerElement ? headerElement.offsetHeight : 0;
    const newHeight = iframeRef.current.contentWindow.document.body.scrollHeight + headerHeight || 200;
    
    // Resetting height to a slightly smaller value before reducing it again
    iframeRef.current.style.height = `${newHeight}px`;
    setIframeHeight(newHeight);
  }
}, []);


  useEffect(() => {
    handleResizeIframe(); // Initial resize
    window.addEventListener("resize", handleResizeIframe);
    return () => {
      window.removeEventListener("resize", handleResizeIframe);
    };
  }, [handleResizeIframe]);

  useEffect(() => {
    OnBoardingHttpService.getServiceAgreement().then((res) => {
      let urlSource = `${res.data.serviceAgreementInfo.url}?embedded=true`;
      setUrlSource(urlSource);
      setServiceAgreementResponse(res.data);
    });
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (onbordingStore.submitPageAuthorization) {
      setOpenLoader(true);

      OnBoardingHttpService.registerOrganisationAdmin(
        RegisterAdminPayload(onbordingStore)
      )
        .then(() => {
          HttpService.login(
            onbordingStore.orgAdminInformation.orgAdminEmail,
            onbordingStore.orgAdminInformation.orgPassword
          )
            .then((res) => {
              const token = res.data;
              LocalStorageService.updateToken(token);

              OnBoardingHttpService.addOrganisation(
                AddOrgPayload(onbordingStore)
              )
                .then(() => {
                  OnBoardingHttpService.registerBilling()
                    .then((res) => {
                      OnBoardingHttpService.addBillingAddress(
                        addBillingAddressPayload(onbordingStore)
                      )
                        .then(() => {
                          OnBoardingHttpService.addBillingPaymentSource(
                            addBillingpaymentSourcePayload(onbordingStore)
                          )
                            .then((res) => {
                              let planId =
                                onbordingStore.selectedPackage.packageNumber ===
                                1
                                  ? "starter"
                                  : "freetrial";
                              OnBoardingHttpService.addBillingSubscribePlan(
                                addBillingSubscribePlanpayload(
                                  serviceAgreementResponse
                                ),
                                planId
                              )
                                .then(() => {
                                  HttpService.getOrganisationAdminDetails().then(
                                    (res) => {
                                      LocalStorageService.updateUser(res.data);
                                      HttpService.getAdminAvatarImage().then(
                                        (imageBase64) => {
                                          LocalStorageService.updateProfilePic(
                                            imageBase64
                                          );
                                          navigate("/start");
                                          clearOnboardingStore();
                                          setOpenLoader(false);
                                        }
                                      );
                                    }
                                  );
                                })
                                .catch((error) => {
                                  setOpenLoader(false);
                                  setOpenSnackBar(true);
                                  setError(
                                    error.response.data.errorDescription
                                  );
                                });
                            })
                            .catch((error) => {
                              setOpenLoader(false);
                              setOpenSnackBar(true);
                              setError(error.response.data.errorDescription);
                            });
                        })
                        .catch((error) => {
                          setOpenLoader(false);
                          setOpenSnackBar(true);
                          setError(error.response.data.errorDescription);
                        });
                    })
                    .catch((error) => {
                      setOpenLoader(false);
                      setOpenSnackBar(true);
                      setError(error.response.data.errorDescription);
                    });
                })
                .catch((error) => {
                  setOpenLoader(false);
                  setOpenSnackBar(true);
                  setError(error.response.data.errorDescription);
                });
            })
            .catch((error) => {
              setOpenLoader(false);
              setOpenSnackBar(true);
              setError(
                error.response.data.errorDescription
                  ? error.response.data.errorDescription
                  : error.response.data.Message
              );
            });
        })
        .catch((error) => {
          setOpenLoader(false);
          setOpenSnackBar(true);
          setError(error.response.data.Message);
        });
    } else {
      setOpenSnackBar(true);
      setError(t("onboarding.pleaseAgreeServicesAgreement"));
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <LoaderComponent openLoader={openLoader} />
      <Typography variant="h6" color={"black"} fontWeight="bold">
        {t("onboarding.serviceAgreement")}
      </Typography>
      <Typography variant="subtitle2" mt={2} mb={1}>
        {t("onboarding.serviceAgreementDescription")}:
      </Typography>

      {urlSource && (
        <div
          id="target"
          style={{ width: "100%", minHeight: `${iframeHeight}px` }}
        >
          <iframe
            ref={iframeRef}
            src={urlSource}
            style={{ width: "100%", height: "100%", border: "none" }}
          ></iframe>
        </div>
      )}

      <FormControlLabel
        control={
          <Checkbox
            checked={onbordingStore.submitPageAuthorization}
            onChange={(e) =>
              useOnBoardingStore
                .getState()
                .updateSubmitPageAuthorization(e.target.checked)
            }
          />
        }
        label={
          <Typography variant="subtitle2">
            {t("onboarding.serviceAgreementConfimrText")}
          </Typography>
        }
      />

      <ButtonContainer>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={() => handleBack()}
        >
          <Typography variant="subtitle2">{t("onboarding.prev")}</Typography>
        </Button>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            color: "black",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          type="submit"
          endIcon={<KeyboardArrowRightIcon />}
        >
          <Typography variant="subtitle2">{t("onboarding.submit")}</Typography>
        </Button>
      </ButtonContainer>
    </form>
  );
};

export default ServiceAgreement;
