/* eslint-disable no-prototype-builtins */
export function generateJsonFromSchema(schema: any): any {
  const instance: any = {};

  if (schema && schema.properties) {
    Object.entries(schema.properties).forEach(([key, value]: [string, any]) => {
      instance[key] = generateValue(value);
    });
  }
  return instance;
}

function generateValue(schema: any): any {
  if (schema.type === "object") {
    const obj: any = {};
    Object.entries(schema.properties).forEach(([key, value]: [string, any]) => {
      obj[key] = generateValue(value);
    });
    return obj;
  } else if (schema.type === "array") {
    if (schema.items.type === "object") {
      // Handle array of objects
      const arr: any[] = [];
      for (let i = 0; i < 2; i++) {
        // Change 2 to the desired array length
        arr.push(generateValue(schema.items));
      }
      return arr;
    } else {
      // Generate array of primitive types
      const arr: any[] = [];
      for (let i = 0; i < 2; i++) {
        // Change 2 to the desired array length
        arr.push(generatePrimitive(schema.items));
      }
      return arr;
    }
  } else {
    return generatePrimitive(schema);
  }
}

function generatePrimitive(schema: any): any {
  switch (schema.type) {
    case "string":
      return "";
    case "number":
      return 0;
    case "integer":
      return 0;
    case "boolean":
      return true;
    default:
      return null;
  }
}

export function validateJson(jsonString: any) {
  try {
    const jsonObject = JSON.parse(jsonString);
    return validateObject(jsonObject);
  } catch (e) {
    return false;
  }
}

function validateObject(obj: any) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (typeof value === "object" && value !== null) {
        if (!validateObject(value)) return false;
      } else if (
        value === "" ||
        value === undefined ||
        value === null ||
        value === "$.credentialSubject."
      ) {
        return false;
      }
    }
  }
  return true;
}

export function removeLimitDisclosureFromSchema(
  schema: any,
  keyToRemove: string
): any {
  const removeKey = (obj: any) => {
    if (obj && typeof obj === "object") {
      if (obj.hasOwnProperty(keyToRemove)) {
        delete obj[keyToRemove];
      }
      Object.keys(obj).forEach((key) => {
        if (obj[key] && typeof obj[key] === "object") {
          removeKey(obj[key]);
        }
      });
    }
  };

  let clonedSchema;
  try {
    clonedSchema = JSON.parse(JSON.stringify(schema));
  } catch (error) {
    return {};
  }

  removeKey(clonedSchema);
  return clonedSchema;
}
