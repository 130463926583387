import { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  Form,
  useRefresh,
  useRecordContext,
} from "react-admin";

import { Box, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import BreadCrumb from "../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { TableEmptyMessage } from "../../components/tableEmptyMessage";
import useLanguageChange from "../../utils/translateTableLanguage";

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import ContentPasteGoOutlinedIcon from "@mui/icons-material/ContentPasteGoOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import ViewDDA from "../../components/modals/viewDDAModal";
import { downloadDAAsJson } from "../../utils/downloadDAasJson";
import GeneralModal from "../../components/modals/generalModal";
import { getLawfulBasisOfProcessing } from "../../interfaces/DataAgreement";
import VersionDropdown from "../../components/dda/versionDropdown";
import SignDDAModal from "../../components/modals/signDDAModal";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const SignedAgreements = () => {
  const [openViewExchangeRecordModal, setOpenViewExchangeRecordModal] =
    useState(false);
  const [
    selectededDataAgreementFromDataAgreement,
    setSelectededDataAgreementFromDataAgreement,
  ] = useState<any>();
  const [openPullRecordModal, setOpenPullRecordModal] = useState(false);
  const [openSignedDDAModal, setOpenSignedDDAModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { t } = useTranslation("translation");
  const key = useLanguageChange();

  const refresh = useRefresh();

  const onRefetch = () => {
    refresh();
  };

  const LawfulBasisField = (props: any) => {
    const record = useRecordContext(props);

    if (!record || !props.source) {
      return null;
    }

    return (
      <Typography variant="body2">
        {getLawfulBasisOfProcessing(
          record.data_disclosure_agreement.lawfulBasis
        )}
      </Typography>
    );
  };

  const VersionField = (props: any) => {
    const record = useRecordContext(props);

    if (!record || !props.source) {
      return null;
    }

    return <VersionDropdown record={record} />;
  };

  const IconsFIeld = (props: any) => {
    const record = useRecordContext(props);

    if (!record || !props.source) {
      return null;
    }

    return (
      record[props.source] && (
        <Box display={"flex"} justifyContent={"space-around"}>
          <Tooltip title={t("signedAgreements.viewDDA")} placement="top">
            <RemoveRedEyeOutlinedIcon
              onClick={() => {
                setOpenViewExchangeRecordModal(true);
                setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>

          <Tooltip title={t("signedAgreements.downloadDDA")} placement="top">
            <CloudDownloadOutlinedIcon
              onClick={() => {
                downloadDAAsJson(record.data_disclosure_agreement, "");
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>

          <Tooltip
            title={t("signedAgreements.pullDataHistory")}
            placement="top"
          >
            <HistoryOutlinedIcon
              // onClick={() => {
              // navigate("/pullDataHistory");
              // useSSIStore
              //   .getState()
              //   .updateDataAgreementForIssuanceHistory(record)
              // }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>

          <Tooltip title={t("signedAgreements.pullDataFromDS")} placement="top">
            {/* write condition to check pull data is for not own dda */}
            <ContentPasteGoOutlinedIcon
              onClick={() => {
                setOpenPullRecordModal(true),
                  setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>

          <Tooltip
            title={t("signedAgreements.deleteSignedDda")}
            placement="top"
          >
            {/* write condition to check pull data is for not own dda */}
            <DeleteOutlineOutlinedIcon
              onClick={() => {
                setOpenDeleteModal(true),
                  setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>
        </Box>
      )
    );
  };

  return (
    <Container>
      <Form>
        <BreadCrumb Link={t("sidebar.signedAgreements")} />

        <HeaderContainer>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              {t("sidebar.signedAgreements")}
            </Typography>
            <Tooltip
              title={t("signedAgreements.signDataDisclosureAgreement")}
              placement="top"
            >
              <EditOutlinedIcon
                onClick={() => {
                  setOpenSignedDDAModal(true);
                }}
                style={{
                  cursor: "pointer",
                  marginLeft: "7px",
                }}
              />
            </Tooltip>
          </Box>
        </HeaderContainer>
        <Typography variant="body2" mt={1.25}>
          {t("signedAgreements.pageDescription")}
        </Typography>
      </Form>

      <List
        actions={false}
        sx={{ width: "100%", overflow: "hidden" }}
        empty={<TableEmptyMessage />}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              width: "100%",
              "& .RaDatagrid-rowCell:last-child": {
                width: { md: "30%", lg: "19%" },
              },
            }}
            key={key}
          >
            <TextField
              source="data_disclosure_agreement.purpose"
              label={t("dataAgreements.usagePurpose")}
              sortable={false}
            />
            <VersionField
              source="id"
              label={t("dataAgreements.version")}
              sortable={false}
              cellClassName="custom-version-column"
            />
            <TextField
              source="data_disclosure_agreement.dataSharingRestrictions.industrySector"
              label={t("gettingStarted.sector")}
              sortable={false}
            />
            <TextField
              source="data_disclosure_agreement.dataSharingRestrictions.policyUrl"
              label={t("common.policyUrl")}
              sortable={false}
            />
            <LawfulBasisField
              source="data_disclosure_agreement.lawfulBasis"
              label={t("dataAgreements.lawfulBasisOfProcessing")}
              sortable={false}
            />

            <IconsFIeld source="template_id" sortable={false} label={""} />
          </Datagrid>
        </Box>
      </List>

      {/* Modals */}

      <ViewDDA
        open={openViewExchangeRecordModal}
        setOpen={setOpenViewExchangeRecordModal}
        headerText={t("signedAgreements.viewDDA")}
        selectedData={selectededDataAgreementFromDataAgreement}
      />

      <GeneralModal
        open={openPullRecordModal}
        setOpen={setOpenPullRecordModal}
        headerText={`${t("signedAgreements.pullData")}:`}
        confirmText="PULL"
        confirmButtonText={`${t("signedAgreements.pull")}`}
        resourceName="signedDda"
        onRefetch={onRefetch}
        selectededDataAgreementFromDataAgreement={
          selectededDataAgreementFromDataAgreement
        }
        modalDescriptionText={
          <Typography variant="body1">
            {t("signedAgreements.pullModalDescription1")}
            <b>PULL</b>
            {t("signedAgreements.pullModalDescription2")}
          </Typography>
        }
      />

      <GeneralModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        headerText={`${t("signedAgreements.deleteSignedDda")}:`}
        confirmText="DELETE"
        confirmButtonText={`${t("common.delete")}`}
        resourceName="signedDda"
        onRefetch={onRefetch}
        selectededDataAgreementFromDataAgreement={
          selectededDataAgreementFromDataAgreement
        }
        modalDescriptionText={
          <Typography variant="body1">
            {t("signedAgreements.deleteDescription1")}
            <b>DELETE</b>
            {t("signedAgreements.deleteDescription2")}
          </Typography>
        }
      />

      <SignDDAModal
        open={openSignedDDAModal}
        setOpen={setOpenSignedDDAModal}
        headerText={t("signedAgreements.signDataDisclosureAgreement")}
        onRefetch={onRefetch}
      />
    </Container>
  );
};

export default SignedAgreements;
