import { Fragment, useState } from "react";

import { Box, Typography } from "@mui/material";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

import { DataAttribute } from "./DataAttribute";
import { useTranslation } from "react-i18next";
import RestrictionModal from "../modals/restrictionModal";
import { useWatch } from "react-hook-form";

interface Props {
  mode: string;
  append?: any;
  fields?: any;
  remove?: any;
  formController?: any;
  methods?: any;
  subTextFromSchema?: string;
}

const DataAgreementPersonalDataTable = (props: Props) => {
  const { t } = useTranslation("translation");
  const {
    mode,
    subTextFromSchema,
    append,
    fields,
    remove,
    formController,
    methods,
  } = props;
  const [openRestrictionModal, setOpenRestrictionModal] = useState(false);
  const { register } = formController;
  const isExistingSchema = useWatch({
    control: methods.control,
    name: `isExistingSchema`,
  });

  const AttributeType = useWatch({
    control: methods.control,
    name: `AttributeType`,
  });

  const addDataAttributeField = () => {
    append({
      attributeName: "",
      attributeDescription: "",
      schemaId: "",
      credDefId: "",
    });
  };

  return (
    <Box style={{ marginBottom: "4rem" }}>
      <Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <Typography variant="subtitle1">
            {t("dataAgreements.dataAttributes")}
            <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
          </Typography>
          <AddCircleOutlineOutlinedIcon
            type="submit"
            onClick={mode === "Read" ||  (AttributeType === "data_source" &&
            isExistingSchema === true) ? () => {} : () => addDataAttributeField()}
            style={{
              cursor: mode === "Read" || (AttributeType === "data_source" &&
              isExistingSchema === true)  ? "not-allowed" : "pointer",
              marginLeft: "5px",
            }}
          />
        </Box>
        <Typography variant="body2" style={{ marginBottom: "15px" }}>
          {subTextFromSchema}
        </Typography>
      </Box>
      {fields?.map((item: any, index: number) => {
        return (
          <Fragment key={item.id}>
            <DataAttribute
              index={index}
              mode={mode}
              remove={remove}
              formController={formController}
              fields={fields}
              addDataAttributeField={addDataAttributeField}
              setOpenRestrictionModal={setOpenRestrictionModal}
              methods={methods}
            />
          </Fragment>
        );
      })}

      <RestrictionModal
        openRestrictionModal={openRestrictionModal}
        setOpenRestrictionModal={setOpenRestrictionModal}
        mode={props.mode}
        fields={props.fields}
        register={register}
        remove={remove}
        formController={formController}
        addDataAttributeField={addDataAttributeField}
        methods={methods}
      />
    </Box>
  );
};

export default DataAgreementPersonalDataTable;
