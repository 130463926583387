import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useContext } from "react";
import { buttonStyle, disabledButtonstyle } from "../modals/modalStyle";
import { HttpService } from "../../service/HTTPService";
import {
  DataAgreementPayload,
  validateDataAttributes,
} from "./DataAgreementActions";
import { OrganizationDetailsCRUDContext } from "../../contexts/organizationDetailsCrud";

interface Props {
  mode: string;
  methods: any;
  isFormDataChanged: any;
  successCallback: any;
  defaultValue: any;
  setSelectedDropdownValue: Dispatch<SetStateAction<any>>;
  setImportedDataAgreement: Dispatch<SetStateAction<any>>;
  setOpen: Dispatch<SetStateAction<any>>;
  setError: Dispatch<SetStateAction<any>>;
  setOpenSnackBar: Dispatch<SetStateAction<any>>;
  selectedDataAgreement: any;
  setOpenLoader: Dispatch<SetStateAction<boolean>>;
}
const DataAgreementPublishButton = (props: Props) => {
  const {
    mode,
    methods,
    isFormDataChanged,
    setOpen,
    successCallback,
    defaultValue,
    setSelectedDropdownValue,
    setImportedDataAgreement,
    setError,
    setOpenSnackBar,
    selectedDataAgreement,
    setOpenLoader,
  } = props;

  const { t } = useTranslation("translation");
  const { organisationDetails } = useContext(OrganizationDetailsCRUDContext);

  const onPublish = (createdData: any) => {
    if (validateDataAttributes(createdData) && mode !== "Read") {
      if (mode === "Create") {
        setOpenLoader(true);
        let active = true;
        let lifecycle = "complete";
        HttpService.addDataAgreements(
          DataAgreementPayload(
            createdData,
            active,
            lifecycle,
            mode,
            organisationDetails
          )
        )
          .then(() => {
            setOpenLoader(false);
            successCallback();
            methods.reset({ ...defaultValue });
            setSelectedDropdownValue({});
            setImportedDataAgreement(null);
            setOpen(false);
          })
          .catch((error) => {
            console.log("ERROR publish da create");

            setOpenLoader(false);
            let errorDescription = error?.response?.data?.errorDescription;
            setError(
              errorDescription === "Data agreement purpose exists"
                ? t("dataAgreements.purposeExist")
                : errorDescription
            );
            setOpenSnackBar(true);
          });
      } else if (
        mode === "Update" &&
        (selectedDataAgreement && selectedDataAgreement.lifecycle === "draft"
          ? selectedDataAgreement.lifecycle === "draft"
          : isFormDataChanged(methods.formState))
      ) {
        setOpenLoader(true);
        let active = true;
        let lifecycle = "complete";
        HttpService.updateDataAgreementById(
          DataAgreementPayload(
            createdData,
            active,
            lifecycle,
            mode,
            organisationDetails,
            selectedDataAgreement
          ),
          selectedDataAgreement?.id
        )
          .then(() => {
            setOpenLoader(false);
            successCallback();
            setSelectedDropdownValue({});
            setImportedDataAgreement({});
            methods.reset({ ...defaultValue });
            setOpen(false);
          })
          .catch(() => {
            setOpenLoader(false);
            setError(t("common.commonErrorMessage"));
            setOpenSnackBar(true);
          });
      } else return {};
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={methods.handleSubmit(onPublish)}
        style={
          methods.formState.isValid &&
          mode !== "Read" &&
          (selectedDataAgreement && selectedDataAgreement.lifecycle === "draft"
            ? selectedDataAgreement.lifecycle === "draft"
            : isFormDataChanged(methods.formState))
            ? buttonStyle
            : disabledButtonstyle
        }
        sx={{
          cursor:
            methods.formState.isValid &&
            mode !== "Read" &&
            (selectedDataAgreement &&
            selectedDataAgreement.lifecycle === "draft"
              ? selectedDataAgreement.lifecycle === "draft"
              : isFormDataChanged(methods.formState))
              ? "pointer"
              : "not-allowed",
          color:
            methods.formState.isValid &&
            mode !== "Read" &&
            (selectedDataAgreement &&
            selectedDataAgreement.lifecycle === "draft"
              ? selectedDataAgreement.lifecycle === "draft"
              : isFormDataChanged(methods.formState))
              ? "black"
              : "#6D7676",
          "&:hover": {
            backgroundColor: "black",
            color: "white",
          },
          marginLeft: "15px",
        }}
      >
        {t("dataAgreements.publish")}{" "}
      </Button>
    </>
  );
};

export default DataAgreementPublishButton;
