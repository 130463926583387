import PresentationDefinitionIDDropDown from "../dropdowns/presentationDefinitionIDDropdown";
import ReusableCheckbox from "../checkboxes/reusableCheckbox";
import { Typography, Box } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const DynamicCredentialRequestComponent = ({
  control,
  setValue,
  grantTypeDropdownWatch,
}: any) => {
  const { t } = useTranslation("translation");

  const dynamicCredentialRequestValue = useWatch({
    control: control,
    name: `dynamicCredentialRequest`,
  });

  useEffect(() => {
    if (dynamicCredentialRequestValue === false) {
      setValue("presentationDefinitionId", "");
    }
  }, [dynamicCredentialRequestValue]);

  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle1">
          {t("credentials.dynamicCredential")}
        </Typography>

        <ReusableCheckbox
          control={control}
          name="dynamicCredentialRequest"
          checkBoxlabel=""
          disabledValue={grantTypeDropdownWatch === "pre-authorised" && true}
        />
      </Box>

      <PresentationDefinitionIDDropDown
        control={control}
        dynamicCredentialRequestValue={dynamicCredentialRequestValue}
      />
    </>
  );
};

export default DynamicCredentialRequestComponent;
