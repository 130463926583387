import * as React from "react";
import { useState, useEffect } from "react";
import { Dispatch, SetStateAction } from "react";
import { Drawer, Typography, Box, Tooltip } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Container, HeaderContainer, DetailsContainer } from "./modalStyle";
import { useTranslation } from "react-i18next";
import DataSchemaTemplateContainer from "../dataAgreements/DataSchemaTemplateContainer";
import { useWatch } from "react-hook-form";
import ExistingSchemaIDContainer from "../dataAgreements/ExistingSchemaIdContainer";
import LoaderComponent from "../LoaderComponent";
import { HttpServiceForDigitalWallet } from "../../service/digitalWalletHttpService";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  mode: string;
  append: any;
  fields: any;
  remove: any;
  methods: any;
  formController: any;
  insert: any;
  setValue: any;
  selectededDataAgreementFromDataAgreement: any
}

export default function DataSchemaModal(props: Props) {
  const {
    open,
    setOpen,
    mode,
    append,
    fields,
    remove,
    methods,
    formController,
    insert,
    setValue,
    selectededDataAgreementFromDataAgreement
  } = props;

  const { t } = useTranslation("translation");
  const [openLoader, setOpenLoader] = useState(false);
  const [disableBackButton, setDisableBackButton] = useState(false);
  const [tooltipMesage, setToolTipMessage] = useState("");

  const schemaId = useWatch({
    control: methods.control,
    name: `schemaId`,
  });

  const isExistingSchemaForInput = useWatch({
    control: methods.control,
    name: `isExistingSchemaForInput`,
  });

  const dataAttributes = useWatch({
    control: methods.control,
    name: `dataAttributes`,
  });



  const [searchValue, setSearchValue] = useState(
    isExistingSchemaForInput === true ? schemaId : ""
  );


  useEffect(() => {
    // this useEffect is used to check weather data attribute is changed 
    const dataAttributefromDA = selectededDataAgreementFromDataAgreement?.dataAttributes?.map((attribute: any) => {
      const { name, description, restrictions, ...otherProps } = attribute;
      return {
        attributeName: name,
        attributeDescription: description,
        schemaId: "",
        credDefId: "",
        ...otherProps,
      };
    });

    // Deep comparison of arrays using JSON.stringify
    if (mode === "Update" && isExistingSchemaForInput === true && JSON.stringify(dataAttributes) !== JSON.stringify(dataAttributefromDA)) {
      setValue("isExistingSchemaForInput", false);
      setSearchValue("");
    }
  }, [dataAttributes]);
  
  

  const [existingSchemaValue, setExistingSchemaValue] = useState<any>();

  useEffect(() => {
    if (schemaId !== "" && schemaId !== searchValue && searchValue.length > 0) {
      setDisableBackButton(true);
      setToolTipMessage("Please provide a valid schema id");
    }
    if (searchValue?.length === 0) {
      setDisableBackButton(false);
      setToolTipMessage("");
      setValue("schemaId", "");
      setValue("isExistingSchema", false);
      setExistingSchemaValue(null);
    }
    if (schemaId === searchValue) {
      setDisableBackButton(false);
      setToolTipMessage("");
    }
  }, [searchValue]);

  const AttributeType = useWatch({
    control: methods.control,
    name: `AttributeType`,
  });

  const handleCloseModal = () => {
    if (disableBackButton === false) {
      setOpen(false);
    } else {
      setOpenLoader(true);
      HttpServiceForDigitalWallet.getSchemaValuesByExistingId(searchValue)
        .then((data: any) => {
          setOpenLoader(false);
          // append value to form
          data.data.schema.attrNames?.forEach((attrName: string) => {
            remove(-1);
            append({
              attributeName: attrName,
              attributeDescription: "",
              schemaId: "",
              credDefId: "",
            });
          });
          setExistingSchemaValue(data.data.schema);
          setValue("schemaId", data.data.schema.id);
          setValue("isExistingSchema", true);
          setOpen(false);
          setDisableBackButton(false);
          setToolTipMessage("");
        })
        .catch(() => {
          setOpenLoader(false);
          setValue("schemaId", "");
          setValue("isExistingSchema", false);
          setExistingSchemaValue(null);
          setToolTipMessage("Please provide a valid schema id");
        });
    }
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container sx={{ width: "510px" }}>
          <HeaderContainer>
            <Box pl={2} style={{ display: "flex", alignItems: "center" }}>
              <Tooltip
                title={
                  tooltipMesage !== "" &&
                  t("dataAgreements.provideAValidSchemaId")
                }
                placement="top"
              >
                <ChevronLeftIcon
                  onClick={handleCloseModal}
                  sx={{
                    marginRight: 1,
                    cursor: tooltipMesage ? "not-allowed" : "pointer",
                    color: "#F3F3F6",
                  }}
                />
              </Tooltip>
              <Typography color="#F3F3F6">
                {AttributeType === "data_using_service" ? (
                  <>
                    {t("dataAgreements.chooseExistingSchemas")} :{" "}
                    {t("dataAgreements.DUSVerifier")}
                  </>
                ) : (
                  <>
                    {t("dataAgreements.chooseExistingSchemas")} :{" "}
                    {t("dataAgreements.DSIssuer")}
                  </>
                )}
              </Typography>
            </Box>
          </HeaderContainer>
          <DetailsContainer style={{ width: "100%" }}>
            {AttributeType === "data_using_service" ? (
              <DataSchemaTemplateContainer
                mode={mode}
                append={append}
                fields={fields}
                remove={remove}
                methods={methods}
                formController={formController}
                insert={insert}
              />
            ) : (
              <ExistingSchemaIDContainer
                append={append}
                insert={insert}
                fields={fields}
                setOpenLoader={setOpenLoader}
                remove={remove}
                setValue={setValue}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                existingSchemaValue={existingSchemaValue}
                setExistingSchemaValue={setExistingSchemaValue}
                setToolTipMessage={setToolTipMessage}
                setDisableBackButton={setDisableBackButton}
              />
            )}
          </DetailsContainer>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
