import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CommonButton from "../buttons/commonButton";
import { Box } from "@mui/system";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";
import { useNavigate } from "react-router-dom";

const BillingAddress = () => {
  const { t } = useTranslation("translation");
  const navigate = useNavigate()
  const billingAddress = useDigitalWalletStore(
    (state) => state.readExistingBillingDetails?.billingInfo?.address
  );

  return (
    <>
      <Typography color="black" variant="subtitle1" fontWeight="bold" mb={0.5}>
        {t("onboarding.billingAddress")}
      </Typography>

      {billingAddress?.name === "" && (
        <Typography
          variant="subtitle2"
          color={"black"}
          mt={1}
          mb={billingAddress?.name !== "" ? 1 : 0.5}
        >
          {t("billing.pleaseAddABillingAddress")}
        </Typography>
      )}
      {billingAddress?.name !== "" && (
        <Typography variant="subtitle2" mb={0.1}>
          <span style={{ fontWeight: "bold" }}>
            {t("onboarding.billingAdmin")}:
          </span>{" "}
          <span>{billingAddress?.name}</span>
        </Typography>
      )}

      <Box
        style={{
          width: "100%",
          marginLeft: "auto",
          display: "flex",
          justifyContent:
            billingAddress?.name !== "" ? "space-between" : "flex-end",
          height: billingAddress?.name !== "" ? "52px" : ""
        }}
      >
        {billingAddress?.name !== "" && (
          <Box display={"grid"} alignContent="flex-start">
            <Typography variant="caption">{billingAddress?.line1}</Typography>
            <Typography variant="caption">{billingAddress?.line2}</Typography>
            <Typography variant="caption">
              <span>{billingAddress?.city}</span>,{" "}
              <span>{billingAddress?.state}</span>,{" "}
              <span>{billingAddress?.postalCode}</span>
            </Typography>
          </Box>
        )}
        <Box display={"grid"} alignContent="end">
          <CommonButton
            buttonName={
              billingAddress?.name !== "" ? t("billing.EDIT") : t("billing.add")
            }
            width={120}
            style={{ marginTop: "auto" }}
            handleClick={()=>navigate('/billingAddress')}
          />
        </Box>
      </Box>
    </>
  );
};

export default BillingAddress;
