import i18n from "i18next";
import { formatISODateToLocalString } from "../utils/formatISODateToLocalString";
import { formatDate, formatMoney } from "../utils/invoiceUtils";
import { capitalizeFirstLetter } from "../utils/capitaliseFIrstLetter";
import moment from "moment";

export const getOptinValues = (optIn: boolean) => {
  if (optIn === true) {
    return "Opt-in";
  } else {
    return "Opt-out";
  }
};

export const getLawfulBasisOfProcessing = (
  LawfulBasisOfProcessing: string,
  t: (key: string) => string
) => {
  if (LawfulBasisOfProcessing === "consent") {
    return t("dataAgreements.consent");
  } else if (LawfulBasisOfProcessing === "contract") {
    return t("dataAgreements.contract");
  } else if (LawfulBasisOfProcessing === "legal_obligation") {
    return t("dataAgreements.legalObligation");
  } else if (LawfulBasisOfProcessing === "vital_interest") {
    return t("dataAgreements.vitalInterest");
  } else if (LawfulBasisOfProcessing === "public_task") {
    return t("dataAgreements.publicTask");
  } else if (LawfulBasisOfProcessing === "legitimate_interest") {
    return t("dataAgreements.legitimateInterest");
  } else null;
};

export const getStateForCredentialHistory = (
  state: string,
  t: (key: string) => string
) => {
  if (state === "proposal_sent") {
    return t("issuanceHistory.proposalSent");
  } else if (state === "proposal_received") {
    return t("issuanceHistory.proposalReceived");
  } else if (state === "offer_sent") {
    return t("issuanceHistory.offerSent");
  } else if (state === "offer_received") {
    return t("issuanceHistory.offerReceived");
  } else if (state === "request_sent") {
    return t("issuanceHistory.requestSent");
  } else if (state === "request_received") {
    return t("issuanceHistory.requestReceived");
  } else if (state === "credential_issued") {
    return t("issuanceHistory.credentialIssued");
  } else if (state === "credential_received") {
    return t("issuanceHistory.credentialReceived");
  } else if (state === "credential_acked") {
    return t("issuanceHistory.credentialAcked");
  } else return null;
};

export const getStateForCredentialHistoryForEWC = (
  state: string,
  t: (key: string) => string
) => {
  if (state === "offer_sent") {
    return t("issuanceHistory.offerSent");
  } else if (state === "offer_received") {
    return t("issuanceHistory.offerReceived");
  } else if (state === "credential_acked") {
    return t("issuanceHistory.credentialAcked");
  } else if (state === "credential_issued") {
    return t("issuanceHistory.credentialReceived");
  }else if (state === "credential_pending") {
    return t("holder.credential_pending");
  } else return state;
};

export const getStateForVerificationForEWC = (
  state: string,
  t: (key: string) => string
) => {
  if (state === "request_sent") {
    return t("issuanceHistory.requestSent");
  } else if (state === "offer_received") {
    return t("issuanceHistory.requestReceived");
  } else if (state === "credential_acked") {
    return t("issuanceHistory.credentialAcked");
  } else if (state === "presentation_acked") {
    return t("verificationsHistory.presentationAcked");
  } else if (state === "request_received") {
    return t("issuanceHistory.requestReceived");
  } else if (state === "presentation_pending") {
    return t("holder.presentation_pending");
  } else return state;
};

export const convertConsentRecordsForClient = (consentRecords: any) => {
  const consentRecordsConvertedDataForClientPurpose =
    consentRecords.consentRecords.map((consentRecords: any, index: number) => {
      const { id, optIn, timestamp, dataAgreement, ...otherProps } =
        consentRecords;
      return {
        id: index,
        consentRecordId: id,
        optIn: getOptinValues(optIn),
        timestamp: formatISODateToLocalString(timestamp),
        dataAgreement: {
          lawfulBasis: getLawfulBasisOfProcessing(
            dataAgreement.lawfulBasis,
            i18n.t
          ),
          purpose: dataAgreement.purpose,
          version: dataAgreement.version,
        },
        ...otherProps,
      };
    });
  const convertedConsentRecords = {
    consentRecords: consentRecordsConvertedDataForClientPurpose,
    pagination: consentRecords.pagination,
  };

  return convertedConsentRecords;
};

export const convertViewLogsForClient = (viewLogs: any) => {
  const viewLogsConvertedDataForClientPurpose = viewLogs.logs.map(
    (viewLogs: any) => {
      const { timestamp, ...otherProps } = viewLogs;
      return {
        timestamp: formatISODateToLocalString(timestamp),
        ...otherProps,
      };
    }
  );
  const convertedViewLogs = {
    logs: viewLogsConvertedDataForClientPurpose,
    pagination: viewLogs.pagination,
  };

  return convertedViewLogs;
};

export const convertConnectionsForClient = (Connections: any) => {
  const ConnectionsConvertedDataForClientPurpose = Connections.connections.map(
    (Connections: any) => {
      const { updatedAt, ...otherProps } = Connections;
      const date = new Date(updatedAt);
      const formattedDate = date.toISOString().slice(0, 16) + "Z";

      return {
        updatedAt: formatISODateToLocalString(formattedDate),
        ...otherProps,
      };
    }
  );
  const convertedConnections = {
    connections: ConnectionsConvertedDataForClientPurpose,
    pagination: Connections.pagination,
  };

  return convertedConnections;
};

export const convertIssuanceHistoryForClient = (Connections: any) => {
  const ConnectionsConvertedDataForClientPurpose =
    Connections.credentialHistory.map((Connections: any) => {
      const { updatedAt, state, ...otherProps } = Connections;
      const date = new Date(updatedAt);
      const formattedDate = date.toISOString().slice(0, 16) + "Z";

      return {
        state: getStateForCredentialHistory(state, i18n.t),
        updatedAt: formatISODateToLocalString(formattedDate),
        ...otherProps,
      };
    });
  const convertedConnections = {
    credentialHistory: ConnectionsConvertedDataForClientPurpose,
    pagination: Connections.pagination,
  };

  return convertedConnections;
};

export const convertVerificationsHistoryForClient = (verifications: any) => {
  const verficationsConvertedDataForClientPurpose =
    verifications.verificationHistory.map((verification: any) => {
      const { updatedAt, ...otherProps } = verification;
      const date = new Date(updatedAt);
      const formattedDate = date.toISOString().slice(0, 16) + "Z";

      return {
        updatedAt: formatISODateToLocalString(formattedDate),
        ...otherProps,
      };
    });

  const convertedVerifications = {
    verificationHistory: verficationsConvertedDataForClientPurpose,
    pagination: verifications.pagination,
  };

  return convertedVerifications;
};

export const convertInvoiceForClientPurpose = (invoices: any) => {
  const updatedInvoice = invoices.invoices.map(
    (invoice: any, index: number) => {
      const { invoiceDate, dueAmount, paymentStatus, ...otherProps } = invoice;
      return {
        id: index,
        dueAmount: "SEK " + formatMoney(dueAmount / 100),
        paymentStatus: capitalizeFirstLetter(paymentStatus),
        invoiceDate: formatDate(invoiceDate),
        ...otherProps,
      };
    }
  );
  return updatedInvoice;
};

export const convertUserRequestForClientPurpose = (data: any) => {
  const updatedUserRequests = data.dataRequests.map((requests: any) => {
    const { requestedDate, closedDate, ...otherProps } = requests;
    return {
      requestedDate: formatISODateToLocalString(requestedDate),
      closedDate: formatISODateToLocalString(closedDate),
      ...otherProps,
    };
  });

  const updatedUserRequestsForClient = {
    dataRequests: updatedUserRequests,
    isDataDeleteRequestOngoing: data.isDataDeleteRequestOngoing,
    isDataDownloadRequestOngoing: data.isDataDownloadRequestOngoing,
    isRequestsOngoing: data.isRequestsOngoing,
    pagination: data.pagination,
  };

  return updatedUserRequestsForClient;
};

export const convertIssuanceHistoryForEWCForClient = (data: any) => {
  const ConvertedDataForClientPurpose = data.credentialHistory.map(
    (Connections: any) => {
      const { updatedAt, status, ...otherProps } = Connections;
      const m = moment.unix(updatedAt);
      const formattedDate = m.format("MMMM Do YYYY, h:mmA");

      return {
        status: getStateForCredentialHistoryForEWC(status, i18n.t),
        updatedAt: formattedDate,
        ...otherProps,
      };
    }
  );
  const convertedConnections = {
    credentialHistory: ConvertedDataForClientPurpose,
    pagination: data.pagination,
  };

  return convertedConnections;
};

export const convertVerificationHistoryForEWCForClient = (apidata: any) => {
  const ConvertedDataForClientPurpose = apidata.verificationHistory.map(
    (data: any) => {
      const { updatedAt, status, ...otherProps } = data;
      const m = moment.unix(updatedAt);
      const formattedDate = m.format("MMMM Do YYYY, h:mmA");

      return {
        status: getStateForVerificationForEWC(status, i18n.t),
        updatedAt: formattedDate,
        ...otherProps,
      };
    }
  );
  const convertedVerifications = {
    verificationHistory: ConvertedDataForClientPurpose,
    pagination: apidata.pagination,
  };

  return convertedVerifications;
};
