import { Admin, CustomRoutes, Resource } from "react-admin";
import { MyLayout } from "./components/layout/MyLayout";
import { Login } from "./pages/login/Login";
import { dataProvider } from "./components/providers/dataProvider";
import { authProvider } from "./components/providers/authprovider";
import { theme } from "./components/theme/theme";
import { QueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import Footer from "./components/layout/PageFooter";
import { configStore } from "./store/configStore";
import { Route } from "react-router-dom";

import GettingStarted from "./pages/gettingStarted/GettingStarted";
import DataAgreements from "./pages/dataAgreements/DataAgreements";
import PersonalData from "./pages/personalData/personalData";
import UserRecords from "./pages/userRecords/userRecords";
import UserAccess from "./pages/userAccess/userAcess";
import Privacyboard from "./pages/privacyBoard/privacyBoard";
import ManageAdmin from "./pages/manageAdmin/manageAdmin";
import DeveloperAPIs from "./pages/developerAPIs/developerAPIs";
import ViewLogs from "./pages/viewLogs/viewLogs";
import Webhooks from "./pages/webhooks/webhooks";
import Connections from "./pages/connections/Connections";
import Credentials from "./pages/credentials/credentials";
import Verifications from "./pages/verifications/Verifications";
import CredentialsHistory from "./pages/credentialsHistory/CredentialsHistory";
import VerificationsHistory from "./pages/verificationsHistory/verificationsHistory";
import DataVerifierUser from "./pages/dataVerifierUser/dataVerifierUser";
import DigitalWalletBase from "./pages/digitalWalletBase/digitalWalletBase";
import Notifications from "./pages/notifications/notifications";
import BillingPage from "./pages/billing/billing";
import Onboarding from "./pages/onboarding/onboarding";
import BillingAddressPage from "./pages/billing/billingAddress";
import PaymentDetailsPage from "./pages/billing/paymentDetails";
import OrderingWizard from "./pages/billing/orderingWizard";
import SignedAgreements from "./pages/signedAgreements/signedAgreements";
import PullDataHistory from "./pages/signedAgreements/pullDataHistory";
import DataDisclosureAgreement from "./pages/dataDisclosureAgreement/dataDisclosureAgreement";
import UserRequests from "./pages/userRequests/userRequests";
import CredentialsEWC from "./pages/credentials/credentialsEWC";
import CredentialsHistoryEWC from "./pages/credentialsHistory/CredentialsHistoryEWC";

// icons
import HouseIcon from "@mui/icons-material/HouseOutlined";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import HealthAndSafetyOutlinedIcon from "@mui/icons-material/HealthAndSafetyOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DigitalWalletBaseEWC from "./pages/digitalWalletBase/digitalWalletBaseEWC";
import VerificationsHistoryEwc from "./pages/verificationsHistory/verificationsHistoryEwc";
import VerificationsEwc from "./pages/verifications/VerificationsEwc";
import Holder from "./pages/holder/holder";


function App() {
  const { t } = useTranslation("translation");
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
  const version = configStore.appVersion;
  const LayoutWithFooter = (props: any) => {
    const { children, ...rest } = props;
    return (
      <>
        <MyLayout {...rest}>{children}</MyLayout>
        <Footer version={version} />
      </>
    );
  };

  return (
    <>
      <Admin
        queryClient={queryClient}
        layout={LayoutWithFooter}
        loginPage={Login}
        dataProvider={dataProvider}
        theme={theme}
        authProvider={authProvider}
        requireAuth={true}
      >
        <Resource
          name="start"
          options={{ label: t("sidebar.gettingStarted") }}
          list={GettingStarted}
          icon={HouseIcon}
        />
        <Resource
          name="dataagreement"
          options={{ label: t("sidebar.dataAgreements") }}
          list={DataAgreements}
          icon={InsertDriveFileOutlinedIcon}
        />
        <Resource
          name="personaldata"
          options={{ label: t("sidebar.personalData") }}
          list={PersonalData}
          icon={InsertChartOutlinedOutlinedIcon}
        />
        <Resource
          name="aries-cloud-agent"
          options={{ label: t("sidebar.digitalWalletBase") }}
          list={DigitalWalletBase}
        />
        <Resource
          name="oid4vc"
          options={{ label: t("sidebar.digitalWalletBase") }}
          list={DigitalWalletBaseEWC}
        />
        <Resource
          name="connections"
          options={{ label: t("sidebar.connections") }}
          list={Connections}
        />
        <Resource
          name="credentials"
          options={{ label: t("sidebar.credentials") }}
          list={Credentials}
        />
        <Resource
          name="oid4vc-credentials"
          options={{ label: t("sidebar.credentials") }}
          list={CredentialsEWC}
        />
        <Resource
          name="verifications"
          options={{ label: t("sidebar.verifications") }}
          list={Verifications}
        />
        <Resource
          name="oid4vc-verifications"
          options={{ label: t("sidebar.verifications") }}
          list={VerificationsEwc}
        />
        <Resource
          name="configuration"
          options={{ label: t("sidebar.configuration") }}
          list={UserAccess}
        />
        <Resource
          name="consentrecords"
          options={{ label: t("sidebar.consentRecords") }}
          list={UserRecords}
        />
        <Resource
          name="dataverifierusers"
          options={{ label: t("sidebar.consentRecords") }}
          list={DataVerifierUser}
        />
        <Resource
          name="notifications"
          options={{ label: t("sidebar.notifications") }}
          list={Notifications}
        />
        <Resource
          name="privacydashboard"
          options={{ label: t("sidebar.privacyDashboard") }}
          list={Privacyboard}
          icon={HealthAndSafetyOutlinedIcon}
        />
        <Resource
          name="manageadmin"
          options={{ label: t("sidebar.manageAdmin") }}
          list={ManageAdmin}
        />
        <Resource
          name="developerapi"
          options={{ label: t("sidebar.developerAPIs") }}
          list={DeveloperAPIs}
        />
        <Resource
          name="viewlogs"
          options={{ label: t("sidebar.viewLogs") }}
          list={ViewLogs}
        />
        <Resource
          name="webhooks"
          options={{ label: t("sidebar.Webhooks") }}
          list={Webhooks}
        />
        <Resource
          name="billing"
          options={{ label: t("sidebar.billing") }}
          list={BillingPage}
        />
        <Resource
          name="userRequests"
          options={{ label: t("sidebar.userRequests") }}
          list={UserRequests}
        />

        <Resource name="issuance-history" list={CredentialsHistory} />
        <Resource name="oid4vc-issuance-history" list={CredentialsHistoryEWC} />
        <Resource name="verification-history" list={VerificationsHistory} />
        <Resource name="oid4vc-verification-history" list={VerificationsHistoryEwc} />
        <Resource name="oid4vc-holder" list={Holder} />


        <Resource name="billingAddress" list={BillingAddressPage} />
        <Resource name="paymentDetails" list={PaymentDetailsPage} />
        <Resource name="orderingwizard" list={OrderingWizard} />

        <Resource
          name="dataDisclosureAgreements"
          list={DataDisclosureAgreement}
          options={{ label: t("sidebar.dataDisclosureAgreements") }}
          icon={HealthAndSafetyOutlinedIcon}
        />

        <Resource
          name="signedAgreements"
          list={SignedAgreements}
          options={{ label: t("sidebar.signedAgreements") }}
          icon={HealthAndSafetyOutlinedIcon}
        />
        <Resource name="pullDataHistory" list={PullDataHistory} />

        <CustomRoutes noLayout>
          <Route path="/onboarding" element={<Onboarding />} />
        </CustomRoutes>
      </Admin>
    </>
  );
}

export default App;