import { useState } from "react";
import {
  List,
  Datagrid,
  Form,
  useRefresh,
  useRecordContext,
  TextField,
} from "react-admin";

import {
  Box,
  Typography,
  Tooltip,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Radio,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// icons
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";

import BreadCrumb from "../../components/Breadcrumbs";
import GeneralModal from "../../components/modals/generalModal";

import { useTranslation } from "react-i18next";
import { TableEmptyMessage } from "../../components/tableEmptyMessage";
import useLanguageChange from "../../utils/translateTableLanguage";
import QRCodeGenerateModal from "../../components/modals/QRCodeGenerateModal";
import ReceiveConnectionModal from "../../components/modals/receiveConnectionModal";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const Connections = () => {
  const [openCreateConnections, setOpenCreateConnections] = useState(false);
  const [openReceiveConnections, setOpenReceiveConnections] = useState(false);
  const [openDeleteConnections, setOpenDeleteConnections] = useState(false);
  const [openConfigureConnections, setOpenConfigureConnections] =
    useState(false);
  const [
    connectionDetailsForDeleteOrConfigure,
    setConnectionDetailsForDeleteOrConfigure,
  ] = useState<any>();
  const { t } = useTranslation("translation");
  const key = useLanguageChange();
  const digitalWalletDeploymentStatus = useDigitalWalletStore(
    (state) => state.deploymentStatus
  );

  const refresh = useRefresh();

  const onRefetch = () => {
    refresh();
  };

  const IconsField = (props: any) => {
    const record = useRecordContext(props);
    if (!record || !props.source) {
      return null;
    }
    return (
      record[props.source] && (
        <Box
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          {/* <Tooltip
            title={
              digitalWalletDeploymentStatus === "Not Configured"
                ? t("connections.disableReceiveConnection")
                : record.marketplace_flag === true
                ? t("connections.visitDataMarketPlace")
                : t("connections.dataMarketPlaceNotConfigured")
            }
            placement="top"
          >
            <StorefrontOutlinedIcon
              // onClick={() => {

              // }}
              fontSize="small"
              style={{
                cursor:
                  digitalWalletDeploymentStatus === "Deployed" &&
                  record.marketplace_flag === true
                    ? "pointer"
                    : "not-allowed",
                color:
                  digitalWalletDeploymentStatus === "Deployed" &&
                  record.marketplace_flag === true
                    ? "#4D4D4F"
                    : "#B9B9B9",
              }}
            />
          </Tooltip> */}
          {/* <Tooltip
            title={
              digitalWalletDeploymentStatus === "Not Configured"
                ? t("connections.disableReceiveConnection")
                : record.marketplace_flag === true
                ? t("connections.cannotConfigureAgain")
                : t("connections.configureMarketPlace")
            }
            placement="top"
          >
            <SettingsOutlinedIcon
              onClick={() => {
                digitalWalletDeploymentStatus === "Deployed" &&
                  // record.marketplace_flag === false &&
                  setOpenConfigureConnections(true);
                setConnectionDetailsForDeleteOrConfigure(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                // digitalWalletDeploymentStatus === "Deployed" &&
                // record.marketplace_flag === true
                //   ? "pointer"
                //   : "not-allowed",
                color: "#4D4D4F",
                // digitalWalletDeploymentStatus === "Deployed" &&
                // record.marketplace_flag === true
                //   ? "#4D4D4F"
                //   : "#B9B9B9",
              }}
            />
          </Tooltip> */}
          <Tooltip
            title={
              digitalWalletDeploymentStatus === "Not Configured"
                ? t("connections.disableReceiveConnection")
                : t("connections.deleteConnection")
            }
            placement="top"
          >
            <DeleteOutlineOutlinedIcon
              onClick={() => {
                digitalWalletDeploymentStatus === "Deployed" &&
                  setOpenDeleteConnections(true);
                setConnectionDetailsForDeleteOrConfigure(record);
              }}
              fontSize="small"
              style={{
                cursor:
                  digitalWalletDeploymentStatus === "Deployed"
                    ? "pointer"
                    : "not-allowed",
                color:
                  digitalWalletDeploymentStatus === "Deployed"
                    ? "#4D4D4F"
                    : "#B9B9B9",
              }}
            />
          </Tooltip>
        </Box>
      )
    );
  };

  const handleChange = (value: any) => {
    // if (value === "data_source") {
    //   setListFilterValue("data_source");
    // } else if (value === "data_using_service") {
    //   setListFilterValue("data_using_service");
    // } else if (value === "all") {
    //   setListFilterValue("all");
    // }
  };

  return (
    <Container>
      <Form>
        <BreadCrumb
          Link={t("sidebar.ariesDigitalWallet")}
          Link2={t("sidebar.connections")}
        />
        <HeaderContainer>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              {t("sidebar.connections")}
            </Typography>
            <Tooltip
              title={
                digitalWalletDeploymentStatus === "Deployed"
                  ? t("connections.createConnections")
                  : t("connections.disableCreateConnection")
              }
              placement="top"
            >
              <AddCircleOutlineOutlinedIcon
                onClick={() => {
                  digitalWalletDeploymentStatus === "Deployed" &&
                    setOpenCreateConnections(true);
                }}
                style={{
                  cursor:
                    digitalWalletDeploymentStatus === "Deployed"
                      ? "pointer"
                      : "not-allowed",
                  marginLeft: "7px",
                }}
              />
            </Tooltip>
            <Tooltip
              title={
                digitalWalletDeploymentStatus === "Deployed"
                  ? t("connections.receiveConnection")
                  : t("connections.disableReceiveConnection")
              }
              placement="top"
            >
              <FileDownloadOutlinedIcon
                onClick={() => {
                  digitalWalletDeploymentStatus === "Deployed" &&
                    setOpenReceiveConnections(true);
                }}
                style={{
                  cursor:
                    digitalWalletDeploymentStatus === "Deployed"
                      ? "pointer"
                      : "not-allowed",
                  marginLeft: "7px",
                }}
              />
            </Tooltip>
          </Box>

          {/* <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="all"
              name="radio-buttons-group"
              row
            >
              <FormControlLabel
                value="all"
                onChange={() => handleChange("all")}
                control={<Radio name={"all"} color="default" size="small" />}
                label={
                  <Typography variant="body2">{t("common.all")}</Typography>
                }
              />
              <FormControlLabel
                value="data_source"
                onChange={() => handleChange("data_source")}
                control={
                  <Radio name={"data_source"} color="default" size="small" />
                }
                label={
                  <Typography variant="body2">
                    {t("connections.marketPlace")}
                  </Typography>
                }
              />
              <FormControlLabel
                value="data_using_service"
                onChange={() => handleChange("data_using_service")}
                control={
                  <Radio
                    name={"data_using_service"}
                    color="default"
                    size="small"
                  />
                }
                label={
                  <Typography variant="body2">
                    {t("connections.organisation")}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl> */}
        </HeaderContainer>
        <Typography variant="body2" mt={1.25}>
          {t("connections.pageDescription")}
        </Typography>
      </Form>

      <List
        empty={<TableEmptyMessage />}
        actions={false}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              width: "100%",
              // "& .RaDatagrid-rowCell:last-child": {
              //   width: { md: "30%", lg: "19%" },
              // },
            }}
            key={key}
          >
            <TextField
              source="theirLabel"
              sortable={false}
              label={t("connections.theirLabel")}
            />
            <TextField
              source="myDid"
              sortable={false}
              label={t("connections.myDecentralisedIdentifier")}
            />
            <TextField
              source="theirDid"
              sortable={false}
              label={t("connections.theirDecentralisedIdentifier")}
            />
            <TextField
              source="updatedAt"
              sortable={false}
              label={t("connections.connectedOn")}
            />
            <TextField
              source="connectionId"
              sortable={false}
              label={t("connections.connectionIdentifier")}
            />

            <IconsField source="id" sortable={false} label={""} />
          </Datagrid>
        </Box>
      </List>

      {/* Modals */}

      <GeneralModal
        open={openDeleteConnections}
        setOpen={setOpenDeleteConnections}
        headerText={`${t("connections.deleteConnection")}:`}
        confirmText="DELETE"
        confirmButtonText={`${t("common.delete")}`}
        resourceName={"connections"}
        onRefetch={onRefetch}
        connectionDetailsForDelete={connectionDetailsForDeleteOrConfigure}
        modalDescriptionText={
          <Typography variant="body1">
            {t("connections.deleteDescription1")} <b>DELETE</b>
            {t("connections.deleteDescription2")}
          </Typography>
        }
      />

      <GeneralModal
        open={openConfigureConnections}
        setOpen={setOpenConfigureConnections}
        headerText={`${t("connections.configureAsDataMarketplace")}:`}
        confirmText="CONFIGURE"
        confirmButtonText={`${t("connections.configure")}`}
        resourceName={"connections"}
        onRefetch={onRefetch}
        connectionDetailsForDelete={connectionDetailsForDeleteOrConfigure}
        modalDescriptionText={
          <Typography variant="body1">
            {t("connections.configureDescription1")} <b>CONFIGURE</b>
            {t("connections.configureDescription2")}
          </Typography>
        }
      />

      <QRCodeGenerateModal
        open={openCreateConnections}
        setOpen={setOpenCreateConnections}
        mode={"organisation"}
        onRefetch={onRefetch}
        connectionPage={true}
      />

      <ReceiveConnectionModal
        open={openReceiveConnections}
        setOpen={setOpenReceiveConnections}
        headerText={t("connections.receiveConnectionInvitation")}
        onRefetch={onRefetch}
      />
    </Container>
  );
};

export default Connections;
