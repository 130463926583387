import { Menu, MenuProps } from "react-admin";
import { useState } from "react";

import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";

import SubMenu from "./SubMenu";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";

type MenuName =
  | "manageUsers"
  | "account"
  | "ssi"
  | "dataExchange"
  | "supportEvents"
  | "ewc";

export default function MyMenu({ dense = false }: MenuProps) {
  const { t } = useTranslation("translation");

  const organisationFeatures = useDigitalWalletStore(
    (state) => state.organisationFeatures
  );

  const [state, setState] = useState({
    manageUsers: false,
    account: false,
    ssi: false,
    dataExchange: false,
    supportEvents: false,
    ewc: false,
  });

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        marginTop: 0,
        position: "fixed",
        height: "auto",
        backgroundColor: "#F7F6F6",
        paddingTop: "32px",
        top: { xs: 0, sm: 54 },
        bottom: 0,
        overflowY: "auto",
      }}
    >
      <Menu>
        {organisationFeatures.gettingStarted && (
          <Menu.ResourceItem name="start" />
        )}
        {organisationFeatures.dataAgreements && (
          <Menu.ResourceItem name="dataagreement" />
        )}
        {organisationFeatures.managedData && (
          <Menu.ResourceItem name="personaldata" />
        )}
        {/* <SubMenu
        handleToggle={() => handleToggle("dataExchange")}
        isOpen={state.dataExchange}
        name={t("dataAgreements.dataExchange")}
        icon={<CloudQueueOutlinedIcon />}
        dense={!dense}
      >
        <Menu.Item
          to="/dataDisclosureAgreements"
          primaryText={t("sidebar.dataDisclosureAgreements")}
        />
        <Menu.Item
          to="/signedAgreements"
          primaryText={t("sidebar.signedAgreements")}
        />
      </SubMenu> */}
        {organisationFeatures.digitalWalletAries && (
          <SubMenu
            handleToggle={() => handleToggle("ssi")}
            isOpen={state.ssi}
            name={t("sidebar.ariesDigitalWallet")}
            icon={<AccountBalanceWalletOutlinedIcon />}
            dense={!dense}
          >
            <Menu.Item
              to="/aries-cloud-agent"
              primaryText={t("sidebar.digitalWalletBase")}
            />
            <Menu.Item
              to="/connections"
              primaryText={t("sidebar.connections")}
            />
            <Menu.Item
              to="/credentials"
              primaryText={t("sidebar.credentials")}
            />
            <Menu.Item
              to="/verifications"
              primaryText={t("sidebar.verifications")}
            />
          </SubMenu>
        )}
        {organisationFeatures.digitalWalletOid4vc && (
          <SubMenu
            handleToggle={() => handleToggle("ewc")}
            isOpen={state.ewc}
            name={t("sidebar.openIDDigitalWallet")}
            icon={<AccountBalanceWalletOutlinedIcon />}
            dense={!dense}
          >
            <Menu.Item
              to="/oid4vc"
              primaryText={t("sidebar.digitalWalletBase")}
            />
            <Menu.Item
              to="/oid4vc-credentials"
              primaryText={t("sidebar.credentials")}
            />
            <Menu.Item
              to="/oid4vc-holder"
              primaryText={t("sidebar.holder")}
            />
            <Menu.Item
              to="/oid4vc-verifications"
              primaryText={t("sidebar.verifications")}
            />
          </SubMenu>
        )}

        {organisationFeatures.manageUsers && (
          <SubMenu
            handleToggle={() => handleToggle("manageUsers")}
            isOpen={state.manageUsers}
            name={t("sidebar.manageUsers")}
            icon={<PeopleAltOutlinedIcon />}
            dense={!dense}
          >
            <Menu.Item
              to="/configuration"
              primaryText={t("sidebar.configuration")}
            />
            <Menu.Item
              to="/consentrecords"
              primaryText={t("sidebar.consentRecords")}
            />
            <Menu.Item
              to="/dataverifierusers"
              primaryText={t("sidebar.dataverifierusers")}
            />
            <Menu.Item
              to="/notifications"
              primaryText={t("sidebar.notifications")}
            />
          </SubMenu>
        )}
        {organisationFeatures.privacyDashboard && (
          <Menu.ResourceItem name="privacydashboard" />
        )}
        {organisationFeatures.account && (
          <SubMenu
            handleToggle={() => handleToggle("account")}
            isOpen={state.account}
            name={t("sidebar.account")}
            icon={<LockOutlinedIcon />}
            dense={!dense}
          >
            <Menu.Item
              to="/manageadmin"
              primaryText={t("sidebar.manageAdmin")}
            />
            <Menu.Item
              to="/developerapi"
              primaryText={t("sidebar.developerAPIs")}
            />
            <Menu.Item to="/viewlogs" primaryText={t("sidebar.viewLogs")} />
            <Menu.Item to="/billing" primaryText={t("sidebar.billing")} />
          </SubMenu>
        )}
        {organisationFeatures.supportEvents && (
          <SubMenu
            handleToggle={() => handleToggle("supportEvents")}
            isOpen={state.supportEvents}
            name={t("sidebar.supportEvents")}
            icon={<CalendarTodayOutlinedIcon />}
            dense={!dense}
          >
            <Menu.Item to="/webhooks" primaryText={t("sidebar.webhooks")} />
            <Menu.Item
              to="/userRequests"
              primaryText={t("sidebar.userRequests")}
            />
          </SubMenu>
        )}
      </Menu>
    </Box>
  );
}
