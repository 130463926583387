import * as React from "react";
import {Dispatch, SetStateAction } from "react";

import { Drawer, Typography, Button, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
} from "../modalStyle";

import { useTranslation } from "react-i18next";
import EditorField from "../../jsonEditor/editorField";

interface Props {
  open: boolean;
  onCloseModal: any;
  headerText: string;
  methods: any;
  handleChange: any;
  name: string;
  inputFieldName: string;
  inputPlaceHolderName: string;
  credentialDefinitionModalMode?: string;
  setJsonSchemaError?: any;
  schema:any;
  mode:string;
  setError:Dispatch<SetStateAction<string>>
}


export default function EwcIssueCredentialManuallybyJson(props: Props) {
  const { t } = useTranslation("translation");
  const {
    open,
    headerText,
    methods,
    handleChange,
    name,
    inputFieldName,
    mode,
    onCloseModal,
    schema,
    setError
  } = props;

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <Container sx={{ width: "500px" }}>
          <form>
            <HeaderContainer>
              <Box pl={2}>
                <Box display={"flex"}>
                  <ChevronLeftIcon
                    onClick={onCloseModal}
                    sx={{
                      marginRight: 1,
                      cursor: "pointer",
                      color: "#F3F3F6",
                    }}
                  />
                  <Typography color="#F3F3F6">{headerText}</Typography>
                </Box>
              </Box>
              <CloseIcon
                onClick={onCloseModal}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <DetailsContainer style={{ paddingBottom: "80px" }}>
              <Box p={1.5}>
                <Typography variant="subtitle1" mt={1.5}>
                  {inputFieldName}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>

                <EditorField
                  name={name}
                  methods={methods}
                  handleChange={handleChange}
                  schema={JSON.stringify(schema)}
                  mode={mode}
                  setError={setError}
                />
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={onCloseModal}
                style={buttonStyle}
                sx={{
                  marginLeft: "10px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>
            </FooterContainer>
          </form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
