import { Box, Tooltip, Typography } from "@mui/material";
import CSS from "csstype";

import AttribueValueInputAccordintoType from "./attribueValueInputAccordintoType";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { t } from "i18next";
export interface Props {
  key: any;
  index: any;
  handleInputChange: any;
  attributeName: any;
  attributeValue: any;
  attributeType: any;
}

const tableCellStyle: CSS.Properties = {
  fontWeight: "normal",
  fontSize: "14px",
  borderTop: "solid 1px #dee2e6",
  textAlign: "left",
  borderRight: "solid 1px #dee2e6",
  fontFamily: "Inter,Helvetica,Arial,sans-serif",
};

export const DataAttributeValueInputForOID4VC = (props: Props) => {
  const {
    key,
    index,
    handleInputChange,
    attributeType,
    attributeValue,
    attributeName,
  } = props;

  return (
    <tbody>
      <tr>
        <th
          style={{
            ...tableCellStyle,
            borderTop: index === 0 ? 0 : "solid 1px #dee2e6",
          }}
          scope="row"
        >
          {attributeName}
        </th>
        <td
          style={{
            ...tableCellStyle,
            borderTop: index === 0 ? 0 : "solid 1px #dee2e6",
          }}
        >
          {attributeType !== "object" && attributeType !== "array" ? (
            <AttribueValueInputAccordintoType
              type={attributeType}
              attributeName={attributeName}
              index={index}
              handleInputChange={handleInputChange}
              attributeValue={attributeValue}
            />
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              key={key}
            >
              <Typography
                variant="subtitle2"
                color={"grey"}
                sx={{ cursor: "not-allowed" }}
              >
                {attributeName} ({attributeType})
              </Typography>
              <Tooltip
                placement="top"
                title={t("holder.messageToShowIfNestedDaraPresent")}
              >
                <InfoOutlinedIcon />
              </Tooltip>
            </Box>
          )}
        </td>
      </tr>
    </tbody>
  );
};
