import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useOnBoardingStore } from "../../store/onBoardingStore";
import { Box } from "@mui/system";

const BillingAddressDetailsContainer = () => {
  const { t } = useTranslation("translation");
  const onbordingStore = useOnBoardingStore((state) => state.billingAddress);

  return (
    <Box p={4}>
      <Typography variant="subtitle1" color={"black"} fontWeight="bold">
        {t("onboarding.billingAddress")}
      </Typography>
      <Typography variant="subtitle2" mt={2} mb={1}>
        <span style={{ fontWeight: "bold" }}>
          {t("onboarding.billingAdmin")}:
        </span>{" "}
        <span>{onbordingStore.adminName}</span>
      </Typography>

      <Typography variant="subtitle2">{onbordingStore.addressLine1}</Typography>
      <Typography variant="subtitle2">{onbordingStore.addressLine2}</Typography>
      <Typography variant="subtitle2">
        <span>{onbordingStore.city}</span>, <span>{onbordingStore.state}</span>,{" "}
        <span>{onbordingStore.postalCode}</span>
      </Typography>
    </Box>
  );
};

export default BillingAddressDetailsContainer;
