import { Box, Typography } from "@mui/material";

const titleAttrRestrictionStyle = (isLastAttribute: boolean) => ({
  fontWeight: "normal",
  margin: "10px 10px 5px 10px",
  borderBottom: isLastAttribute ? "none" : "solid 1px #dee2e6",
  lineHeight: "1.5rem",
});

const tableAttrAdditionalInfoStyle = {
  border: 0,
  width: "100%",
  maxWidth: "100%",
  marginBottom: "0rem",
  backgroundColor: "#FFFF",
};

const blurredTextStyle = {
  filter: "blur(4px)",
};

interface Props {
  selectedData: any;
  blurPersonalData: any;
  ssi: string;
}

export const DataAttributeCardEWC = (props: Props) => {
  const { selectedData, blurPersonalData } = props;


  return (
    <Box
      style={{
        marginTop: "10px",
        border: "1px solid #DFE0E1",
        borderRadius: 5,
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      {selectedData.dataAttributeValues?.map((attribute: any, index: number) => {
        const isLastAttribute =
          index === (selectedData.dataAttributeValues?.length || 0) - 1;

        return (
          <Box key={index} style={titleAttrRestrictionStyle(isLastAttribute)}>
            <table style={tableAttrAdditionalInfoStyle}>
              <tbody>
                <tr>
                  <td>
                    <Typography variant="subtitle2">{attribute.name}</Typography>
                  </td>
                  <td>
                    <Typography
                      variant="subtitle2"
                      align="right"
                      style={blurPersonalData ? blurredTextStyle : {}}
                    >
                      {attribute.value}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
        );
      })}
    </Box>
  );
};
