/* eslint-disable no-prototype-builtins */
// Constants for credential types
export const CREDENTIAL_TYPES: { [key: string]: string } = {
  VERIFIABLE_CREDENTIAL: "VerifiableCredential",
  VERIFIABLE_LEGAL_PERSONAL_IDENTIFICATION_DATA:
    "VerifiableLegalPersonalIdentificationData",
  VERIFIABLE_LEGAL_PERSONAL_IDENTIFICATION_DATA_SDJWT:
    "VerifiableLegalPersonalIdentificationData",
  VERIFIABLE_CERTIFICATE_OF_REGISTRATION: "VerifiableCertificateOfRegistration",
  VERIFIABLE_CERTIFICATE_OF_REGISTRATION_SDJWT:
    "VerifiableCertificateOfRegistration",
  VERIFIABLE_PORTABLE_DOCUMENT_A1: "VerifiablePortableDocumentA1",
  VERIFIABLE_PORTABLE_DOCUMENT_A1_SDJWT: "VerifiablePortableDocumentA1",
  VERIFIABLE_ATTESTATION: "VerifiableAttestation",
  VERIFIABLE_PASSPORT: "Passport",
};

// Constants for credential type labels
export const CREDENTIAL_TYPE_LABELS: { [key: string]: string } = {
  VERIFIABLE_LEGAL_PERSONAL_IDENTIFICATION_DATA:
    "Legal Personal Identification Data (LPID)",
  VERIFIABLE_LEGAL_PERSONAL_IDENTIFICATION_DATA_SDJWT:
    "Legal Personal Identification Data (LPID)",
  VERIFIABLE_CERTIFICATE_OF_REGISTRATION: "Certificate Of Registration",
  VERIFIABLE_CERTIFICATE_OF_REGISTRATION_SDJWT: "Certificate Of Registration",
  VERIFIABLE_PORTABLE_DOCUMENT_A1: "Portable Document A1 (PDA1)",
  VERIFIABLE_PORTABLE_DOCUMENT_A1_SDJWT: "Portable Document A1 (PDA1)",
  VERIFIABLE_ATTESTATION: "VerifiableAttestation",
  VERIFIABLE_PASSPORT: "Passport",
};
