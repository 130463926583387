import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";
import { Tooltip } from "@mui/material";

interface Props {
  control: any;
  getValues: any;
  setValue: any;
  setCheckWebhookIsSelected: Dispatch<SetStateAction<boolean>>;
  webhookEventTypes: string[];
}

const CheckboxTreeForAPIKey = (props: Props) => {
  const {
    control,
    getValues,
    setValue,
    setCheckWebhookIsSelected,
    webhookEventTypes,
  } = props;
  const { t } = useTranslation("translation");

  const [handleChangeTrigger, setandleChangeTrigger] = useState(false);
  const digitalWalletDeploymentStatus = useDigitalWalletStore(
    (state) => state.deploymentStatus
  );

  const handleParentRadioChange = (value: string) => {
    if (value === "all") {
      webhookEventTypes.forEach((type: string) => {
        if (
          digitalWalletDeploymentStatus === "Not Configured" &&
          !type.startsWith("digitalwallet")
        ) {
          setValue(`webhookEventTypes.${type}`, true); // Set false if digital wallet is not configured
        } else if (digitalWalletDeploymentStatus === "Deployed") {
          setValue(`webhookEventTypes.${type}`, true);
        }
      });
    } else if (value === "selected") {
      webhookEventTypes.forEach((type: string) => {
        setValue(`webhookEventTypes.${type}`, false);
      });
    }
    setValue("radioGroup", value);
    setandleChangeTrigger(!handleChangeTrigger);
  };

  const handleCheckboxChange = (fieldName: string, checked: boolean) => {
    setValue(fieldName, checked);

    const allChecked = webhookEventTypes.every((type: string) =>
      getValues(`webhookEventTypes.${type}`)
    );

    const anyChecked = webhookEventTypes.some((type: string) =>
      getValues(`webhookEventTypes.${type}`)
    );

    if (allChecked) {
      setValue("radioGroup", "all");
    } else if (anyChecked) {
      setValue("radioGroup", "selected");
    }
    setandleChangeTrigger(!handleChangeTrigger);
  };

  useEffect(() => {
    let webhookEventTypesIsChecked = webhookEventTypes.filter(
      (type: string) => getValues(`webhookEventTypes.${type}`) === true
    );

    let consentManagementWebhook: string[] = [];
    let digitalWalletWebhook: string[] = [];

    webhookEventTypesIsChecked.forEach((type: string) => {
      const [parent, child] = type.split(".");
      if (parent === "digitalwallet") {
        digitalWalletWebhook.push(type);
      } else {
        consentManagementWebhook.push(type);
      }
    });

    consentManagementWebhook.length > 0 || digitalWalletWebhook.length > 0
      ? setCheckWebhookIsSelected(true)
      : setCheckWebhookIsSelected(false);
  }, [handleChangeTrigger]);

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <label
          style={{
            fontSize: 14,
            fontFamily: "Inter,Helvetica,Arial,sans-serif",
          }}
        >
          <Controller
            name="radioGroup"
            control={control}
            render={({ field }) => (
              <>
                <input
                  type="radio"
                  value="all"
                  style={{ fontSize: "14px" }}
                  checked={field.value === "all"}
                  onChange={() => handleParentRadioChange("all")}
                />
                {t("webhooks.allEvents")}
              </>
            )}
          />
        </label>
      </div>

      <div style={{ marginTop: "10px" }}>
        <label
          style={{
            fontSize: 14,
            fontFamily: "Inter,Helvetica,Arial,sans-serif",
          }}
        >
          <Controller
            name="radioGroup"
            control={control}
            render={({ field }) => (
              <>
                <input
                  type="radio"
                  value="selected"
                  checked={field.value === "selected"}
                  onChange={() => handleParentRadioChange("selected")}
                />
                {t("webhooks.selectedEvents")}
              </>
            )}
          />
        </label>
      </div>
      {webhookEventTypes.map((type: string) => (
       
          <div
            key={type}
            style={{
              margin: "10px",
            }}
          >
             <Tooltip
        title={
         digitalWalletDeploymentStatus === "Not Configured" &&  
         type.startsWith("digitalwallet") &&
          t("webhooks.disableCheckbox")
        }
        placement="top"
      >
            <label
              style={{
                fontSize: 14,
                marginLeft: "20px",
                fontFamily: "Inter,Helvetica,Arial,sans-serif",
                cursor:
                  digitalWalletDeploymentStatus === "Not Configured" &&
                  type.startsWith("digitalwallet")
                    ? "not-allowed"
                    : "pointer",
              }}
            >
              <Controller
                name={`webhookEventTypes.${type}`}
                control={control}
                render={({ field }) => (
                  <>
                    <input
                      style={{
                        cursor:
                          digitalWalletDeploymentStatus === "Not Configured" &&
                          type.startsWith("digitalwallet")
                            ? "not-allowed"
                            : "pointer",
                      }}
                      type="checkbox"
                      checked={field.value}
                      disabled={
                        digitalWalletDeploymentStatus === "Not Configured" &&
                        type.startsWith("digitalwallet")
                      }
                      onChange={() => {
                        field.onChange(!field.value);
                        handleCheckboxChange(
                          `webhookEventTypes.${type}`,
                          !field.value
                        );
                      }}
                    />
                    {type}
                  </>
                )}
              />
            </label>
          </Tooltip>  
          </div>
              ))}
    </>
  );
};

export default CheckboxTreeForAPIKey;
