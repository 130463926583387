import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import BreadCrumb from "../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import PaymentMethod from "../../components/billing/paymentmethod";
import MonthlyUsers from "../../components/billing/monthlyUsers";
import BillingAddress from "../../components/billing/billingAddress";
import PackageSelector from "../../components/billing/packageSelector";
import { useEffect, useState } from "react";
import InvoiceHistory from "../../components/billing/invoiceHistory";
import PricingSimulator from "../../components/billing/pricingSimulator";
import { HttpServiceForDigitalWallet } from "../../service/digitalWalletHttpService";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";
import { OnBoardingHttpService } from "../../service/onboardingService";
import GeneralModal from "../../components/modals/generalModal";
import SnackbarComponent from "../../components/notification";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const DetailsContainer = styled("div")({
  height: "auto",
  width: "100%",
  borderRadius: 2,
});

const Item = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  padding: 15,
  paddingLeft: 20,
  height: "130px",
  borderRadius: 2,
  border: "1px solid #CECECE",
}));

const BillingPage = () => {
  const { t } = useTranslation("translation");
  const [error, setError] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [pricingInfoFromApi, setPricingInforFromApi] = useState();
  const [openTerminateModal, setOpenTerminateModal] = useState(false);
  const [refreshBilling, setRefreshBilling] = useState(false);

  const onRefetch = () => {
    setRefreshBilling(!refreshBilling);
  };

  useEffect(() => {
    HttpServiceForDigitalWallet.readBilling().then((res) => {
      useDigitalWalletStore
        .getState()
        .updateReadExistingBillingDetails(res.organisation);
    });

    OnBoardingHttpService.listPricingInfo().then((res) => {
      setPricingInforFromApi(res.data);
    });
  }, []);

  useEffect(() => {
    HttpServiceForDigitalWallet.getSubscribedPlanDetails().then((res) => {
      useDigitalWalletStore
        .getState()
        .updateSelectedPackage(res.subscriptionInfo);
    });
  }, [refreshBilling]);

  const subscibedPackageDetails = useDigitalWalletStore(
    (state) => state.selectedPackage
  );

  const readExistingBillingDetails = useDigitalWalletStore(
    (state) => state.readExistingBillingDetails
  );

  let percentageUsersWithRespectToLastMonth = 0;

  if (readExistingBillingDetails && readExistingBillingDetails.billingInfo) {
    const { maxUserCounter, prevMonthUsers } =
      readExistingBillingDetails.billingInfo;

    if (prevMonthUsers !== 0) {
      percentageUsersWithRespectToLastMonth =
        (maxUserCounter / prevMonthUsers) * 100;
    }
  }

  return (
    <Container>
      <SnackbarComponent
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={error}
        topStyle={100}
      />
      <BreadCrumb Link={t("sidebar.account")} Link2={t("sidebar.billing")} />

      <DetailsContainer sx={{ flexGrow: 1, marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Item>
              <MonthlyUsers
                maxUsers={
                  readExistingBillingDetails?.billingInfo?.maxUserCounter
                }
                percentage={percentageUsersWithRespectToLastMonth}
              />
            </Item>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Item>
              <PaymentMethod />
            </Item>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Item>
              <BillingAddress />
            </Item>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item lg={8} md={12} sm={12} xs={12}>
            <Item
              sx={{
                height: { lg: "505px", md: "auto", sm: "auto", xs: "auto" },
              }}
            >
              <PackageSelector
                setError={setError}
                setOpenSnackBar={setOpenSnackBar}
                pricingInfoFromApi={pricingInfoFromApi}
                setOpenTerminateModal={setOpenTerminateModal}
              />
            </Item>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Item
              sx={{
                height: { lg: "505px", md: "auto", sm: "auto", xs: "auto" },
              }}
            >
              <PricingSimulator pricingInfoFromApi={pricingInfoFromApi} />
            </Item>
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Item style={{ height: "auto" }}>
              <InvoiceHistory />
            </Item>
          </Grid>
        </Grid>
      </DetailsContainer>

      <GeneralModal
        open={openTerminateModal}
        setOpen={setOpenTerminateModal}
        headerText={`${t("billing.areYouSure")}`}
        confirmText="TERMINATE"
        confirmButtonText={`${t("billing.terminate")}`}
        resourceName={"billing"}
        onRefetch={onRefetch}
        billingPlanDetailsToTerminate={subscibedPackageDetails}
        modalDescriptionText={
          <Typography variant="body1">
            {t("billing.deleteDescription1")} <b>TERMINATE</b>
            {t("billing.deleteDescription2")}
          </Typography>
        }
      />
    </Container>
  );
};

export default BillingPage;
