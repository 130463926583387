import React, { useContext, Dispatch, SetStateAction, useState } from "react";

import { Drawer, Typography, Button, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  disabledButtonstyle,
} from "./modalStyle";
import { OrganizationDetailsCRUDContext } from "../../contexts/organizationDetailsCrud";
import { useTranslation } from "react-i18next";
import QRCodeCopyInput from "../dataAgreements/InputWithCopyQRCode";
import QRCode from "qrcode";
import LoaderComponent from "../LoaderComponent";
import { HttpServiceForDigitalWallet } from "../../service/digitalWalletHttpService";
import { HttpServiceForDigitalWalletEwc } from "../../service/digitalWalletEwcHttpService";

const QRCodeButtonStyle = {
  height: 35,
  borderRadius: 1,
  border: "1px solid #DFDFDF",
  width: "90%",
};

const disabledQRCodeButtonStyle = {
  height: 35,
  borderRadius: 1,
  border: "1px solid #EAEAEA",
  width: "90%",
};

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  mode: string;
  selectededDAfromDAs?: any;
  onRefetch?: any;
  connectionPage?: boolean;
}

export default function QRCodeGenerateModal(props: Props) {
  const {
    open,
    setOpen,
    mode,
    selectededDAfromDAs,
    onRefetch,
    connectionPage,
  } = props;
  const { t } = useTranslation("translation");
  const { organisationDetails } = useContext(OrganizationDetailsCRUDContext);
  const [qrCode, setQRCode] = useState<string | null>(null);
  const [qrCodeValue1, setQRCodeValue1] = useState<string>("");
  const [qrCodeValue2, setQRCodeValue2] = useState<string>("");
  const [openLoader, setOpenLoader] = useState(false);
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);

  const generateQRCode = async () => {
    setOpenLoader(true);
    if (mode === "organisation") {
      HttpServiceForDigitalWallet.generateQrCodeForOrganisations().then(
        (response) => {
          setOpenLoader(false);
          setQRCodeValue2(response.data.connection.invitationUrl);
          setQRCodeValue1(response.data.firebaseDynamicLink);
          QRCode.toDataURL(
            response.data.firebaseDynamicLink,
            (err: any, url: any) => {
              if (err) {
                console.error(err);
              }
              setQRCode(url);
            }
          );
        }
      );
    } else if (mode === "dataAgreement") {
      HttpServiceForDigitalWallet.generateQrCodeForDataAgreement(
        selectededDAfromDAs?.dataExchange.id
      ).then((response) => {
        HttpServiceForDigitalWallet.generateFirebaseDynamicLinkForDataAgreementQrCode(
          selectededDAfromDAs?.dataExchange.id,
          response.data.qrId
        ).then((response) => {
          setOpenLoader(false);
          setQRCodeValue1(response.data.firebaseDynamicLink);
          // setting QRCode
          QRCode.toDataURL(
            response.data.firebaseDynamicLink,
            (err: any, url: any) => {
              if (err) {
                console.error(err);
              }
              setQRCode(url);
            }
          );
        });
      });
    } else if (mode === "presentationDefinition") {
      let payload = {
        presentationDefinitionId: selectededDAfromDAs.presentationDefinitionId,
        requestByReference:true
      };
      HttpServiceForDigitalWalletEwc.sendVeificationForEWC(payload).then(
        (response) => {
          setOpenLoader(false);

          setQRCodeValue1(response.data.verificationHistory.vpTokenQrCode);
          // setting QRCode
          QRCode.toDataURL(
            response.data.verificationHistory.vpTokenQrCode,
            (err: any, url: any) => {
              if (err) {
                console.error(err);
              }
              setQRCode(url);
            }
          );
        }
      );
    }
  };

  const deleteQRCode = async () => {
    setQRCode(null);
    setQRCodeValue1("");
    setQRCodeValue2("");
  };

  const downloadQRCode = () => {
    if (qrCode) {
      const link = document.createElement("a");
      link.href = qrCode;
      link.download = "QRCode.png"; // Change the filename as needed
      link.click();
    }
  };

  const handleCopy1 = () => {
    if (qrCode) {
      navigator.clipboard.writeText(qrCodeValue1);

      setShowTooltip1(true);
      setTimeout(() => {
        setShowTooltip1(false);
      }, 1000);
    }
  };

  const handleCopy2 = () => {
    if (qrCode) {
      navigator.clipboard.writeText(qrCodeValue2);
      setShowTooltip2(true);
      setTimeout(() => {
        setShowTooltip2(false);
      }, 1000);
    }
  };

  const HeaderText = () => {
    if (mode === "organisation") {
      return `${organisationDetails.name} ${t(
        "dataAgreements.QRCodeConnection"
      )}`;
    } else if (mode === "dataAgreement") {
      return `${t("dataAgreements.daQrCode")}:${selectededDAfromDAs?.purpose}`;
    } else if (mode === "presentationDefinition") {
      return `${t("dataAgreements.generateQrCode")}`;
    }
    return "";
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container>
          <form>
            <HeaderContainer>
              <Box pl={2} style={{ overflow: "hidden" }}>
                <Typography
                  style={{
                    color: "#F3F3F6",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {HeaderText()}
                </Typography>
                <Typography color="white">
                  {mode === "dataAgreement" && selectededDAfromDAs?.id}
                </Typography>
              </Box>

              <CloseIcon
                onClick={() => {
                  connectionPage && qrCode && onRefetch();
                  setOpen(false);
                  setQRCode(null);
                }}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>

            <DetailsContainer
              style={{ display: "grid", justifyItems: "center" }}
            >
              <Button
                style={qrCode ? disabledQRCodeButtonStyle : QRCodeButtonStyle}
                onClick={generateQRCode}
                variant="outlined"
                sx={{
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                  color: qrCode ? "#6D7676" : "black",
                  marginTop: 2,
                  cursor: qrCode ? "not-allowed" : "pointer",
                }}
              >
                {t("dataAgreements.generateQrCode")}
              </Button>
              <Button
                style={qrCode ? QRCodeButtonStyle : disabledQRCodeButtonStyle}
                onClick={deleteQRCode}
                variant="outlined"
                sx={{
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                  color: qrCode ? "black" : "#6D7676",
                  cursor: qrCode ? "pointer" : "not-allowed",
                  marginTop: 2,
                }}
              >
                {t("dataAgreements.deleteQrCode")}
              </Button>
              {qrCode && (
                <>
                  <img
                    src={qrCode}
                    alt="QR Code"
                    style={{
                      marginTop: 10,
                      maxWidth: "100%",
                      width:
                        mode === "presentationDefinition" ? "250px" : "auto",
                    }}
                  />
                  <Box
                    style={{
                      width: "100%",
                      display: "grid",
                      justifyItems: "center",
                    }}
                  >
                    <QRCodeCopyInput
                      handleCopy={handleCopy1}
                      qrCodeValue={qrCodeValue1}
                      showTooltip={showTooltip1}
                    />

                    {mode === "organisation" && (
                      <QRCodeCopyInput
                        handleCopy={handleCopy2}
                        qrCodeValue={qrCodeValue2}
                        showTooltip={showTooltip2}
                      />
                    )}
                  </Box>
                </>
              )}
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => {
                  connectionPage && qrCode && onRefetch();
                  setOpen(false);
                  setQRCode(null);
                }}
                style={buttonStyle}
                sx={{
                  color: "black",
                  marginLeft: "15px",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>
              <Button
                variant="outlined"
                onClick={downloadQRCode}
                style={qrCode ? buttonStyle : disabledButtonstyle}
                sx={{
                  cursor: qrCode ? "pointer" : "not-allowed",
                  marginRight: "20px",
                  color: qrCode ? "black" : "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
              >
                {t("dataAgreements.download")}
              </Button>
            </FooterContainer>
          </form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
