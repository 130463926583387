import * as React from "react";
import { Dispatch, SetStateAction } from "react";

import { Drawer, Typography, Button, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  disabledButtonstyle,
} from "./modalStyle";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LoaderComponent from "../LoaderComponent";
import { HttpServiceForDigitalWallet } from "../../service/digitalWalletHttpService";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  onRefetch: any;
}

const inputStyle = {
  width: "100%",
  border: "1",
  outline: "none",
  fontSize: "14px",
  color: "#495057",
  borderWidth: 0,
  borderRadius: 0,
  backgroundColor: "#FFFF",
  borderBottom: "2px solid #DFE0E1",
  height: "120px",
  fontFamily: "Inter,Helvetica,Arial,sans-serif",
  marginTop: "10px",
};

export default function ReceiveConnectionModal(props: Props) {
  const { open, setOpen, headerText, onRefetch } = props;
  const { t } = useTranslation("translation");
  const [openLoader, setOpenLoader] = React.useState(false);

  const { handleSubmit, register, formState, reset } = useForm<any>({
    defaultValues: {
      connectionUrl: "",
    },
    mode: "onChange",
  });

  const onSubmit = (createdData: any) => {
    setOpenLoader(true);

    if (formState.isValid) {
      let payload = {
        connectionUrl: createdData.connectionUrl,
      };

      HttpServiceForDigitalWallet.receiveConnection(payload)
        .then(() => {
          onRefetch();
          reset();
          setOpen(false);
          setOpenLoader(false);
        })
        .catch((error) => {
          console.log("error", error);
          setOpenLoader(false);
        });
    }
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <HeaderContainer>
              <Box pl={2}>
                <Typography color="#F3F3F6">{headerText}</Typography>
              </Box>
              <CloseIcon
                onClick={() => setOpen(false)}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <DetailsContainer>
              <Box p={1.5}>
                <Typography variant="subtitle1" mb={0}>
                  {t("connections.connectionURL")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>
                <textarea
                  placeholder={t("connections.connectionURLPlaceholderDesc")}
                  style={{
                    ...inputStyle,
                  }}
                  {...register("connectionUrl", {
                    required: true,
                    minLength: 1,
                  })}
                  rows={5}
                />
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => setOpen(false)}
                style={buttonStyle}
                sx={{
                  marginLeft: "10px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>
              <Button
                style={formState.isValid ? buttonStyle : disabledButtonstyle}
                sx={{
                  cursor: formState.isValid ? "pointer" : "not-allowed",
                  marginRight: "20px",
                  color: formState.isValid ? "black" : "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
                type="submit"
              >
                {t("connections.receive")}{" "}
              </Button>
            </FooterContainer>
          </form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
