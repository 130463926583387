import { useState } from "react";
import {
  List,
  Datagrid,
  Form,
  useRefresh,
  useRecordContext,
  TextField,
} from "react-admin";

import { Box, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

// icons
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";

import BreadCrumb from "../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";

import { TableEmptyMessage } from "../../components/tableEmptyMessage";
import useLanguageChange from "../../utils/translateTableLanguage";
import DeleteModal from "../../components/modals/generalModal";
import { useSSIStore } from "../../store/ssiStore";
import { downloadDAAsJson } from "../../utils/downloadDAasJson";
import RawExchangeModeModal from "../../components/modals/rawExchangeModeModal";
import ViewExchangeRecordModal from "../../components/modals/viewExchangeRecordModal";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const CredentialsHistory = () => {
  const [openRawExchangeModeModal, setOpenRawExchangeModeModal] =
    useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [
    selectededDataAgreementFromDataAgreement,
    setSelectededDataAgreementFromDataAgreement,
  ] = useState<any>();
  const [openViewExchangeRecordModal, setOpenViewExchangeRecordModal] =
    useState(false);

  const selectedRecord:any = useSSIStore(
    (state) => state.dataAgreementForIssuanceHistory
  );

  const { t } = useTranslation("translation");
  const key = useLanguageChange();

  const refresh = useRefresh();

  const onRefetch = () => {
    refresh();
  };

  const IconsFIeld = (props: any) => {
    const record = useRecordContext(props);

    if (!record || !props.source) {
      return null;
    }

    return (
      record[props.source] && (
        <Box display={"flex"} justifyContent={"space-around"}>
          <Tooltip
            title={t("issuanceHistory.viewIssuedDataAndDA")}
            placement="top"
          >
            <RemoveRedEyeOutlinedIcon
              onClick={() => {
                setOpenViewExchangeRecordModal(true);
                setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>

          <Tooltip
            title={t("issuanceHistory.downloadSignedDA")}
            placement="top"
          >
            <CloudDownloadOutlinedIcon
              onClick={() => {
                downloadDAAsJson(record.dataAgreement, "");
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>

          <Tooltip
            title={t("issuanceHistory.viewRawCredentialExchangeRecord")}
            placement="top"
          >
            <IntegrationInstructionsOutlinedIcon
              onClick={() => {
                setOpenRawExchangeModeModal(true);
                setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>
          <Tooltip
            title={t("issuanceHistory.deleteCredentialExchangeRecord")}
            placement="top"
          >
            <DeleteOutlineOutlinedIcon
              onClick={() => {
                setOpenDeleteModal(true),
                  setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>
        </Box>
      )
    );
  };

  return (
    <Container>
      <Form>
        <BreadCrumb
          Link={t("sidebar.ariesDigitalWallet")}
          Link2={t("sidebar.credentials")}
          Link3={t("issuanceHistory.issuanceHistory")}
        />
        <HeaderContainer>
          <Typography variant="h6" fontWeight="bold">
            {t("issuanceHistory.issuanceHistory")} -{" "}
            {selectedRecord?.purpose}
          </Typography>
        </HeaderContainer>
        <Typography variant="body2" mt={1.25}>
          {t("issuanceHistory.pageDescription")}
        </Typography>
      </Form>
      <List
        empty={<TableEmptyMessage />}
        actions={false}
        sx={{ width: "100%", overflow: "hidden" }}
        filter={{ id: selectedRecord.id }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              overflow: "auto",
              width: "100%",
              "& .RaDatagrid-rowCell:last-child": {
                width: {md:"30%", lg:"19%"}
              }
            }}
            key={key}
          >
            <TextField
              source="updatedAt"
              sortable={false}
              label={t("issuanceHistory.issuanceDate")}
            />

            <TextField
              source="connectionId"
              sortable={false}
              label={t("credentials.connectionID")}
            />
            <TextField
              source="credentialExchangeId"
              sortable={false}
              label={t("issuanceHistory.credentialExchangeID")}
            />
            <TextField
              source="state"
              sortable={false}
              label={t("issuanceHistory.state")}
            />

            <IconsFIeld source="id" sortable={false} label={""} />
          </Datagrid>
        </Box>
      </List>

      {/* Modals */}

      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        headerText={`${t("issuanceHistory.deleteCredentialExchangeRecord")}:`}
        confirmText="DELETE"
        confirmButtonText={`${t("common.delete")}`}
        resourceName="issuanceHistory"
        onRefetch={onRefetch}
        selectededDataAgreementFromDataAgreement={
          selectededDataAgreementFromDataAgreement
        }
        modalDescriptionText={
          <Typography variant="body1">
            {t("issuanceHistory.deleteDescription1")}
            <b>DELETE</b>
            {t("issuanceHistory.deleteDescription2")}
          </Typography>
        }
      />

      <RawExchangeModeModal
        open={openRawExchangeModeModal}
        setOpen={setOpenRawExchangeModeModal}
        headerText={t("issuanceHistory.rawExchangeRecord")}
        selectedData={selectededDataAgreementFromDataAgreement}
        ssi={"issuanceHistory"}
      />

      <ViewExchangeRecordModal
        open={openViewExchangeRecordModal}
        setOpen={setOpenViewExchangeRecordModal}
        headerText={t("issuanceHistory.viewExchangeRecord")}
        selectedData={selectededDataAgreementFromDataAgreement}
        ssi={"issuanceHistory"}
      />
    </Container>
  );
};

export default CredentialsHistory;
