import Grid from "@mui/material/Grid";

import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";
import { useNavigate } from "react-router-dom";

const buttonStyle = {
  height: 30,
  width: "100%",
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const disabledContainerStyle = {
  display: "grid",
  justifyItems: "center",
  alignContent: "space-between",
  padding: "5px",
  height: "350px",
  borderRadius: "5px",
  backgroundColor: "#fff",
  border: "1px solid #DFDFDF",
  transition: "box-shadow 0.3s",
  "&:hover": {
    boxShadow: "0 1px 5px rgba(0,0,0,.4),0 1px 5px hsla(0,0%,51%,.35)",
  },
};

const unSelectedContainerStyle = {
  display: "grid",
  justifyItems: "center",
  alignContent: "space-between",
  padding: "5px",
  height: "350px",
  borderRadius: "5px",
  backgroundColor: "#fff",
  border: "1px solid #DFDFDF",
  transition: "box-shadow 0.3s",
  "&:hover": {
    boxShadow: "0 1px 5px rgba(0,0,0,.4),0 1px 5px hsla(0,0%,51%,.35)",
  },
};

const selectedContainerStyle = {
  display: "grid",
  justifyItems: "center",
  alignContent: "space-between",
  padding: "5px",
  height: "350px",
  borderRadius: "5px",
  backgroundColor: "#fff",
  border: "1px solid black",
  transition: "box-shadow 0.3s",
  boxShadow: "0 1px 5px rgba(0,0,0,.4), 0 1px 5px hsla(0,0%,51%,.35)",
  "&:hover": {
    boxShadow: "0 1px 5px rgba(0,0,0,.4),0 1px 5px hsla(0,0%,51%,.35)",
  },
};

interface Props {
  selectedPackage: number;
  setSelectedPackage: Dispatch<SetStateAction<number>>;
  pricingInfoFromApi: any;
  setOpenTerminateModal: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string>>;
  setOpenSnackBar: Dispatch<SetStateAction<boolean>>;
}
export default function CovidPackageDetailsContainer(props: Props) {
  const {
    selectedPackage,
    pricingInfoFromApi,
    setOpenTerminateModal,
    setOpenSnackBar,
    setError,
  } = props;

  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const packageSelectionDetails = useDigitalWalletStore(
    (state) => state.readExistingBillingDetails.billingInfo
  );

  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" spacing={4}>
          {[3, 4, 5].map((value) => (
            <Grid key={value} item xs={12} sm={6} md={4}>
              <Box
                sx={
                  value === 3
                    ? selectedPackage === 3
                      ? selectedContainerStyle
                      : unSelectedContainerStyle
                    : value === 4
                    ? selectedPackage === 4
                      ? selectedContainerStyle
                      : unSelectedContainerStyle
                    : value === 5
                    ? selectedPackage === 5
                      ? selectedContainerStyle
                      : unSelectedContainerStyle
                    : disabledContainerStyle
                }
              >
                <Box
                  sx={{
                    borderBottom: "1px solid #DFDFDF",
                    height: "40px",
                    width: "90%",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="subtitle1" color={"black"}>
                    {value === 3 && t("onboarding.trial")}
                    {value === 4 && t("onboarding.starter")}
                    {value === 5 && t("billing.premium")}
                  </Typography>
                </Box>
                <Box>
                  {value === 3 && (
                    <Box textAlign={"center"}>
                      <Typography
                        variant="subtitle1"
                        color={"black"}
                        fontWeight={"bold"}
                      >
                        {pricingInfoFromApi?.freeTrialVerifier?.price > 0
                          ? pricingInfoFromApi?.freeTrialVerifier?.price
                          : t("onboarding.free")}
                      </Typography>

                      <Typography variant="subtitle1" color={"black"} mt={4}>
                        {t("onboarding.validFor")}{" "}
                        {pricingInfoFromApi?.freeTrialVerifier?.validity}{" "}
                        {pricingInfoFromApi?.freeTrialVerifier?.validity > 1
                          ? t("onboarding.days")
                          : t("onboarding.day")}
                      </Typography>
                      <Typography variant="subtitle1" color={"black"}>
                        {t("onboarding.upto")}{" "}
                        {
                          pricingInfoFromApi?.freeTrialVerifier?.usageLimit
                            ?.users
                        }{" "}
                        {t("onboarding.users")}
                      </Typography>
                      <Typography variant="subtitle1" color={"black"}>
                        {t("onboarding.upto")}{" "}
                        {
                          pricingInfoFromApi?.freeTrialVerifier?.usageLimit
                            ?.verifications
                        }{" "}
                        {t("billing.verifications")}
                      </Typography>
                      <Typography variant="subtitle1" color={"black"}>
                        {t("onboarding.upto")}{" "}
                        {
                          pricingInfoFromApi?.freeTrialVerifier?.usageLimit
                            ?.verifierappactivation
                        }{" "}
                        {t("billing.verifierAppActivation")}
                      </Typography>
                    </Box>
                  )}

                  {value === 4 && (
                    <Box textAlign={"center"}>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          color={"black"}
                          fontWeight={"bold"}
                        >
                          {t("onboarding.sek")}{" "}
                          {pricingInfoFromApi?.starterVerifier?.price}{" "}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={"grey"}
                          style={{ marginLeft: "10px" }}
                        >
                          {" "}
                          / {t("onboarding.year")}
                        </Typography>
                      </Box>
                      <Typography variant="subtitle1" color={"black"} mt={4}>
                        {t("onboarding.upto")}{" "}
                        {pricingInfoFromApi?.starterVerifier?.usageLimit?.users}{" "}
                        {t("onboarding.users")}
                      </Typography>
                      <Typography variant="subtitle1" color={"black"}>
                        {t("onboarding.upto")}{" "}
                        {
                          pricingInfoFromApi?.starterVerifier?.usageLimit
                            ?.verifications
                        }{" "}
                        {t("billing.verifications")}
                      </Typography>
                      <Typography variant="subtitle1" color={"black"}>
                        {t("onboarding.upto")}{" "}
                        {
                          pricingInfoFromApi?.starterVerifier?.usageLimit
                            ?.verifierappactivation
                        }{" "}
                        {t("billing.verifierAppActivation")}
                      </Typography>
                    </Box>
                  )}

                  {value === 5 && (
                    <Box textAlign={"center"}>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="subtitle1"
                          color={"black"}
                          fontWeight={"bold"}
                        >
                          {t("onboarding.sek")}{" "}
                          {pricingInfoFromApi?.premiumVerifier?.price}*
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={"grey"}
                          style={{ marginLeft: "10px" }}
                        >
                          {" "}
                          / {t("onboarding.userPerYear")}
                        </Typography>
                      </Box>

                      <Typography variant="subtitle1" color={"black"} mt={4}>
                        {pricingInfoFromApi?.premiumVerifier?.usageLimit
                          .users === "UNLIMITED"
                          ? t("onboarding.unlimitedUsers")
                          : "Upto " +
                            pricingInfoFromApi?.premiumVerifier?.usageLimit
                              ?.users +
                            " users"}{" "}
                      </Typography>
                      <Typography variant="subtitle1" color={"black"}>
                        {t("onboarding.upto")}{" "}
                        {
                          pricingInfoFromApi?.premiumVerifier?.usageLimit
                            ?.verifications
                        }{" "}
                        {t("billing.verifications")}
                      </Typography>
                      <Typography variant="subtitle1" color={"black"}>
                        {t("onboarding.upto")}{" "}
                        {
                          pricingInfoFromApi?.premiumVerifier?.usageLimit
                            ?.verifierappactivation
                        }{" "}
                        {t("billing.verifierAppActivation")}
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    borderTop: "1px solid #DFDFDF",
                    height: "40px",
                    width: "90%",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {value === 3 &&
                    (packageSelectionDetails.verifierFreeTrialExpired ===
                    true ? (
                      <Typography
                        variant="subtitle1"
                        color={"black"}
                        fontWeight={"bold"}
                      >
                        {t("billing.expired")}
                      </Typography>
                    ) : (
                      <Button
                        style={buttonStyle}
                        sx={{
                          cursor: "pointer",
                          color: "black",
                          "&:hover": {
                            backgroundColor: "black",
                            color: "white",
                          },
                        }}
                        variant="outlined"
                        onClick={() =>
                          selectedPackage === 3
                            ? setOpenTerminateModal(true)
                            : selectedPackage === null
                            ? (navigate("/orderingwizard"),
                              useDigitalWalletStore
                                .getState()
                                .updateSelectedPackageFromPackageSelector(
                                  "freetrialverifier"
                                ))
                            : (setOpenSnackBar(true),
                              setError(t("billing.errorIfPlanExist")))
                        }
                      >
                        {selectedPackage === 3
                          ? t("billing.terminate")
                          : t("onboarding.select")}
                      </Button>
                    ))}
                  {value === 4 && (
                    <Button
                      style={buttonStyle}
                      sx={{
                        cursor: "pointer",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "black",
                          color: "white",
                        },
                      }}
                      variant="outlined"
                      onClick={() =>
                        selectedPackage === 4
                          ? setOpenTerminateModal(true)
                          : selectedPackage === null
                          ? (navigate("/orderingwizard"),
                            useDigitalWalletStore
                              .getState()
                              .updateSelectedPackageFromPackageSelector(
                                "starterverifier"
                              ))
                          : (setOpenSnackBar(true),
                            setError(t("billing.errorIfPlanExist")))
                      }
                    >
                      {selectedPackage === 4
                        ? t("billing.terminate")
                        : t("onboarding.select")}
                    </Button>
                  )}
                  {value === 5 && (
                    <Button
                      sx={{
                        cursor: "pointer",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "black",
                          color: "white",
                        },
                      }}
                      variant="outlined"
                      style={buttonStyle}
                      onClick={() =>
                        selectedPackage === 5
                          ? setOpenTerminateModal(true)
                          : selectedPackage === null
                          ? (navigate("/orderingwizard"),
                            useDigitalWalletStore
                              .getState()
                              .updateSelectedPackageFromPackageSelector(
                                "premiumverifier"
                              ))
                          : (setOpenSnackBar(true),
                            setError(t("billing.errorIfPlanExist")))
                      }
                    >
                      {selectedPackage === 5
                        ? t("billing.terminate")
                        : t("onboarding.select")}{" "}
                    </Button>
                  )}{" "}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
