import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export let digitalWalletStore = (set: any) => ({
  deploymentStatus: "",
  deploymentStatusForEWC: "",
  readExistingBillingDetails: {
    id: "",
    name: "",
    billingInfo: {
      billingRegistrationID: "",
      maxUserCounter: 0,
      defaultChargeNotified: false,
      currentPeriodEnd: 0,
      prevMonthUsers: 0,
      payPerUserInfo: {
        userCommitment: 0,
        timeCommitment: "",
        cancelOnCommitmentEnd: false,
        commitmentPeriodRemaining: 0,
      },
      defaultPaymentSource: {
        brand: "",
        expiryMonth: 0,
        expiryYear: 0,
        last4Digits: "",
      },
      address: {
        name: "",
        city: "",
        country: "",
        line1: "",
        line2: "",
        postalCode: "",
        state: "",
      },
      serviceAgreementVersion: "",
      freeTrialExpired: false,
      verifierServiceAgreementVersion: "",
      verifierFreeTrialExpired: false,
      verifierUserConnections: 0,
      verifierAppActivations: 0,
    },
  },
  selectedPackage: {
    planType: "",
    trialEnd: 0,
    serviceAgreementVersion: "",
    verifierServiceAgreementVersion: "",
    planCategory: "",
  },
  selectedPackageFromPackageSelector: "notactive",
  cardDetailFromPackageSelector: {},
  selectedCommittedUsage: {
    userCommitment: 0,
    timeCommitment: "Monthly",
  },
  updateDigitalWalletDeploymentStatus: (deploymentStatus: any) =>
    set(() => {
      return { deploymentStatus: deploymentStatus };
    }),
  updateDigitalWalletDeploymentStatusForEWC: (deploymentStatusForEWC: any) =>
    set(() => {
      return { deploymentStatusForEWC: deploymentStatusForEWC };
    }),
  updateReadExistingBillingDetails: (readExistingBillingDetails: any) =>
    set(() => {
      return { readExistingBillingDetails: readExistingBillingDetails };
    }),
  updateSelectedPackage: (selectedPackage: any) =>
    set(() => {
      return { selectedPackage: selectedPackage };
    }),
  updateSelectedPackageFromPackageSelector: (
    selectedPackageFromPackageSelector: string
  ) =>
    set(() => {
      return {
        selectedPackageFromPackageSelector: selectedPackageFromPackageSelector,
      };
    }),
  updateCardDetailFromPackageSelector: (
    cardDetailFromPackageSelector: string
  ) =>
    set(() => {
      return {
        cardDetailFromPackageSelector: cardDetailFromPackageSelector,
      };
    }),
  updateSelectedCommittedUsage: (selectedCommittedUsage: any) =>
    set(() => {
      return {
        selectedCommittedUsage: selectedCommittedUsage,
      };
    }),
  organisationFeatures: {
    gettingStarted: true,
    dataAgreements: true,
    managedData: true,
    digitalWalletAries: true,
    digitalWalletOid4vc: true,
    manageUsers: true,
    privacyDashboard: true,
    account: true,
    supportEvents: true,
  },
  updateOrgFeatures: (organisationFeatures: any) =>
    set(() => ({ organisationFeatures: organisationFeatures })),
});

let devtoolsStore = devtools(digitalWalletStore);
let persistStore = persist(devtoolsStore, { name: "digitalWalletStore" });

export const useDigitalWalletStore = create(persistStore);
