import { AppBar, Box } from "@mui/material";

import Logo from "../../assets/iGrant_210_55_BW.svg";
import { useNavigate } from "react-router-dom";

export default function OnboardingAppBar() {
  const navigate = useNavigate();
  return (
    <Box>
      <AppBar
        sx={{
          backgroundColor: "#343A40",
          height: 90,
          display: "flex",
          justifyContent: "center",
          boxShadow: "none",
          padding: 0,
        }}
      >
        <Box
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            width={"230px"}
            height={"75px"}
            style={{cursor:"pointer"}}
            onClick={() => navigate("/login")}
          />
        </Box>
      </AppBar>
    </Box>
  );
}
