import * as React from "react";
import { Dispatch, SetStateAction, useState, useEffect } from "react";

import { Drawer, Typography, Button, Box, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
} from "./modalStyle";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CommonDropdownWithDefaultSelectValue from "../dropdowns/commonDropdownWithDefaultSelectValue";
import LoaderComponent from "../LoaderComponent";
import SnackbarComponent from "../notification";
import { HttpService } from "../../service/HTTPService";
import { isFormDataChanged } from "../../utils/isFormDataChanged";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  versions: any;
  privacyBoardDetails: any;
}

export default function ConfigurePrivacyboard(props: Props) {
  const { t } = useTranslation("translation");
  const { open, setOpen, headerText, versions, privacyBoardDetails } = props;
  const [error, setError] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const { control, handleSubmit, formState, reset, register, watch } =
    useForm<any>({
      mode: "onChange",
    });

  useEffect(() => {
    let defaultValues = {
      pbVersion: "",
      deployedDomainAddress: "",
    };

    if (
      privacyBoardDetails?.statusStr === "Deployed" ||
      privacyBoardDetails?.statusStr === "Requested"
    ) {
      reset({
        pbVersion: privacyBoardDetails.version,
        deployedDomainAddress: privacyBoardDetails.hostname,
      });
    } else reset({ ...defaultValues });
  }, [open]);

  const deployedVersion = watch("pbVersion");

  const onSubmit = (createdData: any) => {
    let payload = {
      hostname: createdData.deployedDomainAddress + ".igrant.io",
      version: createdData.pbVersion,
    };

    if (
      privacyBoardDetails?.statusStr === "Deployed" &&
      isFormDataChanged(formState)
    ) {
      setOpenLoader(true);
      if (createdData.pbVersion !== privacyBoardDetails.version) {
        HttpService.updatePrivacyBoardVersion({
          version: createdData.pbVersion,
        })
          .then(() => {
            setOpen(false);
            setOpenLoader(false);
          })
          .catch((error) => {
            setOpenSnackBar(true);
            setError(error.response.data.errorDescription);
            setOpenLoader(false);
          });
      }
      if (createdData.deployedDomainAddress !== privacyBoardDetails.hostname) {
        setOpenLoader(true);
        HttpService.updatePrivacyBoardHostname({
          hostname: createdData.deployedDomainAddress + ".igrant.io",
        })
          .then(() => {
            setOpen(false);
            setOpenLoader(false);
          })
          .catch((error) => {
            setOpenSnackBar(true);
            setError(error.response.data.errorDescription);
            setOpenLoader(false);
          });
      }
    } else if (isFormDataChanged(formState)) {
      HttpService.createPrivacyBoard(payload)
        .then(() => {
          setOpen(false);
          setOpenLoader(false);
        })
        .catch((error) => {
          setOpenSnackBar(true);
          setError(error.response.data.errorDescription);
          setOpenLoader(false);
        });
    }
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />

        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SnackbarComponent
              open={openSnackBar}
              setOpen={setOpenSnackBar}
              message={error}
              topStyle={100}
            />
            <HeaderContainer>
              <Box pl={2}>
                <Typography color="#F3F3F6">{headerText}</Typography>
              </Box>
              <CloseIcon
                onClick={() => setOpen(false)}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <DetailsContainer>
              <Box p={1.5}>
                <Typography variant="subtitle1" mb={0}>
                  {t("digitalWalletBase.dashboardRelease")}:
                </Typography>

                <CommonDropdownWithDefaultSelectValue
                  dropdownValue={versions}
                  control={control}
                  nameOfSelect={"pbVersion"}
                  valueKey={"version"}
                  displayKey="version"
                  selectedValue={deployedVersion}
                />

                <Typography variant="subtitle1" mb={0} mt={1}>
                  {t("privacyDashboard.deployedDomainAddress")}:
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    sx={{ margin: 0, marginBottom: "10px", width: "70%" }}
                    variant="standard"
                    placeholder="Please type at least 3 characters"
                    {...register("deployedDomainAddress", {
                      required: true,
                      minLength: 3,
                    })}
                    value={watch("deployedDomainAddress")?.replace(
                      ".igrant.io",
                      ""
                    )}
                  />

                  <Typography>.igrant.io</Typography>
                </Box>
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => setOpen(false)}
                style={buttonStyle}
                sx={{
                  marginLeft: "15px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>

              <Button
                variant="outlined"
                style={buttonStyle}
                sx={{
                  cursor:
                    formState.isValid && isFormDataChanged(formState)
                      ? "pointer"
                      : "not-allowed",
                  marginRight: "20px",
                  color:
                    formState.isValid && isFormDataChanged(formState)
                      ? "black"
                      : "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                type="submit"
              >
                {t("common.confirm")}
              </Button>
            </FooterContainer>
          </form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
