import { useState } from "react";
import {
  List,
  Datagrid,
  Form,
  useRefresh,
  useRecordContext,
  TextField,
} from "react-admin";

import { Box, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

// icons
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

import BreadCrumb from "../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { TableEmptyMessage } from "../../components/tableEmptyMessage";
import useLanguageChange from "../../utils/translateTableLanguage";
import DeleteModal from "../../components/modals/generalModal";
import DataAgreementModal from "../../components/modals/dataAgreementModal";
import IssueCredetialModal from "../../components/modals/issueCredetialOrSendVerificationRequestModalModal";
import { useSSIStore } from "../../store/ssiStore";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";
import VersionDropdown from "../../components/dataAgreements/versionDropdown";
import { getIconColor } from "../../utils/dataAgreementUtils";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const Credentials = () => {
  const [openDataAgreementModal, setOpenDataAgreementModal] = useState(false);
  const [dataAgreementMode, setDataAgreementMode] = useState("");
  const [openIssueCredentialModal, setOpenIssueCredentialModal] =
    useState(false);
  const [openDeleteDataAgreementModal, setOpenDeleteDataAgreementModal] =
    useState(false);
  const [
    selectededDataAgreementFromDataAgreement,
    setSelectededDataAgreementFromDataAgreement,
  ] = useState<any>();
  const [importedDataAgreement, setImportedDataAgreement] = useState<any>(null);

  // Store the selected versions and table values
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<any>({});
  const [
    selectedDropdownDataAgreementValue,
    setSelectedDropdownDataAgreementValue,
  ] = useState<any>({});

  const navigate = useNavigate();
  const digitalWalletDeploymentStatus = useDigitalWalletStore(
    (state) => state.deploymentStatus
  );

  const { t } = useTranslation("translation");
  const key = useLanguageChange();

  const refresh = useRefresh();

  const onRefetch = () => {
    refresh();
  };

  const getTextColor = (record: any) => {
    if (record.active === false) {
      if (selectedDropdownValue[record.id] === record.version) {
        return "#FF0C10"; // Red color
      } else if (selectedDropdownValue[record.id] === undefined) {
        return "#FF0C10";
      } else if (selectedDropdownValue[record.id] !== record.version) {
        return "black";
      }
      return "#FF0C10";
    }

    return "black";
  };

  const PurposeField = (props: any) => {
    const record = useRecordContext(props);
    const textColor = getTextColor(record);

    if (!record || !props.source) {
      return null;
    }
    return (
      <Typography
        variant="body2"
        sx={{
          color: textColor,
        }}
      >
        {selectedDropdownValue[record.id] === undefined ||
        selectedDropdownDataAgreementValue[record.id] === undefined ||
        selectedDropdownDataAgreementValue[record.id]?.version ===
          record.version
          ? record[props.source]
          : selectedDropdownDataAgreementValue[record.id]?.purpose}
      </Typography>
    );
  };

  const VersionField = (props: any) => {
    const record = useRecordContext(props);

    if (!record || !props.source) {
      return null;
    }

    return (
      <VersionDropdown
        record={record}
        setSelectedValue={setSelectedDropdownValue}
        selectedValue={selectedDropdownValue}
        key={record.id}
        setSelectedDropdownDataAgreementValue={
          setSelectedDropdownDataAgreementValue
        }
      />
    );
  };

  const IconsFIeld = (props: any) => {
    const record = useRecordContext(props);
    const textColor = getIconColor(
      record,
      "",
      selectedDropdownValue,
      digitalWalletDeploymentStatus
    );
    const editOrDeleteIconColor = getIconColor(
      record,
      "editOrDelete",
      selectedDropdownValue,
      digitalWalletDeploymentStatus
    );

    if (!record || !props.source) {
      return null;
    }

    return (
      record[props.source] && (
        <Box display={"flex"} justifyContent={"space-around"}>
          <Tooltip title={t("dataAgreements.viewDA")} placement="top">
            <RemoveRedEyeOutlinedIcon
              onClick={() => {
                setOpenDataAgreementModal(true);
                setDataAgreementMode("Read");
                setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>

          <Tooltip title={t("credentials.issuanceHistory")} placement="top">
            <HistoryOutlinedIcon
              onClick={() => {
                (record?.selectedRevision === undefined ||
                  (record?.selectedRevision &&
                    record?.selectedRevision.version === record.version)) &&
                  (navigate("/issuance-history"),
                  useSSIStore
                    .getState()
                    .updateDataAgreementForIssuanceHistory(record));
              }}
              fontSize="small"
              style={{
                cursor:
                  record?.selectedRevision &&
                  record?.selectedRevision.version !== record.version
                    ? "not-allowed"
                    : "pointer",
                color:
                  record?.selectedRevision &&
                  record?.selectedRevision.version !== record.version
                    ? "#B9B9B9"
                    : textColor,
              }}
            />
          </Tooltip>

          <Tooltip
            title={
              digitalWalletDeploymentStatus === "Not Configured" &&
              record.methodOfUse !== "null"
                ? t("credentials.disableCreateCredentials")
                : t("credentials.issueCredential")
            }
            placement="top"
          >
            <SendOutlinedIcon
              onClick={() => {
                digitalWalletDeploymentStatus === "Deployed" &&
                  (record?.selectedRevision === undefined ||
                    (record?.selectedRevision &&
                      record?.selectedRevision.version === record.version)) &&
                  setOpenIssueCredentialModal(true);
                setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor:
                  digitalWalletDeploymentStatus === "Deployed"
                    ? record?.selectedRevision &&
                      record?.selectedRevision.version !== record.version
                      ? "not-allowed"
                      : "pointer"
                    : "not-allowed",
                color:
                  record?.selectedRevision &&
                  record?.selectedRevision.version !== record.version
                    ? "#B9B9B9"
                    : editOrDeleteIconColor,
              }}
            />
          </Tooltip>

          <Tooltip
            title={
              digitalWalletDeploymentStatus === "Not Configured" &&
              record.methodOfUse !== "null"
                ? t("credentials.disableCreateCredentials")
                : t("dataAgreements.editDA")
            }
            placement="top"
          >
            <EditOutlinedIcon
              onClick={() => {
                digitalWalletDeploymentStatus === "Deployed" &&
                  (record?.selectedRevision === undefined ||
                    (record?.selectedRevision &&
                      record?.selectedRevision.version === record.version)) &&
                  setOpenDataAgreementModal(true);
                setDataAgreementMode("Update");
                setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor:
                  digitalWalletDeploymentStatus === "Deployed"
                    ? record?.selectedRevision &&
                      record?.selectedRevision.version !== record.version
                      ? "not-allowed"
                      : "pointer"
                    : "not-allowed",
                color:
                  record?.selectedRevision &&
                  record?.selectedRevision.version !== record.version
                    ? "#B9B9B9"
                    : editOrDeleteIconColor,
              }}
            />
          </Tooltip>
          <Tooltip
            title={
              digitalWalletDeploymentStatus === "Not Configured" &&
              record.methodOfUse !== "null"
                ? t("credentials.disableCreateCredentials")
                : t("dataAgreements.deleteDA")
            }
            placement="top"
          >
            <DeleteOutlineOutlinedIcon
              onClick={() => {
                ((digitalWalletDeploymentStatus === "Not Configured" &&
                  record.methodOfUse === "null") ||
                  digitalWalletDeploymentStatus === "Deployed") &&
                  (record?.selectedRevision === undefined ||
                    (record?.selectedRevision &&
                      record?.selectedRevision.version === record.version)) &&
                  setOpenDeleteDataAgreementModal(true),
                  setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor:
                  digitalWalletDeploymentStatus === "Deployed"
                    ? record?.selectedRevision &&
                      record?.selectedRevision.version !== record.version
                      ? "not-allowed"
                      : "pointer"
                    : "not-allowed",
                color:
                  record?.selectedRevision &&
                  record?.selectedRevision.version !== record.version
                    ? "#B9B9B9"
                    : editOrDeleteIconColor,
              }}
            />
          </Tooltip>
        </Box>
      )
    );
  };

  return (
    <Container>
      <Form>
        <BreadCrumb
          Link={t("sidebar.ariesDigitalWallet")}
          Link2={t("sidebar.credentials")}
        />
        <HeaderContainer>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              {t("credentials.pageTitle")}
            </Typography>
            <Tooltip
              title={
                digitalWalletDeploymentStatus === "Deployed"
                  ? t("dataAgreements.createDA")
                  : t("credentials.disableCreateCredentials")
              }
              placement="top"
            >
              <AddCircleOutlineOutlinedIcon
                onClick={() => {
                  digitalWalletDeploymentStatus === "Deployed" &&
                    (setOpenDataAgreementModal(true),
                    setDataAgreementMode("Create"),
                    setSelectededDataAgreementFromDataAgreement({}));
                }}
                style={{
                  cursor:
                    digitalWalletDeploymentStatus === "Deployed"
                      ? "pointer"
                      : "not-allowed",
                  marginLeft: "7px",
                }}
              />
            </Tooltip>
          </Box>
        </HeaderContainer>
        <Typography variant="body2" mt={1.25}>
          {t("credentials.pageDescription")}
        </Typography>
      </Form>
      <List
        empty={<TableEmptyMessage />}
        actions={false}
        sx={{
          width: "100%",
          "& .RaDatagrid-rowCell:last-child": {
            width: { md: "30%", lg: "19%" },
          },
          overflow: "hidden",
        }}
        filter={{ methodOfUse: "data_source" }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              width: "100%",
            }}
            key={key}
          >
            <PurposeField
              source="purpose"
              label={t("dataAgreements.usagePurpose")}
              sortable={false}
            />
            <VersionField
              source="id"
              label={t("dataAgreements.version")}
              sortable={false}
              cellClassName="custom-version-column"
            />
            <TextField
              source="dataExchange.schemaId"
              sortable={false}
              label={t("credentials.schemaIdentifier")}
            />
            <TextField
              source="dataExchange.credentialDefinitionId"
              sortable={false}
              label={t("credentials.credentialTemplateIdentifier")}
            />

            <IconsFIeld source="id" sortable={false} label={""} />
          </Datagrid>
        </Box>
      </List>

      {/* Modals */}
      <DataAgreementModal
        open={openDataAgreementModal}
        setOpen={setOpenDataAgreementModal}
        mode={dataAgreementMode}
        successCallback={onRefetch}
        selectededDataAgreementFromDataAgreement={
          selectededDataAgreementFromDataAgreement
        }
        setSelectedDropdownValue={setSelectedDropdownValue}
        ssi={"credentials"}
        importedDataAgreement={importedDataAgreement}
        setImportedDataAgreement={setImportedDataAgreement}
      />

      <DeleteModal
        open={openDeleteDataAgreementModal}
        setOpen={setOpenDeleteDataAgreementModal}
        headerText={`${t("credentials.deleteCredentialTemplate")}:`}
        confirmText="DELETE"
        confirmButtonText={`${t("common.delete")}`}
        resourceName="dataagreements"
        onRefetch={onRefetch}
        setSelectedDropdownValue={setSelectedDropdownValue}
        selectededDataAgreementFromDataAgreement={
          selectededDataAgreementFromDataAgreement
        }
        modalDescriptionText={
          <Typography variant="body1">
            {t("credentials.deleteDescription1")}
            <b>DELETE</b>
            {t("credentials.deleteDescription2")}
          </Typography>
        }
      />

      <IssueCredetialModal
        open={openIssueCredentialModal}
        setOpen={setOpenIssueCredentialModal}
        headerText={t("credentials.issueCredentials")}
        onRefetch={onRefetch}
        selectedData={selectededDataAgreementFromDataAgreement}
        ssi={"credentials"}
      />
    </Container>
  );
};

export default Credentials;
