import {
  useState,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
} from "react";
import {
  useForm,
  FormProvider,
  useFieldArray,
  useWatch,
} from "react-hook-form";

import { Drawer, Typography, Box, Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DefaultBanner from "../../assets/OrganisationDefaultBanner.jpeg";
import DefaultLogo from "../../assets/OrganisationDefaultLogo.png";

import LoaderComponent from "../LoaderComponent";
import DataAgreementPersonalDataTable from "../dataAgreements/DataAgreementPersonalDataTable";
import DataAgreementPolicy from "../dataAgreements/DataAgreementPolicy";
import DPIAConfigurations from "../dataAgreements/DPIAConfiguration";
import DataSchemaModal from "./dataSchemaModal";
import {
  Container,
  HeaderContainer,
  BannerContainer,
  DetailsContainer,
  FooterContainer,
} from "./modalStyle";
import { HttpService } from "../../service/HTTPService";
import { Purpose } from "../dataAgreements/Purpose";
import { Version } from "../dataAgreements/Version";
import { DataExchangeModeFormControl } from "../dataAgreements/DataExchangeMode";
import { PurposeDescription } from "../dataAgreements/PurposeDescription";
import { LawfullBasisOfProcessingFormControll } from "../dataAgreements/LawfullBasisOfProcessing";
import { OrganizationDetailsCRUDContext } from "../../contexts/organizationDetailsCrud";
import SnackbarComponent from "../notification";
import { isFormDataChanged } from "../../utils/isFormDataChanged";
import { useTranslation } from "react-i18next";
import DataAgreementSaveButton from "../dataAgreements/DataAgreementModalSaveButton";
import DataAgreementPublishButton from "../dataAgreements/DataAgreementModalPublishButton";
import { DexProfileSelectFormControll } from "../dataAgreements/dexProfileDropdown";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";
import { getDexaProfileValueForCreateDA } from "../dataAgreements/DataAgreementActions";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  mode: string;
  successCallback?: any;
  resourceName?: string;
  selectededDataAgreementFromDataAgreement?: any;
  setSelectedDropdownValue?: any;
  importedDataAgreement?: any;
  setImportedDataAgreement?: any;
  ssi?: string;
  dataAgreementType?: string | undefined;
  Dex?: boolean;
}

let defaultValue = {
  Name: "",
  Description: "",
  Version: "1.0.0",
  AttributeType: "null",
  LawfulBasisOfProcessing: "consent",
  PolicyURL: "https://igrant.io/policy.html",
  Jurisdiction: "London, GB",
  IndustryScope: "Retail",
  StorageLocation: "Europe",
  dataRetentionPeriodDays: 0,
  Restriction: "Europe",
  Shared3PP: false,
  DpiaDate: new Date().toISOString().slice(0, 16),
  DpiaSummaryURL: "https://privacyant.se/dpia_results.html",
  schemaId: "",
  isExistingSchema: false,
  isExistingSchemaForInput: false,
  DexProfile: "AIP10",
  dataAttributes: [
    {
      attributeName: "",
      attributeDescription: "",
      schemaId: "",
      credDefId: "",
    },
  ],
};

export default function DataAgreementModal(props: Props) {
  const {
    open,
    setOpen,
    mode,
    successCallback,
    resourceName,
    selectededDataAgreementFromDataAgreement,
    setSelectedDropdownValue,
    importedDataAgreement,
    setImportedDataAgreement,
    ssi,
    dataAgreementType,
    Dex,
  } = props;
  const { t } = useTranslation("translation");
  const [openLoader, setOpenLoader] = useState(false);
  const [selectedDataAgreement, setSelectedDataAgreement] = useState<any>();
  const [dataAgreementIdForUserRecordes, setDataAgreementIdForUserRecordes] =
    useState("");
  const [policyDetailsForInitialValue, setPolicyDetailsForInitialValue] =
    useState<any>();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    HttpService.listAllPolicies().then((response) => {
      setPolicyDetailsForInitialValue(response[0]);
    });
  }, [open]);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValue,
    },
  });
  const { control, setValue } = methods;

  const { fields, remove, append, insert } = useFieldArray({
    control,
    name: "dataAttributes",
    rules: {
      required: true,
    },
  });

  const digitalWalletDeploymentStatus = useDigitalWalletStore(
    (state) => state.deploymentStatus
  );

  const digitalWalletDeploymentStatusForEWC = useDigitalWalletStore(
    (state) => state.deploymentStatusForEWC
  );

  const AttributeType = useWatch({
    control: methods.control,
    name: `AttributeType`,
  });

  const DexProfile = useWatch({
    control: methods.control,
    name: `DexProfile`,
  });

  useEffect(() => {
    if (mode === "Create" && importedDataAgreement?.id === undefined) {
      methods.reset({
        Name: "",
        Description: "",
        Version: "1.0.0",
        AttributeType: ssi
          ? ssi === "credentials"
            ? "data_source"
            : "data_using_service"
          : "null",
        LawfulBasisOfProcessing: "consent",
        PolicyURL: policyDetailsForInitialValue?.url,
        Jurisdiction: policyDetailsForInitialValue?.jurisdiction,
        IndustryScope: policyDetailsForInitialValue?.industrySector,
        StorageLocation: policyDetailsForInitialValue?.storageLocation,
        dataRetentionPeriodDays: Math.floor(
          policyDetailsForInitialValue?.dataRetentionPeriodDays / 365
        ),
        Restriction: policyDetailsForInitialValue?.geographicRestriction,
        Shared3PP: policyDetailsForInitialValue?.thirdPartyDataSharing,
        DpiaDate: new Date().toISOString().slice(0, 16),
        DpiaSummaryURL: "https://privacyant.se/dpia_results.html",
        schemaId: "",
        isExistingSchema: false,
        // DexProfile: getDexaProfileValueForCreateDA(
        //   ssi,
        //   Dex,
        //   digitalWalletDeploymentStatus,
        //   digitalWalletDeploymentStatusForEWC
        // ),
        DexProfile: "AIP10",
        dataAttributes: [
          {
            attributeName: "",
            attributeDescription: "",
            schemaId: "",
            credDefId: "",
          },
        ],
      });
    } else {
      methods.reset({
        Name: importedDataAgreement?.purpose,
        Description: importedDataAgreement?.purposeDescription,
        Version: importedDataAgreement?.version,
        AttributeType: importedDataAgreement?.methodOfUse,
        LawfulBasisOfProcessing: importedDataAgreement?.lawfulBasis,
        PolicyURL: importedDataAgreement?.policy?.url,
        Jurisdiction: importedDataAgreement?.policy?.jurisdiction,
        IndustryScope: importedDataAgreement?.policy?.industrySector,
        StorageLocation: importedDataAgreement?.policy?.storageLocation,
        dataRetentionPeriodDays: Math.floor(
          importedDataAgreement?.policy?.dataRetentionPeriodDays / 365
        ),
        Restriction: importedDataAgreement?.policy?.geographicRestriction,
        Shared3PP: importedDataAgreement?.policy?.thirdPartyDataSharing,
        DpiaDate: importedDataAgreement?.dpiaDate,
        DpiaSummaryURL: importedDataAgreement?.dpiaSummaryUrl,
        schemaId: importedDataAgreement?.dataExchange?.schemaId,
        isExistingSchemaForInput:
          importedDataAgreement?.dataExchange?.isExistingSchema,
        isExistingSchema: false,
        DexProfile:
          importedDataAgreement?.dataExchange?.dataExchangeProfile === ""
            ? "None"
            : importedDataAgreement?.dataExchange?.dataExchangeProfile,
        dataAttributes: importedDataAgreement?.dataAttributes?.map(
          (attribute: any) => {
            const { name, description, restrictions, ...otherProps } =
              attribute;
            return {
              attributeName: name,
              attributeDescription: description,
              schemaId:
                restrictions &&
                restrictions.length > 0 &&
                restrictions[0].schemaId !== null
                  ? restrictions[0].schemaId
                  : "",
              credDefId:
                restrictions &&
                restrictions.length > 0 &&
                restrictions[0].credDefId !== null
                  ? restrictions[0].credDefId
                  : "",
              ...otherProps,
            };
          }
        ),
      });
    }

    if (
      selectededDataAgreementFromDataAgreement &&
      resourceName !== "userrecords" &&
      mode !== "Create"
    ) {
      let dataAgreements = selectededDataAgreementFromDataAgreement;
      let dataAttributes =
        selectededDataAgreementFromDataAgreement.dataAttributes;

      setSelectedDataAgreement(dataAgreements);
      if (mode === "Update") {
        methods.reset({
          Name: dataAgreements.purpose,
          Description: dataAgreements.purposeDescription,
          Version:
            dataAgreements.version === "" ? "0.0.0" : dataAgreements.version,
          AttributeType: dataAgreements.methodOfUse,
          LawfulBasisOfProcessing: dataAgreements.lawfulBasis,
          PolicyURL: dataAgreements?.policy?.url,
          Jurisdiction: dataAgreements?.policy?.jurisdiction,
          IndustryScope: dataAgreements.policy.industrySector,
          StorageLocation: dataAgreements.policy.storageLocation,
          dataRetentionPeriodDays: Math.floor(
            dataAgreements.policy.dataRetentionPeriodDays / 365
          ),
          Restriction: dataAgreements.policy.geographicRestriction,
          Shared3PP: dataAgreements.policy.thirdPartyDataSharing,
          DpiaDate: dataAgreements.dpiaDate,
          DpiaSummaryURL: dataAgreements.dpiaSummaryUrl,
          schemaId: dataAgreements.dataExchange.schemaId,
          isExistingSchemaForInput:
            dataAgreements.dataExchange.isExistingSchema,
          isExistingSchema: false,
          DexProfile:
            dataAgreements?.dataExchange?.dataExchangeProfile === ""
              ? "None"
              : dataAgreements?.dataExchange?.dataExchangeProfile,
          dataAttributes: dataAttributes?.map((attribute: any) => {
            const { name, description, restrictions, ...otherProps } =
              attribute;
            return {
              attributeName: name,
              attributeDescription: description,
              schemaId:
                restrictions &&
                restrictions.length > 0 &&
                restrictions[0].schemaId !== null
                  ? restrictions[0].schemaId
                  : "",
              credDefId:
                restrictions &&
                restrictions.length > 0 &&
                restrictions[0].credDefId !== null
                  ? restrictions[0].credDefId
                  : "",
              ...otherProps,
            };
          }),
        });
      } else if (
        mode === "Read" &&
        dataAgreements.selectedRevision !== undefined &&
        dataAgreements?.selectedRevision?.version !== dataAgreements.version
      ) {
        // View Da for old versions
        methods.reset({
          Name: dataAgreements.selectedRevision.purpose,
          Description: dataAgreements.selectedRevision.purposeDescription,
          Version: dataAgreements.selectedRevision.version,
          AttributeType: dataAgreements.selectedRevision.methodOfUse,
          LawfulBasisOfProcessing: dataAgreements.selectedRevision.lawfulBasis,
          PolicyURL: dataAgreements.selectedRevision.policy.url,
          Jurisdiction: dataAgreements.selectedRevision.policy.jurisdiction,
          IndustryScope: dataAgreements.selectedRevision.policy.industrySector,
          StorageLocation:
            dataAgreements.selectedRevision.policy.storageLocation,
          dataRetentionPeriodDays: Math.floor(
            dataAgreements.selectedRevision.policy.dataRetentionPeriodDays / 365
          ),
          Restriction:
            dataAgreements.selectedRevision.policy.geographicRestriction,
          Shared3PP:
            dataAgreements.selectedRevision.policy.thirdPartyDataSharing,
          DpiaDate: dataAgreements.selectedRevision.dpiaDate,
          DpiaSummaryURL: dataAgreements.selectedRevision.dpiaSummaryUrl,
          DexProfile:
            dataAgreements?.dataExchange?.dataExchangeProfile === ""
              ? "None"
              : dataAgreements?.dataExchange?.dataExchangeProfile,
          dataAttributes: dataAgreements.selectedRevision.dataAttributes?.map(
            (attribute: any) => {
              const { name, description, restrictions, ...otherProps } =
                attribute;

              return {
                attributeName: name,
                attributeDescription: description,
                schemaId:
                  restrictions &&
                  restrictions.length > 0 &&
                  restrictions[0].schemaId !== null
                    ? restrictions[0].schemaId
                    : "",
                credDefId:
                  restrictions &&
                  restrictions.length > 0 &&
                  restrictions[0].credDefId !== null
                    ? restrictions[0].credDefId
                    : "",
                ...otherProps,
              };
            }
          ),
        });
      } else if (
        mode === "Read" &&
        (dataAgreements?.selectedRevision?.version === dataAgreements.version ||
          dataAgreements.selectedRevision === undefined) &&
        resourceName !== "dda"
      ) {
        // View Da for latest versions
        methods.reset({
          Name: dataAgreements.purpose,
          Description: dataAgreements.purposeDescription,
          Version:
            dataAgreements.version === "" ? "0.0.0" : dataAgreements.version,
          AttributeType: dataAgreements.methodOfUse,
          LawfulBasisOfProcessing: dataAgreements.lawfulBasis,
          PolicyURL: dataAgreements.policy.url,
          Jurisdiction: dataAgreements.policy.jurisdiction,
          IndustryScope: dataAgreements.policy.industrySector,
          StorageLocation: dataAgreements.policy.storageLocation,
          dataRetentionPeriodDays: Math.floor(
            dataAgreements.policy.dataRetentionPeriodDays / 365
          ),
          Restriction: dataAgreements.policy.geographicRestriction,
          Shared3PP: dataAgreements.policy.thirdPartyDataSharing,
          DpiaDate: dataAgreements.dpiaDate,
          DpiaSummaryURL: dataAgreements.dpiaSummaryUrl,
          DexProfile:
            dataAgreements?.dataExchange?.dataExchangeProfile === ""
              ? "None"
              : dataAgreements?.dataExchange?.dataExchangeProfile,
          dataAttributes: dataAttributes?.map((attribute: any) => {
            const { name, description, restrictions, ...otherProps } =
              attribute;

            return {
              attributeName: name,
              attributeDescription: description,
              schemaId:
                restrictions &&
                restrictions.length > 0 &&
                restrictions[0].schemaId !== null
                  ? restrictions[0].schemaId
                  : "",
              credDefId:
                restrictions &&
                restrictions.length > 0 &&
                restrictions[0].credDefId !== null
                  ? restrictions[0].credDefId
                  : "",
              ...otherProps,
            };
          }),
        });
      }
    }

    if (
      selectededDataAgreementFromDataAgreement &&
      (resourceName === "userrecords" || resourceName === "dda")
    ) {
      setDataAgreementIdForUserRecordes(
        selectededDataAgreementFromDataAgreement.id
      );
      setSelectedDataAgreement(selectededDataAgreementFromDataAgreement);

      methods.reset({
        Name: selectededDataAgreementFromDataAgreement.purpose,
        Description:
          selectededDataAgreementFromDataAgreement.purposeDescription,
        Version: selectededDataAgreementFromDataAgreement.version,
        AttributeType: selectededDataAgreementFromDataAgreement.methodOfUse,
        LawfulBasisOfProcessing:
          selectededDataAgreementFromDataAgreement.lawfulBasis,
        PolicyURL: selectededDataAgreementFromDataAgreement.policy.url,
        Jurisdiction:
          selectededDataAgreementFromDataAgreement.policy.jurisdiction,
        IndustryScope:
          selectededDataAgreementFromDataAgreement.policy.industrySector,
        StorageLocation:
          selectededDataAgreementFromDataAgreement.policy.storageLocation,
        dataRetentionPeriodDays: Math.floor(
          selectededDataAgreementFromDataAgreement.policy
            .dataRetentionPeriodDays / 365
        ),
        Restriction:
          selectededDataAgreementFromDataAgreement.policy.geographicRestriction,
        Shared3PP:
          selectededDataAgreementFromDataAgreement.policy.thirdPartyDataSharing,
        DpiaDate: selectededDataAgreementFromDataAgreement.dpiaDate,
        DpiaSummaryURL: selectededDataAgreementFromDataAgreement.dpiaSummaryUrl,
        DexProfile:
          selectededDataAgreementFromDataAgreement?.dataExchange
            ?.dataExchangeProfile === ""
            ? "None"
            : selectededDataAgreementFromDataAgreement?.dataExchange
                ?.dataExchangeProfile,
        dataAttributes:
          selectededDataAgreementFromDataAgreement.dataAttributes.map(
            (attribute: any) => {
              const { name, description, restrictions, ...otherProps } =
                attribute;
              return {
                attributeName: name,
                attributeDescription: description,
                schemaId:
                  restrictions &&
                  restrictions.length > 0 &&
                  restrictions[0].schemaId,
                credDefId:
                  restrictions &&
                  restrictions.length > 0 &&
                  restrictions[0].credDefId,
                ...otherProps,
              };
            }
          ),
      });
    }
  }, [
    selectededDataAgreementFromDataAgreement,
    open,
    mode,
    policyDetailsForInitialValue,
    resourceName,
    importedDataAgreement,
    methods,
  ]);

  const [openExistingSchemaModal, setOpenExistingSchemaModal] = useState(false);

  const { organisationDetails, logoImageBase64, coverImageBase64 }: any =
    useContext(OrganizationDetailsCRUDContext);

  function getDataAgreementId(
    resourceName: string,
    dataAgreementIdForUserRecordes: string,
    selectedDataAgreement: any
  ) {
    if (resourceName === "userrecords") {
      return dataAgreementIdForUserRecordes;
    } else {
      if (selectedDataAgreement) {
        const { methodOfUse, version, active, dataExchange, id } =
          selectedDataAgreement;
        if (methodOfUse === "null") {
          return id;
        } else if (
          methodOfUse !== "null" &&
          (version !== "1.0.0" || (version === "1.0.0" && active === true))
        ) {
          return dataExchange?.id;
        } else return id;
      }
    }
  }

  return (
    <>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container
          style={{ overflow: openLoader ? "hidden" : "auto" }}
          sx={{ width: "494px" }}
        >
          <FormProvider {...methods}>
            <SnackbarComponent
              open={openSnackBar}
              setOpen={setOpenSnackBar}
              message={error}
            />
            <form>
              <HeaderContainer>
                <Box pl={2} style={{ overflow: "hidden" }}>
                  <Typography
                    style={{
                      color: "#F3F3F6",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {mode === "Create" && t("dataAgreements.addDA")}
                    {mode === "Update" &&
                      `${t("dataAgreements.editDA")}:  ${
                        selectedDataAgreement?.purpose
                      }`}
                    {mode === "Read" &&
                      `${t("dataAgreements.viewDA")}: ${
                        selectedDataAgreement?.selectedRevision?.purpose ||
                        selectedDataAgreement?.purpose
                      }`}
                  </Typography>
                  {mode !== "Create" && (
                    <Typography color="#F3F3F6">
                      {getDataAgreementId(
                        resourceName as string,
                        dataAgreementIdForUserRecordes,
                        selectedDataAgreement
                      )}
                    </Typography>
                  )}
                </Box>
                <CloseIcon
                  onClick={() => {
                    setOpen(false);
                    methods.reset({ ...defaultValue });
                    ssi === undefined &&
                      setImportedDataAgreement &&
                      setImportedDataAgreement(null);
                  }}
                  sx={{
                    paddingRight: 2,
                    cursor: "pointer",
                    color: "#F3F3F6",
                  }}
                />
              </HeaderContainer>
              <BannerContainer>
                <Box
                  style={{ height: "150px", width: "100%" }}
                  component="img"
                  alt="Banner"
                  src={
                    coverImageBase64
                      ? `data:image/jpeg;charset=utf-8;base64,${coverImageBase64}`
                      : DefaultBanner
                  }
                />
              </BannerContainer>
              <Box sx={{ marginBottom: "60px" }}>
                <Avatar
                  src={
                    logoImageBase64
                      ? `data:image/jpeg;charset=utf-8;base64,${logoImageBase64}`
                      : DefaultLogo
                  }
                  style={{
                    position: "absolute",
                    marginLeft: 50,
                    marginTop: "-65px",
                    width: "110px",
                    height: "110px",
                    border: "solid white 6px",
                  }}
                />
              </Box>
              <DetailsContainer>
                <Box p={1.5}>
                  <Typography variant="h6" fontWeight="bold">
                    {organisationDetails.name}
                  </Typography>
                  <Typography variant="subtitle1" mt={3}>
                    {t("common.overView")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color="#9F9F9F"
                    mt={1}
                    sx={{ wordWrap: "breakWord" }}
                  >
                    {organisationDetails.description}
                  </Typography>

                  <Box mt={2}>
                    <Purpose open={props.open} mode={props.mode} />

                    <Version />

                    {/* <DexProfileSelectFormControll
                      open={props.open}
                      mode={props.mode}
                      selectededDataAgreementFromDataAgreement={
                        selectededDataAgreementFromDataAgreement
                      }
                      ssi={ssi}
                      dataAgreementType={dataAgreementType}
                    /> */}

                    {/* Data exchange select */}
                    <DataExchangeModeFormControl
                      open={props.open}
                      mode={props.mode}
                      selectededDataAgreementFromDataAgreement={
                        selectededDataAgreementFromDataAgreement
                      }
                      ssi={ssi}
                      dataAgreementType={dataAgreementType}
                      methods={methods}
                    />

                    <Typography
                      style={{
                        fontSize: "14px",
                        textDecoration: "underline",
                        color:
                          mode !== "Read" &&
                          DexProfile !== "EWC10" &&
                          (AttributeType === "data_using_service" ||
                            AttributeType === "data_source")
                            ? "blue"
                            : "grey",
                        marginTop: "-7px",
                        cursor:
                          mode !== "Read" &&
                          (AttributeType === "data_using_service" ||
                            AttributeType === "data_source")
                            ? "pointer"
                            : "not-allowed",
                      }}
                      onClick={() =>
                        mode !== "Read" &&
                        DexProfile !== "EWC10" &&
                        (AttributeType === "data_using_service" ||
                          AttributeType === "data_source")
                          ? setOpenExistingSchemaModal(true)
                          : null
                      }
                    >
                      ({t("dataAgreements.chooseExistingSchemas")})
                    </Typography>

                    <PurposeDescription open={props.open} mode={props.mode} />

                    <LawfullBasisOfProcessingFormControll
                      open={props.open}
                      mode={props.mode}
                    />

                    <Typography variant="subtitle1">
                      {t("dataAgreements.dataPolicyConfigurations")}
                      <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                    </Typography>
                    <DataAgreementPolicy mode={mode} />

                    <Typography variant="subtitle1">
                      {t("dataAgreements.DPIAConfigurations")}
                    </Typography>
                    <DPIAConfigurations mode={mode} />
                  </Box>

                  <DataAgreementPersonalDataTable
                    mode={mode}
                    append={append}
                    fields={fields}
                    remove={remove}
                    formController={control}
                    methods={methods}
                  />
                </Box>
              </DetailsContainer>
              <FooterContainer
                style={{
                  flexDirection: "row-reverse",
                }}
              >
                <DataAgreementSaveButton
                  mode={mode}
                  methods={methods}
                  isFormDataChanged={isFormDataChanged}
                  successCallback={successCallback}
                  defaultValue={defaultValue}
                  setSelectedDropdownValue={setSelectedDropdownValue}
                  setImportedDataAgreement={setImportedDataAgreement}
                  setOpen={setOpen}
                  setError={setError}
                  setOpenSnackBar={setOpenSnackBar}
                  selectedDataAgreement={selectedDataAgreement}
                  setOpenLoader={setOpenLoader}
                  ssi={ssi}
                />

                <DataAgreementPublishButton
                  mode={mode}
                  methods={methods}
                  isFormDataChanged={isFormDataChanged}
                  successCallback={successCallback}
                  defaultValue={defaultValue}
                  setSelectedDropdownValue={setSelectedDropdownValue}
                  setImportedDataAgreement={setImportedDataAgreement}
                  setOpen={setOpen}
                  setError={setError}
                  setOpenSnackBar={setOpenSnackBar}
                  selectedDataAgreement={selectedDataAgreement}
                  setOpenLoader={setOpenLoader}
                />
              </FooterContainer>
            </form>
          </FormProvider>

          <DataSchemaModal
            open={openExistingSchemaModal}
            setOpen={setOpenExistingSchemaModal}
            mode={mode}
            append={append}
            fields={fields}
            remove={remove}
            methods={methods}
            formController={control}
            insert={insert}
            setValue={setValue}
            selectededDataAgreementFromDataAgreement={
              selectededDataAgreementFromDataAgreement
            }
          />
        </Container>
      </Drawer>
    </>
  );
}
