import * as React from "react";
import { Dispatch, SetStateAction, useState } from "react";

import { Drawer, Typography, Button, Box, Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
} from "../modalStyle";
import { useTranslation } from "react-i18next";
import { HttpServiceForDigitalWalletEwc } from "../../../service/digitalWalletEwcHttpService";
import SnackbarComponent from "../../notification";
import LoaderComponent from "../../LoaderComponent";
import unknownOrgLogo from "../../../assets/unknownOrgLogo.png";
import CustomTabs from "../../holder/tabs";
import { CarouselStepper } from "../../holder/carousel";
import ViewReceivedOrSharedCredential from "./viewReceivedCredential";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  onRefetch: any;
  inputDescriptor: any;
  selectedData: any;
  closeRecievePresentationModal?: Dispatch<SetStateAction<boolean>>;
  selectedFirstTable?: boolean;
}

export default function SendVerificationsModal(props: Props) {
  const {
    open,
    setOpen,
    headerText,
    onRefetch,
    inputDescriptor,
    selectedData,
    closeRecievePresentationModal,
    selectedFirstTable,
  } = props;
  const { t } = useTranslation("translation");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");
  const [openLoader, setOpenLoader] = useState(false);

  const [activeTab, setActiveTab] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [openViewModal, setOpenViewModal] = useState(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    setCurrentPage(0);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getCurrentDescriptor = (): any =>
    inputDescriptor && inputDescriptor[activeTab];
  const getCurrentCredential = (): any =>
    inputDescriptor && getCurrentDescriptor()?.matchedCredentials[currentPage];

  const onSubmit = () => {
    const selectedId = getCurrentDescriptor()?.id;
    const selectedClientId = getCurrentCredential()?.credentialId;
    // console.log("Selected ID:", selectedId);
    // console.log("Selected Client ID:", selectedClientId);
    setOpenLoader(true);

    const payload = {
      inputDescriptors: [
        {
          id: selectedId,
          credentialId: selectedClientId,
        },
      ],
    };
    HttpServiceForDigitalWalletEwc.sendVerifications(
      payload,
      selectedData.presentationId
    )
      .then(() => {
        setTimeout(() => {
          onRefetch();
        }, 1000);
        setOpen(false);
        setOpenLoader(false);
        closeRecievePresentationModal && closeRecievePresentationModal(false);
      })
      .catch((error) => {
        setOpenSnackBar(true);
        setError(error.response.data.errorDescription);
        setOpenLoader(false);
      });
  };

  const getOrganisationLogo = () => {
    if (selectedFirstTable === true) {
      return selectedData?.issuer?.logo
        ? selectedData?.issuer?.logo
        : unknownOrgLogo;
    } else {
      return selectedData?.clientMetadata?.logoUri
        ? selectedData?.clientMetadata?.logoUri
        : unknownOrgLogo;
    }
  };

  const getOrganisationName = () => {
    if (selectedFirstTable === true) {
      return selectedData?.issuer?.name
        ? selectedData?.issuer?.name
        : t("holder.Unknown");
    } else {
      return selectedData?.clientMetadata?.clientName
        ? selectedData?.clientMetadata?.clientName
        : t("holder.Unknown");
    }
  };

  const getOrganisationLocation = () => {
    if (selectedFirstTable === true) {
      return selectedData?.issuer?.location
        ? selectedData?.issuer?.location
        : t("holder.notDiscoverable");
    } else {
      return selectedData?.clientMetadata?.location
        ? selectedData?.clientMetadata?.location
        : t("holder.notDiscoverable");
    }
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container style={{ overflow: openLoader ? "hidden" : undefined }}>
          <form>
            <SnackbarComponent
              open={openSnackBar}
              setOpen={setOpenSnackBar}
              message={error}
              topStyle={100}
            />
            <HeaderContainer>
              <Box pl={2} sx={{ display: "flex", alignItems: "center" }}>
                <ChevronLeftIcon
                  onClick={() => {
                    setOpen(false);
                  }}
                  sx={{
                    marginRight: 1,
                    cursor: "pointer",
                    color: "#F3F3F6",
                  }}
                />
                <Typography color="#F3F3F6">{headerText}</Typography>
              </Box>
              <CloseIcon
                onClick={() => {
                  setOpen(false);
                  closeRecievePresentationModal &&
                    closeRecievePresentationModal(false);
                }}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <DetailsContainer sx={{ paddingBottom: "100px" }}>
              <Box p={1.5}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    src={getOrganisationLogo()}
                    style={{
                      // position: "absolute",
                      marginLeft: 10,
                      width: "110px",
                      height: "110px",
                      border: "solid white 6px",
                    }}
                  />
                  <Box>
                    {" "}
                    <Typography variant="h6" fontWeight="bold">
                      {getOrganisationName()}
                    </Typography>
                    <Typography color="#9F9F9F">
                      {getOrganisationLocation()}
                    </Typography>
                  </Box>
                </Box>

                <Typography
                  color="#9F9F9F"
                  mt={1}
                  mb={2}
                  variant="body2"
                  sx={{ wordWrap: "breakWord" }}
                >
                  {t("holder.confirmToShare")}{" "}
                  <span style={{ fontWeight: "bold", color: "#707070" }}>
                    {getOrganisationName()}
                  </span>
                  .
                </Typography>

                <CustomTabs
                  tabs={inputDescriptor}
                  activeTab={activeTab}
                  onTabChange={handleTabChange}
                  mode={"Create"}
                />

                <CarouselStepper
                  items={getCurrentDescriptor()?.matchedCredentials}
                  current={currentPage}
                  onChange={handlePageChange}
                  setOpenViewModal={setOpenViewModal}
                />
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => {
                  setOpen(false);
                  closeRecievePresentationModal &&
                    closeRecievePresentationModal(false);
                }}
                style={buttonStyle}
                sx={{
                  marginLeft: "15px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>

              <Button
                variant="outlined"
                style={buttonStyle}
                sx={{
                  cursor: "pointer",
                  marginRight: "20px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                onClick={onSubmit}
              >
                {t("onboarding.submit")}
              </Button>
            </FooterContainer>
          </form>

          <ViewReceivedOrSharedCredential
            open={openViewModal}
            setOpen={setOpenViewModal}
            headerText={t("holder.viewStoredCredential")}
            selectedFirstTable={"not-table"}
            credentialId={getCurrentCredential()?.credentialId}
            closeRecievePresentationModal={closeRecievePresentationModal}
            closeSendVerificationModal={setOpen}
          />
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
