import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

interface Props {
  attributeKey: string;
  attributeValue: any;
}

const Table = styled('table')({
  width: '100%',
  maxWidth: '100%',
  borderCollapse: 'collapse',
  tableLayout: 'fixed',
});

const TableCell = styled('td')({
  fontWeight: 'normal',
  fontSize: '14px',
  border: 'solid 1px #dee2e6',
  textAlign: 'left',
  fontFamily: 'Inter,Helvetica,Arial,sans-serif',
  padding: '5px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const CheckboxCell = styled(TableCell)({
  width: '10%',
});

const TruncatedCell: React.FC<{ content: string }> = ({ content }) => (
  <Tooltip title={content} arrow placement="top">
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
      {content}
    </div>
  </Tooltip>
);

export const DataAttributeTableForSendVerification: React.FC<Props> = ({
  attributeKey,
  attributeValue,
}) => {
  const flattenObject = (
    obj: any,
    prefix = ""
  ): { [key: string]: string | number | boolean } => {
    return Object.keys(obj).reduce((acc: { [key: string]: string | number | boolean }, k) => {
      const pre = prefix.length ? prefix + "." : "";
      if (
        typeof obj[k] === "object" &&
        obj[k] !== null &&
        !Array.isArray(obj[k])
      ) {
        Object.assign(acc, flattenObject(obj[k], pre + k));
      } else if (Array.isArray(obj[k])) {
        obj[k].forEach((item: any, index: number) => {
          if (typeof item === "object" && item !== null) {
            Object.assign(acc, flattenObject(item, `${pre}${k}[${index}]`));
          } else {
            acc[`${pre}${k}[${index}]`] = item;
          }
        });
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});
  };

  const flattenedData = flattenObject({ [attributeKey]: attributeValue });

  return (
    <Table>
      <colgroup>
        <col style={{ width: "50%" }} />
        <col style={{ width: "40%" }} />
        <col style={{ width: "10%" }} />
      </colgroup>
      <tbody>
        {Object.entries(flattenedData).map(([key, value]) => {
          const displayKey = key.replace(/^[$]\.credentialSubject\./, "");
          return (
            <tr key={key}>
              <TableCell>
                <TruncatedCell content={displayKey} />
              </TableCell>
              <TableCell>
                <TruncatedCell content={String(value)} />
              </TableCell>
              <CheckboxCell>
                <input
                  type="checkbox"
                  checked
                  disabled
                  style={{
                    margin: "0 auto",
                    display: "block",
                    cursor: "not-allowed",
                  }}
                  readOnly
                />
              </CheckboxCell>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default DataAttributeTableForSendVerification;