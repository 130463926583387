import { useState } from "react";
import {
  List,
  Datagrid,
  Form,
  useRefresh,
  useRecordContext,
  TextField,
} from "react-admin";

import { Box, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

// icons
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";

import BreadCrumb from "../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";

import { TableEmptyMessage } from "../../components/tableEmptyMessage";
import useLanguageChange from "../../utils/translateTableLanguage";
import DeleteModal from "../../components/modals/generalModal";
import moment from "moment";
import ViewExchangeRecordModal from "../../components/modals/viewExchangeRecordModal";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";
import EwcCreateCredentialDefinitionModal from "../../components/modals/ewcModals/ewcCreateCredentialDefinitionModal";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useNavigate } from "react-router-dom";
import { useSSIStore } from "../../store/ssiStore";
import EwcIssuenceModal from "../../components/modals/ewcModals/ewcIssuenceModal";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const CredentialsEWC = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [
    selectededDataAgreementFromDataAgreement,
    setSelectededDataAgreementFromDataAgreement,
  ] = useState<any>();
  const [openViewExchangeRecordModal, setOpenViewExchangeRecordModal] =
    useState(false);
  const [openIssueCredentialModal, setOpenIssueCredentialModal] =
    useState(false);
  const navigate = useNavigate();
  const [
    openCreateCredentialDefinitionModal,
    setOpenCreateCredentialDefinitionModal,
  ] = useState(false);
  // for controlling issuence modal create and update
  const [issuanceModalMode, setIssuanceModalMode] = useState("Create");
  const [credentialDefinitionModalMode, setCredentialDefinitionModalMode] =
    useState("Create");

  const digitalWalletDeploymentStatus = useDigitalWalletStore(
    (state) => state.deploymentStatusForEWC
  );

  const { t } = useTranslation("translation");
  const key = useLanguageChange();

  const refresh = useRefresh();

  const onRefetch = () => {
    refresh();
  };

  const IconsFIeld = (props: any) => {
    const record = useRecordContext(props);

    if (!record || !props.source) {
      return null;
    }

    return (
      record[props.source] && (
        <Box display={"flex"} justifyContent={"space-around"}>
          <Tooltip
            title={t("issuanceHistory.viewCredentialDefinition")}
            placement="top"
          >
            <RemoveRedEyeOutlinedIcon
              onClick={() => {
                setSelectededDataAgreementFromDataAgreement(record);
                setCredentialDefinitionModalMode("View");
                setOpenCreateCredentialDefinitionModal(true);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>

          <Tooltip title={t("credentials.issuanceHistory")} placement="top">
            <HistoryOutlinedIcon
              onClick={() => {
                navigate("/oid4vc-issuance-history"),
                  useSSIStore
                    .getState()
                    .updateDataAgreementForIssuanceHistory(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>

          <Tooltip title={t("credentials.issueCredential")} placement="top">
            <SendOutlinedIcon
              onClick={() => {
                setSelectededDataAgreementFromDataAgreement(record);
                setIssuanceModalMode("Create");
                setOpenIssueCredentialModal(true);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>
          <Tooltip
            title={t("issuanceHistory.editCredentialDefinition")}
            placement="top"
          >
            <EditOutlinedIcon
              onClick={() => {
                setSelectededDataAgreementFromDataAgreement(record);
                setCredentialDefinitionModalMode("Update");
                setOpenCreateCredentialDefinitionModal(true);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>

          {/* <Tooltip
            title={t("issuanceHistory.viewIssuedDataAndDA")}
            placement="top"
          >
            <RemoveRedEyeOutlinedIcon
              onClick={() => {
                record.dataAttributeValues !== null &&
                  setOpenViewExchangeRecordModal(true);
                setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor:
                  record.dataAttributeValues !== null
                    ? "pointer"
                    : "not-allowed",
                color:
                  record.dataAttributeValues !== null ? "#4D4D4F" : "#B9B9B9",
              }}
            />
          </Tooltip> */}

          <Tooltip
            title={t("issuanceHistory.deleteCredentialDefinition")}
            placement="top"
          >
            <DeleteOutlineOutlinedIcon
              onClick={() => {
                setOpenDeleteModal(true),
                  setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>
        </Box>
      )
    );
  };

  const CreatedDateField = (props: any) => {
    const record = useRecordContext(props);

    const m = moment.unix(record[props.source]);
    const formattedDate = m.format("MMMM Do YYYY, h:mmA");

    if (!record || !props.source) {
      return null;
    }

    return (
      <Typography
        variant="body2"
        sx={{
          color: "#4D4D4F",
        }}
      >
        {formattedDate}
      </Typography>
    );
  };

  const credentialModalHeaderText =
    credentialDefinitionModalMode === "Create"
      ? t("issuanceHistory.createCredentialDefinition")
      : credentialDefinitionModalMode === "View"
        ? t("issuanceHistory.viewCredentialDefinition")
        : credentialDefinitionModalMode === "Update"
          ? t("issuanceHistory.editCredentialDefinition")
          : "";

  return (
    <Container>
      <Form>
        <BreadCrumb
          Link={t("sidebar.openIDDigitalWallet")}
          Link2={t("sidebar.credentials")}
        />
        <HeaderContainer>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              {t("credentials.pageTitle")}
            </Typography>
            <Tooltip
              title={
                digitalWalletDeploymentStatus === "Deployed"
                  ? t("issuanceHistory.createCredentialDefinition")
                  : t("credentials.disableCreateCredentials")
              }
              placement="top"
            >
              <AddCircleOutlineOutlinedIcon
                onClick={() => {
                  digitalWalletDeploymentStatus === "Deployed" &&
                    setOpenCreateCredentialDefinitionModal(true);
                  setSelectededDataAgreementFromDataAgreement(null);
                  setCredentialDefinitionModalMode("Create");
                }}
                style={{
                  cursor:
                    digitalWalletDeploymentStatus === "Deployed"
                      ? "pointer"
                      : "not-allowed",
                  marginLeft: "7px",
                }}
              />
            </Tooltip>
          </Box>
        </HeaderContainer>
        <Typography variant="body2" mt={1.25}>
          {t("credentials.pageDescription")}
        </Typography>
      </Form>
      <List
        empty={<TableEmptyMessage />}
        actions={false}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              overflow: "auto",
              width: "100%",
              "& .RaDatagrid-rowCell:last-child": {
                width: { md: "30%", lg: "19%" },
              },
            }}
            key={key}
          >
            <TextField
              source="label"
              sortable={false}
              label={t("dataAgreements.usagePurpose")}
            />
            <CreatedDateField
              source="createdAt"
              sortable={false}
              label={t("credentials.creationDate")}
            />

            <TextField
              source="credentialDefinitionId"
              sortable={false}
              label={t("credentials.credentialDefinitonID")}
            />
            <TextField
              source="credentialType"
              sortable={false}
              label={t("issuanceHistory.credentialType")}
            />

            <IconsFIeld source="id" sortable={false} label={""} />
          </Datagrid>
        </Box>
      </List>

      {/* Modals */}

      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        headerText={`${t("issuanceHistory.deleteCredentialDefinition")}`}
        confirmText="DELETE"
        confirmButtonText={`${t("common.delete")}`}
        resourceName="credentialDefinionEWC"
        onRefetch={onRefetch}
        selectededDataAgreementFromDataAgreement={
          selectededDataAgreementFromDataAgreement
        }
        modalDescriptionText={
          <Typography variant="body1">
            {t("issuanceHistory.deleteDescription1")}
            <b>DELETE</b>
            {t("issuanceHistory.deleteDescription2")}
          </Typography>
        }
      />

      <ViewExchangeRecordModal
        open={openViewExchangeRecordModal}
        setOpen={setOpenViewExchangeRecordModal}
        headerText={t("issuanceHistory.viewExchangeRecord")}
        selectedData={selectededDataAgreementFromDataAgreement}
        ssi={"issuanceHistoryEWC"}
      />

      <EwcCreateCredentialDefinitionModal
        open={openCreateCredentialDefinitionModal}
        setOpen={setOpenCreateCredentialDefinitionModal}
        headerText={credentialModalHeaderText}
        onRefetch={onRefetch}
        ssi={"credentials"}
        credentialDefinitionModalMode={credentialDefinitionModalMode}
        selectedData={selectededDataAgreementFromDataAgreement}
      />

      <EwcIssuenceModal
        open={openIssueCredentialModal}
        setOpen={setOpenIssueCredentialModal}
        headerText={t("credentials.issueCredentials")}
        onRefetch={onRefetch}
        ssi={"credentials"}
        issuanceModalMode={issuanceModalMode}
        selectedData={selectededDataAgreementFromDataAgreement}
      />
    </Container>
  );
};

export default CredentialsEWC;
