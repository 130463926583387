import {
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ReactPhoneInput from "react-phone-input-2";
import { validatePhoneNumber } from "../../utils/validatePhoneNumber";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { useForm } from "react-hook-form";
import { OnBoardingHttpService } from "../../service/onboardingService";
import { useOnBoardingStore } from "../../store/onBoardingStore";
const buttonStyle = {
  height: 30,
  width: 150,
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const phoneInputStyle = {
  borderWidth: 1,
  borderBottomWidth: 1.2,
  width: "100%",
};

const phoneInputStyleError = {
  borderWidth: 1,
  borderBottomWidth: 1.2,
  width: "100%",
  borderBottomColor: "red",
  borderColor: "red",
};

interface Props {
  handleNext: any;
  setError: Dispatch<SetStateAction<string>>;
  setOpenSnackBar: Dispatch<SetStateAction<boolean>>;
}

const OrganisationAdministratorInformation = (props: Props) => {
  const { handleNext, setError, setOpenSnackBar } = props;
  const [phone, setPhone] = useState("");

  const { handleSubmit, register, formState, reset } = useForm<any>({
    mode: "onChange",
    defaultValues: {},
  });

  const onbordingStore = useOnBoardingStore(
    (state) => state.orgAdminInformation
  );

  useEffect(() => {
    let defaultValues = {
      orgName: onbordingStore.orgName ? onbordingStore.orgName :  "",
      orgAdminName: onbordingStore.orgAdminName ? onbordingStore.orgAdminName :  "",
      orgAdminEmail: onbordingStore.orgAdminEmail ? onbordingStore.orgAdminEmail :  "",
      orgPassword: onbordingStore.orgPassword ? onbordingStore.orgPassword :  "",
    };

    setPhone(onbordingStore.orgPhone ? onbordingStore.orgPhone : "" )
    reset({ ...defaultValues });
  }, []);

  const { t } = useTranslation("translation");
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (createdData: any) => {
    if (validatePhoneNumber(phone)) {
      // update value in store
      useOnBoardingStore.getState().updateOrgAdminInformation({
        orgName: createdData.orgName,
        orgAdminName: createdData.orgAdminName,
        orgAdminEmail: createdData.orgAdminEmail,
        orgPassword: createdData.orgPassword,
        orgPhone: phone,
      });

      OnBoardingHttpService.verifyEmail({
        email: createdData.orgAdminEmail,
      }).then((res) => {
        if (res.data.result === true) {
          OnBoardingHttpService.validatePhone({ phone: phone }).then((res) => {
            if (res.data.result === true) {
              OnBoardingHttpService.verifyPhone({ phone: phone })
                .then(() => {
                  handleNext();
                })
                .catch((error) => {
                  setOpenSnackBar(true);
                  setError(error.response.data.errorDescription);
                });
            } else {
              setOpenSnackBar(true);
              setError(res.data.message);
            }
          });
        } else {
          setOpenSnackBar(true);
          setError(res.data.message);
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" color={"black"} fontWeight="bold">
        {t("onboarding.organisationAdministratorInformation")}
      </Typography>
      <Typography variant="subtitle2" mt={2}>
        {t("gettingStarted.organisationName")}
      </Typography>
      <TextField
        autoComplete="off"
        variant="outlined"
        error={
          formState.submitCount > 0 && (formState.errors.orgName ? true : false)
        }
        sx={{
          width: { xs: "100%", lg: "65%" },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgName
                  ? "#f44336"
                  : "grey",
            },
            "&:hover fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgName
                  ? "#f44336"
                  : "grey",
            },
            "&.Mui-focused fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgName
                  ? "#f44336"
                  : "grey",
            },
          },
        }}
        placeholder={t("gettingStarted.organisationName")}
        {...register("orgName", {
          required: true,
          minLength: 1,
        })}
      />
      <Typography variant="subtitle2" mt={2}>
        {t("onboarding.adminName")}
      </Typography>
      <TextField
        autoComplete="off"
        variant="outlined"
        sx={{
          width: { xs: "100%", lg: "65%" },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgAdminName
                  ? "#f44336"
                  : "grey",
            },
            "&:hover fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgAdminName
                  ? "#f44336"
                  : "grey",
            },
            "&.Mui-focused fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgAdminName
                  ? "#f44336"
                  : "grey",
            },
          },
        }}
        placeholder={t("onboarding.adminName")}
        {...register("orgAdminName", {
          required: true,
          minLength: 1,
        })}
      />
      <Typography variant="subtitle2" mt={2}>
        {t("onboarding.adminEmail")}
      </Typography>
      <TextField
        autoComplete="off"
        variant="outlined"
        sx={{
          width: { xs: "100%", lg: "65%" },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgAdminEmail
                  ? "#f44336"
                  : "grey",
            },
            "&:hover fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgAdminEmail
                  ? "#f44336"
                  : "grey",
            },
            "&.Mui-focused fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgAdminEmail
                  ? "#f44336"
                  : "grey",
            },
          },
        }}
        placeholder={t("onboarding.adminEmail")}
        {...register("orgAdminEmail", {
          required: true,
          minLength: 3,
          pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        })}
      />
      <Typography variant="subtitle2" mt={2} mb={1}>
        {t("onboarding.adminPhone")}
      </Typography>
      <Box sx={{ width: { xs: "100%", lg: "65%" } }}>
        <ReactPhoneInput
          country={"se"}
          inputProps={{ name: "phone", autoComplete: "off" }}
          inputStyle={
            formState.submitCount > 0 && !validatePhoneNumber(phone)
              ? phoneInputStyleError
              : phoneInputStyle
          }
          placeholder={t("onboarding.adminPhone")}
          value={phone}
          onChange={(value) => {
            setPhone(value);
            validatePhoneNumber(value);
          }}
        />
      </Box>
      <Typography variant="subtitle2" mt={2}>
        {t("onboarding.adminPassword")}
      </Typography>
      <TextField
        autoComplete="off"
        variant="outlined"
        sx={{
          width: { xs: "100%", lg: "65%" },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgPassword
                  ? "#f44336"
                  : "grey",
            },
            "&:hover fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgPassword
                  ? "#f44336"
                  : "grey",
            },
            "&.Mui-focused fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgPassword
                  ? "#f44336"
                  : "grey",
            },
          },
        }}
        placeholder={t("onboarding.adminPassword")}
        type={showPassword ? "text" : "password"}
        {...register("orgPassword", {
          required: true,
          minLength: 8,
        })}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword((prev) => !prev)}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityOff style={{ color: "black" }} />
                ) : (
                  <Visibility style={{ color: "black" }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Box mt={2}>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            color: "black",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          type="submit"
          endIcon={<KeyboardArrowRightIcon />}
        >
          <Typography variant="subtitle2">{t("onboarding.next")}</Typography>
        </Button>
      </Box>
      
    </form>
  );
};

export default OrganisationAdministratorInformation;
