import { Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { styled } from "@mui/material/styles";
import CheckoutForm from "./stripeCardForm";
import { Elements } from "react-stripe-elements";
import { StripeProvider } from "react-stripe-elements";
import { useOnBoardingStore } from "../../store/onBoardingStore";
import { Box } from "@mui/system";

const buttonStyle = {
  height: 30,
  width: 150,
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent:"space-between",
  marginTop: "20px",
}));

interface Props {
  handleNext: any;
  handleBack: any;
  setError: Dispatch<SetStateAction<string>>;
  setOpenSnackBar: Dispatch<SetStateAction<boolean>>;
}

const DetailsContainer = styled("div")(({ theme }) => ({
  display: "grid",
  border: "1px solid #DFE0E1",
  borderRadius: 3,
  padding: "12px",
  width: "100%",
}));

const PaymentInformation = (props: Props) => {
  const { handleNext, handleBack } = props;
  const onbordingStore: any = useOnBoardingStore((state) => state.cardDetails);

  const [addCard, setAddCard] = useState(
    onbordingStore.card !== undefined ? true : false
  );
  const { t } = useTranslation("translation");

  const handleSubmit = (event: React.FormEvent) => {
    if (onbordingStore.card !== undefined) {
      handleNext();
    }
  };

  return (
    <>
      <Typography variant="h6" color={"black"} fontWeight="bold">
        {t("onboarding.paymentInformation")}
      </Typography>

      <Typography variant="subtitle2" color={"black"} mt={2} mb={2}>
        {t("onboarding.paymentInfoPageDescription")}:
      </Typography>
      <DetailsContainer>
        <Typography variant="subtitle1" color={"black"} fontWeight="bold">
          {addCard === false
            ? t("onboarding.cardDetails")
            : t("onboarding.paymentMethod")}
        </Typography>

        <Box mt={1} mb={1} style={{ height: "20px" }}>
          <Typography variant="subtitle2" color={"black"}>
            {addCard === false ? t("onboarding.enterCardDetails") : ""}
          </Typography>
        </Box>

        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string}>
          <Elements>
            <CheckoutForm addCard={addCard} setAddCard={setAddCard} />
          </Elements>
        </StripeProvider>
      </DetailsContainer>

      <ButtonContainer>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={handleBack}
        >
          <Typography variant="subtitle2">{t("onboarding.prev")}</Typography>
        </Button>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            color: onbordingStore.card !== undefined ? "black" : "#6D7676",
            cursor:
              onbordingStore.card !== undefined ? "pointer" : "not-allowed",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          onClick={handleSubmit}
          endIcon={<KeyboardArrowRightIcon />}
        >
          <Typography variant="subtitle2">{t("onboarding.next")}</Typography>
        </Button>
      </ButtonContainer>
    </>
  );
};

export default PaymentInformation;
