import React, { Dispatch, SetStateAction } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExistingSchamIdChips from "./ExistingSchemaIdChips";
import { HttpServiceForDigitalWallet } from "../../service/digitalWalletHttpService";

interface Props {
  append: any;
  setOpenLoader: Dispatch<SetStateAction<boolean>>;
  insert: any;
  fields: any;
  remove: any;
  setValue: any;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  existingSchemaValue: any;
  setExistingSchemaValue: Dispatch<SetStateAction<any>>;
  setToolTipMessage: Dispatch<SetStateAction<string>>;
  setDisableBackButton: Dispatch<SetStateAction<boolean>>;
}

const ExistingSchemaIDContainer = (props: Props) => {
  const {
    append,
    setOpenLoader,
    remove,
    setValue,
    searchValue,
    setSearchValue,
    existingSchemaValue,
    setExistingSchemaValue,
    setToolTipMessage,
    setDisableBackButton,
    fields,
  } = props;

  const { t } = useTranslation("translation");

  const handleSearch = () => {
    setOpenLoader(true);

    HttpServiceForDigitalWallet.getSchemaValuesByExistingId(searchValue)
      .then((data: any) => {
        setOpenLoader(false);

        if (fields.length > 0) {
          // Remove all existing fields
          for (let i = fields.length - 1; i >= 0; i--) {
            remove(i);
          }
        }

        // append value to form
        data.data.schema.attrNames?.forEach((attrName: string) => {
          append({
            attributeName: attrName,
            attributeDescription: "",
            schemaId: "",
            credDefId: "",
          });
        });
        setExistingSchemaValue(data.data.schema);

        setValue("schemaId", data.data.schema.id);
        setValue("isExistingSchema", true);
        setDisableBackButton(false);
        setToolTipMessage("");
      })
      .catch(() => {
        setOpenLoader(false);
        setValue("schemaId", "");
        setValue("isExistingSchema", false);
        setExistingSchemaValue(null);
      });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      searchValue.length > 0 && handleSearch();
    }
  };

  return (
    <Box p={2}>
      <Typography variant="subtitle1" mb={0}>
        {t("dataAgreements.existingSchemaID")}
        <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
      </Typography>
      <TextField
        autoComplete="off"
        sx={{ margin: 0 }}
        autoFocus
        variant="standard"
        fullWidth
        placeholder={t("dataAgreements.existingSchemaIDForReuse")}
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
        onKeyDown={handleKeyDown}
      />
      <Box mt={2} sx={{ display: "flex", flexWrap: "wrap" }}>
        {existingSchemaValue?.attrNames?.map((result: any, index: number) => (
          <ExistingSchamIdChips key={index} index={index} attrName={result} />
        ))}
      </Box>
    </Box>
  );
};

export default ExistingSchemaIDContainer;
