import { Box } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

const titleAttrRestrictionStyle = (isLastAttribute: boolean) => ({
  fontWeight: "normal",
  margin: "0px 10px 0px 10px",
  borderBottom: isLastAttribute ? "none" : "solid 1px #dee2e6",
  lineHeight: "1.5rem",
});

const tableAttrAdditionalInfoStyle = {
  border: 0,
  width: "100%",
  maxWidth: "100%",
  marginBottom: "0rem",
  backgroundColor: "#FFFF",
};

interface Props {
  fields: any;
  mode: string;
  control: any;
  remove: any;
}

const inputStyleAttr = {
  width: "85%",
  color: "#495057",
  border: "1",
  borderWidth: 0,
  padding: 0,
  paddingBottom: 1,
  borderRadius: 0,
  fontSize: "14px",
  borderBottomWidth: 1.2,
  backgroundColor: "#FFFF",
  borderBottomColor: "red", //'#DFE0E1',
  marginRight: "10px",
  fontFamily: "Inter,Helvetica,Arial,sans-serif",
};

export const DataAttributeCardEWCPresentationDefinition = (props: Props) => {
  const { fields, mode, control, remove } = props;
  const { t } = useTranslation("translation");

  return (
    <Box
      style={{
        marginTop: "10px",
        border: "1px solid #DFE0E1",
        borderRadius: 5,
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      {fields.map((field: any, index: number) => {
        const isLastAttribute = index === (fields?.length || 0) - 1;

        return (
          <Box style={titleAttrRestrictionStyle(isLastAttribute)}             key={field.id}
          >
            <table style={tableAttrAdditionalInfoStyle}>
              <tbody>
                <tr style={{ display: "flex", alignItems: "center" }}>
                  <Controller
                    name={`fields.${index}.path`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <input
                        value={value}
                        onChange={onChange}
                        disabled={mode === "View"}
                        placeholder={t("personalData.dataAttributeName")}
                        style={{
                          ...inputStyleAttr,
                          border: "none",
                          outline: "none",
                          width: "100%",
                          cursor: mode === "View" ? "not-allowed" : "auto",
                        }}
                        type="text"
                        autoComplete="off"
                      />
                    )}
                  />

                  <th style={{ marginTop: "-5px" }}>
                    <Controller
                      name={`fields.${index}.isHidden`}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <input
                          disabled={mode === "View"}
                          type="checkbox"
                          style={{
                            transform: "scale(1.3)",
                            cursor: mode === "View" ? "not-allowed" : "pointer",
                          }}
                          checked={!value}
                          onChange={() => onChange(!value)}
                        />
                      )}
                    />
                  </th>

                  <th>
                    <DeleteOutlineOutlinedIcon
                      style={{
                        cursor: mode === "View" ? "not-allowed" : "pointer",
                      }}
                      onClick={() => remove(index)}
                    />
                  </th>
                </tr>
              </tbody>
            </table>
          </Box>
        );
      })}
    </Box>
  );
};
