import * as React from "react";
import { Dispatch, SetStateAction, useEffect } from "react";

import { Drawer, Typography, Button, Box, Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import DefaultBanner from "../../../assets/OrganisationDefaultBanner.jpeg";
import unknownOrgLogo from "../../../assets/unknownOrgLogo.png";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  BannerContainer,
} from "../modalStyle";

import { useTranslation } from "react-i18next";

import { DataAgreementPolicyCardForEWC } from "../../issuanceHistory/dataAgreementPolicyCardForEWC";
import { DataAttributeCardHolder } from "../../holder/dataAttributeCardHolder";
import DateShowComponent from "../../holder/dateShowingComponent";
import { HttpServiceForDigitalWalletEwc } from "../../../service/digitalWalletEwcHttpService";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  selectedData?: any;
  selectedFirstTable?: true | false | "not-table";
  credentialId?: any;
  closeRecievePresentationModal?: Dispatch<SetStateAction<boolean>>;
  closeSendVerificationModal?: Dispatch<SetStateAction<boolean>>;
}

export default function ViewReceivedCredential(props: Props) {
  const { t } = useTranslation("translation");
  const {
    open,
    setOpen,
    headerText,
    selectedData,
    selectedFirstTable,
    credentialId,
    closeRecievePresentationModal,
    closeSendVerificationModal,
  } = props;

  const [selectedDataFromApi, setSelectedDataFromApi] = React.useState<any>();

  useEffect(() => {
    if (selectedFirstTable === "not-table") {
      HttpServiceForDigitalWalletEwc.readCredentialForEWC(credentialId).then(
        (res: any) => {
          setSelectedDataFromApi(res.credential);
        }
      );
    }
  }, [open]);

  const [blurPersonalData, setBlurPersonalData] = React.useState(true);

  const getBannerImage = () => {
    if (selectedFirstTable === true) {
      return selectedData?.issuer?.cover && selectedData?.issuer?.cover !== ""
        ? selectedData?.issuer?.cover
        : DefaultBanner;
    } else {
      return selectedDataFromApi?.issuer?.cover &&
        selectedDataFromApi?.issuer?.cover !== ""
        ? selectedDataFromApi?.issuer?.cover
        : DefaultBanner;
    }
  };

  const getLogoImage = () => {
    if (selectedFirstTable === true) {
      return selectedData?.issuer?.logo && selectedData?.issuer?.logo !== ""
        ? selectedData?.issuer?.logo
        : unknownOrgLogo;
    } else {
      return selectedDataFromApi?.issuer?.logo &&
        selectedDataFromApi?.issuer?.logo !== ""
        ? selectedDataFromApi?.issuer?.logo
        : unknownOrgLogo;
    }
  };

  const getOrgName = () => {
    if (selectedFirstTable === true) {
      return selectedData?.issuer?.name && selectedData?.issuer?.name !== ""
        ? selectedData?.issuer?.name
        : t("holder.Unknown");
    } else {
      return selectedDataFromApi?.issuer?.name &&
        selectedDataFromApi?.issuer?.name !== ""
        ? selectedDataFromApi?.issuer?.name
        : t("holder.Unknown");
    }
  };
  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <Container>
          <HeaderContainer>
            <Box
              pl={2}
              style={{
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
              }}
            >
              {selectedFirstTable === "not-table" && (
                <ChevronLeftIcon
                  onClick={() => {
                    setOpen(false);
                  }}
                  sx={{
                    marginRight: 1,
                    cursor: "pointer",
                    color: "#F3F3F6",
                  }}
                />
              )}
              <Typography
                style={{
                  color: "#F3F3F6",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {headerText}
              </Typography>
            </Box>
            <CloseIcon
              onClick={() => {
                selectedFirstTable === "not-table"
                  ? (setOpen(false),
                    closeRecievePresentationModal?.(false),
                    closeSendVerificationModal?.(false))
                  : setOpen(false);
              }}
              sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
            />
          </HeaderContainer>
          <BannerContainer>
            <Box
              style={{ height: "150px", width: "100%" }}
              component="img"
              alt="Banner"
              src={getBannerImage()}
            />
            <Box
              style={{
                position: "absolute",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                background: "#000000bf",
                right: "10px",
                top: "90px",
                cursor: "pointer",
              }}
              onClick={() => {
                setBlurPersonalData(!blurPersonalData);
              }}
            >
              {blurPersonalData ? (
                <VisibilityOffOutlinedIcon style={{ color: "white" }} />
              ) : (
                <VisibilityOutlinedIcon style={{ color: "white" }} />
              )}
            </Box>
          </BannerContainer>
          <Box sx={{ marginBottom: "60px" }}>
            <Avatar
              src={getLogoImage()}
              style={{
                position: "absolute",
                marginLeft: 50,
                marginTop: "-65px",
                width: "110px",
                height: "110px",
                border: "solid white 6px",
                backgroundColor: "white",
              }}
            />
          </Box>
          <DetailsContainer>
            <Box p={1.5}>
              <Typography variant="h6" fontWeight="bold">
                {getOrgName()}
              </Typography>

              <Typography color="grey" mt={2} variant="subtitle1">
                {selectedFirstTable === true
                  ? selectedData?.credential?.vc?.type[0]
                  : selectedDataFromApi?.credential?.vc?.type[0]}
              </Typography>

              <DataAttributeCardHolder
                selectedData={
                  selectedFirstTable === true
                    ? selectedData?.credential?.vc?.credentialSubject
                    : selectedDataFromApi?.credential?.vc?.credentialSubject
                }
                blurPersonalData={blurPersonalData}
              />

              <DateShowComponent
                selectedData={
                  selectedFirstTable === true
                    ? selectedData
                    : selectedDataFromApi
                }
              />

              <DataAgreementPolicyCardForEWC />
            </Box>
          </DetailsContainer>
          <FooterContainer>
            <Button
              onClick={() => {
                selectedFirstTable === "not-table"
                  ? (setOpen(false),
                    closeRecievePresentationModal?.(false),
                    closeSendVerificationModal?.(false))
                  : setOpen(false);
              }}
              style={buttonStyle}
              sx={{
                marginLeft: "15px",
                color: "black",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              variant="outlined"
            >
              {t("common.close")}
            </Button>
          </FooterContainer>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
