import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import "../../index.css";

interface Props {
  steps: any[];
  activeStep: number;
  orientation?: string;
}

export default function VerticalStepper(props: Props) {
  const { steps, activeStep, orientation } = props;

  return (
    <Box sx={{ width: "100%", marginTop: "20px" }}>
      <Stepper
        activeStep={activeStep}
        orientation={orientation ? "horizontal" : "vertical"}
        alternativeLabel={orientation ? true : false}
      >
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}
