import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Item = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1),
  justifyContent: "center",
  color: "#0000",
  height: 90,
  border: "1px solid #E1E1E1",
  borderRadius: "7px",
  cursor: "pointer",
}));

const GridItem = ({ onClick, translationKey, isExternalLink = false }: any) => {
  const { t } = useTranslation("translation");
  
  const content = (
    <Typography
      variant="body1"
      color="grey"
      component={isExternalLink ? "a" : "div"}
      href={isExternalLink ? "https://docs.igrant.io/docs/" : undefined}
      target={isExternalLink ? "_blank" : undefined}
      rel={isExternalLink ? "noreferrer" : undefined}
      sx={{
        textDecoration: "none",
      }}
    >
      {t(translationKey)}
    </Typography>
  );

  return (
    <Grid item lg={3} md={6} sm={6} xs={12}>
      <Item onClick={onClick}>
        {content}
      </Item>
    </Grid>
  );
};

const QuickActionCards = () => {
  const navigate = useNavigate();

  const gridItems = [
    { onClick: () => navigate("/webhooks"), translationKey: "sidebar.supportEvents" },
    { onClick: () => navigate("/developerapi"), translationKey: "sidebar.developerAPIs" },
    { onClick: () => navigate("/manageadmin"), translationKey: "gettingStarted.manageAdminUsers" },
    { onClick: undefined, translationKey: "gettingStarted.developerDocumentation", isExternalLink: true },
  ];

  return (
    <Grid container spacing={2}>
      {gridItems.map((item, index) => (
        <GridItem key={index} {...item} />
      ))}
    </Grid>
  );
};

export default QuickActionCards;