import { useRecordContext } from "react-admin";
import {
  getStateForCredentialHistoryForEWC,
  getStateForVerificationForEWC,
} from "../../service/adapter";
import { Box, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { determineVerificationFormat } from "../../utils/verificationRequestUtils";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

import { downloadDAAsJson } from "../../utils/downloadDAasJson";
import { HttpServiceForDigitalWalletEwc } from "../../service/digitalWalletEwcHttpService";

export const IconsFIeld = (props: any) => {
  const { t } = useTranslation("translation");
  const record = useRecordContext(props);
  const lockIconColor =
    record.userPinRequired === true &&
    record.userPin === "" &&
    record.credentialStatus === "credential_pending"
      ? "#FF0C10"
      : "#B9B9B9";

  const refreshIconColor =
    record.acceptanceToken !== "" &&
    record.credentialStatus === "credential_pending"
      ? "#FF0C10"
      : "#B9B9B9";

  const additionalVerifiCationIcon =
    record.presentationId !== "" &&
    record.credentialStatus === "credential_pending" &&
    record.acceptanceToken === ""
      ? "#FF0C10"
      : "#B9B9B9";

  if (!record || !props.source) {
    return null;
  }

  return (
    record[props.source] && (
      <Box display={"flex"} justifyContent={"space-around"}>
        {props.tableNumber === "table1" ? (
          <>
            {" "}
            <Tooltip
              title={t("holder.toolTipForTransactionCodeOpenIcon")}
              placement="top"
            >
              <LockOutlinedIcon
                onClick={() => {
                  record.userPinRequired === true &&
                    record.userPin === "" &&
                    record.credentialStatus === "credential_pending" &&
                    (props.setSelectedData(record),
                    props.setOpenTransactionCodeModal(true));
                }}
                fontSize="small"
                style={{
                  cursor:
                    record.userPinRequired === true &&
                    record.userPin === "" &&
                    record.credentialStatus === "credential_pending"
                      ? "pointer"
                      : "not-allowed",
                  color: lockIconColor,
                }}
              />
            </Tooltip>
            <Tooltip
              title={t("holder.additionalVerificationsRequired")}
              placement="top"
            >
              <PendingActionsIcon
                onClick={() => {
                  const triggerSendVerification =
                    record.presentationId !== "" &&
                    record.credentialStatus === "credential_pending" &&
                    record.acceptanceToken === "";

                  if (triggerSendVerification) {
                    const presentationId = record.presentationId;
                    props.setSelectedData(record);

                    HttpServiceForDigitalWalletEwc.filterVerificationForEWC(
                      presentationId
                    )
                      .then((res) => {
                        console.log("res");
                        props.setInputDescriptorData(res.inputDescriptors);

                        const lengthOfMatchedCredential =
                          res.inputDescriptors[0].matchedCredentials?.length ||
                          0;

                        if (lengthOfMatchedCredential === 0) {
                          props.setError(t("holder.insufficientData"));
                          props.setOpenSnackBar(true);
                        } else {
                          props.setOpenSendVerificationsModal(true);
                        }
                      })
                      .catch((error) => {
                        props.setOpenSnackBar(true);
                        props.setError(
                          error?.response?.data?.errorDescription ||
                            "An error occurred"
                        );
                      });
                  }
                }}
                fontSize="small"
                style={{
                  cursor:
                    record.presentationId !== "" &&
                    record.credentialStatus === "credential_pending" &&
                    record.acceptanceToken === ""
                      ? "pointer"
                      : "not-allowed",
                  color: additionalVerifiCationIcon,
                }}
              />
            </Tooltip>
            <Tooltip
              title={t("holder.receiveDeferredCredential")}
              placement="top"
            >
              <ReplayOutlinedIcon
                onClick={() => {
                  record.acceptanceToken !== "" &&
                    record.credentialStatus === "credential_pending" &&
                    HttpServiceForDigitalWalletEwc.receiveDefferedCredential(
                      record.credentialId
                    )
                      .then(() => {
                        props.onRefetch();
                      })
                      .catch((error) => {
                        props.setOpenSnackBar(true);
                        props.setError(error.response.data.errorDescription);
                      });
                }}
                fontSize="small"
                style={{
                  cursor:
                    record.acceptanceToken !== "" &&
                    record.credentialStatus === "credential_pending"
                      ? "pointer"
                      : "not-allowed",
                  color: refreshIconColor,
                }}
              />
            </Tooltip>
          </>
        ) : (
          <></>
        )}

        <Tooltip
          title={
            props.tableNumber === "table1"
              ? t("holder.viewReceivedCredential")
              : t("holder.viewSharedCredential")
          }
          placement="top"
        >
          <RemoveRedEyeOutlinedIcon
            onClick={() => {
              (record.credentialStatus === "credential_acked" ||
                record.status === "presentation_acked") &&
                (props.tableNumber === "table1"
                  ? props.setSelectedFirstTable(true)
                  : props.setSelectedFirstTable(false),
                props.setOpenViewModal(true),
                props.setSelectedData(record));
            }}
            fontSize="small"
            style={{
              cursor:
                record.credentialStatus === "credential_acked" ||
                record.status === "presentation_acked"
                  ? "pointer"
                  : "not-allowed",
              color:
                record.credentialStatus === "credential_acked" ||
                record.status === "presentation_acked"
                  ? "#4D4D4F"
                  : "#B9B9B9",
            }}
          />
        </Tooltip>

        <Tooltip
          title={
            props.tableNumber === "table1"
              ? t("holder.downloadReceivedCredential")
              : t("holder.downloadSharedCredential")
          }
          placement="top"
        >
          <CloudDownloadOutlinedIcon
            onClick={() => {
              downloadDAAsJson(record, "");
            }}
            fontSize="small"
            style={{
              cursor: "pointer",
              color: "#4D4D4F",
            }}
          />
        </Tooltip>

        <Tooltip
          title={
            props.tableNumber === "table1"
              ? t("holder.deleteReceivedCredential")
              : t("holder.deleteSharedCredential")
          }
          placement="top"
        >
          <DeleteOutlineOutlinedIcon
            onClick={() => {
              props.tableNumber === "table1"
                ? props.setSelectedFirstTable(true)
                : props.setSelectedFirstTable(false);
              props.setOpenDeleteModal(true), props.setSelectedData(record);
            }}
            fontSize="small"
            style={{
              cursor: "pointer",
              color: "#4D4D4F",
            }}
          />
        </Tooltip>
      </Box>
    )
  );
};

export const CredentialFormatFieldForVerification = (props: any) => {
  const record = useRecordContext(props);

  if (!record || !props.source) {
    return null;
  }

  const formatValue = determineVerificationFormat(
    JSON.parse(record.presentationDefinition)
  );

  return <Typography variant="body2">{formatValue}</Typography>;
};

export const CredentialFormatFieldForCredential = (props: any) => {
  const record = useRecordContext(props);

  if (!record || !props.source) {
    return null;
  }

  const formatValue = () => {
    if (record[props.source] === "vc+sd-jwt") {
      return "SD-JWT";
    } else if (record[props.source] === "jwt_vc") {
      return "JWT";
    } else if (record[props.source] === "") {
      return "Unknown";
    }
    return record[props.source];
  };

  return <Typography variant="body2">{formatValue()}</Typography>;
};

export const CredentialTypeFieldForCredential = (props: any) => {
  const record = useRecordContext(props);

  if (!record || !props.source) {
    return null;
  }
  const value = record?.credential?.vc?.type
    ? record?.credential?.vc?.type
    : "Unknown";

  return <Typography variant="body2">{value}</Typography>;
};

export const CredentialTypeFieldForVerification = (props: any) => {
  const record = useRecordContext(props);

  if (!record || !props.source) {
    return null;
  }

  const parsedPresentationDefinition = JSON.parse(
    record.presentationDefinition
  );

  const value =
    parsedPresentationDefinition.input_descriptors[0].constraints.fields[0]
      ?.filter?.contains?.const;

  return <Typography variant="body2">{value ? value : "Unknown"}</Typography>;
};

export const DateRecievedField = (props: any) => {
  const record = useRecordContext(props);

  const m = moment.unix(record[props.source]);
  const formattedDate = m.format("MMMM Do YYYY, h:mmA");

  if (!record || !props.source) {
    return null;
  }

  return (
    <Typography
      variant="body2"
      sx={{
        color: "#4D4D4F",
      }}
    >
      {formattedDate}
    </Typography>
  );
};

export const StateField = (props: any) => {
  const record = useRecordContext(props);

  if (!record || !props.source) {
    return null;
  }

  const credentialState = getStateForCredentialHistoryForEWC(
    record[props.source],
    i18n.t
  );
  const verificationState = getStateForVerificationForEWC(
    record[props.source],
    i18n.t
  );

  const value =
    props.tableNumber === "table1" ? credentialState : verificationState;
  return <Typography variant="body2">{value}</Typography>;
};

export const OrganisationNameField = (props: any) => {
  const record = useRecordContext(props);

  if (!record || !props.source) {
    return null;
  }

  const issuerName = record?.issuer?.name ? record?.issuer?.name : "Unknown";
  const verifierName = record?.clientMetadata?.clientName
    ? record?.clientMetadata?.clientName
    : "Unknown";

  let displayValue = props.tableNumber === "table1" ? issuerName : verifierName;
  return <Typography variant="body2">{displayValue}</Typography>;
};
