import MasterCardImage from "../assets/mastercard.png";
import VisaImage from "../assets/visa.png";

export const getBrandLogo = (brand:any) => {
  let defaultBrandLogo = "";
  let brandLogo = defaultBrandLogo; // TODO Put a default image
  if (brand) {
    switch (brand) {
      case "American Express":
        brandLogo = brandLogo + "amex.png";
        break;
      case "Discover":
        brandLogo = brandLogo + "discover.png";
        break;
      case "Diners Club":
        brandLogo = brandLogo + "dinersclub.png";
        break;
      case "JCB":
        brandLogo = brandLogo + "jcb.png";
        break;
      case "MasterCard":
        brandLogo = MasterCardImage;
        break;
      case "UnionPay":
        brandLogo = brandLogo + "unionpay.png";
        break;
      case "Visa":
        brandLogo = VisaImage;
        break;
      default:
        brandLogo = defaultBrandLogo;
        break;
    }
  }
  return brandLogo;
};

export const formatExpiryDate = ({ month, year } : any) => {
    let expiryDate = "";
    if (month && year) {
      if (month < 10) {
        expiryDate = "0";
      }
      expiryDate = expiryDate + month + "/" + year;
    }
    return expiryDate;
  };
