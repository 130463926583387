import * as React from "react";
import { Dispatch, SetStateAction, useState, useEffect } from "react";

import { Drawer, Typography, Button, Box, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
} from "../modalStyle";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { HttpServiceForDigitalWalletEwc } from "../../../service/digitalWalletEwcHttpService";
import SnackbarComponent from "../../notification";
import LoaderComponent from "../../LoaderComponent";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  onRefetch: any;
  selectedData: any;
}

let defaultValue = {
  userPin: "",
};

export default function TransactionCodeModal(props: Props) {
  const { open, setOpen, headerText, onRefetch, selectedData } = props;
  const { t } = useTranslation("translation");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");
  const [openLoader, setOpenLoader] = useState(false);

  const {
    handleSubmit,
    formState,
    register,
    formState: { errors },
    reset,
  } = useForm<any>({
    mode: "onChange",
    defaultValues: defaultValue,
  });

  useEffect(() => {
    reset({ ...defaultValue });
  }, [open]);

  const onSubmit = (createdData: any) => {
    let payload = {
      userPin: createdData.userPin,
    };

    setOpenLoader(true);
    HttpServiceForDigitalWalletEwc.receiveCredentialWithUserPinForEWC(
      payload,
      selectedData.credentialId
    )
      .then(() => {
        onRefetch();
        setOpen(false);
        setOpenLoader(false);
      })
      .catch((error) => {
        setOpenSnackBar(true);
        setError(error.response.data.errorDescription);
        setOpenLoader(false);
      });
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SnackbarComponent
              open={openSnackBar}
              setOpen={setOpenSnackBar}
              message={error}
              topStyle={100}
            />
            <HeaderContainer>
              <Box pl={2}>
                <Typography color="#F3F3F6">{headerText}</Typography>
              </Box>
              <CloseIcon
                onClick={() => setOpen(false)}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <DetailsContainer>
              <Box p={1.5}>
                <Typography variant="subtitle1" mb={0} mt={1}>
                  {t("credentials.userPin")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>

                <TextField
                  variant="standard"
                  autoComplete="off"
                  fullWidth
                  error={!!errors.userPin}
                  sx={{
                    marginBottom: "15px",
                    "& .MuiInput-underline.Mui-error:after": {
                      borderBottom: "2px solid #d32f2f",
                    },
                    "& .MuiInput-underline.Mui-error:before": {
                      borderBottom: "2px solid #d32f2f",
                    },
                    "& .MuiInput-input.Mui-disabled": {
                      cursor: "not-allowed",
                    },
                  }}
                  placeholder={t("credentials.userPinPlaceHolder")}
                  {...register(`userPin`, {
                    pattern: /^[0-9]*$/,
                    minLength: 4,
                    maxLength: 4,
                  })}
                />
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => setOpen(false)}
                style={buttonStyle}
                sx={{
                  marginLeft: "15px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>

              <Button
                variant="outlined"
                style={buttonStyle}
                sx={{
                  cursor: formState.isValid ? "pointer" : "not-allowed",
                  marginRight: "20px",
                  color: formState.isValid ? "black" : "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                type="submit"
              >
                {t("connections.receive")}
              </Button>
            </FooterContainer>
          </form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
