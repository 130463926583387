import { useGetIdentity } from "react-admin";

import MenuIcon from "@mui/icons-material/Menu";

import { AppBar, Box, Toolbar, IconButton } from "@mui/material";
import { AppBarMenu } from "./AppBarMenu";
import { useSidebarState } from "ra-ui-materialui";

import Logo from "../../assets/logo_200.png";
import { useNavigate } from "react-router-dom";

export default function MyAppBar() {
  const [open, setOpen] = useSidebarState();
  const { identity } = useGetIdentity();
  const navigate = useNavigate();

  return (
    <Box>
      <AppBar
        sx={{
          backgroundColor: "#343A40",
          height: 80,
          display: "flex",
          justifyContent: "center",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpen(!open)}
          >
            <MenuIcon style={{ height: 60, width: 60 }} />
          </IconButton>
          <img
            src={Logo}
            alt="Logo"
            width={"200px"}
            height={"60px"}
            style={{cursor:"pointer"}}
            onClick={()=>navigate("/start")}
          />

          <AppBarMenu
            firstName={identity?.name}
            lastVisited={identity?.lastVisited}
            email={identity?.email}
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
