import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

// Setup ssiStore
export let ssiStore = (set: any) => ({
  dataAgreementForIssuanceHistory: null,
  dataAgreementForVerificationsHistory: null,
  updateDataAgreementForIssuanceHistory: (
    dataAgreementForIssuanceHistory: any
  ) =>
    set(() => {
      return {
        dataAgreementForIssuanceHistory: dataAgreementForIssuanceHistory,
      };
    }),
  updateDataAgreementForVerificationsHistory: (
    dataAgreementForVerificationsHistory: any
  ) =>
    set(() => {
      return {
        dataAgreementForVerificationsHistory:
          dataAgreementForVerificationsHistory,
      };
    }),
});

let devtoolsStore = devtools(ssiStore);
let persistStore = persist(devtoolsStore, { name: "ssiStore" });

export const useSSIStore = create(persistStore);
