import { convertYearsToDays } from "../../utils/convertYearsToDays";

export interface createdDataProps {
  Name: string;
  Version: string;
  AttributeType: number;
  Description: string;
  LawfulBasisOfProcessing: number;
  PolicyURL: string;
  Jurisdiction: string;
  IndustryScope: string;
  StorageLocation: string;
  dataRetentionPeriodDays: number;
  Restriction: string;
  Shared3PP: boolean;
  DpiaDate: any;
  DpiaSummaryURL: string;
  dataAttributes: any;
  schemaId: string;
  isExistingSchema: boolean;
  DexProfile: string;
}

export const DataAgreementPayload = (
  createdData: createdDataProps,
  active: boolean,
  lifecycle: string,
  mode: string,
  organisationDetails: any,
  selectedDataAgreement?: any
): any => {
  console.log("createdData", createdData.DexProfile)
  return {
    dataAgreement: {
      id: mode === "Update" ? selectedDataAgreement.id : "",
      version: createdData.Version,
      controllerId: mode === "Update" && selectedDataAgreement?.controllerId,
      controllerUrl: organisationDetails.policyUrl,
      controllerName: organisationDetails.name,
      policy: {
        id: mode === "Update" && selectedDataAgreement?.policy?.name,
        name: "string",
        version: mode === "Update" && selectedDataAgreement?.policy?.version,
        url: createdData.PolicyURL,
        jurisdiction: createdData.Jurisdiction,
        industrySector: createdData.IndustryScope,
        dataRetentionPeriodDays: convertYearsToDays(
          createdData.dataRetentionPeriodDays
        ),
        geographicRestriction: createdData.Restriction,
        storageLocation: createdData.StorageLocation,
        thirdPartyDataSharing: createdData.Shared3PP,
      },
      purpose: createdData.Name,
      purposeDescription: createdData.Description,
      lawfulBasis: createdData.LawfulBasisOfProcessing,
      methodOfUse: createdData.AttributeType,
      dpiaDate: createdData.DpiaDate,
      dpiaSummaryUrl: createdData.DpiaSummaryURL,
      signature: {
        id: mode === "Update" && selectedDataAgreement?.signature.id,
        payload: mode === "Update" && selectedDataAgreement?.signature.payload,
        signature:
          mode === "Update" && selectedDataAgreement?.signature?.signature,
        verificationMethod:
          mode === "Update" &&
          selectedDataAgreement?.signature?.verificationMethod,
        verificationPayload:
          mode === "Update" &&
          selectedDataAgreement?.signature?.verificationPayload,

        verificationPayloadHash:
          mode === "Update" &&
          selectedDataAgreement?.signature?.verificationPayloadHash,
        verificationArtifact:
          mode === "Update" &&
          selectedDataAgreement?.signature?.verificationArtifact,
        verificationSignedBy:
          mode === "Update" &&
          selectedDataAgreement?.signature?.verificationSignedBy,
        verificationSignedAs:
          mode === "Update" &&
          selectedDataAgreement?.signature?.verificationSignedAs,
        verificationJwsHeader:
          mode === "Update" &&
          selectedDataAgreement?.signature?.verificationJwsHeader,
        timestamp:
          mode === "Update" && selectedDataAgreement?.signature?.timestamp,
        signedWithoutObjectReference:
          mode === "Update" &&
          selectedDataAgreement?.signature?.signedWithoutObjectReference,
        objectType:
          mode === "Update" && selectedDataAgreement?.signature?.objectType,
        objectReference:
          mode === "Update" &&
          selectedDataAgreement?.signature?.objectReference,
      },
      active: active,
      forgettable: mode === "Update" && selectedDataAgreement?.forgettable,
      compatibleWithVersionId:
        mode === "Update" && selectedDataAgreement?.forgettable,
      lifecycle: lifecycle,
      dataExchange: {
        schemaId: createdData.isExistingSchema ? createdData.schemaId : "",
        isExistingSchema: createdData.isExistingSchema,
        id: mode === "Update" && selectedDataAgreement?.dataExchange?.id,
        credentialDefinitionId:
          mode === "Update" &&
          selectedDataAgreement?.dataExchange?.credentialDefinitionId,
        dataExchangeProfile:
          createdData.DexProfile === "None" ? "" : createdData.DexProfile,
        firebaseDynamicLink:
          mode === "Update" &&
          selectedDataAgreement?.dataExchange?.firebaseDynamicLink,
      },
      dataAttributes: createdData?.dataAttributes?.map(
        (dataAttributes: any) => {
          return {
            id: mode === "Update" ? dataAttributes.id : "",
            name: dataAttributes.attributeName,
            description: dataAttributes.attributeDescription,
            sensitivity: mode === "Update" ? dataAttributes.sensitivity : false,
            category: mode === "Update" ? dataAttributes.category : "string",
            restrictions:
              dataAttributes.schemaId !== "" || dataAttributes.credDefId !== ""
                ? [
                    {
                      schemaId: dataAttributes.schemaId,
                      credDefId: dataAttributes.credDefId,
                    },
                  ]
                : [],
          };
        }
      ),
    },
  };
};

export function validateDataAttributes(data: any) {
  for (const attribute of data.dataAttributes) {
    if (
      attribute.attributeName === "" ||
      attribute.attributeDescription === ""
    ) {
      return false;
    }
  }
  return true;
}

export const getDexaProfileValueForCreateDA = (
  ssi: string | undefined,
  Dex: boolean | undefined,
  digitalWalletDeploymentStatus: string,
  digitalWalletDeploymentStatusForEWC: string
) => {
  if (ssi === "credentials" && Dex === true) {
    return "EWC10";
  } else if (ssi === "credentials" && Dex === false) {
    return "AIP10";
  }
  if (ssi === "verifications" && Dex === true) {
    return "EWC10";
  } else if (ssi === "verifications" && Dex === false) {
    return "AIP10";
  } else {
    if (
      digitalWalletDeploymentStatus === "Not Configured" &&
      digitalWalletDeploymentStatusForEWC === "Not Configured"
    ) {
      return "None";
    } else if (
      digitalWalletDeploymentStatus === "Not Configured" &&
      digitalWalletDeploymentStatusForEWC === "Deployed"
    ) {
      return "EWC10";
    } else if (
      digitalWalletDeploymentStatus === "Deployed" &&
      digitalWalletDeploymentStatusForEWC === "Not Configured"
    ) {
      return "AIP10";
    } else if (
      digitalWalletDeploymentStatus === "Deployed" &&
      digitalWalletDeploymentStatusForEWC === "Deployed"
    ) {
      return "AIP10";
    }
  }
};
