import { Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { styled } from "@mui/material/styles";

import PackageDetailsContainer from "./packagedetailsContainer";
import { Box } from "@mui/system";
import { useOnBoardingStore } from "../../store/onBoardingStore";

const buttonStyle = {
  height: 30,
  width: 150,
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  marginTop: "20px",
  justifyContent: "space-between",
}));

interface Props {
  handleNext: any;
  handleBack: any;
  setError: Dispatch<SetStateAction<string>>;
  setOpenSnackBar: Dispatch<SetStateAction<boolean>>;
}

const PackageDetails = (props: Props) => {
  const { handleNext, setError, setOpenSnackBar, handleBack } = props;
  const onbordingStore: any = useOnBoardingStore((state) => state);

  const [selectedPackage, setSelectedPackage] = useState(
    onbordingStore.selectedPackage.packageNumber
  );

  const { t } = useTranslation("translation");

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (selectedPackage === 0) {
      useOnBoardingStore.getState().updateSelectedPackage({
        packageNumber: selectedPackage,
        packageDetails: onbordingStore.pricingInfoFromApi.freeTrial,
      });
    } else {
      useOnBoardingStore.getState().updateSelectedPackage({
        packageNumber: selectedPackage,
        packageDetails: onbordingStore.pricingInfoFromApi.starter,
      });
    }

    handleNext();
  };
  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6" color={"black"} fontWeight="bold">
        {t("onboarding.packageSelection")}
      </Typography>

      <Typography variant="subtitle2" color={"black"} mt={2} mb={2}>
        {t("onboarding.packageSelectionPageDescription")}
      </Typography>

      <PackageDetailsContainer
        setSelectedPackage={setSelectedPackage}
        selectedPackage={selectedPackage}
      />

      <Box textAlign={"right"} mt={1}>
        <Typography variant="caption">
          *{t("onboarding.discountText")}
        </Typography>
      </Box>

      {selectedPackage === 0 && (
        <Box mt={1}>
          <Typography variant="subtitle2">
            <span style={{ fontWeight: "bold" }}>{t("onboarding.note")}:</span>{" "}
            {t("onboarding.freeTrialPackageDescription")}
          </Typography>
        </Box>
      )}

      <ButtonContainer>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={handleBack}
        >
          <Typography variant="subtitle2">{t("onboarding.prev")}</Typography>
        </Button>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            color: "black",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          type="submit"
          endIcon={<KeyboardArrowRightIcon />}
        >
          <Typography variant="subtitle2">{t("onboarding.next")}</Typography>
        </Button>
      </ButtonContainer>
    </form>
  );
};

export default PackageDetails;
