import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Typography, Box, FormControl } from "@mui/material";

import DataAgreementPersonalDataTable from "../dataAgreements/DataAgreementPersonalDataTable";
import { DataSchemaTemplate } from "../dataAgreements/DataSchemaTemplate";
import { useTranslation } from "react-i18next";
import DataSchemaChips from "./DataSchemaChips";

interface Props {
  mode: string;
  append: any;
  fields: any;
  remove: any;
  methods: any;
  formController: any;
  insert: any;
}

const DataSchemaTemplateContainer = (props: Props) => {
  const { control } = useForm();
  const { mode, append, fields, remove, methods, formController, insert } =
    props;
  const [selectedTemplates, setSelectedTemplates] = useState(null);
  const { t } = useTranslation("translation");

  const handleAddIconClick = (key: string) => {
    if (selectedTemplates) {
      const value = selectedTemplates[key];

      if (
        fields.length === 1 &&
        fields[0].attributeName === "" &&
        fields[0].attributeDescription === ""
      ) {
        const specificIndex = 0;

        insert(specificIndex, {
          attributeName: key,
          attributeDescription: value,
          schemaId: "",
          credDefId: "",
        });

        remove(specificIndex + 1);
      } else {
        append({
          attributeName: key,
          attributeDescription: value,
          schemaId: "",
          credDefId: "",
        });
      }
    }
  };

  return (
    <Box p={1.5}>
      <Typography variant="subtitle1" mt={1.5} mb={1.5}>
        {t("dataAgreements.DataSchemaTemplate")}
      </Typography>
      <Box sx={{ marginBottom: "10px" }}>
        <FormControl style={{ width: "100%" }}>
          <Controller
            name="dataSchemaValue"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DataSchemaTemplate
                onChange={onChange}
                value={value}
                mode={props.mode}
                setSelectedTemplates={setSelectedTemplates}
              />
            )}
          />
        </FormControl>
      </Box>

      <Box mt={1.5} mb={1.5}>
        {selectedTemplates && (
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {Object.keys(selectedTemplates).map((chipKey) => (
              <DataSchemaChips
                key={chipKey}
                chipKey={chipKey}
                handleAddIconClick={handleAddIconClick}
              />
            ))}
          </Box>
        )}
      </Box>

      <DataAgreementPersonalDataTable
        mode={mode}
        append={append}
        fields={fields}
        remove={remove}
        methods={methods}
        formController={formController}
        subTextFromSchema={t("dataAgreements.attributeNamesMatchTheCred")}
      />
    </Box>
  );
};

export default DataSchemaTemplateContainer;
