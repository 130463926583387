import * as React from "react";
import { useState } from "react";
import { Form, TextInput } from "react-admin";
import { Dispatch, SetStateAction } from "react";

import { Drawer, Typography, Button, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  disabledButtonstyle,
} from "./modalStyle";
import { HttpService } from "../../service/HTTPService";
import { useTranslation } from "react-i18next";
import LoaderComponent from "../LoaderComponent";
import { useSSIStore } from "../../store/ssiStore";
import { HttpServiceForDigitalWallet } from "../../service/digitalWalletHttpService";
import { HttpServiceForDDA } from "../../service/ddaHttpService";
import { HttpServiceForDigitalWalletEwc } from "../../service/digitalWalletEwcHttpService";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  confirmText: string;
  headerText: string;
  modalDescriptionText: any;
  onRefetch?: any;
  userAccessId?: string;
  resourceName: string;
  developerApiDeleteID?: string;
  selectededDataAgreementFromDataAgreement?: any;
  selectedWebhookDetails?: any;
  setSelectedDropdownValue?: any;
  confirmButtonText: string;
  connectionDetailsForDelete?: any;
  selectedDataVerifierUser?: any;
  billingPlanDetailsToTerminate?: any;
}

export default function DeleteModal(props: Props) {
  const {
    open,
    setOpen,
    confirmText,
    headerText,
    modalDescriptionText,
    onRefetch,
    userAccessId,
    resourceName,
    developerApiDeleteID,
    selectededDataAgreementFromDataAgreement,
    selectedWebhookDetails,
    setSelectedDropdownValue,
    confirmButtonText,
    connectionDetailsForDelete,
    selectedDataVerifierUser,
    billingPlanDetailsToTerminate,
  } = props;
  const [isOk, setIsOk] = useState(false);
  const [confirmationTextInput, setConfirmationTextInput] = useState("");
  const { t } = useTranslation("translation");
  const [openLoader, setOpenLoader] = useState(false);
  const selectedRecordForIssuenceHistory: any = useSSIStore(
    (state) => state.dataAgreementForIssuanceHistory
  );

  const handleCancelConfirmationText = (event: any) => {
    setConfirmationTextInput(event.target.value);
    event.target.value === confirmText ? setIsOk(true) : setIsOk(false);
  };

  const onSubmit = () => {
    if (isOk === true) {
      setOpenLoader(true);
      if (confirmText === "DELETE" && resourceName === "dataagreements") {
        HttpService.deleteDataAgreement(
          selectededDataAgreementFromDataAgreement.id
        ).then(() => {
          setOpenLoader(false);
          onRefetch();
          setSelectedDropdownValue({});
          setIsOk(false);
          setOpen(false);
        });
      } else if (
        confirmText === "PUBLISH" &&
        resourceName === "dataagreements"
      ) {
        const { active, lifecycle, controllerUrl, ...otherProps } =
          selectededDataAgreementFromDataAgreement;
        const updateDAPayload = {
          dataAgreement: {
            active: true,
            lifecycle: "complete",
            // dummy value should change once backend is changed
            controllerUrl: "string",
            ...otherProps,
          },
        };
        HttpService.updateDataAgreementById(
          updateDAPayload,
          selectededDataAgreementFromDataAgreement.id
        ).then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setSelectedDropdownValue({});
          setOpen(false);
        });
      } else if (confirmText === "DELETE" && userAccessId) {
        HttpService.deleteIdpBy(userAccessId).then(() => {
          setOpenLoader(false);
          setIsOk(false);
          setOpen(false);
        });
      } else if (confirmText === "DELETE" && resourceName === "developerapi") {
        HttpService.deleteApiKey(developerApiDeleteID).then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setOpen(false);
        });
      } else if (confirmText === "DELETE" && resourceName === "webhooks") {
        HttpService.deleteWebhook(selectedWebhookDetails.id).then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setOpen(false);
        });
      } else if (confirmText === "DELETE" && resourceName === "connections") {
        HttpServiceForDigitalWallet.deleteConnections(
          connectionDetailsForDelete.connectionId
        ).then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setOpen(false);
        });
      } else if (
        confirmText === "DELETE" &&
        resourceName === "issuanceHistory"
      ) {
        HttpServiceForDigitalWallet.deleteConnectionsHistory(
          selectededDataAgreementFromDataAgreement.credentialExchangeId
        ).then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setOpen(false);
        });
      } else if (
        confirmText === "DELETE" &&
        resourceName === "verificationsHistory"
      ) {
        HttpServiceForDigitalWallet.deleteVerificationsHistory(
          selectededDataAgreementFromDataAgreement.presentationExchangeId
        ).then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setOpen(false);
        });
      } else if (
        confirmText === "DELETE" &&
        resourceName === "dataverifierusers"
      ) {
        HttpServiceForDigitalWallet.deleteDataVerifierUsers(
          selectedDataVerifierUser.id
        ).then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setOpen(false);
        });
      } else if (confirmText === "TERMINATE" && resourceName === "billing") {
        HttpServiceForDigitalWallet.terminateBillingPlan(
          billingPlanDetailsToTerminate.planType
        ).then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setOpen(false);
        });
      } else if (confirmText === "PULL" && resourceName === "signedDda") {
        HttpServiceForDDA.pullDataFromDataSource("payload", "1233id").then(
          () => {
            setOpenLoader(false);
            onRefetch();
            setIsOk(false);
            setOpen(false);
          }
        );
      } else if (confirmText === "DELETE" && resourceName === "signedDda") {
        HttpServiceForDDA.deleteSignedDataDisclosureAgreement("1233id").then(
          () => {
            setOpenLoader(false);
            onRefetch();
            setIsOk(false);
            setOpen(false);
          }
        );
      } else if (
        confirmText === "PUBLISH" &&
        resourceName === "dataDisclosureAgreements"
      ) {
        HttpServiceForDDA.publishDataDisclosureAgreement(
          "payload",
          "1233id"
        ).then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setOpen(false);
        });
      } else if (
        confirmText === "UPDATE" &&
        resourceName === "dataDisclosureAgreements"
      ) {
        HttpServiceForDDA.updateDataDisclosureAgreement(
          "payload",
          "1233id"
        ).then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setOpen(false);
        });
      } else if (
        confirmText === "DELETE" &&
        resourceName === "dataDisclosureAgreements"
      ) {
        HttpServiceForDDA.deleteDataDisclosureAgreement("1233id").then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setOpen(false);
        });
      } else if (
        confirmText === "CONFIGURE" &&
        resourceName === "connections"
      ) {
        HttpServiceForDDA.configureDataMarketPlace("payload", "1233id").then(
          () => {
            setOpenLoader(false);
            onRefetch();
            setIsOk(false);
            setOpen(false);
          }
        );
      } else if (
        confirmText === "DELETE" &&
        resourceName === "issuanceHistoryEWC"
      ) {
        HttpServiceForDigitalWalletEwc.deleteIssuanceHistoryForEWC(
          selectededDataAgreementFromDataAgreement.CredentialExchangeId
        ).then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setOpen(false);
        });
      } else if (
        confirmText === "DELETE" &&
        resourceName === "verificationHistoryEWC"
      ) {
        HttpServiceForDigitalWalletEwc.deleteVerificationHistoryForEWC(
          selectededDataAgreementFromDataAgreement.presentationExchangeId
        ).then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setOpen(false);
        });
      } else if (
        confirmText === "DELETE" &&
        resourceName === "verificationEWC"
      ) {
        HttpServiceForDigitalWalletEwc.deletePresentationDefinitinoForEWC(
          selectededDataAgreementFromDataAgreement.presentationDefinitionId
        ).then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setOpen(false);
        });
      } else if (
        confirmText === "DELETE" &&
        resourceName === "credentialDefinionEWC"
      ) {
        HttpServiceForDigitalWalletEwc.deleteCredentialDefinionForEWC(
          selectededDataAgreementFromDataAgreement.credentialDefinitionId
        ).then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setOpen(false);
        });
      } else if (
        confirmText === "DELETE" &&
        resourceName === "receivedCredential"
      ) {
        HttpServiceForDigitalWalletEwc.deleteCredentialForEWC(
          selectededDataAgreementFromDataAgreement.credentialId
        ).then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setOpen(false);
        });
      } else if (
        confirmText === "DELETE" &&
        resourceName === "sharedCredential"
      ) {
        HttpServiceForDigitalWalletEwc.deleteVerificationForEWC(
          selectededDataAgreementFromDataAgreement.presentationId
        ).then(() => {
          setOpenLoader(false);
          onRefetch();
          setIsOk(false);
          setOpen(false);
        });
      }
    }
  };

  function getDataAgreementIdForDataAgreements(
    resourceName: string,
    selectedDataAgreement: any
  ) {
    if (resourceName === "dataagreements") {
      if (selectedDataAgreement) {
        const { methodOfUse, version, active, dataExchange, id } =
          selectedDataAgreement;
        if (methodOfUse === "null") {
          return id;
        } else if (
          methodOfUse !== "null" &&
          (version !== "1.0.0" || (version === "1.0.0" && active === true))
        ) {
          return dataExchange?.id;
        } else return id;
      }
    } else if (resourceName === "signedDda") {
      return selectededDataAgreementFromDataAgreement?.instance_id;
    } else if (resourceName === "dataDisclosureAgreements") {
      return selectededDataAgreementFromDataAgreement?.template_id;
    } else if (resourceName === "issuanceHistoryEWC") {
      return selectededDataAgreementFromDataAgreement?.CredentialExchangeId;
    } else if (resourceName === "verificationHistoryEWC") {
      return selectededDataAgreementFromDataAgreement?.presentationExchangeId;
    }
  }

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container>
          <Form>
            <HeaderContainer>
              <Box pl={2} style={{ overflow: "hidden" }}>
                <Typography
                  style={{
                    color: "#F3F3F6",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {headerText}{" "}
                  {resourceName !== "verificationsHistory" &&
                    resourceName !== "issuanceHistory" &&
                    selectededDataAgreementFromDataAgreement?.purpose}
                  {resourceName === "connections" &&
                    connectionDetailsForDelete?.theirLabel}
                  {resourceName === "issuanceHistory" &&
                    selectedRecordForIssuenceHistory?.purpose}
                  {resourceName === "dataverifierusers" &&
                    selectedDataVerifierUser?.Name}
                  {(resourceName === "signedDda" ||
                    resourceName === "dataDisclosureAgreements") &&
                    selectededDataAgreementFromDataAgreement
                      ?.data_disclosure_agreement?.purpose}
                </Typography>
                <Typography color="#F3F3F6">
                  {resourceName === "developerapi" && developerApiDeleteID}
                  {resourceName === "issuanceHistory" &&
                    selectededDataAgreementFromDataAgreement?.credentialExchangeId}
                  {resourceName === "verificationsHistory" &&
                    selectededDataAgreementFromDataAgreement?.presentationExchangeId}
                </Typography>
                <Typography color="#F3F3F6">
                  {getDataAgreementIdForDataAgreements(
                    resourceName as string,
                    selectededDataAgreementFromDataAgreement
                  )}
                </Typography>
                <Typography color="#F3F3F6">
                  {resourceName === "webhooks" && selectedWebhookDetails?.id}
                  {resourceName === "connections" &&
                    connectionDetailsForDelete?.connectionId}
                </Typography>
              </Box>
              <CloseIcon
                onClick={() => {
                  setOpen(false);
                  setIsOk(false);
                }}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <DetailsContainer>
              <Box p={1.5}>
                {modalDescriptionText}
                <TextInput
                  autoFocus
                  source="username"
                  variant="outlined"
                  label={false}
                  fullWidth
                  value={confirmationTextInput}
                  onChange={handleCancelConfirmationText}
                  autoComplete="off"
                />
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => {
                  setOpen(false);
                  setIsOk(false);
                }}
                style={buttonStyle}
                sx={{
                  marginLeft: "15px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.cancel")}
              </Button>
              <Button
                onClick={onSubmit}
                style={!isOk ? disabledButtonstyle : buttonStyle}
                variant="outlined"
                sx={{
                  marginRight: "20px",
                  cursor: !isOk ? "not-allowed" : "pointer",
                  color: !isOk ? "#6D7676" : "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
              >
                {confirmButtonText}
              </Button>
            </FooterContainer>
          </Form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
