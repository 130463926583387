import { Typography, Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useEffect } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { styled } from "@mui/material/styles";

import { useForm } from "react-hook-form";
import { useOnBoardingStore } from "../../store/onBoardingStore";

import BillingAddressInputContainer from "./billingAddressInputContainer";
import BillingAddressDetailsContainer from "./billingAddressDetailsContainet";

const buttonStyle = {
  height: 30,
  width: 150,
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const ButtonContainer = styled("div")(() => ({
  display: "flex",
  marginTop: "20px",
  justifyContent: "space-between",
}));

const DetailsContainer = styled("div")(() => ({
  display: "grid",
  border: "1px solid #DFE0E1",
  borderRadius: 3,
  padding: "12px",
  width: "100%",
}));

interface Props {
  handleNext: any;
  setError: Dispatch<SetStateAction<string>>;
  setOpenSnackBar: Dispatch<SetStateAction<boolean>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

const BillingAddress = (props: Props) => {
  const { handleNext, setError, setOpenSnackBar, setActiveStep } = props;
  const { handleSubmit, register, formState, reset } = useForm<any>({
    mode: "onChange",
    defaultValues: {},
  });

  const onbordingStore = useOnBoardingStore((state) => state.billingAddress);

  useEffect(() => {
    let defaultValues = {
      adminName: onbordingStore.adminName,
      addressLine1: onbordingStore.addressLine1,
      addressLine2: onbordingStore.addressLine2,
      city: onbordingStore.city,
      state: onbordingStore.state,
      country: onbordingStore.country,
      postalCode: onbordingStore.postalCode,
      saveInputValues: onbordingStore.saveInputValues,
    };

    reset({ ...defaultValues });
  }, []);

  const { t } = useTranslation("translation");

  const updateSaveInputValues = (newValue: boolean) => {
    useOnBoardingStore.setState((state) => ({
      billingAddress: {
        ...state.billingAddress,
        saveInputValues: newValue,
      },
    }));
  };

  const onSubmit = (createdData: any) => {
    if (onbordingStore.saveInputValues) {
      updateSaveInputValues(false);
    } else {
      useOnBoardingStore.getState().updateBillingAddress({
        adminName: createdData.adminName,
        addressLine1: createdData.addressLine1,
        addressLine2: createdData.addressLine2,
        city: createdData.city,
        state: createdData.state,
        country: createdData.country,
        postalCode: createdData.postalCode,
        saveInputValues: true,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" color={"black"} fontWeight="bold">
        {t("onboarding.billingAddress")}
      </Typography>
      <Typography variant="subtitle2" mt={1} mb={2}>
        {t("onboarding.billingAddressDescription")}:
      </Typography>

      <DetailsContainer>
        <Box style={{ height: "260px" }}>
          {onbordingStore.saveInputValues ? (
            <BillingAddressDetailsContainer />
          ) : (
            <BillingAddressInputContainer
              formState={formState}
              register={register}
            />
          )}
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "right",
            marginTop: "20px",
          }}
        >
          <Button
            type="submit"
            variant="outlined"
            style={buttonStyle}
            sx={{
              color: "black",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            }}
          >
            {onbordingStore.saveInputValues
              ? t("common.edit")
              : t("common.save")}
          </Button>
        </Box>
      </DetailsContainer>
      {onbordingStore.saveInputValues === false && (
        <Typography variant="subtitle2" mt={1} mb={2}>
          {t("onboarding.saveYourBillingAddressMessage")}
        </Typography>
      )}
      <ButtonContainer>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={() => setActiveStep(4)}
        >
          <Typography variant="subtitle2">{t("onboarding.prev")}</Typography>
        </Button>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: onbordingStore.saveInputValues ? "pointer" : "not-allowed",
            color: onbordingStore.saveInputValues ? "black" : "#6D7676",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          onClick={() => onbordingStore.saveInputValues && handleNext()}
          endIcon={<KeyboardArrowRightIcon />}
        >
          <Typography variant="subtitle2">{t("onboarding.next")}</Typography>
        </Button>
      </ButtonContainer>
    </form>
  );
};

export default BillingAddress;
