import { TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import DiscreteSlider from "../slider";
import { useEffect, useState } from "react";
import CommonButton from "../buttons/commonButton";

const userCommitmentMarks = [
  { value: 0, label: "0" },
  { value: 1, label: "2" },
  { value: 2, label: "4" },
  { value: 3, label: "6" },
  { value: 4, label: "8" },
];

const timeCommitmentMarks = [
  { value: 0, label: "Monthly" },
  { value: 20, label: "Yearly" },
];

interface Props {
  pricingInfoFromApi: any;
}

const PricingSimulator = (props: Props) => {
  const { pricingInfoFromApi } = props;
  const { t } = useTranslation("translation");

  const [subscribersCount, setSubscribersCount] = useState(5000);
  const [userCommitmentSlideValue, setUserCommitmentSlideValue] = useState(1);
  const [timeCommitmentSlideValue, settimeCommitmentSlideValue] = useState(0);
  const [numUsersValidationState, setNumUsersValidationState] =
    useState<any>(null);
  const [pricePerUser, setPricePerUser] = useState(0);
  const [monthlyFee, setMonthlyFee] = useState(0);

  const handleChangeUserCommitment = (
    event: Event,
    newValue: number | number[]
  ) => {
    const newValueAsNumber = Array.isArray(newValue) ? newValue[0] : newValue;
    setUserCommitmentSlideValue(newValueAsNumber);
  };

  const handleChangeTimeCommitment = (
    event: Event,
    newValue: number | number[]
  ) => {
    const newValueAsNumber = Array.isArray(newValue) ? newValue[0] : newValue;
    settimeCommitmentSlideValue(newValueAsNumber);
  };

  const validateInputs = () => {
    let retVal = true;
    if (
      subscribersCount === 0 &&
      userCommitmentSlideValue === 0 &&
      timeCommitmentSlideValue === 0
    ) {
      retVal = false;
      setNumUsersValidationState(null);
    } else {
      if (subscribersCount <= 0) {
        setNumUsersValidationState("error");
        retVal = false;
      }
    }
    return retVal;
  };

  const calculatePrice = () => {
    let newPricePerUser = 0;
    let newMonthlyFee = 0;
    if (validateInputs()) {
      let pricePerUserWithoutTimeCommitmentDiscounts =
        userCommitmentSlideValue > 0
          ? pricingInfoFromApi?.payPerUser?.priceCurveParamA *
            Math.pow(
              pricingInfoFromApi?.payPerUser?.userCommitmentValues[
                userCommitmentSlideValue
              ],
              pricingInfoFromApi?.payPerUser?.priceCurveParamB
            )
          : pricingInfoFromApi?.payPerUser?.basePrice;

      if (subscribersCount) {
        newPricePerUser =
          pricePerUserWithoutTimeCommitmentDiscounts *
          (1 -
            pricingInfoFromApi?.payPerUser?.timeCommitmentDiscounts[
              timeCommitmentSlideValue
            ].value /
              100);
        if (
          subscribersCount <
          pricingInfoFromApi?.payPerUser?.userCommitmentValues[
            userCommitmentSlideValue
          ]
        ) {
          newMonthlyFee =
            pricingInfoFromApi?.payPerUser?.userCommitmentValues[
              userCommitmentSlideValue
            ] * newPricePerUser;
        } else {
          newMonthlyFee = subscribersCount * newPricePerUser;
        }
      }
    }
    setPricePerUser(newPricePerUser);
    setMonthlyFee(newMonthlyFee);
  };

  useEffect(() => {
    calculatePrice();
  }, [
    subscribersCount,
    userCommitmentSlideValue,
    timeCommitmentSlideValue,
    pricingInfoFromApi,
  ]);

  const formatMoney = (
    amount: any,
    decimalCount = 3,
    decimal = ".",
    thousands = ","
  ) => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - parseFloat(i))
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
      console.log(e);
    }
  };

  const reset = () => {
    setSubscribersCount(0);
    setUserCommitmentSlideValue(0);
    settimeCommitmentSlideValue(0);
    setPricePerUser(0);
    setMonthlyFee(0);
    setNumUsersValidationState(null);
  };

  return (
    <>
      <Typography variant="subtitle1" color={"black"} fontWeight="bold">
        {t("billing.pricingSimulatorHeading")}
      </Typography>

      <Typography variant="subtitle2" color={"black"} mt={1} mb={1}>
        {t("billing.pricingSimulatorDescription")}:
      </Typography>

      <Box
        style={{ display: "flex", alignItems: "center", height: "30px" }}
        mt={2.5}
        mb={1}
      >
        <Typography variant="subtitle2" color={"black"}>
          {t("billing.numberOfSubscribers")}:
        </Typography>
        <TextField
          variant="outlined"
          style={{ marginLeft: "20px" }}
          placeholder="eg: 5000000"
          value={subscribersCount}
          type="number"
          onChange={(e) => {
            setSubscribersCount(parseFloat(e.target.value));
          }}
        />
      </Box>

      <Typography variant="subtitle2" color={"black"} mb={1}>
        {t("billing.userCommitment")}:
      </Typography>

      <DiscreteSlider
        value={userCommitmentSlideValue}
        onChange={handleChangeUserCommitment}
        step={1}
        marks={userCommitmentMarks}
        min={0}
        max={4}
      />

      <Typography variant="subtitle2" color={"black"} mt={1} mb={1}>
        {t("billing.timeCommitment")}:
      </Typography>

      <DiscreteSlider
        value={timeCommitmentSlideValue}
        onChange={handleChangeTimeCommitment}
        step={1}
        marks={timeCommitmentMarks}
        min={0}
        max={1}
      />

      <Box
        sx={{
          width: "100%",
          height: "auto",
          backgroundColor: "#F0F0F0",
          padding: 2,
          textAlign: "center",
        }}
        mt={2}
      >
        <Typography variant="subtitle2" color={"black"} mt={1}>
          {t("billing.averageCostPerUser")}:{" "}
          <span style={{ fontWeight: "bold" }}>
            SEK {formatMoney(pricePerUser)}
          </span>
        </Typography>
        <Typography variant="subtitle2" color={"black"} mt={1}>
          {t("billing.estimatedYearlyFee")}:{" "}
          <span style={{ fontWeight: "bold" }}>
            SEK{" "}
            {timeCommitmentSlideValue === 0
              ? formatMoney(monthlyFee, 0)
              : formatMoney(monthlyFee * 12, 0)}
          </span>
        </Typography>
      </Box>

      <Box
        style={{
          width: "100%",
          marginLeft: "auto",
          display: "flex",
          justifyContent: "flex-end",
        }}
        mt={2}
      >
        <CommonButton
          buttonName={t("billing.clear")}
          width={120}
          handleClick={reset}
        />
      </Box>
    </>
  );
};

export default PricingSimulator;
