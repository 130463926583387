import { TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButtons from "./buttonsContainer";

const EulaUpdates = () => {
  const { t } = useTranslation("translation");

  const { handleSubmit, formState, reset, register } = useForm<any>({
    mode: "onChange",
  });

  useEffect(() => {
    let defaultValues = {
      eulaUrl: "",
    };

    reset({ ...defaultValues });
  }, []);

  const onSubmit = (createdData: any) => {
    console.log("createdData", createdData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography color="black" variant="subtitle1" fontWeight="bold">
        {t("notifications.EULAUpdateNotification")}
      </Typography>
      <Box
        sx={{
          display: { xs: "grid", sm: "flex" },
          alignItems: "center",
          justifyContent: "space-between",
          width: "80%",
        }}
      >
        <Typography color="black" variant="subtitle2">
          {t("notifications.EULAUrl")}:
        </Typography>

        <TextField
          autoFocus
          variant="standard"
          sx={{ width: { xs: "100%", sm: "50%" } }}
          placeholder={t("notifications.pleaseTypeValidUrl")}
          {...register("eulaUrl", {
            required: true,
            minLength: 1,
            pattern: {
              value:
                /^(ftp|http|https):\/\/(www\.)?[a-zA-Z0-9@:%._+~#?&//=]{2,}\.[a-z]{2,}(\/[a-zA-Z0-9@:%_+.~#?&//=]*)?$/,
              message: "",
            },
          })}
        />
      </Box>
      <SubmitButtons
        handleSubmit={handleSubmit(onSubmit)}
        clearValues={() => reset({ eulaUrl: "" })}
        disable={!formState.isValid}
        buttonName1={t("notifications.clear")}
        buttonName2={t("notifications.notify")}
      />
    </form>
  );
};

export default EulaUpdates;
