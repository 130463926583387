import { Typography, Button, Checkbox, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { styled } from "@mui/material/styles";

import { useOnBoardingStore } from "../../../store/onBoardingStore";

import { OnBoardingHttpService } from "../../../service/onboardingService";
import { useNavigate } from "react-router-dom";
import LoaderComponent from "../../LoaderComponent";
import "../../../index.css";
import { useDigitalWalletStore } from "../../../store/digitalWalletStore";

const buttonStyle = {
  height: 30,
  width: 150,
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const ButtonContainer = styled("div")(() => ({
  display: "flex",
  marginTop: "20px",
  justifyContent: "space-between",
}));

interface Props {
  setError: Dispatch<SetStateAction<string>>;
  setOpenSnackBar: Dispatch<SetStateAction<boolean>>;
  handleNext: any;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

const ServiceAgreementPage = (props: Props) => {
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const { setError, setOpenSnackBar, handleNext, setActiveStep } = props;
  const [urlSource, setUrlSource] = useState<any>();
  const [openLoader, setOpenLoader] = useState(false);

  const onbordingStore = useOnBoardingStore((state) => state);

  const selectedPackageFromPackageSelector = useDigitalWalletStore(
    (state) => state.selectedPackageFromPackageSelector
  );

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeHeight, setIframeHeight] = useState<number>(200);

  const handleResizeIframe = useCallback(() => {
    if (iframeRef.current && iframeRef.current.contentWindow?.document.body) {
      const headerElement = iframeRef.current.contentDocument?.querySelector(
        ".iframe-header"
      ) as HTMLElement | null;
      const headerHeight = headerElement ? headerElement.offsetHeight : 0;
      const newHeight =
        iframeRef.current.contentWindow.document.body.scrollHeight +
          headerHeight || 200;

      // Resetting height to a slightly smaller value before reducing it again
      iframeRef.current.style.height = `${newHeight}px`;
      setIframeHeight(newHeight);
    }
  }, []);

  useEffect(() => {
    handleResizeIframe(); // Initial resize
    window.addEventListener("resize", handleResizeIframe);
    return () => {
      window.removeEventListener("resize", handleResizeIframe);
    };
  }, [handleResizeIframe]);

  useEffect(() => {
    OnBoardingHttpService.getServiceAgreement().then((res) => {
      let urlSource = `${res.data.serviceAgreementInfo.url}?embedded=true`;
      setUrlSource(urlSource);
    });
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (onbordingStore.submitPageAuthorization) {
      setOpenLoader(true);
      handleNext();
    } else {
      setOpenSnackBar(true);
      setError(t("onboarding.pleaseAgreeServicesAgreement"));
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <LoaderComponent openLoader={openLoader} />

      <Typography variant="subtitle2" mt={2} mb={1}>
        {t("onboarding.serviceAgreementDescription")}:
      </Typography>

      {urlSource && (
        <div
          id="target"
          style={{ width: "100%", minHeight: `${iframeHeight}px` }}
        >
          <iframe
            ref={iframeRef}
            src={urlSource}
            style={{ width: "100%", height: "100%", border: "none" }}
          ></iframe>
        </div>
      )}

      <FormControlLabel
        control={
          <Checkbox
            checked={onbordingStore.submitPageAuthorization}
            onChange={(e) =>
              useOnBoardingStore
                .getState()
                .updateSubmitPageAuthorization(e.target.checked)
            }
          />
        }
        label={
          <Typography variant="subtitle2">
            {t("onboarding.serviceAgreementConfimrText")}
          </Typography>
        }
      />

      <ButtonContainer>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={() =>
            selectedPackageFromPackageSelector === "payperuser"
              ? setActiveStep((prevActiveStep) => prevActiveStep - 1)
              : navigate("/billing")
          }
        >
          <Typography variant="subtitle2">{t("onboarding.prev")}</Typography>
        </Button>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            color: "black",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          type="submit"
          endIcon={<KeyboardArrowRightIcon />}
        >
          <Typography variant="subtitle2">{t("onboarding.next")}</Typography>
        </Button>
      </ButtonContainer>
    </form>
  );
};

export default ServiceAgreementPage;
