import React, { useState } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { formatExpiryDate, getBrandLogo } from "../../utils/billingUtils";
import { Box } from "@mui/system";
import { useOnBoardingStore } from "../../store/onBoardingStore";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  marginTop: "30px",
  justifyContent: "right",
}));

const buttonStyle = {
  height: 30,
  width: 150,
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const CheckoutForm = ({ stripe, addCard, setAddCard, billingPage }: any) => {
  const [valid, setValid] = useState(false);
  const { t } = useTranslation("translation");
  const onbordingStore = useOnBoardingStore((state) => state.cardDetails);
  const cardDetailsForBillingPage = useDigitalWalletStore(
    (state) =>
      state.readExistingBillingDetails?.billingInfo?.defaultPaymentSource
  );

  const [cardDetail, setCardDetails] = useState<any>(
    billingPage ? cardDetailsForBillingPage : onbordingStore
  );

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (valid || cardDetail.card !== undefined) {
      try {
        const { token } = await stripe!.createToken();
        setCardDetails(token);
        useOnBoardingStore.getState().updateCardDetails(token);
        setAddCard(true);
        setValid(false);
      } catch (error) {
        console.error("Error creating token:", error);
      }
    }
  };

  const handleChange = (event: any) => {
    setValid(event.complete);
  };

  return (
    <>
      {addCard ? (
        <Box
          style={{
            height: "80px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={getBrandLogo(
              billingPage
                ? cardDetailsForBillingPage.brand
                : cardDetail?.card?.brand
            )}
            style={{
              height: 70,
              border: 1,
            }}
            className="pl-3 pt-3"
          />
          <Typography variant="subtitle2" textAlign={"right"}>
            XXXX XXXX XXXX{" "}
            {billingPage
              ? cardDetailsForBillingPage.last4Digits
              : cardDetail.card.last4}
            <br />
            <span style={{ fontSize: "14px", color: "grey" }}>
              Expires{" "}
              {formatExpiryDate({
                month: billingPage
                  ? cardDetailsForBillingPage.expiryMonth
                  : cardDetail.card.exp_month,
                year: billingPage
                  ? cardDetailsForBillingPage.expiryYear
                  : cardDetail.card.exp_year,
              })}
            </span>
          </Typography>
        </Box>
      ) : (
        <Box style={{ height: "80px" }} display={"grid"} alignItems={"center"}>
          <CardElement onChange={handleChange} />
        </Box>
      )}

      <ButtonContainer>
        {addCard ? (
          <Button
            onClick={() => setAddCard(false)}
            variant="outlined"
            style={buttonStyle}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "black",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            }}
          >
            {t("common.edit")}
          </Button>
        ) : (
          <>
            <Button
              onClick={() =>
                cardDetail.card !== undefined && setAddCard(!addCard)
              }
              variant="outlined"
              style={buttonStyle}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: cardDetail.card !== undefined ? "black" : "#6D7676",
                cursor:
                  cardDetail.card !== undefined ? "pointer" : "not-allowed",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              variant="outlined"
              style={buttonStyle}
              sx={{
                display: "flex",
                alignItems: "center",
                color: valid ? "black" : "#6D7676",
                cursor: valid ? "pointer" : "not-allowed",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              onClick={(e) => valid && handleSubmit(e)}
            >
              {t("onboarding.addCard")}
            </Button>
          </>
        )}
      </ButtonContainer>
    </>
  );
};

export default injectStripe(CheckoutForm);
