import * as React from "react";
import { Dispatch, SetStateAction } from "react";

import { Drawer, Typography, Button, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
} from "./modalStyle";

import { useTranslation } from "react-i18next";

import SSIPolicyDetailsBox from "../issuanceHistory/ssiPolicyCardDetailsBox";
import VerifiedDABox from "../issuanceHistory/verfiedDABox";
import IndividualDecentralisedIdentifierBox from "../issuanceHistory/individualDecentralisedIdentifierBox";
import { getLawfulBasisOfProcessing } from "../../service/adapter";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  selectedData: any;
  ssi: string;
  setOpenViewExchangeRecordModal: Dispatch<SetStateAction<boolean>>;
}

const titleAttrRestrictionStyle = {
  fontWeight: "normal",
  marginTop: "20px",
  lineHeight: "1.5rem",
  display: "grid",
  alignItems: "center",
  cursor: "pointer",
  border: "1px solid #DFE0E1",
  borderRadius: 5,
  padding: "12px",
};

export default function DataAgreementPolicyCardInSSI(props: Props) {
  const { t, i18n } = useTranslation("translation");
  const {
    open,
    setOpen,
    headerText,
    selectedData,
    ssi,
    setOpenViewExchangeRecordModal,
  } = props;

  const SSIpolicyDetailsForContainer = [
    {
      name: t("dataAgreements.lawfulBasisOfProcessing"),
      value: getLawfulBasisOfProcessing(
        selectedData.dataAgreement?.lawfulBasis,
        i18n.t
      ),
    },
    {
      name: t("dataAgreements.retentionPeriod"),
      value: selectedData.dataAgreement?.dataPolicy?.dataRetentionPeriod,
    },
    {
      name: t("common.policyUrl"),
      value: selectedData.dataAgreement?.dataPolicy?.policyURL,
    },
    {
      name: t("dataAgreements.jurisdiction"),
      value: selectedData.dataAgreement?.dataPolicy?.jurisdiction,
    },
    {
      name: t("dataAgreements.industryScope"),
      value: selectedData.dataAgreement?.dataPolicy?.industrySector,
    },

    {
      name: t("dataAgreements.storageLocation"),
      value: selectedData.dataAgreement?.dataPolicy?.storageLocation,
    },
    {
      name: t("dataAgreements.3pp"),
      value: selectedData.dataAgreement !== null && "False",
    },
    {
      name: t("issuanceHistory.blink"),
      value: "",
    },
    {
      name: t("issuanceHistory.didMyData"),
      value: "",
    },
  ];

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <Container>
          <HeaderContainer>
            <Box pl={2}>
              <Typography color="#F3F3F6">{headerText}</Typography>
            </Box>
            <CloseIcon
              onClick={() => {
                setOpen(false);
                setOpenViewExchangeRecordModal(false);
              }}
              sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
            />
          </HeaderContainer>

          <DetailsContainer
            style={{
              paddingBottom:  "4.5rem" ,
            }}
          >
            <Box m={1.5} style={titleAttrRestrictionStyle}>
              {SSIpolicyDetailsForContainer?.map((policyDetail, index) => (
                <SSIPolicyDetailsBox
                  key={index}
                  selectedData={selectedData}
                  isLastAttribute={
                    index === SSIpolicyDetailsForContainer.length - 1
                  }
                  name={policyDetail.name}
                  value={policyDetail.value}
                />
              ))}
            </Box>

            {selectedData.dataAgreement.proofChain &&
            selectedData.dataAgreement?.proofChain[0] ? (
              <>
                <Box m={1.5} style={titleAttrRestrictionStyle}>
                  <VerifiedDABox selectedData={selectedData} />
                </Box>
                <Box m={1.5} style={titleAttrRestrictionStyle}>
                  <IndividualDecentralisedIdentifierBox
                    selectedData={selectedData}
                  />
                </Box>
              </>
            ) : (
              <Box m={1.5} style={titleAttrRestrictionStyle}>
                <VerifiedDABox selectedData={selectedData} />
              </Box>
            )}
          </DetailsContainer>
          <FooterContainer>
            <Button
              onClick={() => {
                setOpen(false);
                setOpenViewExchangeRecordModal(false);
              }}
              style={buttonStyle}
              sx={{
                marginLeft: "15px",
                color: "black",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              variant="outlined"
            >
              {t("common.close")}
            </Button>
          </FooterContainer>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
