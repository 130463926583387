import * as React from "react";
import { Dispatch, SetStateAction, useState, useEffect } from "react";

import { Drawer, Typography, Button, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
} from "./modalStyle";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import CommonDropdownWithDefaultSelectValue from "../dropdowns/commonDropdownWithDefaultSelectValue";
import { HttpServiceForDigitalWalletEwc } from "../../service/digitalWalletEwcHttpService";
import SnackbarComponent from "../notification";
import LoaderComponent from "../LoaderComponent";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  ledgerNetworks: any;
  infrastructureProvider: any;
  digitalWallet: any;
}

const keyInfrastructure = [
  { label: "iGrant.io Secure Vault", value: "iGrant.io Secure Vault", disabled: false },
  {
    label: "FIDO U2F Security Key",
    value: "FIDO U2F Security Key",
    disabled: true,
  },
  {
    label: "HashiCorp Vault",
    value: "HashiCorp Vault",
    disabled: true,
  },
  {
    label: "Oracle Vault",
    value: "Oracle Vault",
    disabled: true,
  },
];

export default function KeyManagementModalForOrgId(props: Props) {
  const {
    open,
    setOpen,
    headerText,
    ledgerNetworks,
    infrastructureProvider,
    digitalWallet,
  } = props;
  const { t } = useTranslation("translation");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");
  const [openLoader, setOpenLoader] = useState(false);

  const { control, handleSubmit, formState, reset, watch } = useForm<any>({
    mode: "onChange",
  });

  useEffect(() => {
    let defaultValues = {
      infrastructureKey: "iGrant.io Secure Vault",
    };

    if (digitalWallet?.statusStr === "Deployed") {
      reset({
        infrastructureKey: "iGrant.io Secure Vault",
      });
    } else reset({ ...defaultValues });
  }, [open]);

  const selectedInfrastructureKey = watch("infrastructureKey");

  const onSubmit = (createdData: any) => {
    // let payload = {
    //   ledgerId: createdData.dwLedgerNetworks,
    //   infrastructureProvider: createdData.infrastructureProvider,
    // };
    // if (
    //   digitalWallet?.statusStr !== "Deployed"
    // ) {
    //   setOpenLoader(true);
    //   HttpServiceForDigitalWalletEwc.deployDigitalWallet(payload)
    //     .then(() => {
    //       setOpen(false);
    //       setOpenLoader(false);
    //     })
    //     .catch((error) => {
    //       setOpenSnackBar(true);
    //       setError(error.response.data.errorDescription);
    //       setOpenLoader(false);
    //     });
    // }
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SnackbarComponent
              open={openSnackBar}
              setOpen={setOpenSnackBar}
              message={error}
              topStyle={100}
            />
            <HeaderContainer>
              <Box pl={2}>
                <Typography color="#F3F3F6">{headerText}</Typography>
              </Box>
              <CloseIcon
                onClick={() => setOpen(false)}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <DetailsContainer>
              <Box p={1.5}>
                <Typography variant="subtitle1" mb={0} mt={1}>
                  {t("digitalWalletBase.keyInfrastructure")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>

                <CommonDropdownWithDefaultSelectValue
                  dropdownValue={keyInfrastructure}
                  control={control}
                  nameOfSelect={"dwLedgerNetworks"}
                  valueKey={"value"}
                  displayKey={"label"}
                  disabled={false}
                  selectedValue={selectedInfrastructureKey}
                />
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => setOpen(false)}
                style={buttonStyle}
                sx={{
                  marginLeft: "15px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>

              <Button
                variant="outlined"
                style={buttonStyle}
                sx={{
                  cursor:
                    formState.isValid && digitalWallet?.statusStr !== "Deployed"
                      ? "pointer"
                      : "not-allowed",
                  marginRight: "20px",
                  color:
                    formState.isValid && digitalWallet?.statusStr !== "Deployed"
                      ? "black"
                      : "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                type="submit"
              >
                {t("common.confirm")}
              </Button>
            </FooterContainer>
          </form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
