import CSS from "csstype";

import { Box, Typography } from "@mui/material";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useTranslation } from "react-i18next";
import { useWatch } from "react-hook-form";

const tableRestrictionInfoStyle = {
  border: "solid 1px #dee2e6",
  width: "100%",
  maxWidth: "100%",
  marginBottom: "1rem",
  backgroundColor: "transparent",
};

const tableRestrictionInfoColumn: CSS.Properties = {
  fontWeight: "normal",
  borderRight: "solid 1px #dee2e6",
  borderTop: "solid 1px #dee2e6",
  textAlign: "center",
};

const titleAttrRestrictionStyle = {
  fontWeight: "normal",
  margin: "10px 10px 5px 10px",
  borderBottom: "solid 1px #dee2e6",
  lineHeight: "1.5rem",
};

const tableAttrAdditionalInfoStyle = {
  border: 0,
  width: "100%",
  maxWidth: "100%",
  marginBottom: "0rem",
  backgroundColor: "#FFFF",
};

const tableAttrAdditionalInfoColumn: CSS.Properties = {
  fontWeight: "normal",
  border: "0px",
};

const inputStyleAttr = {
  width: "85%",
  color: "#495057",
  border: "1",
  borderWidth: 0,
  padding: 0,
  paddingBottom: 1,
  borderRadius: 0,
  fontSize: "14px",
  borderBottomWidth: 1.2,
  backgroundColor: "#FFFF",
  borderBottomColor: "red", //'#DFE0E1',
  marginRight: "10px",
  fontFamily: "Inter,Helvetica,Arial,sans-serif",
};

export interface Props {
  mode: string;
  index: number;
  remove: any;
  formController: any;
  fields: any;
  register: any;
  methods: any;
}

export const DataAttributeForRestrictions = (props: Props) => {
  const { index, remove, register, methods, mode } = props;
  const { t } = useTranslation("translation");

  const schemaIdValue = useWatch({
    control: methods.control,
    name: `dataAttributes.${index}.schemaId`,
  });

  const schemaIdHasValue = !!schemaIdValue;

  const credDefIdValue = useWatch({
    control: methods.control,
    name: `dataAttributes.${index}.credDefId`,
  });

  const credDefIdHasValue = !!credDefIdValue;

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    if (name === "schemaId-check") {
      methods.setValue(
        `dataAttributes.${props.index}.schemaId`,
        checked ? schemaIdValue : ""
      );
    } else if (name === "credefID-check") {
      methods.setValue(
        `dataAttributes.${props.index}.credDefId`,
        checked ? credDefIdValue : ""
      );
    }
  };

  return (
    <Box
      style={{
        marginBottom: "25px",
        border: "1px solid #DFE0E1",
        borderRadius: 5,
      }}
    >
      <Box style={titleAttrRestrictionStyle}>
        <table style={tableAttrAdditionalInfoStyle}>
          <tbody>
            <tr style={{ display: "flex", alignItems: "center" }}>
              <input
                {...register(`dataAttributes.${props.index}.attributeName`, {
                  required: true,
                  minLength: 3,
                  pattern: {
                    value: /.*\D.*/,
                    message: "",
                  },
                })}
                placeholder={t("dataAgreements.dataAttributesName")}
                disabled={props.mode === "Read"}
                style={{
                  ...inputStyleAttr,
                  border: "none",
                  outline: "none",
                  width: "100%",
                  cursor: props.mode === "Read" ? "not-allowed" : "auto",
                }}
                type="text"
                autoComplete="off"
              />
              <th>
                <DeleteOutlineOutlinedIcon
                  style={{
                    cursor: props.mode === "Read" ? "not-allowed" : "pointer",
                  }}
                  onClick={() => {
                    props.mode !== "Read" && remove(index);
                  }}
                />
              </th>
            </tr>
          </tbody>
        </table>
      </Box>

      <Box style={{ ...titleAttrRestrictionStyle }}>
        <table style={tableAttrAdditionalInfoStyle}>
          <tbody>
            <tr style={{ display: "flex" }}>
              <td style={{ ...tableAttrAdditionalInfoColumn, flexGrow: 1 }}>
                <input
                  {...register(`dataAttributes.${index}.attributeDescription`, {
                    required: true,
                    minLength: 3,
                    pattern: {
                      value: /.*\D.*/,
                      message: "",
                    },
                  })}
                  placeholder={t("dataAgreements.dataAttributesDescription")}
                  disabled={props.mode === "Read"}
                  style={{
                    ...inputStyleAttr,
                    border: "none",
                    outline: "none",
                    width: "100%",
                    cursor: props.mode === "Read" ? "not-allowed" : "auto",
                  }}
                  type="text"
                  autoComplete="off"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
      <Typography variant="subtitle1" ml={1.5} mt={1.5}>
      {t("dataAgreements.restrictions")}
      </Typography>

      <Box style={{ padding: "0px", margin: "5px 10px" }}>
        <table style={tableRestrictionInfoStyle}>
          <tbody>
            <tr>
              <th
                style={{
                  ...tableRestrictionInfoColumn,
                  borderTop: "none",
                }}
              >
                {" "}
                <input
                  name={"schemaId-check"}
                  type="checkbox"
                  style={{
                    cursor: mode === "Read" ? "not-allowed" : "pointer",
                  }}
                  checked={schemaIdHasValue}
                  disabled={mode === "Read"}
                  onChange={handleCheckboxChange}
                />
              </th>

              <th
                style={{
                  ...tableRestrictionInfoColumn,
                  borderTop: "none",
                  fontFamily: "Inter,Helvetica,Arial,sans-serif",
                  fontSize:"14px",
                }}
                scope="row"
              >
                {t("dataAgreements.schemaID")}
              </th>

              <td
                style={{
                  ...tableRestrictionInfoColumn,
                  borderRight: "none",
                  borderTop: "none",
                }}
              >
                <input
                  {...register(`dataAttributes.${props.index}.schemaId`)}
                  disabled={props.mode === "Read"}
                  style={{
                    ...inputStyleAttr,
                    border: "none",
                    outline: "none",
                    width: "100%",
                    cursor: props.mode === "Read" ? "not-allowed" : "auto",
                  }}
                  type="text"
                  autoComplete="off"
                />
              </td>
            </tr>

            <tr>
              <th style={tableRestrictionInfoColumn}>
                <input
                  name={"credefID-check"}
                  type="checkbox"
                  style={{
                    cursor: mode === "Read" ? "not-allowed" : "pointer",
                  }}
                  checked={credDefIdHasValue}
                  disabled={mode === "Read"}
                  onChange={handleCheckboxChange}
                />
              </th>

              <th  style={{
                  ...tableRestrictionInfoColumn,
                  fontSize:"14px",
                  fontFamily: "Inter,Helvetica,Arial,sans-serif",
                }} scope="row">
                {t("dataAgreements.credDefID")}
              </th>

              <td
                style={{
                  ...tableRestrictionInfoColumn,
                  borderRight: "none",
                }}
                data-title="cred-def-id"
              >
                <input
                  {...register(`dataAttributes.${props.index}.credDefId`)}
                  disabled={props.mode === "Read"}
                  style={{
                    ...inputStyleAttr,
                    border: "none",
                    outline: "none",
                    width: "100%",
                    cursor: props.mode === "Read" ? "not-allowed" : "auto",
                  }}
                  type="text"
                  autoComplete="off"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Box>
  );
};
