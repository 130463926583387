import BreadCrumb from "../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { Elements, StripeProvider } from "react-stripe-elements";
import CheckoutForm from "./stripeCardForm";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const FormContainer = styled("div")(({ theme }) => ({
  display: "grid",
  border: "1px solid #DFE0E1",
  borderRadius: 3,
  padding: "12px",
  width: "50%",
  marginTop: "20px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const PaymentDetailsPage = () => {
  const { t } = useTranslation("translation");

  return (
    <Container>
      <BreadCrumb
        Link={t("sidebar.account")}
        Link2={t("sidebar.billing")}
        Link3={t("onboarding.paymentMethod")}
      />

      <HeaderContainer>
        <Typography variant="h6" fontWeight="bold">
          {t("onboarding.paymentMethod")}
        </Typography>
      </HeaderContainer>
      <Typography variant="body2" mt={1.25}>
        {t("billing.addOrEditPaymentMethod")}:
      </Typography>

      <FormContainer>
        <Typography variant="subtitle1" color={"black"} fontWeight="bold">
          {t("onboarding.cardDetails")}
        </Typography>

        <Box mt={1} mb={1} style={{ height: "20px" }}>
          <Typography variant="subtitle2" color={"black"}>
            {t("onboarding.enterCardDetails")}
          </Typography>
        </Box>

        <StripeProvider
          apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string}
        >
          <Elements>
            <CheckoutForm />
          </Elements>
        </StripeProvider>
      </FormContainer>
    </Container>
  );
};

export default PaymentDetailsPage;
