export const DDAENDPOINTS = {
  addDataDisclosureAgreements: () => {
    return "addDataDisclosureAgreements";
  },
  publishDataDisclosureAgreement: (id: string | undefined) => {
    return `/config/data-agreement/${id}`;
  },
  updateDataDisclosureAgreement: (id: string | undefined) => {
    return `/config/data-agreement/${id}`;
  },
  publishDDAtoDataMarketplace: (id: string | undefined) => {
    return `/config/data-agreement/${id}`;
  },
  deleteDataDisclosureAgreement: (id: string | undefined) => {
    return `/config/data-agreement/${id}`;
  },
  configureDataMarketPlace: (id: string | undefined) => {
    return `/config/data-agreement/${id}`;
  },
  pullDataFromDataSource: (id: string | undefined) => {
    return `/config/data-agreement/${id}`;
  },
  signDataDIsclosureAgreements: (id: string | undefined) => {
    return `/config/data-agreement/${id}`;
  },
  deleteSignedDataDisclosureAgreement: (id: string | undefined) => {
    return `/config/data-agreement/${id}`;
  },
};
