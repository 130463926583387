import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { DataAttributeTableForSendVerification } from "./dataAttributeTableForSendVerification";
import { Typography } from "@mui/material";
import { t } from "i18next";

export const CarouselStepper = ({
  items,
  current,
  onChange,
  setOpenViewModal,
}: any) => {
  const theme = useTheme();
  const maxSteps = items?.length;

  const handleNext = () => {
    onChange(current + 1);
  };

  const handleBack = () => {
    onChange(current - 1);
  };

  const handleStepChange = (step: number) => {
    onChange(step);
  };

  return (
    <Box>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={current}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {items?.map((item: any, index: any) => (
          <div key={index}>
            {Math.abs(current - index) <= 2 ? (
              <Box>
                {item &&
                  item.match &&
                  Object.entries(item.match)
                    .filter(([key]) => key.startsWith("$.credentialSubject."))
                    .map(([key, value]) => (
                      <DataAttributeTableForSendVerification
                        key={key}
                        attributeKey={key}
                        attributeValue={value}
                      />
                    ))}
              </Box>
            ) : null}
          </div>
        ))}
      </SwipeableViews>

      <Box display="flex" justifyContent="flex-end" marginTop="10px">
        <Typography
          component="span"
          sx={{
            fontSize: "14px",
            textDecoration: "underline",
            color: "blue",
            cursor: "pointer",
            display: "inline-block",
          }}
          onClick={() => setOpenViewModal(true)}
        >
          {t("holder.viewCredential")}
        </Typography>
      </Box>

      {items.length > 1 && (
        <>
          <Typography color="#9F9F9F" variant="body2" sx={{ mt: 1, mb: 1 }}>
            Multiple data cards found. Choose the right card and data to
            exchange
          </Typography>

          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={current}
            sx={{
              cursor:
                current === maxSteps - 1 || current === 0
                  ? "not-allowed"
                  : "pointer",
              "& .MuiMobileStepper-dotActive": {
                backgroundColor: "#616161", // active dot color
              },
            }}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={current === maxSteps - 1}
                sx={{
                  color: "#616161",
                }}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={current === 0}
                sx={{
                  color: "#616161",
                }}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </>
      )}
    </Box>
  );
};

export default CarouselStepper;
