/* eslint-disable no-lone-blocks */
import { useState } from "react";
import { Form, TextInput, useLogin } from "react-admin";
import { Box, Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

// icons
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

import Logo from "../../assets/iGrant_210_55_BW.svg";
import bgImage from "../../assets/Business_Login.jpg";
import SnackbarComponent from "../../components/notification";
import { configStore } from "../../store/configStore";
import { useTranslation } from "react-i18next";
import Footer from "../../components/layout/LoginFooter";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const Container = styled("div")(({ theme }) => ({
  height: "100vh",
  width: "100%",
  backgroundImage: `url("${bgImage}")`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  // zIndex: -1000,
  // position: "fixed",
  display: "grid",
  justifyItems: "center",
  alignItems: "center",
  [theme.breakpoints.down("xs")]: {
    padding: "5rem",
  },
}));

const FormContainer = styled("div")(({ theme }) => ({
  display: "flex",
  // position: "fixed",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgb(104, 139, 169)",
  color: "white",
  borderRadius: "1.5rem",
  borderWidth: "0px",
  opacity: 0.9,
  marginTop: "-32px",
  padding: "4.5rem",
  [theme.breakpoints.only("xs")]: {
    padding: "2rem",
  },
}));

const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: "2rem",
  width: "450px",
  [theme.breakpoints.down("sm")]: {
    width: "210px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "110px",
  },
}));

const FooterContainer = styled("div")({
  position: "fixed",
  bottom: 16,
  width: "100%",
  display: "flex",
  justifyContent: "right",
});

const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "#fff",
  "&:hover": {
    textDecoration: "underline",
  },
});

export const Login = () => {
  const login = useLogin();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");
  const version = configStore.appVersion;
  const { t } = useTranslation("translation");
  const [Loading, setLoading] = useState(false);
  const submit = () => {
    setLoading(true);
    login({ username, password })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(t("login.errorMessage"));
        setOpenSnackBar(true);
      });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submit();
    }
  };

  return (
    <Container>
      <FormContainer>
        <Form onSubmit={submit}>
          <SnackbarComponent
            open={openSnackBar}
            setOpen={setOpenSnackBar}
            message={error}
          />
          <Box>
            <ImageContainer>
              <img src={Logo} alt="Logo1" style={{ width: "100%" }} />
            </ImageContainer>

            <Box
              style={{
                boxSizing: "border-box",
                padding: 5,
                border: "1px solid #cecece",
                borderRadius: "0.9rem",
                backgroundColor: "#ECF3F7",
                height: "auto",
                overflow: "hidden",
              }}
            >
              <TextInput
                autoFocus
                name="email"
                type="email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                source="username"
                variant="standard"
                sx={{
                  height: "27px",
                  paddingLeft: 1,
                  paddingTop: 0,
                  marginTop: 0,
                }}
                label={false}
                placeholder={t("login.userid")}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  onKeyPress: handleKeyPress,
                }}
              />

              <Divider sx={{ padding: 0, margin: 0 }} />
              <TextInput
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                source="password"
                variant="standard"
                label={false}
                placeholder={t("login.password")}
                fullWidth
                sx={{
                  height: "27px",
                  paddingLeft: 1,
                  paddingTop: 0,
                  marginTop: 0,
                }}
                InputProps={{
                  disableUnderline: true,
                  onKeyPress: handleKeyPress,
                  endAdornment: Loading ? (
                    <CircularProgress
                      style={{
                        color: "#A1A1A1",
                        marginRight: "10px",
                      }}
                      size={24}
                    />
                  ) : (
                    <ArrowCircleRightOutlinedIcon
                      style={{
                        color: "#A1A1A1",
                        cursor: "pointer",
                        right: 100,
                      }}
                      onClick={submit}
                    />
                  ),
                }}
              />
            </Box>

            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Divider
                style={{
                  backgroundColor: "white",
                  width: "93%",
                  marginTop: "6rem",
                  textAlign: "center",
                }}
              />
            </Box>
            <Typography variant="body2" align="center" mt={2} mb={1.5}>
              {t("login.NotAnIgrantBussiness")} &nbsp;
              <StyledLink to={"/onboarding"}>{t("login.enrollNow")}</StyledLink>
            </Typography>
          </Box>
        </Form>
      </FormContainer>
      <FooterContainer>
        <Footer version={version} />
      </FooterContainer>
    </Container>
  );
};

export default Login;
