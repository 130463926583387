import i18n from "i18next";

export const downloadDAAsJson = (record: any, pageName: string) => {
  const updatedRecord = () => {
    if (pageName === "verificationHistoryEWC") {
      const { status, ...otherProps } = record;

      return {
        status: getStateForVerificationForEWCAsInAPI(status, i18n.t),
        ...otherProps,
      };
    } else if (pageName === "credentialHistoryEWC") {
      const { status, ...otherProps } = record;

      return {
        status: getStateForCredentialHistoryForEWCAsInAPI(status, i18n.t),
        ...otherProps,
      };
    } else return record;
  };

  const jsonContent = JSON.stringify(updatedRecord(), null, 2);
  const blob = new Blob([jsonContent], { type: "application/json" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "record.json");
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const getStateForVerificationForEWCAsInAPI = (
  state: string,
  t: (key: string) => string
) => {
  if (state === t("issuanceHistory.requestSent")) {
    return "request_sent";
  } else if (state === t("issuanceHistory.requestReceived")) {
    return "request_received";
  } else if (state === t("issuanceHistory.credentialAcked")) {
    return "credential_acked";
  } else if (state === t("verificationsHistory.presentationAcked")) {
    return "presentation_acked";
  } 
};

export const getStateForCredentialHistoryForEWCAsInAPI = (
  state: string,
  t: (key: string) => string
) => {
  if (state === t("issuanceHistory.offerSent")) {
    return "offer_sent";
  } else if (state === t("issuanceHistory.offerReceived")) {
    return "offer_received";
  } else if (state === t("issuanceHistory.credentialAcked")) {
    return "credential_acked";
  } else if (state === t("issuanceHistory.credentialReceived")) {
    return "credential_issued";
  }
};
