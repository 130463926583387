import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatISODateToLocalString } from "../../utils/formatISODateToLocalString";

const DateShowComponent = ({ selectedData }: any) => {
  const { t } = useTranslation("translation");

  const formattedDateForCredential = formatISODateToLocalString(
    selectedData?.credential?.vc?.issuanceDate
  );

  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
      <Typography color={"grey"} variant="subtitle2">
        {`${t("holder.issuedAt")}: ${formattedDateForCredential}`}
      </Typography>
    </Box>
  );
};

export default DateShowComponent;
