import { useState } from "react";
import { List, Datagrid, TextField, Form, useRecordContext } from "react-admin";

import { Box, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import BreadCrumb from "../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { TableEmptyMessage } from "../../components/tableEmptyMessage";
import useLanguageChange from "../../utils/translateTableLanguage";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import RawExchangeModeModal from "../../components/modals/rawExchangeModeModal";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const PullDataHistory = () => {
  const [openRawExchangeModeModal, setOpenRawExchangeModeModal] =
    useState(false);
  const [
    selectededDataAgreementFromDataAgreement,
    setSelectededDataAgreementFromDataAgreement,
  ] = useState<any>();
  const [listFilterValue, setListFilterValue] = useState("all");
  const { t } = useTranslation("translation");
  const key = useLanguageChange();

  const IconsFIeld = (props: any) => {
    const record = useRecordContext(props);

    if (!record || !props.source) {
      return null;
    }

    return (
      record[props.source] && (
        <Box display={"flex"} justifyContent={"space-around"}>
          <Tooltip
            title={t("signedAgreements.viewPullDataRecord")}
            placement="top"
          >
            <IntegrationInstructionsOutlinedIcon
              onClick={() => {
                setOpenRawExchangeModeModal(true);
                setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>
        </Box>
      )
    );
  };

  return (
    <Container>
      <Form>
        <BreadCrumb
          Link={t("sidebar.signedAgreements")}
          Link2={t("signedAgreements.pullDataHistory")}
        />
        <HeaderContainer>
          <Typography variant="h6" fontWeight="bold">
            {t("signedAgreements.pullDataHistory")}
          </Typography>
        </HeaderContainer>
        <Typography variant="body2" mt={1.25}>
          {t("signedAgreements.pageDescriptionForPullDataHistory")}
        </Typography>
      </Form>

      <List
        actions={false}
        sx={{ width: "100%", overflow: "hidden" }}
        filter={{ status: listFilterValue }}
        empty={<TableEmptyMessage />}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              overflow: "auto",
              width: "100%",
            }}
            key={key}
          >
            <TextField
              source="dda_instance_id"
              label={t("signedAgreements.dataAgreementId")}
              sortable={false}
            />
            <TextField
              source="state"
              label={t("issuanceHistory.state")}
              sortable={false}
            />
            <TextField
              source="updated_at"
              label={t("signedAgreements.updatedAt")}
              sortable={false}
            />

            <IconsFIeld source="dda_instance_id" sortable={false} label={""} />
          </Datagrid>
        </Box>
      </List>

      {/* Modals */}
      <RawExchangeModeModal
        open={openRawExchangeModeModal}
        setOpen={setOpenRawExchangeModeModal}
        headerText={t("signedAgreements.pullDataRecord")}
        selectedData={selectededDataAgreementFromDataAgreement}
        dda={"pullDataRecord"}
      />
    </Container>
  );
};

export default PullDataHistory;
