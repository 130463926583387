export const DIGITALWALLETENDPOINTS = {
  getPredefinedSchemaValues: () => {
    return `/config/digital-wallet/verification-templates/pre-defined`;
  },
  getSchemaValuesByExistingId: (searchValue: any) => {
    return `/digital-wallet/schema/${searchValue}`;
  },

  generateQrCodeForDataAgreement: () => {
    return `/digital-wallet/verification/qr?multi_use=True`;
  },
  generateFirebaseDynamicLinkForDataAgreementQrCode: (qrCodeId: any) => {
    return `/digital-wallet/verification/qr/${qrCodeId}/firebase`;
  },
  generateQrCodeForOrganisations: () => {
    return `/digital-wallet/connection?multi_use=true`;
  },

  // Self-Sovereign Identity apis
  listConnections: (offsetValue: number, pageSize: number) => {
    return `/digital-wallet/connections?state=active&limit=${pageSize}&offset=${offsetValue}`;
  },
  deleteConnections: (id: any) => {
    return `/digital-wallet/connection/${id}`;
  },
  receiveConnection: () => {
    return `/digital-wallet/connection/receive?autoAccept=true`;
  },
  sendOfferForCredentials: () => {
    return `/digital-wallet/credential/issue`;
  },
  OfferForVerificationsRequest: () => {
    return `/digital-wallet/verification/send`;
  },

  listDigitalWalletDataAgreementsWithMethodOfUseFilter: (
    offsetValue: number,
    pageSize: number,
    filter: string,
    revisionId: string | undefined,
    includeRevisions: boolean | string,
    dexProfile: string | undefined,
  ) => {
    let url = `/config/data-agreements?methodOfUse=${filter}&limit=${pageSize}&offset=${offsetValue}&lifecycle=complete&includeRevisions=${includeRevisions}${
      revisionId !== "" ? `&revisionId=${revisionId}` : ""
    }`;
    if (dexProfile !== undefined) {
      url += `&dexProfile=${dexProfile}`
    } else {
      url += `&dexProfile=AIP10`
    }
    return url;
  },
  listIssueCredentialHistoryRecords: (
    offsetValue: number,
    pageSize: number,
    id: any
  ) => {
    return `/digital-wallet/credential/history?state=credential_acked&dataAgreementId=${id}&limit=${pageSize}&offset=${offsetValue}`;
  },
  deleteConnectionsHistory: (credentialExchangeId: any) => {
    return `/digital-wallet/credential/history/${credentialExchangeId}`;
  },
  listIssueVerifiedHistoryRecords: (
    offsetValue: number,
    pageSize: number,
    id: any
  ) => {
    return `/digital-wallet/verification/history?state=verified&dataAgreementId=${id}&limit=${pageSize}&offset=${offsetValue}`;
  },
  deleteVerificationsHistory: (presentationExchangeId: any) => {
    return `/digital-wallet/verification/history/${presentationExchangeId}`;
  },

  // Data verifier User Apis
  listDataVerifierUsers: (offsetValue: number, pageSize: number) => {
    return `/onboard/organisation/data-verifier-users?limit=${pageSize}&offset=${offsetValue}`;
  },
  deleteDataVerifierUsers: (id: string | undefined) => {
    return `/onboard/organisation/data-verifier-user/${id}`;
  },
  sendMagicLink: (id: any) => {
    return `/onboard/organisation/data-verifier-user/${id}/send-magic-link`;
  },
  createDataVerifierUsers: () => {
    return `onboard/organisation/data-verifier-user`;
  },
  updateDataVerifierUsers: (id: any) => {
    return `/onboard/organisation/data-verifier-user/${id}`;
  },

  // Digital wallet apis
  getDigitalWalletVersions: () => {
    return `/config/digital-wallet/releases`;
  },
  getLedgerNetworks: () => {
    return `/config/digital-wallet/ledger-networks`;
  },
  deployDigitalWallet: () => {
    return `/config/digital-wallet`;
  },
  getDigitalWallet: () => {
    return `/config/digital-wallet`;
  },
  updateDigitalWalletVersion: () => {
    return `/config/digital-wallet/version`;
  },
  getOrganisationalIdentifier: () => {
    return `/digital-wallet/organisation-identifier`;
  },

  // billing
  getInvoices: () => {
    return `/onboard/organisation/billing/invoices`;
  },
  readBilling: () => {
    return `/onboard/organisation/billing`;
  },
  getSubscribedPlanDetails: () => {
    return `/onboard/organisation/billing/plan`;
  },
  terminateBillingPlan: (plan: string | undefined) => {
    return `/onboard/organisation/billing/plan/${plan}`;
  },
  createSubscribePlan: (plan: string | undefined) => {
    return `/onboard/organisation/billing/plan/${plan}`;
  },

  // user requests
  listDataRequest: (offsetValue: number, pageSize: number, status: string) => {
    return `/config/organisation/data-requests?limit=${pageSize}&offset=${offsetValue}&status=${status}`;
  },
  updateDataRequest: (id: any) => {
    return `/config/organisation/data-request/${id}`;
  },
};
