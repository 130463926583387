import { DDAENDPOINTS } from "../settings/ddaSettings";
import { getAuthenticatedHeaders, httpClient } from "./HTTPService";

export const HttpServiceForDDA = {
  // dda
  addDataDisclosureAgreements: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.post(
      DDAENDPOINTS.addDataDisclosureAgreements(),
      payload,
      config
    );
  },
  publishDataDisclosureAgreement: async (
    payload: any,
    dataAgreementId: string | undefined
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.put(
      DDAENDPOINTS.publishDataDisclosureAgreement(dataAgreementId),
      payload,
      config
    );
  },
  updateDataDisclosureAgreement: async (
    payload: any,
    dataAgreementId: string | undefined
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.put(
      DDAENDPOINTS.updateDataDisclosureAgreement(dataAgreementId),
      payload,
      config
    );
  },
  publishDDAtoDataMarketplace: async (
    payload: any,
    dataAgreementId: string | undefined
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.put(
      DDAENDPOINTS.publishDDAtoDataMarketplace(dataAgreementId),
      payload,
      config
    );
  },
  deleteDataDisclosureAgreement: async (
    dataAgreementId: string | undefined
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.delete(
      DDAENDPOINTS.deleteDataDisclosureAgreement(dataAgreementId),
      config
    );
  },

  // connections page
  configureDataMarketPlace: async (
    payload: any,
    dataAgreementId: string | undefined
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.put(
      DDAENDPOINTS.configureDataMarketPlace(dataAgreementId),
      payload,
      config
    );
  },

  // signed agreement page
  pullDataFromDataSource: async (
    payload: any,
    dataAgreementId: string | undefined
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.put(
      DDAENDPOINTS.pullDataFromDataSource(dataAgreementId),
      payload,
      config
    );
  },
  signDataDIsclosureAgreements: async (
    payload: any,
    dataAgreementId: string | undefined
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.put(
      DDAENDPOINTS.signDataDIsclosureAgreements(dataAgreementId),
      payload,
      config
    );
  },
  deleteSignedDataDisclosureAgreement: async (
    dataAgreementId: string | undefined
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.delete(
      DDAENDPOINTS.deleteSignedDataDisclosureAgreement(dataAgreementId),
      config
    );
  },
};
