import { Button } from "react-admin";

const CommonButton = ({ buttonName, handleClick, width }: any) => {
  return (
    <Button
      variant="outlined"
      sx={{
        height: 30,
        width: width ? width : 150,
        fontSize:"14px",
        borderRadius: 0,
        border: "1px solid #DFDFDF",
        color: "black",
        "&:hover": {
          backgroundColor: "black",
          color: "white",
        },
      }}
      onClick={handleClick}
    >
     <>{buttonName}</>
    </Button>
  );
};

export default CommonButton;
