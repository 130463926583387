import { styled } from "@mui/system";

import { Chip, IconButton } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

interface ChipProps {
  children?: React.ReactNode;
}

const CustomChip = styled(Chip)<ChipProps>(({ theme }) => ({
  "& .MuiChip-icon": {
    color: theme.palette.text.primary,
  },
}));

interface Props {
  chipKey: string;
  key: string;
  handleAddIconClick: any;
}

const DataSchemaChips = (props: Props) => {
  const { key, chipKey, handleAddIconClick } = props;
  return (
    <CustomChip
      key={key}
      label={
        <>
          {chipKey}
          <IconButton
            size="small"
            color="inherit"
            onClick={() => handleAddIconClick(chipKey)}
          >
            <AddCircleOutlineOutlinedIcon />
          </IconButton>
        </>
      }
      sx={{
        margin: 0.5,
        color: "black",
        backgroundColor: "#E5E5E5",
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    />
  );
};

export default DataSchemaChips;
