import * as React from "react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  Drawer,
  Typography,
  Button,
  Box,
  Avatar,
  Tooltip,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  disabledButtonstyle,
  BannerContainer,
} from "../modalStyle";

import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import LoaderComponent from "../../LoaderComponent";
import { HttpServiceForDigitalWalletEwc } from "../../../service/digitalWalletEwcHttpService";
import SnackbarComponent from "../../notification";
import DefaultBanner from "../../../assets/OrganisationDefaultBanner.jpeg";
import DefaultLogo from "../../../assets/OrganisationDefaultLogo.png";
import { OrganizationDetailsCRUDContext } from "../../../contexts/organizationDetailsCrud";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EwcIssuePresentationDefinitionModal from "./ewcIssuePresentationDefinitionModal";
import {
  CREDENTIAL_TYPES,
  CREDENTIAL_TYPE_LABELS,
} from "../../../utils/credentialsHistoryUtils";
import VerificationFormatCheckbox from "../../checkboxes/verificationFormatCheckbox";
import {
  determineVerificationFormat,
  getPresentationDefinitionPayload,
  handleModalClose,
  stripCredentialSubject,
} from "../../../utils/verificationRequestUtils";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { DataAttributeCardEWCPresentationDefinition } from "../../verificationEWC/dataAttributeCard";
import SelectDefaultValueFOrEwcVerification from "../../dropdowns/selectDefaultValueForEwcVerification";
import { DataTypeCardEWCPresentationDefinition } from "../../verificationEWC/dataTypeCard";
import { isFormDataChanged } from "../../../utils/isFormDataChanged";
import { validateJson } from "../../../utils/issuCredentialUtils";
import { presentationDefinitionJsonSchema } from "../../../utils/jsonSchema";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  onRefetch: any;
  selectedData?: any;
  mode: string;
}

let defaultValue = {
  presentationDropdown: "",
  fields: [],
  limitDisclosure: true,
  typeCheck: "",
  typeFilter: "",
  label: "",
  presentationDefinition: JSON.stringify(
    {
      constraints: {
        fields: [],
      },
    },
    null,
    2
  ),
};

export default function AddPresentationDefinitionModalEwc(props: Props) {
  const { organisationDetails, logoImageBase64, coverImageBase64 }: any =
    React.useContext(OrganizationDetailsCRUDContext);
  const { t } = useTranslation("translation");
  const { open, setOpen, headerText, onRefetch, selectedData, mode } = props;
  const [openLoader, setOpenLoader] = useState(false);
  const [error, setError] = useState<any>("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [
    openEditPresentationDefinitionModal,
    setOpenEditPresentationDefinitionModal,
  ] = useState(false);
  const [isValidJson, setIsValidJson] = useState(true);

  const credntialDropdownValues = [
    {
      label:
        CREDENTIAL_TYPE_LABELS.VERIFIABLE_LEGAL_PERSONAL_IDENTIFICATION_DATA,
      value:
        CREDENTIAL_TYPES.VERIFIABLE_LEGAL_PERSONAL_IDENTIFICATION_DATA_SDJWT,
    },
    {
      label: CREDENTIAL_TYPE_LABELS.VERIFIABLE_CERTIFICATE_OF_REGISTRATION,
      value: CREDENTIAL_TYPES.VERIFIABLE_CERTIFICATE_OF_REGISTRATION_SDJWT,
    },
    {
      label: CREDENTIAL_TYPE_LABELS.VERIFIABLE_PORTABLE_DOCUMENT_A1,
      value: CREDENTIAL_TYPES.VERIFIABLE_PORTABLE_DOCUMENT_A1_SDJWT,
    },
    {
      label: CREDENTIAL_TYPE_LABELS.VERIFIABLE_PASSPORT,
      value: CREDENTIAL_TYPES.VERIFIABLE_PASSPORT,
    },
  ];

  const methods = useForm<any>({
    mode: "onChange",
    defaultValues: {
      ...defaultValue,
    },
  });

  const { control, register, setValue, resetField } = methods;

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: "fields",
  });

  useEffect(() => {
    if (mode === "Create") {
      methods.reset({
        fields: [],
        limitDisclosure: true,
        typeCheck: "",
        typeFilter: "",
        label: "",
        presentationDropdown: "",
        presentationDefinition: JSON.stringify(
          {
            constraints: {
              fields: [],
            },
          },
          null,
          2
        ),
      });
    } else if (mode === "Update" || mode === "View") {
      let presentationDefinitionFieldsForUpdate =
        selectedData?.presentationDefinition?.input_descriptors[0];

      let credentiaTypeForUpdate =
        presentationDefinitionFieldsForUpdate?.constraints?.fields[0]?.filter
          ?.contains?.const;

      const updatedFields =
        selectedData?.presentationDefinition?.input_descriptors[0].constraints.fields
          .filter((field: any) => field.path[0] !== "$.type")
          .map((field: any) => ({
            path: stripCredentialSubject(field.path[0]),
            hasPrefix: field.path[0].startsWith("$.credentialSubject."),
            isHidden: false,
            filter: field.filter,
          }));

      const typeField =
        selectedData?.presentationDefinition?.input_descriptors[0]?.constraints?.fields?.find(
          (field: any) => field.path[0] === "$.type"
        );

      methods.reset({
        label: selectedData?.label,
        fields: updatedFields,
        limitDisclosure:
          determineVerificationFormat(selectedData?.presentationDefinition) ===
          "SD-JWT"
            ? true
            : false,
        typeCheck: typeField ? typeField.path[0] : "",
        typeFilter: typeField.filter.contains.const,
        presentationDropdown: credentiaTypeForUpdate
          ? credentiaTypeForUpdate
          : "",
        presentationDefinition: JSON.stringify(
          {
            constraints: {
              fields: [],
            },
          },
          null,
          2
        ),
      });
    }
  }, [selectedData, open]);

  const presentationDefinitionValue = useWatch({
    control: methods.control,
    name: `presentationDefinition`,
  });

  const selectedDropdownValue = useWatch({
    control: methods.control,
    name: `presentationDropdown`,
  });

  const watchTypeFilter = useWatch({
    control: methods.control,
    name: `typeFilter`,
  });

  const watchFields = useWatch({
    control: methods.control,
    name: `fields`,
  });

  const watchLimitDisclosure = useWatch({
    control: methods.control,
    name: `limitDisclosure`,
  });

  const watchTypeCheck = useWatch({
    control: methods.control,
    name: `typeCheck`,
  });

  const updateJsonFromFields = React.useCallback(() => {
    const updatedJson: any = {
      constraints: {
        fields: [],
      },
    };

    // Add type field first if typeCheck is set
    if (watchTypeCheck) {
      updatedJson.constraints.fields.push({
        path: watchTypeCheck.split(",").map((p: any) => p.trim()),
        ...(watchTypeFilter && {
          filter: {
            type: "array",
            contains: { const: watchTypeFilter },
          },
        }),
      });
    } else {
      updatedJson.constraints.fields.push({
        path: ["$.type"],
        filter: {
          type: "array",
          contains: { const: watchTypeFilter },
        },
      });
    }

    // Add other fields
    updatedJson.constraints.fields.push(
      ...watchFields
        .filter(
          (field: any) => !field?.isHidden && !field?.path?.includes("$.type")
        )
        .map((field: any) => {
          const fieldData: any = {
            path: field?.path
              ?.split(",")
              .map((p: any) =>
                field.hasPrefix ? `$.credentialSubject.${p.trim()}` : p.trim()
              ),
          };
          if (field.filter) {
            fieldData.filter = field.filter;
          }
          return fieldData;
        })
    );

    if (watchLimitDisclosure) {
      updatedJson.constraints.limit_disclosure = "required";
    }

    setValue("presentationDefinition", JSON.stringify(updatedJson, null, 2));
  }, [
    watchFields,
    watchLimitDisclosure,
    watchTypeCheck,
    watchTypeFilter,
    setValue,
  ]);

  useEffect(() => {
    updateJsonFromFields();
  }, [updateJsonFromFields]);

  const handlePresentationDefinitionChange = (value: any) => {
    setValue("presentationDefinition", value, {
      shouldDirty: true,
    });
  };

  const handleAddField = () => {
    append({ path: "", hasPrefix: true, isHidden: false });
  };

  const isSubmitButtonEnabled =
    mode !== "View" &&
    methods.formState.isValid &&
    isValidJson &&
    isFormDataChanged(methods.formState);

  const onSubmit = (createdData: any) => {
    if (isSubmitButtonEnabled) {
      updateJsonFromFields();

      let presentationDefinition = JSON.parse(
        presentationDefinitionValue.trim()
      );

      setOpenLoader(true);
      const payload = getPresentationDefinitionPayload(
        watchLimitDisclosure,
        presentationDefinition,
        createdData.label,
        selectedData,
        mode
      );

      if (mode === "Create") {
        HttpServiceForDigitalWalletEwc.createPresentationDefinitionForEWC(
          payload
        )
          .then(() => {
            onRefetch();
            setOpenLoader(false);
            setOpen(false);
          })
          .catch((err) => {
            setOpenLoader(false);
            setError(err.response.data.errorDescription);
            setOpenSnackBar(true);
          });
      } else if (mode === "Update") {
        HttpServiceForDigitalWalletEwc.updatePresentationDefinitionForEWC(
          payload,
          selectedData.presentationDefinitionId
        )
          .then(() => {
            onRefetch();
            setOpenLoader(false);
            setOpen(false);
          })
          .catch((err) => {
            setOpenLoader(false);
            setError(err.response.data.errorDescription);
            setOpenSnackBar(true);
          });
      }
    }
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container style={{ overflow: openLoader ? "hidden" : undefined }}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <HeaderContainer>
              <SnackbarComponent
                open={openSnackBar}
                setOpen={setOpenSnackBar}
                message={error}
              />
              <Box pl={2}>
                <Typography color="#F3F3F6">{headerText}</Typography>
              </Box>
              <CloseIcon
                onClick={() => {
                  setOpen(false);
                }}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <BannerContainer>
              <Box
                style={{ height: "150px", width: "100%" }}
                component="img"
                alt="Banner"
                src={
                  coverImageBase64
                    ? `data:image/jpeg;charset=utf-8;base64,${coverImageBase64}`
                    : DefaultBanner
                }
              />
            </BannerContainer>
            <Box sx={{ marginBottom: "60px" }}>
              <Avatar
                src={
                  logoImageBase64
                    ? `data:image/jpeg;charset=utf-8;base64,${logoImageBase64}`
                    : DefaultLogo
                }
                style={{
                  position: "absolute",
                  marginLeft: 50,
                  marginTop: "-65px",
                  width: "110px",
                  height: "110px",
                  border: "solid white 6px",
                }}
              />
            </Box>
            <DetailsContainer style={{ paddingBottom: "80px" }}>
              <Box p={1.5}>
                <Typography variant="h6" fontWeight="bold">
                  {organisationDetails.name}
                </Typography>

                <Typography variant="subtitle1" mb={0}>
                  {t("dataAgreements.usagePurpose")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>

                <TextField
                  autoFocus
                  variant="standard"
                  fullWidth
                  autoComplete="off"
                  disabled={mode === "View"}
                  placeholder={t("credentials.usagePurposePlaceHolder")}
                  {...register("label", {
                    required: true,
                    validate: (value) => {
                      const trimmedValue = value.trim();
                      return trimmedValue.length >= 3;
                    },
                    pattern: {
                      value: /^(?=.*\S).+$/,
                      message: "",
                    },
                  })}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      cursor: "not-allowed",
                    },
                    "& .MuiAutocomplete-inputRoot.Mui-disabled .MuiAutocomplete-clearIndicator":
                      {
                        display: "none",
                      },
                  }}
                />

                <Box
                  mt={1.5}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="subtitle1">
                    {t("verificationsHistory.presentationDefinition")}
                    <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                  </Typography>

                  <Tooltip
                    title={"Enter free text in JSON format"}
                    placement="top"
                  >
                    <EditOutlinedIcon
                      onClick={() => {
                        setError("");
                        setOpenEditPresentationDefinitionModal(true);
                      }}
                      fontSize="small"
                      style={{
                        cursor: "pointer",
                        color: "black",
                      }}
                    />
                  </Tooltip>
                </Box>

                <SelectDefaultValueFOrEwcVerification
                  dropdownValue={credntialDropdownValues}
                  control={control}
                  nameOfSelect={"presentationDropdown"}
                  valueKey={"value"}
                  displayKey={"label"}
                  isFieldMandatory={false}
                  setValue={setValue}
                  watchLimitDisclosure={watchLimitDisclosure}
                  selectedValue={selectedDropdownValue}
                  resetField={resetField}
                  mode={mode}
                  presentationDefinitionValue={presentationDefinitionValue}
                />

                <Tooltip
                  title={t("verifications.toolTipMessageForTypeCheck")}
                  placement="top-start"
                >
                  <Typography
                    variant="subtitle1"
                    mt={1}
                    sx={{ cursor: "pointer" }}
                  >
                    {t("verifications.typeCheck")}
                    <span
                      style={{
                        color: "rgba(224, 7, 7, 0.986)",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                </Tooltip>

                <DataTypeCardEWCPresentationDefinition
                  control={control}
                  mode={mode}
                />

                <Box
                  mt={1.5}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="subtitle1">
                      {t("dataAgreements.dataAttributes")}
                    </Typography>
                    <AddCircleOutlineOutlinedIcon
                      type="submit"
                      onClick={() => {
                        mode !== "View" && handleAddField();
                      }}
                      style={{
                        cursor: mode === "View" ? "not-allowed" : "pointer",
                        marginLeft: "5px",
                      }}
                    />
                  </Box>
                  <VerificationFormatCheckbox control={control} mode={mode} />
                </Box>

                {fields.length > 0 && (
                  <DataAttributeCardEWCPresentationDefinition
                    fields={fields}
                    mode={mode}
                    control={control}
                    remove={remove}
                  />
                )}
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                style={disabledButtonstyle}
                sx={{
                  marginLeft: "10px",
                  color: "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                  cursor: "not-allowed",
                }}
                variant="outlined"
              >
                {t("dataAgreements.publish")}
              </Button>
              <Button
                variant="outlined"
                style={
                  isSubmitButtonEnabled ? buttonStyle : disabledButtonstyle
                }
                sx={{
                  cursor: isSubmitButtonEnabled ? "pointer" : "not-allowed",
                  marginRight: "20px",
                  color: isSubmitButtonEnabled ? "black" : "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                type="submit"
              >
                {t("common.save")}
              </Button>
            </FooterContainer>
          </form>
        </Container>

        <EwcIssuePresentationDefinitionModal
          open={openEditPresentationDefinitionModal}
          handleModalClose={handleModalClose}
          name={"presentationDefinition"}
          presentationDefinitionValue={presentationDefinitionValue}
          headerText={headerText}
          handleChange={handlePresentationDefinitionChange}
          inputFieldName={t("verificationsHistory.presentationDefinition")}
          inputPlaceHolderName={t(
            "verificationsHistory.presentationDefinitionPlaceholder"
          )}
          setValue={setValue}
          watchFields={watchFields}
          setError={setError}
          error={error}
          setOpenEditPresentationDefinitionModal={
            setOpenEditPresentationDefinitionModal
          }
          setOpenSnackBar={setOpenSnackBar}
          mode={mode}
          setIsValidJson={setIsValidJson}
          methods={methods}
          schema={presentationDefinitionJsonSchema}
        />
      </Drawer>
    </React.Fragment>
  );
}
