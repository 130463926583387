export const DIGITALWALLETEWCENDPOINTS = {
  // Digital wallet apis
  getLedgerNetworks: () => {
    return `/config/digital-wallet/ledger-networks`;
  },
  deployDigitalWallet: () => {
    return `/config/digital-wallet/openid`;
  },
  getDigitalWallet: () => {
    return `/config/digital-wallet/openid`;
  },
  getOrganisationalIdentifier: () => {
    return `/config/digital-wallet/openid/organisation-identifier`;
  },
  issueCredentialForEWC: () => {
    return `/config/digital-wallet/openid/sdjwt/credential/issue`;
  },
  updateIssueCredentialForEWC: (credentialExchangeId: string | undefined) => {
    return `/config/digital-wallet/openid/sdjwt/credential/history/${credentialExchangeId}`;
  },
  listIssueCredentialHistoryRecordsForEwc: (
    offsetValue: number,
    pageSize: number,
    id: any
  ) => {
    return `/config/digital-wallet/openid/sdjwt/credential/history?credentialDefinitionId=${id}&limit=${pageSize}&offset=${offsetValue}`;
  },
  deleteIssuanceHistoryForEWC: (id: any) => {
    return `/config/digital-wallet/openid/sdjwt/credential/history/${id}`;
  },
  listIssueVerificationHistoryRecordsForEwc: (
    offsetValue: number,
    pageSize: number,
    id: number
  ) => {
    return `/config/digital-wallet/openid/sdjwt/verification/history?presentationDefinitionId=${id}&limit=${pageSize}&offset=${offsetValue}`;
  },
  sendVeificationForEWC: () => {
    return `/config/digital-wallet/openid/sdjwt/verification/send`;
  },
  deleteVerificationHistoryForEWC: (id: any) => {
    return `config/digital-wallet/openid/sdjwt/verification/history/${id}`;
  },
  createCredentialDefinitin: () => {
    return `/config/digital-wallet/openid/sdjwt/credential-definition`;
  },
  updateCredentialDefinitin: (id: any) => {
    return `/config/digital-wallet/openid/sdjwt/credential-definition/${id}`;
  },
  listCredentialDefinitin: (offsetValue: number, pageSize: number) => {
    return `/config/digital-wallet/openid/sdjwt/credential-definitions?limit=${pageSize}&offset=${offsetValue}`;
  },
  deleteCredentialDefinionForEWC: (id: any) => {
    return `/config/digital-wallet/openid/sdjwt/credential-definition/${id}`;
  },
  listIssuePresentationDefinitionForEwc: (
    offsetValue: number,
    pageSize: number
  ) => {
    return `/config/digital-wallet/openid/sdjwt/presentation-definitions?limit=${pageSize}&offset=${offsetValue}`;
  },
  createPresentationDefinitionForEWC: () => {
    return `/config/digital-wallet/openid/sdjwt/presentation-definition`;
  },
  deletePresentationDefinitinoForEWC: (id: any) => {
    return `/config/digital-wallet/openid/sdjwt/presentation-definition/${id}`;
  },
  updatePresentationDefinitionForEWC: (id: any) => {
    return `/config/digital-wallet/openid/sdjwt/presentation-definition/${id}`;
  },
  listCredentialsEwc: (offsetValue: number, pageSize: number) => {
    return `/config/digital-wallet/openid/sdjwt/credentials?limit=${pageSize}&offset=${offsetValue}`;
  },
  listVerificationsEwc: (offsetValue: number, pageSize: number) => {
    return `/config/digital-wallet/openid/sdjwt/verifications?limit=${pageSize}&offset=${offsetValue}`;
  },
  deleteCredentialForEWC: (id: any) => {
    return `/config/digital-wallet/openid/sdjwt/credential/${id}`;
  },
  deleteVerificationForEWC: (id: any) => {
    return `/config/digital-wallet/openid/sdjwt/verification/${id}`;
  },
  receiveCredentialForEWC: () => {
    return `/config/digital-wallet/openid/sdjwt/credential/receive`;
  },
  receiveCredentialWithUserPinForEWC: (id: any) => {
    return `/config/digital-wallet/openid/sdjwt/credential/${id}/user-pin`;
  },
  receiveDefferedCredential: (id: any) => {
    return `/config/digital-wallet/openid/sdjwt/credential/${id}/receive-deferred`;
  },
  readCredentialForEWC: (id: any) => {
    return `/config/digital-wallet/openid/sdjwt/credential/${id}`;
  },
  receiveVerificationForEWC: () => {
    return `/config/digital-wallet/openid/sdjwt/verification/receive
    `;
  },
  filterVerificationForEWC: (id: any) => {
    return `/config/digital-wallet/openid/sdjwt/verification/${id}/filter
    `;
  },
  sendVerifications: (id: any) => {
    return `/config/digital-wallet/openid/sdjwt/verification/${id}/send`;
  },
  getStatistics: () => {
    return `/config/performance-statistics`;
  },
};
