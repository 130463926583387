import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export let onboardingStore = (set: any) => ({
  orgAdminInformation: {
    orgName: "",
    orgAdminName: "",
    orgAdminEmail: "",
    orgPassword: "",
    orgPhone: "",
  },
  orgDetails: {
    orgName: "",
    orgType: "",
    city: "",
    country: "",
    orgNumber: "",
    vat: "",
    authorization: false,
    orgId: "",
  },
  organisationTypesFromApi: [
    {
      id: "",
      type: "HealthCare",
      imageId: "",
      imageUrl: "",
    },
  ],
  pricingInfoFromApi: {},
  selectedPackage: {
    packageNumber: 0,
    packageDetails: {},
  },
  cardDetails: {},
  billingAddress: {
    adminName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    saveInputValues: false,
  },
  submitPageAuthorization: false,
  updateOrgAdminInformation: (orgAdminInformation: any) =>
    set(() => ({ orgAdminInformation: orgAdminInformation })),
  updateOrgDetails: (orgDetails: any) =>
    set(() => ({ orgDetails: orgDetails })),
  updateorganisationTypesFromApi: (organisationTypesFromApi: any) =>
    set(() => ({ organisationTypesFromApi: organisationTypesFromApi })),
  updatePricingInfoFromApi: (pricingInfoFromApi: any) =>
    set(() => ({ pricingInfoFromApi: pricingInfoFromApi })),
  updateSelectedPackage: (selectedPackage: any) =>
    set(() => ({ selectedPackage: selectedPackage })),
  updateCardDetails: (cardDetails: any) =>
    set(() => ({ cardDetails: cardDetails })),
  updateBillingAddress: (billingAddress: any) =>
    set(() => ({ billingAddress: billingAddress })),
  updateSubmitPageAuthorization: (submitPageAuthorization: any) =>
    set(() => ({ submitPageAuthorization: submitPageAuthorization })),
  // datas will be cleared if the user is registered successfully
  clearStore: () =>
    set(() => ({
      orgAdminInformation: {
        orgName: "",
        orgAdminName: "",
        orgAdminEmail: "",
        orgPassword: "",
        orgPhone: "",
      },
      orgDetails: {
        orgName: "",
        orgType: "",
        city: "",
        country: "",
        orgNumber: "",
        vat: "",
        authorization: false,
        orgId: "",
      },
      organisationTypesFromApi: [
        {
          id: "",
          type: "HealthCare",
          imageId: "",
          imageUrl: "",
        },
      ],
      pricingInfoFromApi: {},
      selectedPackage: {
        packageNumber: 0,
        packageDetails: {},
      },
      cardDetails: {},
      billingAddress: {
        adminName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        saveInputValues: false,
      },
      submitPageAuthorization: false,
    })),
});

let devtoolsStore = devtools(onboardingStore);

export const useOnBoardingStore = create(devtoolsStore);
