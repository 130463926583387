import { Box, TextField, IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";

interface Props {
  mode: string;
  control: any;
}

export const DataTypeCardEWCPresentationDefinition = (props: Props) => {
  const { mode, control } = props;
  const { t } = useTranslation("translation");

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <Controller
        name="typeFilter"
        control={control}
        rules={{
          required: true,
        }}
        render={({ field }) => (
          <>
            <TextField
              {...field}
              variant="standard"
              disabled={mode === "View"}
              placeholder={t("verifications.type")}
              sx={{
                width: "100%",
                "& .MuiInputBase-input.Mui-disabled": {
                  cursor: "not-allowed",
                },
                "& .MuiAutocomplete-inputRoot.Mui-disabled .MuiAutocomplete-clearIndicator":
                  {
                    display: "none",
                  },
              }}
              type="text"
              autoComplete="off"
            />
            <Box
              sx={{
                position: "absolute",
                right: 0,
                top: "50%",
                transform: "translateY(-50%)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                disabled={mode === "View"}
                type="checkbox"
                style={{
                  transform: "scale(1.3)",
                  cursor: "not-allowed",
                  marginRight: "0px",
                }}
                checked={true}
                onChange={() => {}}
              />
              <IconButton
                disabled={mode === "View"}
                onClick={() => {}}
                sx={{
                  cursor: "not-allowed",
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
          </>
        )}
      />
    </Box>
  );
};
