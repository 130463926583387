export let defaultUpdateEventTpesForWebooksConvertedForClient:any = {
    consent: {
      allowed: false,
      disallowed: false,
      auto_expiry: false,
    },
    data: {
      delete: {
        initiated: false,
        cancelled: false,
      },
      download: {
        initiated: false,
        cancelled: false,
      },
      update: {
        cancelled: false,
        initiated: false,
      },
    },
    digitalwallet: {
      presentation: {
        presentation_sent: false,
        presentation_received: false,
        proposal_sent: false,
        request_sent: false,
        proposal_received: false,
        verified: false,
        request_received: false,
        presentation_acked: false,
      },
      connection: {
        delete: false,
        error: false,
        invitation: false,
        active: false,
        inactive: false,
        init: false,
        request: false,
        response: false,
      },
      credential: {
        proposal_sent: false,
        offer_sent: false,
        credential_received: false,
        credential_issued: false,
        request_received: false,
        credential_acked: false,
        proposal_received: false,
        offer_received: false,
        request_sent: false,
      },
    },
    org: {
      subscribed: false,
      unsubscribed: false,
    },
    openid:{
      credential: {
        offer_sent: false,
        credential_issued: false,
        credential_acked: false,
        offer_received: false,
        credential_pending:false
      },
      presentation: {
        presentation_acked: false,
        request_received: false,
        request_sent: false,
        presentation_pending: false
      },
    }
  };

  export let defaultEventTpesCreateForWebooksConvertedForClient:any = {
    consent: {
      allowed: false,
      disallowed: false,
      auto_expiry: false,
    },
    data: {
      delete: {
        initiated: false,
        cancelled: false,
      },
      download: {
        initiated: false,
        cancelled: false,
      },
      update: {
        cancelled: false,
        initiated: false,
      },
    },
    digitalwallet: {
      presentation: {
        presentation_sent: false,
        presentation_received: false,
        proposal_sent: false,
        request_sent: false,
        proposal_received: false,
        verified: false,
        request_received: false,
        presentation_acked: false,
      },
      connection: {
        delete: false,
        error: false,
        invitation: false,
        active: false,
        inactive: false,
        init: false,
        request: false,
        response: false,
      },
      credential: {
        proposal_sent: false,
        offer_sent: false,
        credential_received: false,
        credential_issued: false,
        request_received: false,
        credential_acked: false,
        proposal_received: false,
        offer_received: false,
        request_sent: false,
      },
    },
    org: {
      subscribed: false,
      unsubscribed: false,
    },
    openid:{
      credential: {
        offer_sent: false,
        credential_issued: false,
        credential_acked: false,
        offer_received: false,
        credential_pending:false
      },
      presentation: {
        presentation_acked: false,
        request_received: false,
        request_sent: false,
        presentation_pending: false
      },
    }
  };