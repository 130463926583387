import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useContext } from "react";
import { buttonStyle, disabledButtonstyle } from "../modals/modalStyle";
import { HttpService } from "../../service/HTTPService";
import {
  DataAgreementPayload,
  validateDataAttributes,
} from "./DataAgreementActions";
import { OrganizationDetailsCRUDContext } from "../../contexts/organizationDetailsCrud";

interface Props {
  mode: string;
  methods: any;
  isFormDataChanged: any;
  successCallback: any;
  defaultValue: any;
  setSelectedDropdownValue: Dispatch<SetStateAction<any>>;
  setImportedDataAgreement: Dispatch<SetStateAction<any>>;
  setOpen: Dispatch<SetStateAction<any>>;
  setError: Dispatch<SetStateAction<any>>;
  setOpenSnackBar: Dispatch<SetStateAction<any>>;
  selectedDataAgreement: any;
  setOpenLoader: Dispatch<SetStateAction<any>>;
  ssi: string | undefined;
}
const DataAgreementSaveButton = (props: Props) => {
  const {
    mode,
    methods,
    isFormDataChanged,
    setOpen,
    successCallback,
    defaultValue,
    setSelectedDropdownValue,
    setImportedDataAgreement,
    setError,
    setOpenSnackBar,
    selectedDataAgreement,
    setOpenLoader,
    ssi,
  } = props;

  const { t } = useTranslation("translation");
  const { organisationDetails } = useContext(OrganizationDetailsCRUDContext);

  const onSave = (createdData: any) => {
    if (
      validateDataAttributes(createdData) &&
      mode !== "Read" &&
      ssi === undefined
    ) {
      if (mode === "Create") {
        setOpenLoader(true);
        let active = false;
        let lifecycle = "draft";

        HttpService.addDataAgreements(
          DataAgreementPayload(
            createdData,
            active,
            lifecycle,
            mode,
            organisationDetails,
          )
        )
          .then(() => {
            setOpenLoader(false);
            successCallback();
            methods.reset({ ...defaultValue });
            setSelectedDropdownValue({});
            setImportedDataAgreement(null);
            setOpen(false);
          })
          .catch((error: any) => {
            setOpenLoader(false);
            let errorDescription = error.response.data.errorDescription;
            setError(
              errorDescription === "Data agreement purpose exists"
                ? t("dataAgreements.purposeExist")
                : errorDescription
            );
            setOpenSnackBar(true);
          });
      } else if (mode === "Update" && isFormDataChanged(methods.formState)) {
        setOpenLoader(true);
        let active = false;
        let lifecycle = "draft";
        HttpService.updateDataAgreementById(
          DataAgreementPayload(
            createdData,
            active,
            lifecycle,
            mode,
            organisationDetails,
            selectedDataAgreement
          ),
          selectedDataAgreement?.id
        )
          .then(() => {
            setOpenLoader(false);
            successCallback();
            setSelectedDropdownValue({});
            setImportedDataAgreement({});
            methods.reset({ ...defaultValue });
            setOpen(false);
          })
          .catch((error) => {
            setOpenLoader(false);
            let errorDescription = error.response.data.errorDescription;
            setError(
              errorDescription === "Data agreement purpose exists"
                ? t("dataAgreements.purposeExist")
                : errorDescription
            );
            setOpenSnackBar(true);
          });
      } else return {};
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={methods.handleSubmit(onSave)}
        style={
          methods.formState.isValid &&
          mode !== "Read" &&
          isFormDataChanged(methods.formState) &&
          ssi === undefined
            ? buttonStyle
            : disabledButtonstyle
        }
        sx={{
          cursor:
            methods.formState.isValid &&
            mode !== "Read" &&
            isFormDataChanged(methods.formState) &&
            ssi === undefined
              ? "pointer"
              : "not-allowed",
          color:
            methods.formState.isValid &&
            mode !== "Read" &&
            isFormDataChanged(methods.formState) &&
            ssi === undefined
              ? "black"
              : "#6D7676",
          marginRight: "15px",
          "&:hover": {
            backgroundColor: "black",
            color: "white",
          },
        }}
      >
        {t("common.save")}
      </Button>
    </>
  );
};

export default DataAgreementSaveButton;
