import { Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import DiscreteSlider from "../../slider";
import { Box } from "@mui/system";
import { OnBoardingHttpService } from "../../../service/onboardingService";
import { useDigitalWalletStore } from "../../../store/digitalWalletStore";

const buttonStyle = {
  height: 30,
  width: 150,
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const ButtonContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
}));

interface Props {
  handleNext: any;
  setError: Dispatch<SetStateAction<string>>;
  setOpenSnackBar: Dispatch<SetStateAction<boolean>>;
}

const userCommitmentMarks = [
  { value: 0, label: "0" },
  { value: 1, label: "2" },
  { value: 2, label: "4" },
  { value: 3, label: "6" },
  { value: 4, label: "8" },
];

const timeCommitmentMarks = [
  { value: 0, label: "Monthly" },
  { value: 20, label: "Yearly" },
];

const CommittedUsage = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const { handleNext } = props;

  const selectedCommittedUsage = useDigitalWalletStore(
    (state) => state.selectedCommittedUsage
  );

  const [userCommitmentSlideValue, setUserCommitmentSlideValue] = useState(
    selectedCommittedUsage.userCommitment / 2
  );
  const [timeCommitmentSlideValue, settimeCommitmentSlideValue] = useState(
    selectedCommittedUsage.timeCommitment === "Monthly" ? 0 : 1
  );
  const [pricePerUser, setPricePerUser] = useState(0);
  const [pricingInfoFromApi, setPricingInforFromApi] = useState<any>();

  useEffect(() => {
    OnBoardingHttpService.listPricingInfo().then((res) => {
      setPricingInforFromApi(res.data);
    });
  }, []);

  const handleChangeUserCommitment = (
    event: Event,
    newValue: number | number[]
  ) => {
    const newValueAsNumber = Array.isArray(newValue) ? newValue[0] : newValue;
    setUserCommitmentSlideValue(newValueAsNumber);
  };

  const handleChangeTimeCommitment = (
    event: Event,
    newValue: number | number[]
  ) => {
    const newValueAsNumber = Array.isArray(newValue) ? newValue[0] : newValue;
    settimeCommitmentSlideValue(newValueAsNumber);
  };

  const calculatePrice = () => {
    let newPricePerUser = 0;
    let pricePerUserWithoutTimeCommitmentDiscounts =
      userCommitmentSlideValue > 0
        ? pricingInfoFromApi?.payPerUser?.priceCurveParamA *
          Math.pow(
            pricingInfoFromApi?.payPerUser?.userCommitmentValues[
              userCommitmentSlideValue
            ],
            pricingInfoFromApi?.payPerUser?.priceCurveParamB
          )
        : pricingInfoFromApi?.payPerUser?.basePrice;
    newPricePerUser =
      pricePerUserWithoutTimeCommitmentDiscounts *
      (1 -
        pricingInfoFromApi?.payPerUser?.timeCommitmentDiscounts[
          timeCommitmentSlideValue
        ]?.value /
          100);

    setPricePerUser(newPricePerUser);
  };

  const formatMoney = (
    amount: any,
    decimalCount = 3,
    decimal = ".",
    thousands = ","
  ) => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - parseFloat(i))
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    calculatePrice();
  }, [userCommitmentSlideValue, timeCommitmentSlideValue, pricingInfoFromApi]);

  const handleSubmit = () => {
    useDigitalWalletStore.getState().updateSelectedCommittedUsage({
      userCommitment: userCommitmentSlideValue * 2,
      timeCommitment: timeCommitmentSlideValue === 0 ? "Monthly" : "Yearly",
    });
    handleNext();
  };

  return (
    <>
      <Typography variant="subtitle2" color={"black"} mt={2} mb={2}>
        {t("billing.committedUsagePageDescription")}:
      </Typography>

      <Box sx={{ width: { lg: "40%", md: "100%", sm: "100%", xs: "100%" } }}>
        <Typography variant="subtitle2" color={"black"} mb={1}>
          {t("billing.userCommitment")}:
        </Typography>

        <DiscreteSlider
          value={userCommitmentSlideValue}
          onChange={handleChangeUserCommitment}
          step={1}
          marks={userCommitmentMarks}
          min={0}
          max={4}
        />

        <Typography variant="subtitle2" color={"black"} mt={1} mb={1}>
          {t("billing.timeCommitment")}:
        </Typography>

        <DiscreteSlider
          value={timeCommitmentSlideValue}
          onChange={handleChangeTimeCommitment}
          step={1}
          marks={timeCommitmentMarks}
          min={0}
          max={1}
        />

        <Typography variant="subtitle2" color={"black"} mt={1} mb={1}>
          {t("billing.avgCostPerUserBasedOnSelection")}
          <span style={{ fontWeight: "bold" }}>
            {" "}
            SEK
            {formatMoney(pricePerUser, 3)}
          </span>
        </Typography>
      </Box>
      <ButtonContainer>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={() => (
            useDigitalWalletStore.getState().updateSelectedCommittedUsage({
              userCommitment: 0,
              timeCommitment: "Monthly",
            }),
            navigate("/billing")
          )}
        >
          <Typography variant="subtitle2">{t("onboarding.prev")}</Typography>
        </Button>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            color: "black",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          onClick={handleSubmit}
          endIcon={<KeyboardArrowRightIcon />}
        >
          <Typography variant="subtitle2">{t("onboarding.next")}</Typography>
        </Button>
      </ButtonContainer>
    </>
  );
};

export default CommittedUsage;
