export const RegisterAdminPayload = (onbordingStore: any) => {
  return {
    username: onbordingStore.orgAdminInformation.orgAdminEmail,
    name: onbordingStore.orgAdminInformation.orgAdminName,
    password: onbordingStore.orgAdminInformation.orgPassword,
    phone: onbordingStore.orgAdminInformation.orgPhone,
  };
};

export const AddOrgPayload = (onbordingStore: any) => {
  // currently no input fields for description and policyurl
  return {
    description: `${onbordingStore.orgDetails.orgName} is committed to safeguarding your privacy. We process your personal data in line with data agreements, ensuring adherence to ISO27560 standards and legal frameworks like GDPR. For every personal data we process, you can view its usage purpose and make informed choices to opt in or out. For inquiries, contact our Data Protection Officer at dpo@igrant.io`,
    location: onbordingStore.orgDetails.city,
    name: onbordingStore.orgDetails.orgName,
    policyUrl: "https://igrant.io/policy.html",
    typeId: onbordingStore.orgDetails.orgId,
  };
};

export const addBillingAddressPayload = (onbordingStore: any) => {
  return {
    name: onbordingStore.billingAddress.adminName,
    line1: onbordingStore.billingAddress.addressLine1,
    line2: onbordingStore.billingAddress.addressLine2,
    city: onbordingStore.billingAddress.city,
    state: onbordingStore.billingAddress.state,
    country: onbordingStore.billingAddress.country,
    postalCode: onbordingStore.billingAddress.postalCode,
  };
};

export const addBillingpaymentSourcePayload = (onbordingStore: any) => {
  return {
    id: onbordingStore.cardDetails.id,
  };
};

export const addBillingSubscribePlanpayload = (
  serviceAgreementResponse: any
) => {
  return {
    serviceAgreementVersion:
      serviceAgreementResponse.serviceAgreementInfo.version,
    verifierServiceAgreementVersion:
      serviceAgreementResponse.verifierServiceAgreementInfo.version,
  };
};
