import { Select, MenuItem, MenuProps } from "@mui/material";
import { Controller } from "react-hook-form";

interface Props {
  filterValues: any;
  control: any;
  nameOfSelect: string;
}

const dropDownStyle = {
  color: "#495057",
  border: "none",
  outline: "none",
  fontSize: "14px",
  backgroundColor: "transparent",
};

const CountryListDropdown = (props: Props) => {
  const { filterValues, control, nameOfSelect } = props;

  return (
    <Controller
      name={nameOfSelect}
      control={control}
      defaultValue={"Sweden"}
      rules={{
        required: true,
      }}
      render={({ field: { onChange, value } }) => (
        <Select
          onChange={(e: any) => {
            onChange(e);
          }}
          variant="outlined"
          fullWidth
          name={nameOfSelect}
          value={value}
          sx={{ width: { xs: "100%", lg: "65%" } }}
          style={{
            ...dropDownStyle,
            height: "32px",
          }}
          MenuProps={
            {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            } as Partial<MenuProps>
          }
        >
          {filterValues.map((Type: any) => (
            <MenuItem key={Type.value} value={Type.value}>
              {Type.label}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

export default CountryListDropdown;
