/* eslint-disable no-prototype-builtins */
import { v4 as uuidv4 } from "uuid";

export const getPresentationRequestValue = (
  selectedVerificationFormat: any,
  selectedValue: any,
  presentationDefinitionValue: any
) => {
  if (selectedValue === "") {
    return JSON.parse(
      presentationDefinitionValue ? presentationDefinitionValue : null
    );
  } else if (selectedValue === "VerifiableLegalPersonalIdentificationData") {
    return {
      constraints: {
        fields: [
          {
            path: ["$.type"],
            filter: {
              type: "array",
              contains: {
                const: "VerifiableLegalPersonalIdentificationData",
              },
            },
          },
          {
            path: ["$.credentialSubject.identifier"],
          },
          {
            path: ["$.credentialSubject.legalName"],
          },
        ],
      },
    };
  } else if (selectedValue === "VerifiableCertificateOfRegistration") {
    return {
      constraints: {
        fields: [
          {
            path: ["$.type"],
            filter: {
              type: "array",
              contains: {
                const: "VerifiableCertificateOfRegistration",
              },
            },
          },
          {
            path: ["$.credentialSubject.name"],
          },
          {
            path: ["$.credentialSubject.legalForm"],
          },
          {
            path: ["$.credentialSubject.activity"],
          },
          {
            path: ["$.credentialSubject.registrationDate"],
          },
          {
            path: ["$.credentialSubject.legalStatus"],
          },
          {
            path: ["$.credentialSubject.registeredAddress"],
          },
          {
            path: ["$.credentialSubject.registeredAddress.adminUnitLevel1"],
          },
          {
            path: ["$.credentialSubject.registeredAddress.fullAddress"],
          },
          {
            path: ["$.credentialSubject.registeredAddress.locatorDesignator"],
          },
          {
            path: ["$.credentialSubject.registeredAddress.postCode"],
          },
          {
            path: ["$.credentialSubject.registeredAddress.postName"],
          },
          {
            path: ["$.credentialSubject.registeredAddress.thoroughFare"],
          },
          {
            path: ["$.credentialSubject.orgNumber"],
          },
        ],
      },
    };
  } else if (selectedValue === "VerifiablePortableDocumentA1") {
    return {
      constraints: {
        fields: [
          {
            path: ["$.type"],
            filter: {
              type: "array",
              contains: {
                const: "VerifiablePortableDocumentA1",
              },
            },
          },
          {
            path: ["$.credentialSubject.section1"],
          },
          {
            path: ["$.credentialSubject.section1.dateBirth"],
          },
          {
            path: ["$.credentialSubject.section1.forenames"],
          },
          {
            path: ["$.credentialSubject.section1.nationalities"],
          },
          {
            path: ["$.credentialSubject.section1.personalIdentificationNumber"],
          },
          {
            path: ["$.credentialSubject.section1.placeBirth"],
          },
          {
            path: ["$.credentialSubject.section1.sex"],
          },
          {
            path: ["$.credentialSubject.section1.stateOfResidenceAddress"],
          },
          {
            path: ["$.credentialSubject.section1.stateOfStayAddress"],
          },
          {
            path: ["$.credentialSubject.section1.surname"],
          },
          {
            path: ["$.credentialSubject.section1.surnameAtBirth"],
          },
          {
            path: ["$.credentialSubject.section2"],
          },
          {
            path: ["$.credentialSubject.section2.endingDate"],
          },
          {
            path: [
              "$.credentialSubject.section2.memberStateWhichLegislationApplies",
            ],
          },
          {
            path: ["$.credentialSubject.section2.startingDate"],
          },
          {
            path: ["$.credentialSubject.section3"],
          },
          {
            path: ["$.credentialSubject.section3.civilAndEmployedSelfEmployed"],
          },
          {
            path: ["$.credentialSubject.section3.civilServant"],
          },
          {
            path: ["$.credentialSubject.section3.contractStaff"],
          },
          {
            path: ["$.credentialSubject.section3.employedAndSelfEmployed"],
          },
          {
            path: ["$.credentialSubject.section3.employedTwoOrMoreStates"],
          },
          {
            path: ["$.credentialSubject.section3.exception"],
          },
          {
            path: ["$.credentialSubject.section3.exceptionDescription"],
          },
          {
            path: ["$.credentialSubject.section3.flightCrewMember"],
          },
          {
            path: ["$.credentialSubject.section3.mariner"],
          },
          {
            path: ["$.credentialSubject.section3.postedEmployedPerson"],
          },
          {
            path: ["$.credentialSubject.section3.postedSelfEmployedPerson"],
          },
          {
            path: ["$.credentialSubject.section3.selfEmployedTwoOrMoreStates"],
          },
          {
            path: ["$.credentialSubject.section3.workingInStateUnder21"],
          },
          {
            path: ["$.credentialSubject.section4"],
          },
          {
            path: [
              "$.credentialSubject.section4.employerSelfEmployedActivityCodes",
            ],
          },
          {
            path: ["$.credentialSubject.section4.nameBusinessName"],
          },
          {
            path: ["$.credentialSubject.section4.registeredAddress"],
          },
          {
            path: ["$.credentialSubject.section5"],
          },
          {
            path: ["$.credentialSubject.section5.workPlaceAddresses"],
          },
          {
            path: ["$.credentialSubject.section5.workPlaceNames"],
          },
          {
            path: ["$.credentialSubject.section6"],
          },
          {
            path: ["$.credentialSubject.section6.address"],
          },
          {
            path: ["$.credentialSubject.section6.date"],
          },
          {
            path: ["$.credentialSubject.section6.email"],
          },
          {
            path: ["$.credentialSubject.section6.institutionID"],
          },
          {
            path: ["$.credentialSubject.section6.name"],
          },
          {
            path: ["$.credentialSubject.section6.officeFaxNo"],
          },
          {
            path: ["$.credentialSubject.section6.officePhoneNo"],
          },
          {
            path: ["$.credentialSubject.section6.signature"],
          },
        ],
      },
    };
  } else if (selectedValue === "Passport") {
    return {
      constraints: {
        fields: [
          {
            path: ["$.type"],
            filter: {
              type: "array",
              contains: {
                const: "Passport",
              },
            },
          },
          {
            path: ["$.credentialSubject.serialNumber"],
          },
          {
            path: ["$.credentialSubject.firstName"],
          },
          {
            path: ["$.credentialSubject.signature"],
          },
          {
            path: ["$.credentialSubject.personalNumber"],
          },
          {
            path: ["$.credentialSubject.id"],
          },
          {
            path: ["$.credentialSubject.image"],
          },
          {
            path: ["$.credentialSubject.birthDate"],
          },
          {
            path: ["$.credentialSubject.lastName"],
          },
          {
            path: ["$.credentialSubject.gender"],
          },
          {
            path: ["$.credentialSubject.expiryDate"],
          },
          {
            path: ["$.credentialSubject.nationality"],
          },
          {
            path: ["$.credentialSubject.issuerAuthority"],
          },
        ],
      },
    };
  }
};

export const getPresentationDefinitionPayload = (
  watchLimitDisclosure: any,
  selectedConstraints: any,
  label: string,
  selectedData: any,
  mode: string
) => {
  const presentationDefinitionId = uuidv4();
  const inputDescriptorId = uuidv4();

  if (watchLimitDisclosure === true) {
    return {
      label: label,
      presentationDefinition: {
        id:
          mode === "Update"
            ? selectedData.presentationDefinition.id
            : presentationDefinitionId,
        format: {
          "vc+sd-jwt": {
            alg: ["ES256"],
          },
          "vp+sd-jwt": {
            alg: ["ES256"],
          },
        },
        input_descriptors: [
          {
            id:
              mode === "Update"
                ? selectedData.presentationDefinition.input_descriptors[0].id
                : inputDescriptorId,
            constraints: selectedConstraints.constraints,
          },
        ],
      },
    };
  } else if (watchLimitDisclosure === false) {
    return {
      label: label,
      presentationDefinition: {
        id:
          mode === "Update"
            ? selectedData.presentationDefinition.id
            : presentationDefinitionId,
        format: {
          jwt_vc: {
            alg: ["ES256"],
          },
          jwt_vp: {
            alg: ["ES256"],
          },
        },
        input_descriptors: [
          {
            id:
              mode === "Update"
                ? selectedData.presentationDefinition.input_descriptors[0].id
                : inputDescriptorId,
            constraints: selectedConstraints.constraints,
          },
        ],
      },
    };
  }
};

export const jsonSchemaToValidatepresentationDefinition = {
  type: "object",
  properties: {
    limit_disclosure: {
      type: "string",
      enum: ["required"],
    },
    fields: {
      type: "array",
      items: {
        type: "object",
        properties: {
          path: {
            type: "array",
            items: {
              type: "string",
            },
            minItems: 1,
          },
          filter: {
            type: "object",
            properties: {
              type: {
                type: "string",
                enum: ["array"],
              },
              contains: {
                type: "object",
                properties: {
                  const: {
                    type: "string",
                  },
                },
                required: ["const"],
              },
            },
            required: ["type", "contains"],
          },
        },
        required: ["path"],
        additionalProperties: false,
      },
      minItems: 1,
    },
  },
  required: ["fields"],
};

export const determineVerificationFormat = (presentationDefinition: any) => {
  const format = presentationDefinition?.format || {};

  if ("jwt_vc" in format) {
    return "JWT";
  } else if ("vc+sd-jwt" in format) {
    return "SD-JWT";
  } else {
    return "Unknown Format"; // or handle it according to your needs
  }
};

export const stripCredentialSubject = (value: string): string => {
  return value.startsWith("$.credentialSubject.")
    ? value.slice("$.credentialSubject.".length)
    : value;
};

export const handleModalClose = (
  presentationDefinitionValue: any,
  setValue: any,
  watchFields: any,
  error: any,
  setOpenEditPresentationDefinitionModal: any,
  setOpenSnackBar: any,
  setIsValidJson: any,
  setError: any
) => {
  try {
    const parsedJson = JSON.parse(presentationDefinitionValue);

    // Extract type field
    const typeField = parsedJson.constraints.fields.find((field: any) =>
      field.path.includes("$.type")
    );

    if (typeField) {
      setValue("typeCheck", typeField.path.join(", "));

      if (
        typeField.filter &&
        typeField.filter.contains &&
        typeField.filter.contains.const
      ) {
        setValue("typeFilter", typeField.filter.contains.const);
      } else {
        setValue("typeFilter", "");
      }
    } else {
      setValue("typeCheck", "");
      setValue("typeFilter", "");
    }

    const updatedFields = watchFields
      .map((existingField: any) => {
        const matchingField = parsedJson.constraints.fields.find((f: any) =>
          f.path.some(
            (p: any) => stripCredentialSubject(p) === existingField.path
          )
        );

        if (matchingField) {
          return {
            ...existingField,
            filter: matchingField.filter,
            hasPrefix: matchingField.path.some((p: any) =>
              p.startsWith("$.credentialSubject.")
            ),
            isHidden: existingField.isHidden, // Preserve the hidden state
          };
        } else if (existingField.isHidden) {
          // Keep hidden fields even if they're not in the JSON
          return existingField;
        } else {
          // Field is not in JSON and not hidden, so we'll remove it
          return null;
        }
      })
      .filter((field: any) => field !== null);

    // Add new fields from the modal that weren't in the existing state
    parsedJson.constraints.fields.forEach((field: any) => {
      if (
        field.path[0] !== "$.type" &&
        !updatedFields.some(
          (f: any) => f.path === stripCredentialSubject(field.path[0])
        )
      ) {
        updatedFields.push({
          path: stripCredentialSubject(field.path[0]),
          hasPrefix: field.path[0].startsWith("$.credentialSubject."),
          isHidden: false,
          filter: field.filter,
        });
      }
    });

    // Add new fields from JSON that weren't in the existing fields
    parsedJson.constraints.fields.forEach((field: any) => {
      if (!field.path.includes("$.type")) {
        const path = field.path.map(stripCredentialSubject).join(", ");
        const existingField = updatedFields.find((f: any) => f.path === path);
        if (!existingField) {
          updatedFields.push({
            path,
            hasPrefix: field.path.some((p: any) =>
              p.startsWith("$.credentialSubject.")
            ),
            isHidden: false,
            filter: field.filter,
          });
        }
      }
    });

    setValue(
      "fields",
      updatedFields.filter((field: any) => field.path !== undefined)
    );

    const hasLimitDisclosure =
      parsedJson.constraints &&
      parsedJson.constraints.limit_disclosure === "required";
    setValue("limitDisclosure", hasLimitDisclosure);
  } catch (error: any) {
    // setError(error.message);
    // setOpenSnackBar(true);
    // setIsValidJson(false);
  }

  if (error !== "") {
    if (error === "Array does not contain required item. at line number 3") {
      setError(
        "Presentation Definition must contain a field with path '$.type'"
      );
      setOpenSnackBar(true);
      setIsValidJson(false);
    } else {
      setOpenSnackBar(true);
      setIsValidJson(false);
    }
  } else {
    setIsValidJson(true);
  }

  setOpenEditPresentationDefinitionModal(false);
};

export const handleChangeForExistingSchemaDropdown = (
  presentationDefinitionValue: any,
  setValue: any,
  watchLimitDisclosure: any
) => {
  const parsedJson = JSON.parse(presentationDefinitionValue);
  if (watchLimitDisclosure) {
    parsedJson.constraints.limit_disclosure = "required";
  }
  // Extract type field
  const typeField = parsedJson.constraints.fields.find(
    (field: any) => field.path[0] === "$.type"
  );
  if (typeField) {
    setValue("typeCheck", "$.type");
    setValue("typeFilter", typeField.filter.contains.const);
  } else {
    setValue("typeCheck", "");
    setValue("typeFilter", "");
  }

  const updatedFields = parsedJson.constraints.fields
    .filter((field: any) => field.path[0] !== "$.type")
    .map((field: any) => ({
      path: stripCredentialSubject(field.path[0]),
      hasPrefix: field.path[0].startsWith("$.credentialSubject."),
      isHidden: false,
      filter: field.filter,
    }));

  setValue("fields", updatedFields);
};
