import { RadioGroup, Radio, FormControlLabel, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/system";

interface Props {
  control: any;
  modes: any;
  name: string;
}

const EwcIssuanceModeRadio = (props: Props) => {
  const { t } = useTranslation("translation");
  const { control, modes, name } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={"space-between"}
      mt={1.5}
    >
      <Typography variant="subtitle1" mt={0} marginRight={2}>
        {t("credentials.issuanceMode")}
        <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
      </Typography>
      <Controller
        name={name}
        control={control}
        rules={{
          required: true,
        }}
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            aria-label={name}
            name={name}
            value={value}
            onChange={(e) => {
              onChange(e);
            }}
          >
            {modes?.map((data: any) => (
              <FormControlLabel
                key={data.value}
                value={data.value}
                control={<Radio />}
                label={data.label}
              />
            ))}
          </RadioGroup>
        )}
      />
    </Box>
  );
};

export default EwcIssuanceModeRadio;
