/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState } from "react";
import { Grid, Typography, Select, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const StatsBox = styled("div")(({ theme }) => ({
  backgroundColor: "#f7f6f6",
  border: "1px solid #E1E1E1",
  borderRadius: "7px",
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  height: 90,
  overflow: "hidden",
}));

const dropDownStyle = {
  border: "1px solid lightgray",
  outline: "none",
  fontSize: "14px",
  backgroundColor: "#FFFF",
  height: "38px",
  borderRadius: "5px",
  cursor: "pointer",
};

type StatType = "credentialTypes" | "issuedCredentials" | "verifications";
type StatCategory = "all" | "openid4vc" | "aries";

const StatsPage = ({ performanceStatistics }: any) => {
  const { t } = useTranslation("translation");
  const [selectedTypes, setSelectedTypes] = useState<
    Record<StatType, StatCategory>
  >({
    credentialTypes: "openid4vc",
    issuedCredentials: "openid4vc",
    verifications: "openid4vc",
  });

  const handleTypeChange = (statType: StatType) => (event: any) => {
    const value = event.target.value as StatCategory;
    setSelectedTypes((prev) => ({
      ...prev,
      [statType]: value,
    }));
  };

  const getStatValue = (type: StatCategory, field: any): string => {
    if (
      !performanceStatistics ||
      !performanceStatistics[type] ||
      performanceStatistics[type][field] === undefined
    ) {
      return "00";
    }
    return performanceStatistics[type][field]!.toString();
  };

  const stats: Array<{ label: string; value: string; type: StatType }> = [
    {
      label: t("gettingStarted.credentialTypes"),
      value: getStatValue(
        selectedTypes.credentialTypes,
        "totalCountOfCredentialTypes"
      ),
      type: "credentialTypes",
    },
    {
      label: t("gettingStarted.issuedCredentials"),
      value: getStatValue(
        selectedTypes.issuedCredentials,
        "totalCountOfIssuedCredentials"
      ),
      type: "issuedCredentials",
    },
    {
      label: t("billing.verifications"),
      value: getStatValue(
        selectedTypes.verifications,
        "totalCountOfVerifications"
      ),
      type: "verifications",
    },
  ];

  return (
    <Grid container spacing={2}>
      {stats.map((stat, index) => (
        <Grid item lg={4} md={4} sm={6} xs={12} key={index}>
          <StatsBox>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={8}
              style={{
                display: "grid",
                width: "100%",
              }}
            >
              <Typography variant="body1" fontWeight={"bold"}>
                {stat.label}
              </Typography>

              <Select
                value={selectedTypes[stat.type]}
                onChange={handleTypeChange(stat.type)}
                variant="outlined"
                style={{
                  ...dropDownStyle,
                }}
              >
                <MenuItem value="all">{t("common.all")}</MenuItem>
                <MenuItem value="openid4vc">
                  {t("dataAgreements.ewc10")}
                </MenuItem>
                <MenuItem value="aries">{t("dataAgreements.aip10")}</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4} textAlign="right">
              <Typography sx={{ fontSize: "3rem", fontWeight: "bold" }}>
                {stat.value}
              </Typography>
            </Grid>
          </StatsBox>
        </Grid>
      ))}
    </Grid>
  );
};

export default StatsPage;
