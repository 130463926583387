import { useState } from "react";
import {
  Datagrid,
  List,
  TextField,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { useTranslation } from "react-i18next";
import { Typography, Box, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import SendIcon from "@mui/icons-material/Send";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import GeneralModal from "../../components/modals/generalModal";
import BreadCrumb from "../../components/Breadcrumbs";
import { TableEmptyMessage } from "../../components/tableEmptyMessage";
import useLanguageChange from "../../utils/translateTableLanguage";
import SnackbarComponent from "../../components/notification";
import DataVerifierAddAndUpdateModal from "../../components/modals/dataVerifierAddAndUpdateModal";
import { HttpServiceForDigitalWallet } from "../../service/digitalWalletHttpService";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const DetailsContainer = styled("div")({
  height: "auto",
  width: "100%",
  borderRadius: 2,
});

const DataVerifierUser = () => {
  const [openCreateDVU, setOpenCreateDVU] = useState(false);
  const [openDeleteDVU, setOpenDeleteDVU] = useState(false);
  const [selectedDataVerifierUser, setSelectedDataVerifierUser] =
    useState<any>();
  const { t } = useTranslation("translation");
  const key = useLanguageChange();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");
  const [succes, setSucces] = useState("");
  const [mode, setMode] = useState("");
  const refresh = useRefresh();
  const onRefetch = () => {
    refresh();
  };

  const IconsField = (props: any) => {
    const record = useRecordContext(props);

    if (!record || !props.source) {
      return null;
    }

    return (
      record[props.source] && (
        <Box display={"flex"} justifyContent={"space-around"}>
          <Tooltip title={t("dataverifierusers.sendMagicLink")} placement="top">
            <SendIcon
              onClick={() => {
                HttpServiceForDigitalWallet.sendMagicLink(record.id)
                  .then(() => {
                    setOpenSnackBar(true);
                    setSucces(
                      `${t("dataverifierusers.magicLinkSentTo")} ${record.name}`
                    );
                  })
                  .catch(() => {
                    setOpenSnackBar(true);
                    setError(t("common.commonErrorMessage"));
                  });
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>

          <Tooltip
            title={t("common.edit")}
            placement="top"
          >
            <EditOutlinedIcon
              onClick={() => {
                setOpenCreateDVU(true);
                setMode("Update");
                setSelectedDataVerifierUser(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>
          <Tooltip
            title={t("common.Delete")}
            placement="top"
          >
            <DeleteOutlineOutlinedIcon
              onClick={() => {
                setOpenDeleteDVU(true);
                setSelectedDataVerifierUser(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>
        </Box>
      )
    );
  };

  return (
    <Container>
      <BreadCrumb
        Link={t("sidebar.manageUsers")}
        Link2={t("sidebar.dataverifierusers")}
      />
      <SnackbarComponent
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={error}
        successMessage={succes}
        topStyle={100}
      />

      <DetailsContainer sx={{ flexGrow: 1 }}>
        <Box style={{ display: "flex", alignItems: "center" }} mt={1}>
          <Typography color="black" variant="h6" fontWeight="bold">
            {t("sidebar.dataverifierusers")}
          </Typography>
          <AddCircleOutlineOutlinedIcon
            onClick={() => {
              setOpenCreateDVU(true);
              setMode("Create");
            }}
            style={{
              cursor: "pointer",
              marginLeft: "7px",
            }}
          />
        </Box>
        <Typography variant="body2" sx={{ marginTop: "10px" }}>
          {t("dataverifierusers.pageDescription")}
        </Typography>
        <List
          actions={false}
          sx={{ width: "100%", overflow: "hidden" }}
          empty={<TableEmptyMessage />}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Datagrid
              bulkActionButtons={false}
              sx={{
                overflow: "auto",
                width: "100%",
              }}
              key={key}
            >
              <TextField
                source="name"
                label={t("common.name")}
                sortable={false}
              />
              <TextField
                source="phone"
                label={t("dataverifierusers.Phone")}
                sortable={false}
              />
              <TextField
                source="email"
                label={t("manageAdmin.email")}
                sortable={false}
              />

              <IconsField
                source="id"
                label={""}
                textAlign={"center"}
                sortable={false}
              />
            </Datagrid>
          </Box>
        </List>
      </DetailsContainer>

      {/* Modals */}
      <GeneralModal
        open={openDeleteDVU}
        setOpen={setOpenDeleteDVU}
        headerText={`${t("dataverifierusers.deleteDataVerifierUser")}:`}
        confirmText="DELETE"
        confirmButtonText={`${t("common.delete")}`}
        resourceName={"dataverifierusers"}
        selectedDataVerifierUser={selectedDataVerifierUser}
        onRefetch={onRefetch}
        modalDescriptionText={
          <Typography variant="body1">
            {t("dataverifierusers.deleteDescription1")}
            <b>DELETE</b>
            {t("dataverifierusers.deleteDescription2")}
          </Typography>
        }
      />

      <DataVerifierAddAndUpdateModal
        open={openCreateDVU}
        setOpen={setOpenCreateDVU}
        headerText={t("sidebar.dataverifierusers")}
        mode={mode}
        onRefetch={onRefetch}
        selectedDataVerifierUser={selectedDataVerifierUser}
      />
    </Container>
  );
};

export default DataVerifierUser;
