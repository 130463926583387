import * as React from "react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Drawer, Typography, Button, Box, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  disabledButtonstyle,
} from "./modalStyle";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { HttpServiceForDigitalWallet } from "../../service/digitalWalletHttpService";
import SnackbarComponent from "../notification";
import { validatePhoneNumber } from "../../utils/validatePhoneNumber";
import LoaderComponent from "../LoaderComponent";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  mode: string;
  onRefetch: any;
  selectedDataVerifierUser: any;
}

const inputStyle = {
  height: "24px",
  borderWidth: 0,
  borderBottomWidth: 1.2,
  width: "100%",
  borderBottomColor: "rgba(0, 0, 0, 0.42)",
};

export default function DataVerifierAddAndUpdateModal(props: Props) {
  const {
    open,
    setOpen,
    headerText,
    mode,
    onRefetch,
    selectedDataVerifierUser,
  } = props;

  const { handleSubmit, register, formState, reset } = useForm<any>({
    mode: "onChange",
  });

  const { t } = useTranslation("translation");
  const [phone, setPhone] = useState("");
  const [activeBtn, setActiveBtn] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");
  const [openLoader, setOpenLoader] = useState(false);

  useEffect(() => {
    let defaultValues = {
      name: "",
      email: "",
    };

    if (selectedDataVerifierUser && mode === "Update") {
      setPhone(selectedDataVerifierUser.phone);
      reset({
        name: selectedDataVerifierUser.name,
        email: selectedDataVerifierUser.email,
      });
    } else {
      setPhone("+46");
      reset({ ...defaultValues });
    }
  }, [open, selectedDataVerifierUser]);

  const isFormDataChanged = (formState: any) => {
    if (mode === "Update") {
      const { dirtyFields } = formState;

      return (
        Object.keys(dirtyFields).length > 0 ||
        selectedDataVerifierUser.phone !== phone
      );
    }
  };

  const activateButton = (value: any, event: any) => {
    if (event === "phone") {
      if (validatePhoneNumber(value)) {
        setActiveBtn(true);
      } else {
        setActiveBtn(false);
      }
    }
  };

  const onSubmit = (createdData: any) => {
    if (formState.isValid && activeBtn) {
      setOpenLoader(true);
      let payload = {
        name: createdData.name,
        email: createdData.email,
        phone: phone,
      };

      if (mode === "Update" && isFormDataChanged(formState)) {
        HttpServiceForDigitalWallet.updateDataVerifierUsers(
          payload,
          selectedDataVerifierUser.id
        )
          .then(() => {
            onRefetch();
            setOpen(false);
          })
          .catch((error) => {
            setError(error.response.data.errorDescription);
            setOpenSnackBar(true);
          });
      } else if (mode === "Create") {
        HttpServiceForDigitalWallet.createDataVerifierUsers(payload)
          .then(() => {
            onRefetch();
            setOpen(false);
          })
          .catch((error) => {
            setError(error.response.data.errorDescription);
            setOpenSnackBar(true);
          });
      }
    }
    setOpenLoader(false);
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SnackbarComponent
              open={openSnackBar}
              setOpen={setOpenSnackBar}
              message={error}
              topStyle={100}
            />
            <HeaderContainer>
              <Typography color="#F3F3F6" pl={2}>
                {headerText}
              </Typography>
              <CloseIcon
                onClick={() => setOpen(false)}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <DetailsContainer>
              <Box p={1.5}>
                <Typography variant="subtitle1" mb={1.5}>
                  {t("dataverifierusers.confirmEmailAndPhone")}
                </Typography>
                <Typography variant="subtitle1" mb={0}>
                  {t("common.name")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>
                <TextField
                  autoFocus
                  variant="standard"
                  fullWidth
                  placeholder={t("common.name")}
                  {...register("name", {
                    required: true,
                  })}
                />

                <Typography variant="subtitle1" mt={1.5}>
                  {t("manageAdmin.email")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>
                <TextField
                  autoFocus
                  variant="standard"
                  fullWidth
                  placeholder={t("manageAdmin.email")}
                  {...register("email", {
                    required: true,
                    minLength: 3,
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  })}
                />

                <Typography variant="subtitle1" mt={1.5} mb={1.2}>
                  {t("dataverifierusers.mobileNumber")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>

                <ReactPhoneInput
                  country={"se"}
                  inputProps={{ name: "phone" }}
                  inputStyle={inputStyle}
                  value={phone}
                  onChange={(value) => {
                    setPhone(value);
                    activateButton(value, "phone");
                  }}
                />
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => setOpen(false)}
                style={buttonStyle}
                sx={{
                  marginLeft: "15px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>
              <Button
                variant="outlined"
                style={
                  (mode === "Update" &&
                    isFormDataChanged(formState) &&
                    formState.isValid &&
                    activeBtn) ||
                  (mode === "Create" && formState.isValid && activeBtn)
                    ? buttonStyle
                    : disabledButtonstyle
                }
                sx={{
                  cursor:
                    (mode === "Update" &&
                      isFormDataChanged(formState) &&
                      formState.isValid &&
                      activeBtn) ||
                    (mode === "Create" && formState.isValid && activeBtn)
                      ? "pointer"
                      : "not-allowed",
                  marginRight: "20px",
                  color:
                    (mode === "Update" &&
                      isFormDataChanged(formState) &&
                      formState.isValid &&
                      activeBtn) ||
                    (mode === "Create" && formState.isValid && activeBtn)
                      ? "black"
                      : "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                type="submit"
              >
                {t("common.save")}{" "}
              </Button>
            </FooterContainer>
          </form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
