import { PhoneNumberUtil } from "google-libphonenumber";


export const validatePhoneNumber = (value: any) => {
    if (value) {
      let valid = false;
      try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        valid = phoneUtil.isValidNumber(phoneUtil.parse("+" + value));
      } catch (e) {
        valid = false;
      }
      return valid;
    }
    return false;
  };