import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const buttonStyle = {
  height: 30,
  width: 150,
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const ButtonContainer = styled("div")(() => ({
  display: "flex",
  marginTop: "20px",
  justifyContent: "right",
}));

interface Props {
  handleSubmit: any;
  disable: boolean;
  clearValues: any;
  buttonName1: string;
  buttonName2: string;
}

const SubmitButtons = (props: Props) => {
  const { handleSubmit, disable, clearValues, buttonName1, buttonName2 } =
    props;

  return (
    <ButtonContainer>
      <Button
        variant="outlined"
        style={buttonStyle}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "black",
          "&:hover": {
            backgroundColor: "black",
            color: "white",
          },
        }}
        onClick={() => clearValues()}
      >
        {buttonName1}
      </Button>
      <Button
        variant="outlined"
        style={buttonStyle}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: disable ? "not-allowed" : "pointer",
          color: disable ? "#6D7676" : "black",
          "&:hover": {
            backgroundColor: "black",
            color: "white",
          },
        }}
        onClick={() => handleSubmit()}
      >
        {buttonName2}
      </Button>
    </ButtonContainer>
  );
};

export default SubmitButtons;
