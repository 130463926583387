import { Box, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

export interface Props {
  append: any;
  credentialDefinitionModalMode?: any;
  watch: any;
}

export const DataAttributeAppendHeaderForOID4VC = (props: Props) => {
  const { t } = useTranslation("translation");

  const { append, credentialDefinitionModalMode, watch } = props;
  const credentialDefinitionValues = watch("credentialDefinition");

  function checkEmptyNames(credentialDefinitionValues: any) {
    // if any name field value is not filled then append will be disabled
    for (let item of credentialDefinitionValues) {
      if (item.name === "") {
        return false;
      }
    }
    return true;
  }

  const checkEmptyNamesValue = checkEmptyNames(credentialDefinitionValues);

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
        marginTop: "10px",
      }}
    >
      <Typography variant="subtitle1">
        {t("dataAgreements.dataAttributes")}
        <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
      </Typography>

      <AddCircleOutlineOutlinedIcon
        onClick={() =>
          credentialDefinitionModalMode !== "View" &&
          checkEmptyNames(credentialDefinitionValues) &&
          append({
            name: "",
            type: "string",
            properties: [],
            items: {},
            required: true,
            limitDisclosure: watch("credentialFormat"),
          })
        }
        style={{
          marginLeft: "5px",
          cursor:
            credentialDefinitionModalMode === "View" ||
            checkEmptyNamesValue === false
              ? "not-allowed"
              : "pointer",
        }}
      />
    </Box>
  );
};
