import * as React from "react";
import { Dispatch, SetStateAction, useState, useEffect } from "react";

import { Drawer, Typography, Button, Box, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  disabledButtonstyle,
} from "./modalStyle";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SnackbarComponent from "../notification";
import LoaderComponent from "../LoaderComponent";
import ConnectionDropdown from "../dropdowns/ConnectionDropdown";
import { HttpServiceForDDA } from "../../service/ddaHttpService";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  onRefetch: any;
}

export default function SignDDAModal(props: Props) {
  const { open, setOpen, headerText, onRefetch } = props;

  const {
    control,
    handleSubmit,
    register,
    formState,
    reset,
  } = useForm<any>({
    mode: "onChange",
  });

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");
  const [openLoader, setOpenLoader] = useState(false);

  const { t } = useTranslation("translation");

  useEffect(() => {
    let defaultValues = {
      ddaID: "",
      connectionId: "",
    };

    reset({ ...defaultValues });
  }, [open]);

  const onSubmit = (createdData: any) => {
    setOpenLoader(true);

    HttpServiceForDDA.signDataDIsclosureAgreements("payload", "123id")
      .then(() => {
        setOpenLoader(false);
        onRefetch();
        setOpen(false);
      })
      .catch((error: any) => {
        setOpenLoader(false);
        let errorDescription = error.response.data.errorDescription;
        setError(errorDescription);
        setOpenSnackBar(true);
      });
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SnackbarComponent
              open={openSnackBar}
              setOpen={setOpenSnackBar}
              message={error}
            />
            <HeaderContainer>
              <Typography color="#F3F3F6" pl={2}>
                {headerText}
              </Typography>
              <CloseIcon
                onClick={() => {
                  setOpen(false);
                }}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <DetailsContainer style={{ paddingBottom: "50px" }}>
              <Box p={1.5}>
                <Typography variant="subtitle1" mb={0}>
                  {t("signedAgreements.ddaId")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>
                <TextField
                  autoFocus
                  variant="standard"
                  fullWidth
                  placeholder={t("signedAgreements.ddaId")}
                  {...register("ddaID", {
                    required: true,
                    minLength: 3,
                  })}
                />
                <Typography variant="subtitle1" mt={1.5} mb={1}>
                  {t("signedAgreements.dsConnectionID")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>

                <ConnectionDropdown control={control} />
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                style={buttonStyle}
                sx={{
                  marginLeft: "15px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>
              <Button
                variant="outlined"
                style={formState.isValid ? buttonStyle : disabledButtonstyle}
                sx={{
                  cursor: formState.isValid ? "pointer" : "not-allowed",
                  marginRight: "20px",
                  color: formState.isValid ? "black" : "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                type="submit"
              >
                {t("signedAgreements.sign")}{" "}
              </Button>
            </FooterContainer>
          </form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
