import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

const BillingAddressInputContainer = ({ formState, register }: any) => {
  const { t } = useTranslation("translation");
  return (
    <>
      {" "}
      <TextField
        autoComplete="off"
        variant="outlined"
        error={
          formState.submitCount > 0 &&
          (formState.errors.adminName ? true : false)
        }
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.adminName
                  ? "#f44336"
                  : "grey",
            },
            "&:hover fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.adminName
                  ? "#f44336"
                  : "grey",
            },
            "&.Mui-focused fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.adminName
                  ? "#f44336"
                  : "grey",
            },
          },
        }}
        placeholder={t("onboarding.adminName")}
        {...register("adminName", {
          required: true,
          minLength: 1,
        })}
      />
      <TextField
        autoComplete="off"
        variant="outlined"
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.addressLine1
                  ? "#f44336"
                  : "grey",
            },
            "&:hover fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.addressLine1
                  ? "#f44336"
                  : "grey",
            },
            "&.Mui-focused fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.addressLine1
                  ? "#f44336"
                  : "grey",
            },
          },
        }}
        placeholder={t("onboarding.addressLine1")}
        {...register("addressLine1", {
          required: true,
          minLength: 1,
        })}
      />
      <TextField
        autoComplete="off"
        variant="outlined"
        sx={{
          width: "100%",
        }}
        placeholder={t("onboarding.addressLine2")}
        {...register("addressLine2")}
      />
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <TextField
          autoComplete="off"
          variant="outlined"
          sx={{
            width: "49%",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor:
                  formState.submitCount > 0 && formState.errors.city
                    ? "#f44336"
                    : "grey",
              },
              "&:hover fieldset": {
                borderColor:
                  formState.submitCount > 0 && formState.errors.city
                    ? "#f44336"
                    : "grey",
              },
              "&.Mui-focused fieldset": {
                borderColor:
                  formState.submitCount > 0 && formState.errors.city
                    ? "#f44336"
                    : "grey",
              },
            },
          }}
          placeholder={t("onboarding.city")}
          {...register("city", {
            required: true,
            minLength: 1,
          })}
        />

        <TextField
          autoComplete="off"
          variant="outlined"
          sx={{
            width: "49%",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor:
                  formState.submitCount > 0 && formState.errors.state
                    ? "#f44336"
                    : "grey",
              },
              "&:hover fieldset": {
                borderColor:
                  formState.submitCount > 0 && formState.errors.state
                    ? "#f44336"
                    : "grey",
              },
              "&.Mui-focused fieldset": {
                borderColor:
                  formState.submitCount > 0 && formState.errors.state
                    ? "#f44336"
                    : "grey",
              },
            },
          }}
          placeholder={t("issuanceHistory.state")}
          {...register("state", {
            required: true,
            minLength: 1,
          })}
        />
      </Box>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <TextField
          autoComplete="off"
          variant="outlined"
          sx={{
            width: "49%",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor:
                  formState.submitCount > 0 && formState.errors.country
                    ? "#f44336"
                    : "grey",
              },
              "&:hover fieldset": {
                borderColor:
                  formState.submitCount > 0 && formState.errors.country
                    ? "#f44336"
                    : "grey",
              },
              "&.Mui-focused fieldset": {
                borderColor:
                  formState.submitCount > 0 && formState.errors.country
                    ? "#f44336"
                    : "grey",
              },
            },
          }}
          placeholder={t("onboarding.country")}
          {...register("country", {
            required: true,
            minLength: 1,
          })}
        />

        <TextField
          autoComplete="off"
          variant="outlined"
          sx={{
            width: "49%",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor:
                  formState.submitCount > 0 && formState.errors.postalCode
                    ? "#f44336"
                    : "grey",
              },
              "&:hover fieldset": {
                borderColor:
                  formState.submitCount > 0 && formState.errors.postalCode
                    ? "#f44336"
                    : "grey",
              },
              "&.Mui-focused fieldset": {
                borderColor:
                  formState.submitCount > 0 && formState.errors.postalCode
                    ? "#f44336"
                    : "grey",
              },
            },
          }}
          placeholder={t("onboarding.postalCode")}
          {...register("postalCode", {
            required: true,
            minLength: 1,
          })}
        />
      </Box>{" "}
    </>
  );
};

export default BillingAddressInputContainer;
