import { Typography, Box, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TableEmptyMessage } from "../tableEmptyMessage";
import { Datagrid, List, TextField, useRecordContext } from "react-admin";
import useLanguageChange from "../../utils/translateTableLanguage";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";

const InvoiceHistory = () => {
  const { t } = useTranslation("translation");
  const languageChangeKey = useLanguageChange();
  const IconsField = (props: any) => {
    const record = useRecordContext(props);
    if (!record || !props.source) {
      return null;
    }

    return (
      record[props.source] && (
        <Box
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Tooltip title={t("billing.download")} placement="top">
            {record.paymentStatus === "Paid" ? (
              <a download="Invoice.pdf" href={record.invoiceURL}>
                <GetAppOutlinedIcon
                  fontSize="small"
                  style={{
                    cursor: "pointer",
                    marginRight: 10,
                    color: "#4D4D4F",
                  }}
                />
              </a>
            ) : (
              <GetAppOutlinedIcon
                fontSize="small"
                style={{
                  cursor: "not-allowed",
                  marginRight: 10,
                  color: "grey",
                }}
              />
            )}
          </Tooltip>
        </Box>
      )
    );
  };
  return (
    <>
      <Typography color="black" variant="subtitle1" fontWeight="bold" mb={0.5}>
        {t("billing.invoiceHistory")}
      </Typography>

      <List
        actions={false}
        sx={{ width: "100%", overflow: "hidden" }}
        empty={<TableEmptyMessage />}
        pagination={false}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              width: "100%",
              "& .RaDatagrid-rowCell:last-child": {
                width: { md: "30%", lg: "19%" },
              },
            }}
            key={languageChangeKey}
          >
            <TextField
              source="invoiceDate"
              label={t("billing.invoiceDate")}
              sortable={false}
            />
            <TextField
              source="description"
              label={t("billing.invoiceDetails")}
              sortable={false}
            />
            <TextField
              source="dueAmount"
              label={t("billing.invoiceAmount")}
              sortable={false}
            />{" "}
            <TextField
              source="paymentStatus"
              label={t("billing.paymentStatus")}
              sortable={false}
            />
            <IconsField source="invoiceNumber" sortable={false} label={""} />
          </Datagrid>
        </Box>
      </List>
    </>
  );
};

export default InvoiceHistory;
