import * as React from "react";
import { Dispatch, SetStateAction, useState, useEffect } from "react";

import { Drawer, Typography, Button, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
} from "../modalStyle";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { HttpServiceForDigitalWalletEwc } from "../../../service/digitalWalletEwcHttpService";
import SnackbarComponent from "../../notification";
import LoaderComponent from "../../LoaderComponent";
import SendVerificationsModal from "./sendVerificationsModal";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  selectedFirstTable: boolean;
  onRefetch: any;
}

const inputStyle = {
  width: "100%",
  border: "1",
  outline: "none",
  fontSize: "14px",
  color: "#495057",
  borderWidth: 0,
  borderRadius: 0,
  backgroundColor: "#FFFF",
  borderBottom: "2px solid #DFE0E1",
};

let defaultValue = {
  textAreaValue: "",
};
export default function ReceiveCredentialOrPresentationDefinition(
  props: Props
) {
  const { open, setOpen, headerText, selectedFirstTable, onRefetch } = props;
  const { t } = useTranslation("translation");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const [inputDescriptorData, setInputDescriptorData] = useState<any>();
  const [selectedData, setSelectedData] = useState<any>();
  const [openSendVerificationsModal, setOpenSendVerificationsModal] =
    useState<any>(false);

  const { handleSubmit, formState, register, reset } = useForm<any>({
    mode: "onChange",
    defaultValues: defaultValue,
  });

  useEffect(() => {
    reset({ ...defaultValue });
  }, [open]);

  const onSubmit = (createdData: any) => {
    let payload = () => {
      if (selectedFirstTable === true) {
        return { credentialOffer: createdData.textAreaValue };
      } else return { vpTokenQrCode: createdData.textAreaValue };
    };

    setOpenLoader(true);

    if (selectedFirstTable === true) {
      HttpServiceForDigitalWalletEwc.receiveCredentialForEWC(payload())
        .then((res) => {
          if (
            res.credential.presentationId !== "" &&
            res.credential.credentialStatus === "credential_pending" &&
            res.credential.acceptanceToken === ""
          ) {
            let presentationId = res.credential.presentationId;
            setSelectedData(res.credential);
            HttpServiceForDigitalWalletEwc.filterVerificationForEWC(
              presentationId
            )
              .then((res: any) => {
                setOpenLoader(false);
                setInputDescriptorData(res.inputDescriptors);

                const lenthOfMatchedCredential =
                  res.inputDescriptors[0].matchedCredentials?.length;

                if (lenthOfMatchedCredential === 0) {
                  setError(t("holder.insufficientData"));
                  setOpenSnackBar(true);
                } else {
                  setOpenSendVerificationsModal(true);
                }
              })
              .catch((error: any) => {
                setOpenSnackBar(true);
                setError(error?.response?.data?.errorDescription);
                setOpenLoader(false);
              });
          } else {
            onRefetch();
            setOpen(false);
            setOpenLoader(false);
          }
        })
        .catch((error) => {
          setOpenSnackBar(true);
          setError(error?.response?.data?.errorDescription);
          setOpenLoader(false);
        });
    } else {
      HttpServiceForDigitalWalletEwc.receiveVerificationForEWC(payload())
        .then((res: any) => {
          let presentationId = res.presentation.presentationId;
          setSelectedData(res.presentation);
          HttpServiceForDigitalWalletEwc.filterVerificationForEWC(
            presentationId
          )
            .then((res: any) => {
              setOpenLoader(false);
              setInputDescriptorData(res.inputDescriptors);

              const lenthOfMatchedCredential =
                res.inputDescriptors[0].matchedCredentials?.length;

              if (lenthOfMatchedCredential === 0) {
                setError(t("holder.insufficientData"));
                setOpenSnackBar(true);
              } else {
                setOpenSendVerificationsModal(true);
              }
            })
            .catch((error: any) => {
              setOpenSnackBar(true);
              setError(error?.response?.data?.errorDescription);
              setOpenLoader(false);
            });
        })
        .catch((error: any) => {
          setOpenSnackBar(true);
          setError(error?.response?.data?.errorDescription);
          setOpenLoader(false);
        });
    }
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SnackbarComponent
              open={openSnackBar}
              setOpen={setOpenSnackBar}
              message={error}
              topStyle={100}
            />
            <HeaderContainer>
              <Box pl={2}>
                <Typography color="#F3F3F6">{headerText}</Typography>
              </Box>
              <CloseIcon
                onClick={() => {
                  setOpen(false);
                }}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <DetailsContainer>
              <Box p={1.5}>
                <Typography variant="subtitle1" mb={0} mt={1}>
                  {selectedFirstTable
                    ? t("credentials.credentialOffer")
                    : t("verificationsHistory.presentationDefinition")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>

                <textarea
                  placeholder={
                    selectedFirstTable
                      ? t("credentials.credentialOffer")
                      : t("verificationsHistory.presentationDefinition")
                  }
                  style={{
                    ...inputStyle,
                    cursor: "auto",
                    height: "120px",
                    fontFamily: "Inter,Helvetica,Arial,sans-serif",
                  }}
                  {...register("textAreaValue", {
                    required: true,
                  })}
                  rows={5}
                  cols={25}
                  maxLength={500}
                />
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                style={buttonStyle}
                sx={{
                  marginLeft: "15px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>

              <Button
                variant="outlined"
                style={buttonStyle}
                sx={{
                  cursor: formState.isValid ? "pointer" : "not-allowed",
                  marginRight: "20px",
                  color: formState.isValid ? "black" : "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                type="submit"
              >
                {t("connections.receive")}
              </Button>
            </FooterContainer>
          </form>

          <SendVerificationsModal
            open={openSendVerificationsModal}
            setOpen={setOpenSendVerificationsModal}
            headerText={t("holder.chooseCredentials")}
            onRefetch={onRefetch}
            inputDescriptor={inputDescriptorData}
            selectedData={selectedData}
            closeRecievePresentationModal={setOpen}
            selectedFirstTable={selectedFirstTable}
          />
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
