import { combineDataProviders } from "react-admin";
import { HttpService } from "../../service/HTTPService";
import { offSet } from "../../utils/paginateFunction";
import { HttpServiceForDigitalWallet } from "../../service/digitalWalletHttpService";
import { HttpServiceForDigitalWalletEwc } from "../../service/digitalWalletEwcHttpService";

const dataAgreementDataProvider = {
  getList: (resource: any, params: any) => {
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;
    let offsetValue = offSet(pageNumber, pageSize);
    const filter = params.filter.status;

    return HttpService.listDataAgreements(
      offsetValue,
      pageSize,
      filter,
      "",
      true
    )
      .then((dataAgreements) => {
        return {
          data: dataAgreements.dataAgreements,
          total: dataAgreements.pagination.totalItems,
          hasNextPage: dataAgreements.pagination.hasNext,
          hasPreviousPage: dataAgreements.pagination.hasPrevious,
        };
      })
      .catch(() => {});
  },
};

const personalDataDataProvider = {
  getList: (resource: any, params: any) => {
    const filter = params.filter.status;
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;
    let offsetValue = offSet(pageNumber, pageSize);

    return HttpService.listDataAttributes(offsetValue, pageSize, filter)
      .then((dataAttributes) => {
        return {
          data: dataAttributes.dataAttributes,
          total: dataAttributes.pagination.totalItems,
          hasNextPage: dataAttributes.pagination.hasNext,
          hasPreviousPage: dataAttributes.pagination.hasPrevious,
        };
      })
      .catch(() => {});
  },
};

const userRecordsDataProvider = {
  getList: (resource: any, params: any) => {
    const filter = params.filter.status;

    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;

    let offsetValue = offSet(pageNumber, pageSize);
    return HttpService.listAllDataAgreementRecords(
      offsetValue,
      pageSize,
      filter
    )
      .then((dataAgreementRecords) => {
        return {
          data: dataAgreementRecords.consentRecords,
          total: dataAgreementRecords.pagination.totalItems,
          hasNextPage: dataAgreementRecords.pagination.hasNext,
          hasPreviousPage: dataAgreementRecords.pagination.hasPrevious,
        };
      })
      .catch(() => {});
  },
};

const viewLogsProvider = {
  getList: (resource: any, params: any) => {
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;
    const filter = params.filter.status;

    let offsetValue = offSet(pageNumber, pageSize);
    return HttpService.listAllAdminLogs(offsetValue, pageSize, filter)
      .then((allLogs) => {
        return {
          data: allLogs.logs,
          total: allLogs.pagination.totalItems,
          hasNextPage: allLogs.pagination.hasNext,
          hasPreviousPage: allLogs.pagination.hasPrevious,
        };
      })
      .catch(() => {});
  },
};

const developerAPIProvider = {
  getList: (resource: any, params: any) => {
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;

    let offsetValue = offSet(pageNumber, pageSize);
    return HttpService.listAllApiKeys(offsetValue, pageSize)
      .then((apis) => {
        return {
          data: apis.apiKeys,
          total: apis.pagination.totalItems,
          hasNextPage: apis.pagination.hasNext,
          hasPreviousPage: apis.pagination.hasPrevious,
        };
      })
      .catch(() => {});
  },
};

const webhooksProvider = {
  getList: (resource: any, params: any) => {
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;

    let offsetValue = offSet(pageNumber, pageSize);
    return HttpService.listAllWebhooks(offsetValue, pageSize)
      .then((webhooks) => {
        return {
          data: webhooks.webhooks,
          total: webhooks.pagination.totalItems,
          hasNextPage: webhooks.pagination.hasNext,
          hasPreviousPage: webhooks.pagination.hasPrevious,
        };
      })
      .catch(() => {});
  },
};

const connectionsDataProvider = {
  getList: (resource: any, params: any) => {
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;

    let offsetValue = offSet(pageNumber, pageSize);
    return HttpServiceForDigitalWallet.listConnections(offsetValue, pageSize)
      .then((connections) => {
        return {
          data: connections.connections,
          total: connections.pagination.totalItems,
          hasNextPage: connections.pagination.hasNext,
          hasPreviousPage: connections.pagination.hasPrevious,
        };
      })
      .catch(() => {});
  },
};

const credentialsOrVerificationsDataProvider = {
  getList: (resource: any, params: any) => {
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;
    const filter = params.filter.methodOfUse;
    const dexProfile = params.filter.dexProfile;
    let offsetValue = offSet(pageNumber, pageSize);

    return HttpServiceForDigitalWallet.listDigitalWalletDataAgreementsWithMethodOfUseFilter(
      offsetValue,
      pageSize,
      filter,
      "",
      true,
      dexProfile
    )
      .then((dataAgreements) => {
        return {
          data: dataAgreements.dataAgreements,
          total: dataAgreements.pagination.totalItems,
          hasNextPage: dataAgreements.pagination.hasNext,
          hasPreviousPage: dataAgreements.pagination.hasPrevious,
        };
      })
      .catch(() => {});
  },
};

const issuanceHistoryDataProvider = {
  getList: (resource: any, params: any) => {
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;
    const filter = params.filter.id;
    let offsetValue = offSet(pageNumber, pageSize);

    return HttpServiceForDigitalWallet.listIssueCredentialHistoryRecords(
      offsetValue,
      pageSize,
      filter
    )
      .then((issuanceHistory: any) => {
        return {
          data: issuanceHistory.credentialHistory,
          total: issuanceHistory.pagination.totalItems,
          hasNextPage: issuanceHistory.pagination.hasNext,
          hasPreviousPage: issuanceHistory.pagination.hasPrevious,
        };
      })
      .catch(() => {});
  },
};

const verifiedHistoryDataProvider = {
  getList: (resource: any, params: any) => {
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;
    const filter = params.filter.id;
    let offsetValue = offSet(pageNumber, pageSize);

    return HttpServiceForDigitalWallet.listIssueVerifiedHistoryRecords(
      offsetValue,
      pageSize,
      filter
    )
      .then((verificationHistory: any) => {
        return {
          data: verificationHistory.verificationHistory,
          total: verificationHistory.pagination.totalItems,
          hasNextPage: verificationHistory.pagination.hasNext,
          hasPreviousPage: verificationHistory.pagination.hasPrevious,
        };
      })
      .catch(() => {});
  },
};

const dataVerifierUserDataProvider = {
  getList: (resource: any, params: any) => {
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;

    let offsetValue = offSet(pageNumber, pageSize);
    return HttpServiceForDigitalWallet.listDataVerifierUsers(
      offsetValue,
      pageSize
    )
      .then((data: any) => {
        return {
          data: data.dataVerifierUsers,
          total: data.pagination.totalItems,
          hasNextPage: data.pagination.hasNext,
          hasPreviousPage: data.pagination.hasPrevious,
        };
      })
      .catch(() => {});
  },
};

const dexProfilesDigitalWallet = [
  {
    id: 1,
    dexProfileName: "AIP 1.0 (AnonCreds)",
    credentialFormat: "AnonCreds",
    signingAlgorithm: "CL",
    revocationAlgorithm: "Indy Revocation",
    keyManagementIssuer: "did:sov",
    keyManagementHolder: "link secrets",
    trustManagement: "Verifier knows Issuers",
  },
  {
    id: 3,
    dexProfileName: "AIP 1.0 (Self-attested ICAO)",
    credentialFormat: "ICAO DTC",
    signingAlgorithm: "ECDSA",
    revocationAlgorithm: "SLTD database (travel and identity documents)",
    keyManagementIssuer: "raw public keys (non-jwk)",
    keyManagementHolder: "raw public keys (non-jwk)",
    trustManagement: "ICAO Trust list",
  },
  {
    id: 4,
    dexProfileName: "AIP 1.0 (Self-attested x.509)",
    credentialFormat: "x.509",
    signingAlgorithm: "ECDSA",
    revocationAlgorithm: "CRL - certificate revocation list",
    keyManagementIssuer: "raw public keys (non-jwk)",
    keyManagementHolder: "raw public keys (none jwk)",
    trustManagement: "X.509 certificates",
  },
];

const dexProfilesForOpenID4VCDigitalWallet = [
  {
    id: 2,
    dexProfileName: "OpenID4VC",
    credentialFormat: "JWT-VC",
    signingAlgorithm: "ECDSA",
    revocationAlgorithm: "W3C Status List 2021",
    keyManagementIssuer: "did:ebsi (Legal Entity)",
    keyManagementHolder: "did:key (Natural Persons)",
    trustManagement: "EBSI Trust Registries",
  },
  {
    id: 3,
    dexProfileName: "OpenID4VC (Self-attested ICAO)",
    credentialFormat: "ICAO DTC",
    signingAlgorithm: "ECDSA",
    revocationAlgorithm: "SLTD database (travel and identity documents)",
    keyManagementIssuer: "raw public keys (non-jwk)",
    keyManagementHolder: "raw public keys (non-jwk)",
    trustManagement: "ICAO Trust list",
  },
  {
    id: 4,
    dexProfileName: "OpenID4VC (Self-attested x.509)",
    credentialFormat: "x.509",
    signingAlgorithm: "ECDSA",
    revocationAlgorithm: "CRL - certificate revocation list",
    keyManagementIssuer: "raw public keys (non-jwk)",
    keyManagementHolder: "raw public keys (none jwk)",
    trustManagement: "X.509 certificates",
  },
];

export const dexProfilesForOpenID4VCDigitalWalletDataProvider = {
  getList: () => {
    return Promise.resolve({
      data: dexProfilesForOpenID4VCDigitalWallet,
      total: dexProfilesForOpenID4VCDigitalWallet.length,
    });
  },
};

export const dexProfilesDigitalWalletDataProvider = {
  getList: () => {
    return Promise.resolve({
      data: dexProfilesDigitalWallet,
      total: dexProfilesDigitalWallet.length,
    });
  },
};

const invoiceHistoryDataProvider = {
  getList: (resource: any, params: any) => {
    return HttpServiceForDigitalWallet.getInvoices()
      .then((data: any) => {
        return {
          data: data,
          total: data.length,
        };
      })
      .catch(() => {
        return { data: [], total: 0 };
      });
  },
};

const signedAgreement = [
  {
    id: 1,
    data_disclosure_agreement: {
      "@context": [
        "https://raw.githubusercontent.com/decentralised-dataexchange/data-exchange-agreements/main/interface-specs/jsonld/contexts/dexa-context.jsonld",
        "https://w3id.org/security/v2",
      ],
      "@id": "7d2b2434-b91d-4327-bc37-a268818a9a19",
      "@type": ["DataDisclosureAgreement"],
      version: "1.0.0",
      templateId: "2daf66e7-11c9-4bd8-90ea-ed4a1c04bcb0",
      templateVersion: "1.0.0",
      language: "en",
      dataController: {
        did: "did:sov:5FrPfLA8iLtMA3rJqwM7Qg",
        name: "Hospital AB",
        legalId: "did:sov:5FrPfLA8iLtMA3rJqwM7Qg",
        url: "https://igrant.io/policy.html",
        industrySector: "Healthcare",
      },
      agreementPeriod: 365,
      dataSharingRestrictions: {
        policyUrl: "https://igrant.io/policy.html",
        jurisdiction: "Sweden",
        industrySector: "Healthcare",
        dataRetentionPeriod: 365,
        geographicRestriction: "Europe",
        storageLocation: "Europe",
      },
      purpose: "Cancer patient record summary",
      purposeDescription:
        "Cancer patient record summary for research initiatives",
      lawfulBasis: "consent",
      personalData: [
        {
          attributeId: "ce80a3d4-0fba-45e8-8271-ecb3a0d1c0a6",
          attributeName: "Cancer patient record summary",
          attributeDescription: "Summary of records",
        },
      ],
      codeOfConduct: "https://igrant.io/policy.html",
      dataUsingService: {
        did: "did:sov:28EYvbPrGCoEtgntA2oohE",
        name: "Research Institute",
        legalId: "did:sov:28EYvbPrGCoEtgntA2oohE",
        url: "https://igrant.io/policy.html",
        industrySector: "Healthcare",
        usagePurposes: "Cancer patient record summary",
        jurisdiction: "Sweden",
        withdrawal: "https://igrant.io/policy.html",
        privacyRights: "https://igrant.io/policy.html",
        signatureContact: "did:sov:28EYvbPrGCoEtgntA2oohE",
      },
      proofChain: [
        {
          id: "did:sov:5FrPfLA8iLtMA3rJqwM7Qg#1",
          type: "Ed25519Signature2018",
          created: "2024-04-01T19:48:57.943956+00:00",
          verificationMethod: "3KYTwpwNVYNFR9XmQYBEFrYi4TVQXjQSDEYVVqasoXxn",
          proofPurpose: "authentication",
          proofValue:
            "eyJhbGciOiAiRWREU0EiLCAiYjY0IjogZmFsc2UsICJjcml0IjogWyJiNjQiXX0..6krXb1bbnhrtV3rzNnEGbL7z91rv8GfucBJs6PaqADiHVuEwU3oOe3IAdMXSQ92oyQkAiL7hsYA2NSnTL5STDQ",
        },
        {
          id: "did:sov:28EYvbPrGCoEtgntA2oohE#1",
          type: "Ed25519Signature2018",
          created: "2024-04-01T19:48:58.018330+00:00",
          verificationMethod: "cZAVGiRgd9MXrsMo5NwhK4eJ22bBq4j36kx5YzaaYFj",
          proofPurpose: "authentication",
          proofValue:
            "eyJhbGciOiAiRWREU0EiLCAiYjY0IjogZmFsc2UsICJjcml0IjogWyJiNjQiXX0..zLpVC5lQwjRb1AVIUsASgS0kdDT0gq7aFbbGRcAqAcnj015pCm8t6bBmV8SBVTOXD0AegIsGRg88Sjfeiy2CAQ",
        },
      ],
    },
    state: "CAPTURE",
    industry_sector: "healthcare",
    template_id: "2daf66e7-11c9-4bd8-90ea-ed4a1c04bcb0",
    connection_id: "29dea95b-ee4d-4d19-82ea-3ab3c34e74cb",
    updated_at: "2024-04-01 19:48:58.082889Z",
    delete_flag: "false",
    template_version: "1.0.0",
    instance_id: "7d2b2434-b91d-4327-bc37-a268818a9a19",
    created_at: "2024-04-01 19:48:57.962316Z",
    permissions: [],
  },
  {
    id: 2,
    data_disclosure_agreement: {
      "@context": [
        "https://raw.githubusercontent.com/decentralised-dataexchange/data-exchange-agreements/main/interface-specs/jsonld/contexts/dexa-context.jsonld",
        "https://w3id.org/security/v2",
      ],
      "@id": "f6e8c240-94c5-4057-a3d9-eaca670efaf5",
      "@type": ["DataDisclosureAgreement"],
      version: "1.0.0",
      templateId: "b6c5215f-bc6a-4361-8a76-eb012dd3df23",
      templateVersion: "1.0.0",
      language: "en",
      dataController: {
        did: "did:sov:5FrPfLA8iLtMA3rJqwM7Qg",
        name: "Hospital AB",
        legalId: "did:sov:5FrPfLA8iLtMA3rJqwM7Qg",
        url: "https://igrant.io/policy.html",
        industrySector: "Healthcare",
      },
      agreementPeriod: 365,
      dataSharingRestrictions: {
        policyUrl: "https://igrant.io/policy.html",
        jurisdiction: "Sweden",
        industrySector: "Healthcare",
        dataRetentionPeriod: 365,
        geographicRestriction: "Europe",
        storageLocation: "Europe",
      },
      purpose: "Diabetic patient summary",
      purposeDescription:
        "Provide diabetic patient summary for research purposes",
      lawfulBasis: "consent",
      personalData: [
        {
          attributeId: "ecca1af7-3d75-453f-9654-a7775cadd2d1",
          attributeName: "Diabetic patient summary",
          attributeDescription: "Diabetic patient summary",
        },
      ],
      codeOfConduct: "https://igrant.io/policy.html",
      dataUsingService: {
        did: "did:sov:28EYvbPrGCoEtgntA2oohE",
        name: "Research Institute",
        legalId: "did:sov:28EYvbPrGCoEtgntA2oohE",
        url: "https://igrant.io/policy.html",
        industrySector: "Healthcare",
        usagePurposes: "Diabetic patient summary",
        jurisdiction: "Sweden",
        withdrawal: "https://igrant.io/policy.html",
        privacyRights: "https://igrant.io/policy.html",
        signatureContact: "did:sov:28EYvbPrGCoEtgntA2oohE",
      },
      proofChain: [
        {
          id: "did:sov:5FrPfLA8iLtMA3rJqwM7Qg#1",
          type: "Ed25519Signature2018",
          created: "2024-04-01T19:29:07.793211+00:00",
          verificationMethod: "3KYTwpwNVYNFR9XmQYBEFrYi4TVQXjQSDEYVVqasoXxn",
          proofPurpose: "authentication",
          proofValue:
            "eyJhbGciOiAiRWREU0EiLCAiYjY0IjogZmFsc2UsICJjcml0IjogWyJiNjQiXX0..FiJ-Q6keiB1jgG7Zfl2o3-1AbqWqNAvK_qD0VnV8vXTGsBG23qkzpQa1tV3fbncDTn2K03BRdPsE0lbzpncPAg",
        },
        {
          id: "did:sov:28EYvbPrGCoEtgntA2oohE#1",
          type: "Ed25519Signature2018",
          created: "2024-04-01T19:29:10.058658+00:00",
          verificationMethod: "cZAVGiRgd9MXrsMo5NwhK4eJ22bBq4j36kx5YzaaYFj",
          proofPurpose: "authentication",
          proofValue:
            "eyJhbGciOiAiRWREU0EiLCAiYjY0IjogZmFsc2UsICJjcml0IjogWyJiNjQiXX0..rrPjxMVFzMYdX_K5NTMvfAIlfnwaEZXym7rHRzlFTQpF2xMIBGLlL74aMkUhVIllozCoIkGCjKTrI6DO11B2Cg",
        },
      ],
    },
    state: "CAPTURE",
    industry_sector: "healthcare",
    template_id: "b6c5215f-bc6a-4361-8a76-eb012dd3df23",
    connection_id: "f251dc84-14cd-4324-b582-db40745001f2",
    updated_at: "2024-04-01 19:29:10.123542Z",
    delete_flag: "false",
    template_version: "1.0.0",
    instance_id: "f6e8c240-94c5-4057-a3d9-eaca670efaf5",
    created_at: "2024-04-01 19:29:09.078641Z",
    permissions: [],
  },
];

export const signedAgreementDataProvider = {
  getList: () => {
    return Promise.resolve({
      data: signedAgreement,
      total: signedAgreement.length,
    });
  },
};

const pullDataHistory = [
  {
    id: 2,
    token:
      "eyJhbGciOiAiRWREU0EiLCAidHlwZSI6ICJKV1QifQ.eyJpYXQiOiAxNzEyMDAxMDUzLCAiZXhwIjogMTcxMjAwODI1MywgImRkYV9pbnN0YW5jZV9pZCI6ICI3ZDJiMjQzNC1iOTFkLTQzMjctYmMzNy1hMjY4ODE4YTlhMTkiLCAibm9uY2UiOiAiNTY4Mjc5ODA2MzQ2NjI3MTYxNTc5ODE0In0.t9AtmNBtyhCUf-0PU7ufwSbCJcubYTkY2obzutLhH_y_MxqzlwUHXTvf-Q_JLZOar4pCfndloBW4cOVi8rcnCw",
    state: "response",
    updated_at: "2024-04-01 19:50:53.113198Z",
    dda_instance_id: "7d2b2434-b91d-4327-bc37-a268818a9a19",
    dda_template_id: "2daf66e7-11c9-4bd8-90ea-ed4a1c04bcb0",
    nonce: "568279806346627161579814",
    token_packed: {
      protected:
        "eyJlbmMiOiJ4Y2hhY2hhMjBwb2x5MTMwNV9pZXRmIiwidHlwIjoiSldNLzEuMCIsImFsZyI6IkF1dGhjcnlwdCIsInJlY2lwaWVudHMiOlt7ImVuY3J5cHRlZF9rZXkiOiI2OUxDQ1B3dHJYQncwaDZXaTJZWkMxbkdyS2hyTzRWYjAwZ3ZqaWNHbHJ3REEySXpzMWloX3hZc2NmMEpvNnctIiwiaGVhZGVyIjp7ImtpZCI6IkN0bWpEVEpBdksyV0Vzdlp3bVZUajViaER0elFiSjVQemNobUFMTWl1ZXFjIiwiaXYiOiJCRXVadHdmc0tENHROS2oyWXRwY1M4T082dDFjTkM2SCIsInNlbmRlciI6IjZ1RjJ4eHhuem1YVzVZOHI4eldVb1RkdFU1ZVNKWGZwU2t0UXI1SWZfaDl3a281Q2ViREI1cjMxUms2VV9ZNlVEbTBFaU8yQnliRWdEVGVwUk5OTm5ac2kzWVBpVXgtMncxaWRNOXZpcXJkdjRpQmZnMWEtZnZ3b09Ebz0ifX1dfQ==",
      iv: "fPBNgyB_Nq4i8Rzc",
      ciphertext:
        "4ZAjJhxvyh9q2JlgLcIEMbm-ctPpGXsT1hgBpgRBUTJSHo7UVmetXpdmVvYZgulRaDk-j6C6l_jVD-M4VeKfKXXmIzOF5eAd6n0z7td2aIrc-E4a73F-CLEkcKfAFEM_ESPQGdOUmyT9w_dQ_xgWqpbWwpMfq3CdXk1yxEKE3V5_eiCtbO947dOYFd9_iL_q2mwGmQrY0WcbqVa5-aFv00dDyqUICCaDL1fbObA8iIZraJeaBYEBuNelbtWSZGlDiCOo2wDNs9k8qgko3RINCqhEJBA1L731KWtH83FGpW43FzhQX0zM4FDLiBXIoHAFJbAdIy-pzvbF-sdUY4WE4y1U80MkrI-UEbWZz9Mw7-oWHP2GVn7WKO2BTLc26MFkYb8Esgz48H58Y2W63hLqKGiUZajH",
      tag: "xRV91tR9lSFbMWMTpLrH5Q==",
    },
    created_at: "2024-04-01 19:50:53.106147Z",
  },
  {
    id: 2,
    token:
      "eyJhbGciOiAiRWREU0EiLCAidHlwZSI6ICJKV1QifQ.eyJpYXQiOiAxNzEyMDAxMDI0LCAiZXhwIjogMTcxMjAwODIyNCwgImRkYV9pbnN0YW5jZV9pZCI6ICI3ZDJiMjQzNC1iOTFkLTQzMjctYmMzNy1hMjY4ODE4YTlhMTkiLCAibm9uY2UiOiAiMjQ1NzUwOTY2NDUwMzk2OTY3OTk1MzYifQ.nnOAvfzOAk1PALPa_rCiumjOJunMVMbi2TfAoeU2VTvci_1RHve-0uXv6a0FLX6lDFxbHYfK9SCV8Y0FQtdYDw",
    state: "response",
    updated_at: "2024-04-01 19:50:24.202284Z",
    dda_instance_id: "7d2b2434-b91d-4327-bc37-a268818a9a19",
    dda_template_id: "2daf66e7-11c9-4bd8-90ea-ed4a1c04bcb0",
    nonce: "24575096645039696799536",
    token_packed: {
      protected:
        "eyJlbmMiOiJ4Y2hhY2hhMjBwb2x5MTMwNV9pZXRmIiwidHlwIjoiSldNLzEuMCIsImFsZyI6IkF1dGhjcnlwdCIsInJlY2lwaWVudHMiOlt7ImVuY3J5cHRlZF9rZXkiOiJlQV85X0pCd28zLThJbzVkTnUwNWl1NndJLU1mY2Z1M3NDUlJjNDBwU0pBTVJqYTZ1Uk1qSUNTYVpySlowaWJJIiwiaGVhZGVyIjp7ImtpZCI6IkN0bWpEVEpBdksyV0Vzdlp3bVZUajViaER0elFiSjVQemNobUFMTWl1ZXFjIiwiaXYiOiJLRkp0MkoxWmh0aEV0elh1R1NYOEdjSkNpTC1rQUtNNSIsInNlbmRlciI6ImV5OUtFV2VZUGstOUdRMnFJMzBreGJLM2xET1ZxUTlQNGFjX2w1T0ZhWHZGaEpDclRXRTFtVFpZMURnT1VlQjlteDR1SnpyM25LaVJCRXdNMUt0VVU4VWRkY0xrOURicW1STXVLMHA1TVJ3UV9wVjh1X19SaXZBR3FIdz0ifX1dfQ==",
      iv: "oEMhfD2c2KeuxiPl",
      ciphertext:
        "Cvha3ITkL9hkwOss_gFf9Jtrp1q_YGqconwYVUuztm2G02LbaqWwvy9LIIdWf1-7krIl-ZoTN5ZWDRAxMXg7nRuOAIg0hZjLUUCbDrju0zJdSz0SGXja2RZgcXJ-jnNbK50Fb9WU5hmW3UWB2bh-muExEVqJJwwJAn2tMypvj312tflO1OveUTHEaXiWLPGGkhQRc_VLpKxyqjYk3-jdzpmLYjubwN-hvuMHoLaRDwu9OHE1n7Mbsw_L6ddF4ctgk3PXLTs0k3XEY91qAp5Em_oJy_BRVdHrJpFDkaiwB2GqoQBVkzaXZMQ5Kq29dx8iUSQiC8OSte6vOU7HdHME5z9mnVez50Lfc1-dcqmo9dMARSIXj9PVXWPrawNyXhR1OOO34gloBdntK0DF1JjMHiXqzKo=",
      tag: "G6KiXUgKZi0jZ66HTzQXBA==",
    },
    created_at: "2024-04-01 19:50:24.195356Z",
  },
];

export const pullDataHistoryDataProvider = {
  getList: () => {
    return Promise.resolve({
      data: pullDataHistory,
      total: pullDataHistory.length,
    });
  },
};

const dataDisclosureAgreements = [
  {
    id: "0c1ae0df-b32a-4ce7-bc09-5d4e35913eb3",
    data_disclosure_agreement: {
      "@context": [
        "https://raw.githubusercontent.com/decentralised-dataexchange/data-exchange-agreements/main/interface-specs/jsonld/contexts/dexa-context.jsonld",
        "https://w3id.org/security/v2",
      ],
      "@id": "64140c40-8896-472f-83e6-308878f8ee25",
      "@type": ["DataDisclosureAgreement"],
      version: "1.0.0",
      language: "en",
      dataController: {
        did: "did:sov:5FrPfLA8iLtMA3rJqwM7Qg",
        name: "Hospital AB",
        legalId: "did:sov:5FrPfLA8iLtMA3rJqwM7Qg",
        url: "https://igrant.io/policy.html",
        industrySector: "Healthcare",
      },
      agreementPeriod: 365,
      dataSharingRestrictions: {
        policyUrl: "https://dda.io/policy.html",
        jurisdiction: "India",
        industrySector: "Healthcare",
        dataRetentionPeriod: 365,
        geographicRestriction: "Europe",
        storageLocation: "Europe",
      },
      purpose: "Corona patient records",
      purposeDescription: "Corona patient records",
      lawfulBasis: "consent",
      personalData: [
        {
          attributeId: "ccec7e9c-1777-4a7f-9b54-d8eaf526afc5",
          attributeName: "Corona patient records",
          attributeDescription: "Corona patient records",
        },
      ],
      codeOfConduct: "https://dda.io/policy.html",
    },
    state: "DEFINITION",
    latest_version_flag: "true",
    industry_sector: "healthcare",
    template_id: "6602c2ee8b3a694e41bf7459",
    updated_at: "2024-04-03 12:38:58.396882Z",
    delete_flag: "false",
    template_version: "1.0.0",
    da_template_version: "2.0.0",
    publish_flag: "false",
    created_at: "2024-04-03 12:38:58.396882Z",
  },
  {
    id: "26a41014-e9ae-43e6-92d5-d80eba168dd1",
    // da_template_id: "26a41014-e9ae-43e6-92d5-d80eba168dd1",
    data_disclosure_agreement: {
      "@context": [
        "https://raw.githubusercontent.com/decentralised-dataexchange/data-exchange-agreements/main/interface-specs/jsonld/contexts/dexa-context.jsonld",
        "https://w3id.org/security/v2",
      ],
      "@id": "312f8808-01c3-4f68-bfd0-46ea3538bfe2",
      "@type": ["DataDisclosureAgreement"],
      version: "2.0.0",
      language: "en",
      dataController: {
        did: "did:sov:5FrPfLA8iLtMA3rJqwM7Qg",
        name: "Hospital AB",
        legalId: "did:sov:5FrPfLA8iLtMA3rJqwM7Qg",
        url: "https://igrant.io/policy.html",
        industrySector: "Healthcare",
      },
      agreementPeriod: 365,
      dataSharingRestrictions: {
        policyUrl: "https://igrant.io/policy.html",
        jurisdiction: "Sweden",
        industrySector: "Healthcare",
        dataRetentionPeriod: 365,
        geographicRestriction: "Europe",
        storageLocation: "Europe",
      },
      purpose: "Allergic patient record Data",
      purposeDescription: "Allergic patient record",
      lawfulBasis: "consent",
      personalData: [
        {
          attributeId: "e811feef-f4d2-49cf-aa90-6f89bd8e727a",
          attributeName: "Allergic patient record",
          attributeDescription: "Allergic patient record",
        },
      ],
      codeOfConduct: "https://igrant.io/policy.html",
    },
    state: "DEFINITION",
    latest_version_flag: "true",
    industry_sector: "healthcare",
    template_id: "66047c308b3a694e41bf87cf",
    updated_at: "2024-04-03 10:05:52.005079Z",
    delete_flag: "false",
    template_version: "2.0.0",
    da_template_version: "1.0.0",
    publish_flag: "true",
    created_at: "2024-04-03 10:05:52.005079Z",
  },
  {
    id: "8bdbec81-4865-4753-8697-b4710c961b67",
    // da_template_id: "8bdbec81-4865-4753-8697-b4710c961b67",
    data_disclosure_agreement: {
      "@context": [
        "https://raw.githubusercontent.com/decentralised-dataexchange/data-exchange-agreements/main/interface-specs/jsonld/contexts/dexa-context.jsonld",
        "https://w3id.org/security/v2",
      ],
      "@id": "2daf66e7-11c9-4bd8-90ea-ed4a1c04bcb0",
      "@type": ["DataDisclosureAgreement"],
      version: "1.0.0",
      language: "en",
      dataController: {
        did: "did:sov:5FrPfLA8iLtMA3rJqwM7Qg",
        name: "Hospital AB",
        legalId: "did:sov:5FrPfLA8iLtMA3rJqwM7Qg",
        url: "https://igrant.io/policy.html",
        industrySector: "Healthcare",
      },
      agreementPeriod: 365,
      dataSharingRestrictions: {
        policyUrl: "https://igrant.io/policy.html",
        jurisdiction: "Sweden",
        industrySector: "Healthcare",
        dataRetentionPeriod: 365,
        geographicRestriction: "Europe",
        storageLocation: "Europe",
      },
      purpose: "Cancer patient record summary",
      purposeDescription:
        "Cancer patient record summary for research initiatives",
      lawfulBasis: "consent",
      personalData: [
        {
          attributeId: "ce80a3d4-0fba-45e8-8271-ecb3a0d1c0a6",
          attributeName: "Cancer patient record summary",
          attributeDescription: "Summary of records",
        },
      ],
      codeOfConduct: "https://igrant.io/policy.html",
    },
    state: "DEFINITION",
    latest_version_flag: "true",
    industry_sector: "healthcare",
    template_id: "661e67c6095250eb6329017f",
    updated_at: "2024-04-01 19:47:05.424337Z",
    delete_flag: "false",
    template_version: "1.0.0",
    da_template_version: "1.0.0",
    publish_flag: "true",
    created_at: "2024-04-01 19:47:05.424337Z",
    marketplacePublished: true,
  },
];

export const dataDisclosureAgreementsDataProvider = {
  getList: () => {
    return Promise.resolve({
      data: dataDisclosureAgreements,
      total: dataDisclosureAgreements.length,
    });
  },
};

const userRequestDataProvider = {
  getList: (resource: any, params: any) => {
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;
    let offsetValue = offSet(pageNumber, pageSize);
    const status = params.filter.status;

    if (status === "open") {
      return HttpServiceForDigitalWallet.listDataRequest(
        offsetValue,
        pageSize,
        status
      )
        .then((data: any) => {
          return {
            data: data.dataRequests,
            total: data.pagination.totalItems,
            hasNextPage: data.pagination.hasNext,
            hasPreviousPage: data.pagination.hasPrevious,
          };
        })
        .catch(() => {
          return { data: [], total: 0 };
        });
    }

    if (status === "closed") {
      return HttpServiceForDigitalWallet.listDataRequest(
        offsetValue,
        pageSize,
        status
      )
        .then((data: any) => {
          return {
            data: data.dataRequests,
            total: data.pagination.totalItems,
            hasNextPage: data.pagination.hasNext,
            hasPreviousPage: data.pagination.hasPrevious,
          };
        })
        .catch(() => {
          return { data: [], total: 0 };
        });
    }
  },
};

const issuanceHistoryDataProviderForEWC = {
  getList: (resource: any, params: any) => {
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;
    let offsetValue = offSet(pageNumber, pageSize);
    const filter = params.filter.id;

    return HttpServiceForDigitalWalletEwc.listIssueCredentialHistoryRecordsForEwc(
      offsetValue,
      pageSize,
      filter
    )
      .then((issuanceHistory: any) => {
        return {
          data: issuanceHistory.credentialHistory,
          total: issuanceHistory.pagination.totalItems,
          hasNextPage: issuanceHistory.pagination.hasNext,
          hasPreviousPage: issuanceHistory.pagination.hasPrevious,
        };
      })
      .catch(() => {});
  },
};

const verifiedHistoryDataProviderForEwc = {
  getList: (resource: any, params: any) => {
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;
    let offsetValue = offSet(pageNumber, pageSize);
    let filter = params.filter.id;
    return HttpServiceForDigitalWalletEwc.listIssueVerificationHistoryRecordsForEwc(
      offsetValue,
      pageSize,
      filter
    )
      .then((verificationHistory: any) => {
        return {
          data: verificationHistory.verificationHistory,
          total: verificationHistory.pagination.totalItems,
          hasNextPage: verificationHistory.pagination.hasNext,
          hasPreviousPage: verificationHistory.pagination.hasPrevious,
        };
      })
      .catch(() => {});
  },
};

const listCredentialDefinitionDataProviderForEWC = {
  getList: (resource: any, params: any) => {
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;
    let offsetValue = offSet(pageNumber, pageSize);

    return HttpServiceForDigitalWalletEwc.listCredentialDefinitin(
      offsetValue,
      pageSize
    )
      .then((data: any) => {
        return {
          data: data.credentialDefinition,
          total: data.pagination.totalItems,
          hasNextPage: data.pagination.hasNext,
          hasPreviousPage: data.pagination.hasPrevious,
        };
      })
      .catch(() => {});
  },
};

const listPresentationDefinitionDataProviderForEwc = {
  getList: (resource: any, params: any) => {
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;
    let offsetValue = offSet(pageNumber, pageSize);

    return HttpServiceForDigitalWalletEwc.listIssuePresentationDefinitionForEwc(
      offsetValue,
      pageSize
    )
      .then((presentationDefinition: any) => {
        return {
          data: presentationDefinition.presentationDefinition,
          total: presentationDefinition.pagination.totalItems,
          hasNextPage: presentationDefinition.pagination.hasNext,
          hasPreviousPage: presentationDefinition.pagination.hasPrevious,
        };
      })
      .catch(() => {});
  },
};

const holderReceivedCredentialsDataProvider = {
  getList: (resource: any, params: any) => {
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;
    let offsetValue = offSet(pageNumber, pageSize);
    return HttpServiceForDigitalWalletEwc.listCredentialsEwc(
      offsetValue,
      pageSize
    )
      .then((data) => {
        return {
          data: data.credential,
          total: data.pagination.totalItems,
          hasNextPage: data.pagination.hasNext,
          hasPreviousPage: data.pagination.hasPrevious,
        };
      })
      .catch(() => {
        return { data: [], total: 0 };
      });
  },
};

const holderSharedCredentialsDataProvider = {
  getList: (resource: any, params: any) => {
    let pageSize = params.pagination.perPage;
    let pageNumber = params.pagination.page;
    let offsetValue = offSet(pageNumber, pageSize);
    return HttpServiceForDigitalWalletEwc.listVerificationsEwc(
      offsetValue,
      pageSize
    )
      .then((data) => {
        return {
          data: data.presentation,
          total: data.pagination.totalItems,
          hasNextPage: data.pagination.hasNext,
          hasPreviousPage: data.pagination.hasPrevious,
        };
      })
      .catch(() => {
        return { data: [], total: 0 };
      });
  },
};

export const dataProvider = combineDataProviders((resource): any => {
  switch (resource) {
    case "dataagreement":
      return dataAgreementDataProvider;
    case "personaldata":
      return personalDataDataProvider;
    case "viewlogs":
      return viewLogsProvider;
    case "consentrecords":
      return userRecordsDataProvider;
    case "developerapi":
      return developerAPIProvider;
    case "webhooks":
      return webhooksProvider;
    case "connections":
      return connectionsDataProvider;
    case "credentials":
      return credentialsOrVerificationsDataProvider;
    case "verifications":
      return credentialsOrVerificationsDataProvider;
    case "issuance-history":
      return issuanceHistoryDataProvider;
    case "verification-history":
      return verifiedHistoryDataProvider;
    case "dataverifierusers":
      return dataVerifierUserDataProvider;
    case "aries-cloud-agent":
      return dexProfilesDigitalWalletDataProvider;
    case "billing":
      return invoiceHistoryDataProvider;
    case "signedAgreements":
      return signedAgreementDataProvider;
    case "pullDataHistory":
      return pullDataHistoryDataProvider;
    case "dataDisclosureAgreements":
      return dataDisclosureAgreementsDataProvider;
    case "userRequests":
      return userRequestDataProvider;
    case "oid4vc-credentials":
      return listCredentialDefinitionDataProviderForEWC;
    case "oid4vc-issuance-history":
      return issuanceHistoryDataProviderForEWC;
    case "oid4vc":
      return dexProfilesForOpenID4VCDigitalWalletDataProvider;
    case "oid4vc-verifications":
      return listPresentationDefinitionDataProviderForEwc;
    case "oid4vc-verification-history":
      return verifiedHistoryDataProviderForEwc;
    case "holder-received-credentials":
      return holderReceivedCredentialsDataProvider;
    case "holder-shared-credentials":
      return holderSharedCredentialsDataProvider;
  }
});
