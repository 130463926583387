import * as React from "react";
import { Dispatch, SetStateAction } from "react";

import { Drawer, Typography, Button, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
} from "./modalStyle";

import { useTranslation } from "react-i18next";

import SSIPolicyDetailsBox from "../issuanceHistory/ssiPolicyCardDetailsBox";
import { getLawfulBasisOfProcessing } from "../../service/adapter";
import VerfiedDABoxForDDA from "../dda/verfiedDABoxForDDA";
import IndividualDecentralisedIdentifierBoxForDDA from "../dda/individualDecentralisedIdentifierBoxForDDA";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  selectedData: any;
  setOpenViewExchangeRecordModal: Dispatch<SetStateAction<boolean>>;
}

const titleAttrRestrictionStyle = {
  fontWeight: "normal",
  marginTop: "20px",
  lineHeight: "1.5rem",
  display: "grid",
  alignItems: "center",
  cursor: "pointer",
  border: "1px solid #DFE0E1",
  borderRadius: 5,
  padding: "12px",
};

export default function DDAPolicyCardInSSI(props: Props) {
  const { t, i18n } = useTranslation("translation");
  const {
    open,
    setOpen,
    headerText,
    selectedData,
    setOpenViewExchangeRecordModal,
  } = props;

  const SSIpolicyDetailsForContainer = [
    {
      name: t("dataAgreements.lawfulBasisOfProcessing"),
      value: getLawfulBasisOfProcessing(
        selectedData.data_disclosure_agreement?.lawfulBasis,
        i18n.t
      ),
    },
    {
      name: t("dataAgreements.retentionPeriod"),
      value:
        selectedData.data_disclosure_agreement?.dataSharingRestrictions
          ?.dataRetentionPeriod,
    },
    {
      name: t("common.policyUrl"),
      value:
        selectedData.data_disclosure_agreement?.dataSharingRestrictions
          ?.policyUrl,
    },
    {
      name: t("dataAgreements.jurisdiction"),
      value:
        selectedData.data_disclosure_agreement?.dataSharingRestrictions
          ?.jurisdiction,
    },
    {
      name: t("dataAgreements.industryScope"),
      value:
        selectedData.data_disclosure_agreement?.dataSharingRestrictions
          ?.industrySector,
    },

    {
      name: t("dataAgreements.storageLocation"),
      value:
        selectedData.data_disclosure_agreement?.dataSharingRestrictions
          ?.storageLocation,
    },
    {
      name: t("dataAgreements.3pp"),
      value: selectedData.data_disclosure_agreement !== null && "False",
    },
    {
      name: t("issuanceHistory.blink"),
      value: "",
    },
    {
      name: t("issuanceHistory.didMyData"),
      value: "",
    },
  ];


  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <Container>
          <HeaderContainer>
            <Box pl={2}>
              <Typography color="#F3F3F6">{headerText}</Typography>
            </Box>
            <CloseIcon
              onClick={() => {
                setOpen(false);
                setOpenViewExchangeRecordModal(false);
              }}
              sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
            />
          </HeaderContainer>

          <DetailsContainer
            style={{
              paddingBottom: "4.5rem",
            }}
          >
            <Box m={1.5} style={titleAttrRestrictionStyle}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "solid 1px #dee2e6",
                  padding: "12px",
                }}
              >
                <Typography variant="subtitle2">{t("common.name")}</Typography>
                <Typography color="grey" variant="subtitle2">
                  {selectedData.data_disclosure_agreement.dataController.name}
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "solid 1px #dee2e6",
                  padding: "12px",
                }}
              >
                <Typography variant="subtitle2">
                  {t("signedAgreements.did")}
                </Typography>
                <Typography color="grey" variant="subtitle2">
                  {selectedData.data_disclosure_agreement.dataController.did}
                </Typography>
              </Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "solid 1px #dee2e6",
                  padding: "12px",
                }}
              >
                <Typography variant="subtitle2">
                  {t("signedAgreements.legalID")}
                </Typography>
                <Typography color="grey" variant="subtitle2">
                  {selectedData.data_disclosure_agreement.dataController.legalId}
                </Typography>
              </Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "solid 1px #dee2e6",
                  padding: "12px",
                }}
              >
                <Typography variant="subtitle2">
                  {t("signedAgreements.url")}
                </Typography>
                <Typography color="grey" variant="subtitle2">
                  {selectedData.data_disclosure_agreement.dataController.url}
                </Typography>
              </Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "none",
                  padding: "12px",
                }}
              >
                <Typography variant="subtitle2">
                  {t("signedAgreements.industrySector")}
                </Typography>
                <Typography
                  color="grey"
                  variant="subtitle2"
                  textAlign={"right"}
                >
                  {
                    selectedData.data_disclosure_agreement.dataController
                      .industrySector
                  }
                </Typography>
              </Box>
            </Box>

            <Box m={1.5} style={titleAttrRestrictionStyle}>
              {SSIpolicyDetailsForContainer?.map((policyDetail, index) => (
                <SSIPolicyDetailsBox
                  key={index}
                  selectedData={selectedData}
                  isLastAttribute={
                    index === SSIpolicyDetailsForContainer.length - 1
                  }
                  name={policyDetail.name}
                  value={policyDetail.value}
                />
              ))}
            </Box>

            <Box m={1.5} style={titleAttrRestrictionStyle}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "solid 1px #dee2e6",
                  padding: "12px",
                }}
              >
                <Typography variant="subtitle2">
                  {t("consentRecords.purpose")}
                </Typography>
                <Typography color="grey" variant="subtitle2">
                  {selectedData.data_disclosure_agreement.purpose}
                </Typography>
              </Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "none",
                  padding: "12px",
                }}
              >
                <Typography variant="subtitle2">
                  {t("signedAgreements.purposeDescription")}
                </Typography>
                <Typography
                  color="grey"
                  variant="subtitle2"
                  textAlign={"right"}
                >
                  {selectedData.data_disclosure_agreement.purposeDescription}
                </Typography>
              </Box>
            </Box>
            {selectedData.data_disclosure_agreement.proofChain &&
            selectedData.data_disclosure_agreement?.proofChain[0] ? (
              <>
                <Box m={1.5} style={titleAttrRestrictionStyle}>
                  <VerfiedDABoxForDDA selectedData={selectedData} />
                </Box>
                <Box m={1.5} style={titleAttrRestrictionStyle}>
                  <IndividualDecentralisedIdentifierBoxForDDA
                    selectedData={selectedData}
                  />
                </Box>
              </>
            ) : (
              <Box m={1.5} style={titleAttrRestrictionStyle}>
                <VerfiedDABoxForDDA selectedData={selectedData} />
              </Box>
            )}
          </DetailsContainer>
          <FooterContainer>
            <Button
              onClick={() => {
                setOpen(false);
                setOpenViewExchangeRecordModal(false);
              }}
              style={buttonStyle}
              sx={{
                marginLeft: "15px",
                color: "black",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              variant="outlined"
            >
              {t("common.close")}
            </Button>
          </FooterContainer>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
