import * as React from "react";
import { Dispatch, SetStateAction, useState, useEffect } from "react";

import { Drawer, Typography, Button, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
} from "./modalStyle";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import CommonDropdownWithDefaultSelectValue from "../dropdowns/commonDropdownWithDefaultSelectValue";
import { HttpServiceForDigitalWallet } from "../../service/digitalWalletHttpService";
import SnackbarComponent from "../notification";
import LoaderComponent from "../LoaderComponent";
import { isFormDataChanged } from "../../utils/isFormDataChanged";
import TrustAnchorDropdown from "../dropdowns/trustAnchorDropdown";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  versions: any;
  ledgerNetworks: any;
  infrastructureProvider: any;
  digitalWallet: any;
}

export default function ConfigureDigitalWalletBase(props: Props) {
  const {
    open,
    setOpen,
    headerText,
    versions,
    ledgerNetworks,
    infrastructureProvider,
    digitalWallet,
  } = props;
  const { t } = useTranslation("translation");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");
  const [openLoader, setOpenLoader] = useState(false);

  const { control, handleSubmit, formState, reset, watch } = useForm<any>({
    mode: "onChange",
  });

  useEffect(() => {
    let defaultValues = {
      dwLedgerNetworks: "",
      dwVersion: "",
      infrastructureProvider: "",
    };

    if (digitalWallet?.statusStr === "Deployed") {
      reset({
        dwLedgerNetworks: digitalWallet.ledgerID,
        dwVersion: digitalWallet.version,
        infrastructureProvider: digitalWallet.infrastructureProvider,
      });
    } else reset({ ...defaultValues });
  }, [open]);

  const deployedLedgerNetworks = watch("dwLedgerNetworks");
  const deployedVersion = watch("dwVersion");
  const deployedInfrastructureProvider = watch("infrastructureProvider");

  const onSubmit = (createdData: any) => {
    let payload = {
      version: createdData.dwVersion,
      ledgerId: createdData.dwLedgerNetworks,
      infrastructureProvider: createdData.infrastructureProvider,
    };

    if (
      digitalWallet?.statusStr === "Deployed" &&
      isFormDataChanged(formState)
    ) {
      setOpenLoader(true);
      HttpServiceForDigitalWallet.updateDigitalWalletVersion({
        version: createdData.dwVersion,
      })
        .then(() => {
          setOpen(false);
          setOpenLoader(false);
        })
        .catch((error) => {
          setOpenSnackBar(true);
          setError(error.response.data.errorDescription);
          setOpenLoader(false);
        });
    } else if (isFormDataChanged(formState)) {
      setOpenLoader(true);
      HttpServiceForDigitalWallet.deployDigitalWallet(payload)
        .then(() => {
          setOpen(false);
          setOpenLoader(false);
        })
        .catch((error) => {
          setOpenSnackBar(true);
          setError(error.response.data.errorDescription);
          setOpenLoader(false);
        });
    }
  };
  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SnackbarComponent
              open={openSnackBar}
              setOpen={setOpenSnackBar}
              message={error}
              topStyle={100}
            />
            <HeaderContainer>
              <Box pl={2}>
                <Typography color="#F3F3F6">{headerText}</Typography>
              </Box>
              <CloseIcon
                onClick={() => setOpen(false)}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <DetailsContainer>
              <Box p={1.5}>
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                  mb={1.5}
                >
                  <Typography variant="subtitle1" mb={0}>
                    {t("digitalWalletBase.deploymentStatus")}:
                  </Typography>
                  <Typography variant="subtitle1" mb={0}>
                    {digitalWallet?.statusStr?.toUpperCase()}
                  </Typography>
                </Box>
                <Typography variant="subtitle1" mb={0}>
                  {t("digitalWalletBase.dashboardRelease")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>

                <CommonDropdownWithDefaultSelectValue
                  dropdownValue={versions}
                  control={control}
                  nameOfSelect={"dwVersion"}
                  valueKey={"version"}
                  displayKey="version"
                  selectedValue={deployedVersion}
                />

                <Typography variant="subtitle1" mb={0} mt={1}>
                  {t("digitalWalletBase.defaultTrustAnchor")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>

                <TrustAnchorDropdown
                  dropdownValue={ledgerNetworks}
                  control={control}
                  nameOfSelect={"dwLedgerNetworks"}
                  valueKey={"id"}
                  displayKey={"str"}
                  digitalWallet={digitalWallet}
                  selectedValue={deployedLedgerNetworks}
                  toolTipMessage={t("digitalWalletBase.contactTrustAnchorForRegisteringPublicDID")}
                />

                <Typography variant="subtitle1" mb={0} mt={1}>
                  {t("digitalWalletBase.infrasctructureProvider")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>

                <CommonDropdownWithDefaultSelectValue
                  dropdownValue={infrastructureProvider}
                  control={control}
                  nameOfSelect={"infrastructureProvider"}
                  valueKey={"value"}
                  displayKey={"value"}
                  digitalWallet={digitalWallet}
                  selectedValue={deployedInfrastructureProvider}
                />
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => setOpen(false)}
                style={buttonStyle}
                sx={{
                  marginLeft: "15px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>

              <Button
                variant="outlined"
                style={buttonStyle}
                sx={{
                  cursor:
                    formState.isValid && isFormDataChanged(formState)
                      ? "pointer"
                      : "not-allowed",
                  marginRight: "20px",
                  color:
                    formState.isValid && isFormDataChanged(formState)
                      ? "black"
                      : "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                type="submit"
              >
                {t("common.confirm")}
              </Button>
            </FooterContainer>
          </form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
