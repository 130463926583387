import { ONBOARDINGENDPOINTS } from "../settings/onboardingSettings";
import { httpClient, getAuthenticatedHeaders } from "./HTTPService";

export const OnBoardingHttpService = {
  verifyEmail: async (payload: any): Promise<any> => {
    const config: object = {
      headers: {},
    };

    return httpClient.post(ONBOARDINGENDPOINTS.verifyEmail(), payload, config);
  },
  validatePhone: async (payload: any): Promise<any> => {
    const config: object = {
      headers: {},
    };

    return httpClient.post(
      ONBOARDINGENDPOINTS.validatePhone(),
      payload,
      config
    );
  },
  verifyPhone: async (payload: any): Promise<any> => {
    const config: object = {
      headers: {},
    };

    return httpClient.post(ONBOARDINGENDPOINTS.verifyPhone(), payload, config);
  },
  verifyOtp: async (payload: any): Promise<any> => {
    const config: object = {
      headers: {},
    };

    return httpClient.post(ONBOARDINGENDPOINTS.verifyOtp(), payload, config);
  },
  listOrgTypes: async (): Promise<any> => {
    const config: object = {
      headers: {},
    };

    return httpClient.get(ONBOARDINGENDPOINTS.listOrgTypes(), config);
  },
  listPricingInfo: async (): Promise<any> => {
    const config: object = {
      headers: {},
    };

    return httpClient.get(ONBOARDINGENDPOINTS.listPricingInfo(), config);
  },
  getServiceAgreement: async (): Promise<any> => {
    const config: object = {
      headers: {},
    };

    return httpClient.get(ONBOARDINGENDPOINTS.getServiceAgreement(), config);
  },
  registerOrganisationAdmin: async (payload: any): Promise<any> => {
    const config: object = {
      headers: {},
    };

    return httpClient.post(
      ONBOARDINGENDPOINTS.registerOrganisationAdmin(),
      payload,
      config
    );
  },
  addOrganisation: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };

    return httpClient.post(
      ONBOARDINGENDPOINTS.addOrganisation(),
      payload,
      config
    );
  },
  registerBilling: async (): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };

    return httpClient.post(ONBOARDINGENDPOINTS.registerBilling(), "", config);
  },
  addBillingAddress: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };

    return httpClient.post(
      ONBOARDINGENDPOINTS.addBillingAddress(),
      payload,
      config
    );
  },
  addBillingPaymentSource: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };

    return httpClient.post(
      ONBOARDINGENDPOINTS.addBillingPaymentSource(),
      payload,
      config
    );
  },
  addBillingSubscribePlan: async (
    payload: any,
    planId: string | undefined
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };

    return httpClient.post(
      ONBOARDINGENDPOINTS.addBillingSubscribePlan(planId),
      payload,
      config
    );
  },
  getOrganisationFeatures: async (): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };

    return httpClient.get(
      ONBOARDINGENDPOINTS.getOrganisationFeatures(),
      config
    );
  },
  updateOrganisationFeatures: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.put(
      ONBOARDINGENDPOINTS.updateOrganisationFeatures(),
      payload,
      config
    );
  },
};