import { useEffect } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import BreadCrumb from "../../components/Breadcrumbs";
import { Box } from "@mui/system";

import { useTranslation } from "react-i18next";
import CommonDropdownWithDefaultSelectValue from "../../components/dropdowns/commonDropdownWithDefaultSelectValue";
import { useForm } from "react-hook-form";
import EulaUpdates from "../../components/notificationsPageComponents/eulaUpdates";
import DataBreaches from "../../components/notificationsPageComponents/dataBreaches";
import OtherEventNotifications from "../../components/notificationsPageComponents/otherEventNotifications";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const Item = styled("div")({
  backgroundColor: "#fff",
  padding: "25px 30px 25px 30px",
  marginTop: "15px",
  height: "auto",
  borderRadius: 3,
  border: "1px solid #E1E1E1",
});

const Notifications = () => {
  const { t } = useTranslation("translation");
  const { control, reset, watch } = useForm<any>({
    mode: "onChange",
  });

  const NotificationTypes = [
    {
      label: t("notifications.eulaUpdates"),
      value: t("notifications.eulaUpdates"),
    },
    {
      label: t("notifications.dataBreaches"),
      value: t("notifications.dataBreaches"),
    },
    {
      label: t("notifications.otherEvents"),
      value: t("notifications.otherEvents"),
    },
  ];

  useEffect(() => {
    let defaultValues = {
      selectedNotificationType: "Data Breaches",
    };

    reset({ ...defaultValues });
  }, []);

  const selectedNotificationType = watch("selectedNotificationType");

  return (
    <Container>
      <BreadCrumb
        Link={t("sidebar.manageUsers")}
        Link2={t("sidebar.notifications")}
      />
      <HeaderContainer>
        <Typography variant="h6" fontWeight="bold">
          {t("notifications.pageHeading")}
        </Typography>
      </HeaderContainer>
      <Typography variant="body2" mt={1.25}>
        {t("notifications.pageDescription")}
      </Typography>

      <Box
        sx={{ display: { xs: "grid", sm: "flex" }, alignItems: "center" }}
        mt={2}
      >
        <Typography color="black" variant="body2">
          {t("notifications.notificationType")}:
        </Typography>

        <Box
          sx={{
            width: { xs: "70%", sm: "50%", md: "40%", lg: "30%" },
            marginLeft: { xs: "0px", sm: "30px" },
          }}
        >
          <CommonDropdownWithDefaultSelectValue
            dropdownValue={NotificationTypes}
            control={control}
            nameOfSelect={"selectedNotificationType"}
            valueKey={"value"}
            displayKey={"label"}
            selectedValue={selectedNotificationType}
          />
        </Box>
      </Box>

      <Item>
        {selectedNotificationType === "EULA Updates" && <EulaUpdates />}

        {selectedNotificationType === "Data Breaches" && <DataBreaches />}

        {selectedNotificationType === "Other Events" && <OtherEventNotifications />}
      </Item>
    </Container>
  );
};

export default Notifications;
