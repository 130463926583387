import { Tabs, Tab, styled, IconButton, Box, Tooltip } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const StyledTabs = styled(Tabs)({
  minHeight: "36px",
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiTabs-scrollButtons": {
    "&.Mui-disabled": {
      opacity: 0.3,
    },
  },
});

const StyledTab = styled(Tab)({
  minHeight: "36px",
  padding: "6px 16px",
  textTransform: "none",
  fontSize: "14px",
  fontWeight: 400,
  color: "#000",
  border: "1px solid #ccc",
  borderRadius: "4px 4px 0 0",
  "&.Mui-selected": {
    color: "#000",
    fontWeight: 500,
    backgroundColor: "#fff",
  },
  "&:not(.Mui-selected)": {
    backgroundColor: "#f0f0f0",
  },
});

const TruncatedLabel = ({ label }: any) => {
  const truncatedLabel = label?.length > 40 ? `${label.slice(0, 40)}...` : label;

  return (
    <Tooltip title={label} arrow placement="top">
      <span>{truncatedLabel}</span>
    </Tooltip>
  );
};

const CustomTabs = ({ tabs, activeTab, onTabChange, mode }: any) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <StyledTabs
        value={activeTab}
        onChange={onTabChange}
        variant="scrollable"
        scrollButtons="auto"
        ScrollButtonComponent={({ direction, ...other }) => (
          <IconButton {...other} size="small">
            {direction === "left" ? (
              <KeyboardArrowLeftIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        )}
      >
        {tabs.map((tab: any) => (
          <StyledTab
            key={tab.id}
            label={
              <TruncatedLabel
                label={
                  mode === "View"
                    ? tab?.credentialType[tab?.credentialType?.length - 1]
                    : tab?.matchedCredentials?.[0]?.credentialType[
                        tab?.matchedCredentials?.[0]?.credentialType?.length - 1
                      ]
                }
              />
            }
          />
        ))}
      </StyledTabs>
    </Box>
  );
};

export default CustomTabs;
