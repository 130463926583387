import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import { Drawer, Typography, Button, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
} from "../modalStyle";

import { useTranslation } from "react-i18next";
import EditorField from "../../jsonEditor/editorField";

interface Props {
  open: boolean;
  handleModalClose: any;
  headerText: string;
  handleChange: any;
  inputFieldName: string;
  inputPlaceHolderName: string;
  setValue: any;
  watchFields: any;
  setError: Dispatch<SetStateAction<string>>;
  setOpenEditPresentationDefinitionModal: any;
  setOpenSnackBar: any;
  mode: string;
  setIsValidJson: any;
  methods: any;
  schema: any;
  name: any;
  presentationDefinitionValue:any;
  error:any
}

export default function EwcIssuePresentationDefinitionModal(props: Props) {
  const { t } = useTranslation("translation");
  const {
    open,
    handleModalClose,
    headerText,
    handleChange,
    inputFieldName,
    setValue,
    watchFields,
    setError,
    setOpenEditPresentationDefinitionModal,
    setOpenSnackBar,
    mode,
    setIsValidJson,
    methods,
    schema,
    name,
    presentationDefinitionValue,
    error
  } = props;

  const onCloseModal = () => {
    handleModalClose(
      presentationDefinitionValue,
      setValue,
      watchFields,
      error,
      setOpenEditPresentationDefinitionModal,
      setOpenSnackBar,
      setIsValidJson,
      setError
    );
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <Container sx={{ width: "500px" }}>
          <form>
            <HeaderContainer>
              <Box pl={2}>
                <Box display={"flex"}>
                  <ChevronLeftIcon
                    onClick={onCloseModal}
                    sx={{
                      marginRight: 1,
                      cursor: "pointer",
                      color: "#F3F3F6",
                    }}
                  />
                  <Typography color="#F3F3F6">{headerText}</Typography>
                </Box>
              </Box>
              <CloseIcon
                onClick={onCloseModal}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <DetailsContainer style={{ paddingBottom: "80px" }}>
              <Box p={1.5}>
                <Typography variant="subtitle1" mt={1.5}>
                  {inputFieldName}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>

                <EditorField
                  name={name}
                  methods={methods}
                  handleChange={handleChange}
                  schema={JSON.stringify(schema)}
                  mode={mode}
                  setError={setError}
                />
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={onCloseModal}
                style={buttonStyle}
                sx={{
                  marginLeft: "10px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>
            </FooterContainer>
          </form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
