import { Box } from "@mui/system";
import { Tooltip, Typography } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

const PageHeaderComponent = ({
  headerName,
  tooltip,
  setOpenCreateModal,
  tableNumber,
  setSelectedFirstTable,
}: any) => {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          {headerName}
        </Typography>
        <Tooltip title={tooltip} placement="top">
          <AddCircleOutlineOutlinedIcon
            onClick={() => {
              tableNumber === "table1"
                ? setSelectedFirstTable(true)
                : setSelectedFirstTable(false);
              setOpenCreateModal(true);
            }}
            style={{ cursor: "pointer", marginLeft: "7px" }}
          />
        </Tooltip>
      </Box>
      <SettingsOutlinedIcon style={{ cursor: "not-allowed", color: "gray" }} />
    </Box>
  );
};

export default PageHeaderComponent;
