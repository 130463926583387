import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

interface Props {
  value: number;
  onChange: any;
  step: number;
  marks: any;
  min: number;
  max: number;
}

export default function DiscreteSlider(props: Props) {
  const { value, onChange, step, marks, min, max } = props;

  return (
    <Box sx={{ width: "100%", paddingLeft: 5, paddingRight: 5, height:"60px" }}>
      <Slider
        value={value}
        onChange={onChange}
        step={step}
        marks={marks}
        min={min}
        max={max}
        sx={{
          color: "grey",
          width: "100%",
          height: 0,
          "& .MuiSlider-thumb": {
            width: 10,
            height: 10,
          },
          "& .MuiSlider-rail": {
            height: 4,
          },
          //  '& .MuiSlider-mark': {
          //  for customising the mark of slider
          // },
        }}
      />
    </Box>
  );
}
