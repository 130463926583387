import { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import BreadCrumb from "../../components/Breadcrumbs";
import { Box } from "@mui/system";
import ConfigurePrivacyboard from "../../components/modals/configurePrivacyboard";
import { HttpService } from "../../service/HTTPService";
import { useTranslation } from "react-i18next";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const Item = styled("div")({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#fff",
  padding: "25px 30px 25px 30px",
  marginTop: "15px",
  justifyContent: "space-between",
  height: "auto",
  borderRadius: 3,
  border: "1px solid #E1E1E1",
});

const ItemGrid = styled("div")({
  display: "Grid",
  backgroundColor: "#fff",
  padding: "15px 30px 15px 30px",
  marginTop: "13px",
  height: "auto",
  borderRadius: 3,
  border: "1px solid #E1E1E1",
});

const buttonStyle = {
  height: 35,
  borderRadius: 1,
  border: "1px solid #DFDFDF",
  width: "auto",
  paddingLeft: "50px",
  paddingRight: "50px",
};

const Privacyboard = () => {
  const { t } = useTranslation("translation");
  const [openPrivacyBoardModal, setOpenPrivacyBoardModal] = useState(false);
  const [privacyBoardDetails, setPrivacyBoardDetails] = useState<any>();
  const [versions, setVersions] = useState();

  useEffect(() => {
    HttpService.getPrivacyBoard().then((res) => {
      setPrivacyBoardDetails(res.data);
    });
    HttpService.getPrivacyBoardVersion().then((res) => {
      setVersions(res.data.versions);
    });
  }, [openPrivacyBoardModal]);

  return (
    <Container>
      <BreadCrumb Link={t("sidebar.privacyDashboard")} />
      <HeaderContainer>
        <Typography variant="h6" fontWeight="bold">
          {t("sidebar.privacyDashboard")}
        </Typography>
      </HeaderContainer>
      <Typography variant="body2" mt={1.25}>
        {t("privacyDashboard.pageDescription")}
      </Typography>

      <Item>
        <Typography color="black" variant="subtitle1" fontWeight="bold">
          {t("privacyDashboard.currentStatus")}
        </Typography>
        <Box sx={{ display: { xs: "grid", sm: "flex" }, alignItems: "center" }}>
          <Typography color="black" variant="subtitle1" fontWeight="bold">
            {privacyBoardDetails?.statusStr?.toUpperCase()}
          </Typography>

          <Button
            style={buttonStyle}
            variant="outlined"
            sx={{
              marginLeft: { sx: 0, md: "20px" },
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
              color: "black",
            }}
            onClick={() => setOpenPrivacyBoardModal(true)}
          >
            {t("privacyDashboard.configure")}
          </Button>
        </Box>
      </Item>
      <ItemGrid>
        <Typography color="black" variant="subtitle1" fontWeight="bold">
          {t("privacyDashboard.privacyDashboardSettings")}
        </Typography>
        <Grid container mt={1.5}>
          <Grid item lg={3} md={5} sm={5} xs={12}>
            <Typography color="black" variant="body2">
              {t("privacyDashboard.deployedVersion")}:
            </Typography>
          </Grid>
          <Grid item lg={5} md={7} sm={7} xs={12}>
            <Typography color="grey" variant="body2">
              {privacyBoardDetails?.version ? privacyBoardDetails?.version : ""}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={3} md={5} sm={5} xs={12}>
            <Typography color="black" variant="body2">
              {t("privacyDashboard.deployedDomainAddress")}:
            </Typography>
          </Grid>
          <Grid item lg={5} md={7} sm={7} xs={12}>
            <a
              style={{
                color: "blue",
                fontSize: "14px",
                textDecoration: "none",
              }}
              href={
                privacyBoardDetails?.hostname
                  ? privacyBoardDetails.hostname.includes("http://") ||
                    privacyBoardDetails.hostname.includes("https://")
                    ? privacyBoardDetails.hostname.replace(
                        /^(?:https?:\/\/)?(?:www\.)?/i,
                        ""
                      )
                    : `//${privacyBoardDetails.hostname}`
                  : ""
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {privacyBoardDetails?.hostname
                ? privacyBoardDetails?.hostname
                : ""}
            </a>
          </Grid>
        </Grid>
      </ItemGrid>

      {/* Modals */}

      <ConfigurePrivacyboard
        open={openPrivacyBoardModal}
        setOpen={setOpenPrivacyBoardModal}
        headerText={"Privacy Dashboard Configuration"}
        versions={versions}
        privacyBoardDetails={privacyBoardDetails}
      />
    </Container>
  );
};

export default Privacyboard;
