import { DIGITALWALLETENDPOINTS } from "../settings/digitalWalletSetting";
import { getAuthenticatedHeaders, httpClient } from "./HTTPService";
import {
  convertConnectionsForClient,
  convertInvoiceForClientPurpose,
  convertIssuanceHistoryForClient,
  convertUserRequestForClientPurpose,
  convertVerificationsHistoryForClient,
} from "./adapter";
import { AnyRecord } from "dns";

export const HttpServiceForDigitalWallet = {
  getPredefinedSchemaValues: async (): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.get(
      DIGITALWALLETENDPOINTS.getPredefinedSchemaValues(),
      config
    );
  },
  getSchemaValuesByExistingId: async (searchValue: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.get(
      DIGITALWALLETENDPOINTS.getSchemaValuesByExistingId(searchValue),
      config
    );
  },

  createDataVerifierUsers: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .post(DIGITALWALLETENDPOINTS.createDataVerifierUsers(), payload, config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },

  listDigitalWalletDataAgreementsWithMethodOfUseFilter: async (
    offsetValue: number,
    pageSize: number,
    methodOfUse: string,
    revisionId: string | undefined,
    includeRevisions: boolean | string,
    dexProfile: string | undefined
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(
        DIGITALWALLETENDPOINTS.listDigitalWalletDataAgreementsWithMethodOfUseFilter(
          offsetValue,
          pageSize,
          methodOfUse,
          revisionId,
          includeRevisions,
          dexProfile
        ),
        config
      )
      .then((res) => {
        const dataAgreements: any = res.data;
        return dataAgreements;
      });
  },

  //  Data verifiers api
  listDataVerifierUsers: async (
    offsetValue: number,
    pageSize: number
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(
        DIGITALWALLETENDPOINTS.listDataVerifierUsers(offsetValue, pageSize),
        config
      )
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  sendMagicLink: async (id: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .post(DIGITALWALLETENDPOINTS.sendMagicLink(id), "", config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  deleteDataVerifierUsers: async (id: string | undefined): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .delete(DIGITALWALLETENDPOINTS.deleteDataVerifierUsers(id), config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  updateDataVerifierUsers: async (payload: any, id: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .put(DIGITALWALLETENDPOINTS.updateDataVerifierUsers(id), payload, config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },

  // Digital wallets
  getDigitalWalletVersions: async (): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(DIGITALWALLETENDPOINTS.getDigitalWalletVersions(), config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  getLedgerNetworks: async (): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(DIGITALWALLETENDPOINTS.getLedgerNetworks(), config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  deployDigitalWallet: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .post(DIGITALWALLETENDPOINTS.deployDigitalWallet(), payload, config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  getDigitalWallet: async (): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(DIGITALWALLETENDPOINTS.getDigitalWallet(), config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  updateDigitalWalletVersion: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .put(DIGITALWALLETENDPOINTS.updateDigitalWalletVersion(), payload, config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  getOrganisationalIdentifier: async (): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(DIGITALWALLETENDPOINTS.getOrganisationalIdentifier(), config)
      .then((res) => {
        return res.data;
      });
  },

  // connections
  generateQrCodeForOrganisations: async (): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.post(
      DIGITALWALLETENDPOINTS.generateQrCodeForOrganisations(),
      "",
      config
    );
  },
  listConnections: async (
    offsetValue: number,
    pageSize: number
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(
        DIGITALWALLETENDPOINTS.listConnections(offsetValue, pageSize),
        config
      )
      .then((res) => {
        const connections: any = res.data;
        return convertConnectionsForClient(connections);
      });
  },
  deleteConnections: async (id: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.delete(
      DIGITALWALLETENDPOINTS.deleteConnections(id),
      config
    );
  },
  receiveConnection: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.post(
      DIGITALWALLETENDPOINTS.receiveConnection(),
      payload,
      config
    );
  },

  // credentials
  sendOfferForCredentials: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.post(
      DIGITALWALLETENDPOINTS.sendOfferForCredentials(),
      payload,
      config
    );
  },
  listIssueCredentialHistoryRecords: async (
    offsetValue: number,
    pageSize: number,
    id: AnyRecord
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(
        DIGITALWALLETENDPOINTS.listIssueCredentialHistoryRecords(
          offsetValue,
          pageSize,
          id
        ),
        config
      )
      .then((res) => {
        const CredentialHistoryRecords: any = res.data;
        return convertIssuanceHistoryForClient(CredentialHistoryRecords);
      });
  },
  deleteConnectionsHistory: async (credentialExchangeId: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.delete(
      DIGITALWALLETENDPOINTS.deleteConnectionsHistory(credentialExchangeId),
      config
    );
  },

  // Verifications
  OfferForVerificationsRequest: async (payload: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.post(
      DIGITALWALLETENDPOINTS.OfferForVerificationsRequest(),
      payload,
      config
    );
  },
  generateQrCodeForDataAgreement: async (id: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.post(
      DIGITALWALLETENDPOINTS.generateQrCodeForDataAgreement(),
      { dataAgreementId: id },
      config
    );
  },
  generateFirebaseDynamicLinkForDataAgreementQrCode: async (
    dataAgreementId: any,
    qrCodeId: any
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.post(
      DIGITALWALLETENDPOINTS.generateFirebaseDynamicLinkForDataAgreementQrCode(
        qrCodeId
      ),
      { dataAgreementId: dataAgreementId },
      config
    );
  },
  listIssueVerifiedHistoryRecords: async (
    offsetValue: number,
    pageSize: number,
    id: AnyRecord
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(
        DIGITALWALLETENDPOINTS.listIssueVerifiedHistoryRecords(
          offsetValue,
          pageSize,
          id
        ),
        config
      )
      .then((res) => {
        const records: any = res.data;
        return convertVerificationsHistoryForClient(records);
      });
  },
  deleteVerificationsHistory: async (
    presentationExchangeId: any
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.delete(
      DIGITALWALLETENDPOINTS.deleteVerificationsHistory(presentationExchangeId),
      config
    );
  },

  // billing
  getInvoices: async (): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(DIGITALWALLETENDPOINTS.getInvoices(), config)
      .then((res) => {
        return convertInvoiceForClientPurpose(res.data);
      });
  },
  readBilling: async (): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(DIGITALWALLETENDPOINTS.readBilling(), config)
      .then((res) => {
        return res.data;
      });
  },
  getSubscribedPlanDetails: async (): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(DIGITALWALLETENDPOINTS.getSubscribedPlanDetails(), config)
      .then((res) => {
        return res.data;
      });
  },
  terminateBillingPlan: async (plan: string | undefined): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .delete(DIGITALWALLETENDPOINTS.terminateBillingPlan(plan), config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
  createSubscribePlan: async (
    payload: any,
    plan: string | undefined
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient.post(
      DIGITALWALLETENDPOINTS.createSubscribePlan(plan),
      payload,
      config
    );
  },

  // user requests
  listDataRequest: async (
    offsetValue: number,
    pageSize: number,
    status: string
  ): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .get(
        DIGITALWALLETENDPOINTS.listDataRequest(offsetValue, pageSize, status),
        config
      )
      .then((res) => {
        return convertUserRequestForClientPurpose(res.data);
      });
  },
  updateDataRequest: async (payload: any, id: any): Promise<any> => {
    const config: object = {
      headers: { ...getAuthenticatedHeaders() },
    };
    return httpClient
      .put(DIGITALWALLETENDPOINTS.updateDataRequest(id), payload, config)
      .then((res) => {
        const data: any = res.data;

        return data;
      });
  },
};
