import React, {
  useState,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import CSS from "csstype";

import { Box, Drawer, Typography, Avatar, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Banner from "../../assets/OrganisationDefaultBanner.jpeg";
import logo from "../../assets/OrganisationDefaultLogo.png";

import {
  Container,
  HeaderContainer,
  BannerContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  disabledButtonstyle,
} from "./modalStyle";
import { OrganizationDetailsCRUDContext } from "../../contexts/organizationDetailsCrud";
import { useForm, FormProvider } from "react-hook-form";
import { HttpService } from "../../service/HTTPService";
import { useTranslation } from "react-i18next";
import LoaderComponent from "../LoaderComponent";
import SelectDAforDDA from "../dropdowns/selectDAforDDA";
import DataAgreementModal from "./dataAgreementModal";
import { LanguageFormControl } from "../dda/languageSelectionDropdown";
import { useGetList } from "react-admin";
import { HttpServiceForDDA } from "../../service/ddaHttpService";
import SnackbarComponent from "../notification";

const tableCellStyle: CSS.Properties = {
  fontWeight: "normal",
  fontSize: "14px",
  borderTop: "solid 1px #dee2e6",
  textAlign: "left",
  borderRight: "solid 1px #dee2e6",
  fontFamily: "Inter,Helvetica,Arial,sans-serif",
};

const inputDataConfigStyle = {
  color: "#495057",
  border: "none",
  outline: "none",
  fontSize: "14px",
  width: "100%",
  backgroundColor: "transparent",
  fontFamily: "Inter,Helvetica,Arial,sans-serif",
};

const inputStyle = {
  width: "100%",
  border: "1",
  outline: "none",
  fontSize: "14px",
  color: "#495057",
  borderWidth: 0,
  borderRadius: 0,
  backgroundColor: "#FFFF",
  borderBottom: "2px solid #DFE0E1",
};

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  mode: string;
  selectedDDA: any;
  successCallback: any;
}

export default function CreateAndReadDDA(props: Props) {
  const { open, setOpen, mode, selectedDDA, successCallback } = props;
  const { t } = useTranslation("translation");
  const { data: listAllRecords } = useGetList("dataDisclosureAgreements");

  const { organisationDetails, logoImageBase64, coverImageBase64 } = useContext(
    OrganizationDetailsCRUDContext
  );

  const [listAllDataAgreeements, setListDataAgreements] = useState<any>();
  const [filteredDataAgreements, setFilteredDataAgreements] = useState<any>();
  const [selectedDAforViewDA, setSelectedDAforViewDA] = useState<any>(null);
  const [openDAModal, setOpenDaModal] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");

  const [openLoader, setOpenLoader] = useState(false);

  const methods = useForm({
    mode: "onChange",
  });

  const { control, register, reset } = methods;

  useEffect(() => {
    HttpService.listDataAgreements(0, 25000, "complete", "", true).then(
      (response) => {
        if (listAllRecords && listAllRecords.length > 0) {
          const templateIds = listAllRecords.map(
            (record) => record.template_id
          );
          const filteredDataAgreements = response.dataAgreements.filter(
            (dataAgreement: any) => !templateIds.includes(dataAgreement?.id)
          );
          setListDataAgreements(response.dataAgreements);
          setFilteredDataAgreements(filteredDataAgreements);
        }
      }
    );
  }, [open]);

  useEffect(() => {
    let defaultValues = {
      url: "",
      jurisdiction: "",
      industrySector: "",
      dataRetentionPeriodDays: "1",
      geographicRestriction: "",
      storageLocation: "",
      dataControllerLegalId: "9845003DEB6IBEF57A85",
      agreementPeriod: "1",
      language: "english",
      connectedDA: "",
    };
    if (mode === "Read") {
      reset({
        url: selectedDDA?.data_disclosure_agreement.dataSharingRestrictions
          .policyUrl,
        jurisdiction:
          selectedDDA?.data_disclosure_agreement.dataSharingRestrictions
            .jurisdiction,
        industrySector:
          selectedDDA?.data_disclosure_agreement.dataSharingRestrictions
            .industrySector,
        dataRetentionPeriodDays:
          selectedDDA?.data_disclosure_agreement.dataSharingRestrictions
            .dataRetentionPeriod,
        geographicRestriction:
          selectedDDA?.data_disclosure_agreement.dataSharingRestrictions
            .geographicRestriction,
        storageLocation:
          selectedDDA?.data_disclosure_agreement.dataSharingRestrictions
            .storageLocation,
        dataControllerLegalId: "9845003DEB6IBEF57A85",
        agreementPeriod: "1",
        language: "english",
        connectedDA: selectedDDA?.template_id,
      });
    } else if (mode === "Create") reset({ ...defaultValues });
  }, [open, mode]);

  const onSave = (createdData: any) => {
    if (mode !== "Read") {
      setOpenLoader(true);

      HttpServiceForDDA.addDataDisclosureAgreements("payload")
        .then(() => {
          setOpenLoader(false);
          successCallback();
          methods.reset();
          setOpen(false);
          setSelectedDAforViewDA(null);
        })
        .catch((error: any) => {
          setOpenLoader(false);
          let errorDescription = error.response.data.errorDescription;
          setError(errorDescription);
          setOpenSnackBar(true);
        });
    }
  };

  const onPublish = (createdData: any) => {
    if (mode !== "Read") {
      setOpenLoader(true);

      HttpServiceForDDA.addDataDisclosureAgreements("payload")
        .then(() => {
          setOpenLoader(false);
          successCallback();
          methods.reset();
          setOpen(false);
          setSelectedDAforViewDA(null);
        })
        .catch((error: any) => {
          setOpenLoader(false);
          let errorDescription = error.response.data.errorDescription;
          setError(errorDescription);
          setOpenSnackBar(true);
        });
    }
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container style={{ overflow: openLoader ? "hidden" : "auto" }}>
          <FormProvider {...methods}>
            <SnackbarComponent
              open={openSnackBar}
              setOpen={setOpenSnackBar}
              message={error}
            />
            <form>
              <HeaderContainer>
                <Box pl={2} style={{ overflow: "hidden" }}>
                  <Typography
                    style={{
                      color: "#F3F3F6",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {mode === "Read"
                      ? `${t("dataDisclosureAgreements.viewDDA")}:${
                          selectedDDA?.data_disclosure_agreement?.purpose
                        }`
                      : t("dataDisclosureAgreements.addDDA")}{" "}
                  </Typography>

                  <Typography color="#F3F3F6">
                    {mode === "Read" && selectedDDA?.template_id}
                  </Typography>
                </Box>
                <CloseIcon
                  onClick={() => {
                    setOpen(false),
                      methods.reset(),
                      setSelectedDAforViewDA(null);
                  }}
                  sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
                />
              </HeaderContainer>
              <BannerContainer>
                <Box
                  style={{ height: "150px", width: "100%" }}
                  component="img"
                  alt="Banner"
                  src={
                    coverImageBase64
                      ? `data:image/jpeg;charset=utf-8;base64,${coverImageBase64}`
                      : Banner
                  }
                />
              </BannerContainer>
              <Box sx={{ marginBottom: "60px" }}>
                <Avatar
                  src={
                    logoImageBase64
                      ? `data:image/jpeg;charset=utf-8;base64,${logoImageBase64}`
                      : logo
                  }
                  style={{
                    position: "absolute",
                    marginLeft: 50,
                    marginTop: "-65px",
                    width: "110px",
                    height: "110px",
                    border: "solid white 6px",
                  }}
                />
              </Box>
              <DetailsContainer>
                <Box p={1.5}>
                  <Typography variant="h6" fontWeight="bold">
                    {organisationDetails.name}
                  </Typography>
                  <Typography color="#9F9F9F">
                    {organisationDetails.location}
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    {t("common.overView")}
                  </Typography>
                  <Typography
                    color="#9F9F9F"
                    mt={1}
                    variant="body2"
                    sx={{ wordWrap: "breakWord" }}
                  >
                    {organisationDetails.description}
                  </Typography>

                  <Typography mt={1.3} variant="subtitle1">
                    {t("dataDisclosureAgreements.connectedDA")}
                    <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                  </Typography>

                  <SelectDAforDDA
                    dropdownValue={
                      mode === "Read"
                        ? listAllDataAgreeements
                        : filteredDataAgreements
                    }
                    control={control}
                    nameOfSelect={"connectedDA"}
                    valueKey={"id"}
                    displayKey={"purpose"}
                    selectedValue={selectedDDA?.template_id}
                    label={t("dataDisclosureAgreements.selectADA")}
                    setSelectedDAforViewDA={setSelectedDAforViewDA}
                    mode={mode}
                  />

                  <Typography
                    style={{
                      fontSize: "14px",
                      textDecoration: "underline",
                      color: selectedDAforViewDA === null ? "grey" : "blue",
                      cursor:
                        selectedDAforViewDA === null
                          ? "not-allowed"
                          : "pointer",
                    }}
                    onClick={() =>
                      selectedDAforViewDA !== null && setOpenDaModal(true)
                    }
                  >
                    ({t("dataAgreements.viewDA")})
                  </Typography>

                  <LanguageFormControl />

                  <Typography mt={1.3} mb={1.3} variant="subtitle1">
                    {t("dataDisclosureAgreements.dataControllerLegalID")}
                  </Typography>
                  <input
                    style={{
                      ...inputStyle,
                      cursor: "not-allowed",
                    }}
                    type="text"
                    {...register("dataControllerLegalId", { required: true })}
                    autoComplete="off"
                    disabled
                  />

                  <Typography mt={1.3} mb={1.3} variant="subtitle1">
                    {t("dataDisclosureAgreements.agreementPeriod")}
                  </Typography>
                  <input
                    style={{
                      ...inputStyle,
                      cursor: "not-allowed",
                    }}
                    type="text"
                    {...register("agreementPeriod", { required: true })}
                    autoComplete="off"
                    disabled
                  />

                  <Box mt={2}>
                    <Typography variant="subtitle1">
                      {" "}
                      {t(
                        "dataDisclosureAgreements.dataSharingRestrictions"
                      )}{" "}
                    </Typography>
                    <table
                      style={{
                        border: "solid 1px #dee2e6",
                        width: "100%",
                        maxWidth: "100%",
                        marginBottom: "5rem",
                        marginTop: ".5rem",
                      }}
                    >
                      <tbody>
                        <tr>
                          <th
                            style={{ ...tableCellStyle, borderTop: 0 }}
                            scope="row"
                          >
                            {t("common.policyUrl")}
                          </th>

                          <td
                            style={{
                              ...tableCellStyle,
                              borderTop: 0,
                              borderRight: 0,
                            }}
                          >
                            <input
                              autoComplete="off"
                              type="text"
                              disabled
                              style={{
                                ...inputDataConfigStyle,
                                cursor: "not-allowed",
                              }}
                              {...register("url", {
                                required: true,
                                minLength: 1,
                              })}
                            />
                          </td>
                        </tr>

                        <tr>
                          <th style={tableCellStyle} scope="row">
                            {t("dataAgreements.jurisdiction")}
                          </th>

                          <td
                            style={{
                              ...tableCellStyle,
                              borderRight: 0,
                              fontSize: 12,
                            }}
                          >
                            <input
                              autoComplete="off"
                              type="text"
                              disabled
                              style={{
                                ...inputDataConfigStyle,
                                cursor: "not-allowed",
                              }}
                              {...register("jurisdiction", {
                                required: true,
                                minLength: 1,
                              })}
                            />
                          </td>
                        </tr>

                        <tr>
                          <th style={tableCellStyle} scope="row">
                            {t("dataAgreements.industryScope")}
                          </th>

                          <td
                            style={{
                              ...tableCellStyle,
                              borderRight: 0,
                              fontSize: 12,
                            }}
                          >
                            <input
                              autoComplete="off"
                              type="text"
                              {...register("industrySector", {
                                required: true,
                                minLength: 1,
                              })}
                              disabled
                              style={{
                                ...inputDataConfigStyle,
                                cursor: "not-allowed",
                              }}
                            />
                          </td>
                        </tr>

                        <tr>
                          <th style={tableCellStyle} scope="row">
                            {t("dataAgreements.storageLocation")}
                          </th>

                          <td
                            style={{
                              ...tableCellStyle,
                              borderRight: 0,
                              fontSize: 12,
                            }}
                          >
                            <input
                              autoComplete="off"
                              type="text"
                              disabled
                              style={{
                                ...inputDataConfigStyle,
                                cursor: "not-allowed",
                              }}
                              {...register("storageLocation", {
                                required: true,
                                minLength: 1,
                              })}
                            />
                          </td>
                        </tr>

                        <tr>
                          <th style={tableCellStyle} scope="row">
                            {t("dataAgreements.retentionPeriod")}
                          </th>

                          <td
                            style={{
                              ...tableCellStyle,
                              borderRight: 0,
                              fontSize: 12,
                            }}
                          >
                            <input
                              autoComplete="off"
                              disabled
                              style={{
                                ...inputDataConfigStyle,
                                cursor: "not-allowed",
                              }}
                              {...register("dataRetentionPeriodDays", {
                                required: true,
                                minLength: 1,
                              })}
                            />
                          </td>
                        </tr>

                        <tr>
                          <th style={tableCellStyle}>
                            {t("dataAgreements.geographicRestriction")}
                          </th>

                          <td style={{ ...tableCellStyle, borderRight: 0 }}>
                            <input
                              autoComplete="off"
                              disabled
                              style={{
                                ...inputDataConfigStyle,
                                cursor: "not-allowed",
                              }}
                              {...register("geographicRestriction", {
                                required: true,
                                minLength: 1,
                              })}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Box>
                </Box>
              </DetailsContainer>
              <FooterContainer>
                <Button
                  style={
                    methods.formState.isValid
                      ? buttonStyle
                      : disabledButtonstyle
                  }
                  sx={{
                    cursor:
                      mode !== "Read" && methods.formState.isValid
                        ? "pointer"
                        : "not-allowed",
                    marginLeft: "20px",
                    color:
                      mode !== "Read" && methods.formState.isValid
                        ? "black"
                        : "#6D7676",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                    },
                  }}
                  variant="outlined"
                  onClick={methods.handleSubmit(onPublish)}
                >
                  {t("dataAgreements.publish")}
                </Button>
                <Button
                  onClick={methods.handleSubmit(onSave)}
                  variant="outlined"
                  style={
                    methods.formState.isValid
                      ? buttonStyle
                      : disabledButtonstyle
                  }
                  sx={{
                    cursor:
                      mode !== "Read" && methods.formState.isValid
                        ? "pointer"
                        : "not-allowed",
                    marginRight: "20px",
                    color:
                      mode !== "Read" && methods.formState.isValid
                        ? "black"
                        : "#6D7676",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                    },
                  }}
                >
                  {t("common.save")}
                </Button>
              </FooterContainer>
            </form>
          </FormProvider>

          <DataAgreementModal
            open={openDAModal}
            setOpen={setOpenDaModal}
            mode={"Read"}
            resourceName="dda"
            selectededDataAgreementFromDataAgreement={selectedDAforViewDA}
          />
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
