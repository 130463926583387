import VerticalStepper from "../../components/onboarding/stepper";
import OnboardingAppBar from "../../components/layout/onboardAppbar";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import Footer from "../../components/layout/PageFooter";
import { configStore } from "../../store/configStore";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import OrganisationAdministratorInformation from "../../components/onboarding/organisationAdministratorInformation";
import MobileVerification from "../../components/onboarding/mobileVerification";
import SnackbarComponent from "../../components/notification";
import OrganisationDetails from "../../components/onboarding/organisationDetails";
import PackageDetails from "../../components/onboarding/packageDetails";
import { OnBoardingHttpService } from "../../service/onboardingService";
import { useOnBoardingStore } from "../../store/onBoardingStore";
import PaymentInformation from "../../components/onboarding/paymentInformation";
import BillingAddress from "../../components/onboarding/billingAddress";
import ServiceAgreement from "../../components/onboarding/serviceAgreement";

const Container = styled("div")(({ theme }) => ({
  margin: "100px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const StepperContainer = styled("div")(({ theme }) => ({
  padding: "0 2rem 0 3rem",
  [theme.breakpoints.down("sm")]: {
    margin: "50px 0 10px 0",
  },
}));

const DetailsContainer = styled("div")(() => ({
  display: "grid",
  border: "1px solid #DFE0E1",
  borderRadius: 3,
  padding: "12px",
  width: "100%",
}));

const Onboarding = () => {
  const version = configStore.appVersion;
  const { t } = useTranslation("translation");
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [successMessage, setSuccessMessage] = useState("")

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const steps = [
    t("onboarding.orgAdminInformation"),
    t("onboarding.mobileVerification"),
    t("onboarding.organisationInformation"),
    t("onboarding.packageSelection"),
    t("onboarding.paymentInformation"),
    t("onboarding.billingAddress"),
    t("onboarding.serviceAgreement"),
  ];

  useEffect(() => {
    OnBoardingHttpService.listPricingInfo().then((res) => {
      useOnBoardingStore.getState().updatePricingInfoFromApi(res.data);
    });
  }, []);

  return (
    <Container>
      <OnboardingAppBar />
      <SnackbarComponent
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={error}
        topStyle={100}
        successMessage={successMessage}
      />

      <Grid container mt={1.5}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <StepperContainer>
            <VerticalStepper steps={steps} activeStep={activeStep} />
          </StepperContainer>
        </Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={12}
          xs={12}
          sx={{ marginTop: { xs: "20px", sm: "20px", md: 0 } }}
        >
          <DetailsContainer>
            {activeStep === 0 && (
              <OrganisationAdministratorInformation
                handleNext={handleNext}
                setError={setError}
                setOpenSnackBar={setOpenSnackBar}
              />
            )}
            {activeStep === 1 && (
              <MobileVerification
                handleBack={handleBack}
                handleNext={handleNext}
                setError={setError}
                setOpenSnackBar={setOpenSnackBar}
                setSuccessMessage={setSuccessMessage}
              />
            )}

            {activeStep === 2 && (
              <OrganisationDetails
                setActiveStep={setActiveStep}
                handleNext={handleNext}
                setError={setError}
                setOpenSnackBar={setOpenSnackBar}
              />
            )}

            {activeStep === 3 && (
              <PackageDetails
                handleBack={handleBack}
                handleNext={handleNext}
                setError={setError}
                setOpenSnackBar={setOpenSnackBar}
              />
            )}

            {activeStep === 4 && (
              <PaymentInformation
                handleBack={handleBack}
                handleNext={handleNext}
                setError={setError}
                setOpenSnackBar={setOpenSnackBar}
              />
            )}

            {activeStep === 5 && (
              <BillingAddress
                setActiveStep={setActiveStep}
                handleNext={handleNext}
                setError={setError}
                setOpenSnackBar={setOpenSnackBar}
              />
            )}

            {activeStep === 6 && (
              <ServiceAgreement
                handleBack={handleBack}
                setError={setError}
                setOpenSnackBar={setOpenSnackBar}
              />
            )}
          </DetailsContainer>
        </Grid>
      </Grid>
      <Footer version={version} />
    </Container>
  );
};

export default Onboarding;
