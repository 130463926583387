import { Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { styled } from "@mui/material/styles";
import { useDigitalWalletStore } from "../../../store/digitalWalletStore";
import { useNavigate } from "react-router-dom";
import { HttpServiceForDigitalWallet } from "../../../service/digitalWalletHttpService";
import { OnBoardingHttpService } from "../../../service/onboardingService";

const buttonStyle = {
  height: 30,
  width: 150,
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const ButtonContainer = styled("div")(() => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
}));

interface Props {
  handleBack: any;
  setError: Dispatch<SetStateAction<string>>;
  setOpenSnackBar: Dispatch<SetStateAction<boolean>>;
  setSuccessMessage: Dispatch<SetStateAction<string>>;
}

const DetailsContainer = styled("div")(({ theme }) => ({
  display: "grid",
  border: "1px solid #DFE0E1",
  borderRadius: 3,
  padding: "12px",
  width: "50%",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const ReviewAndConfirmOrder = (props: Props) => {
  const { handleBack, setSuccessMessage, setOpenSnackBar } = props;
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const [showOkButton, setShowOkButton] = useState(false);
  const [pricingInfoFromApi, setPricingInforFromApi] = useState<any>();

  const selectedPackageFromPackageSelector = useDigitalWalletStore(
    (state) => state.selectedPackageFromPackageSelector
  );

  const selectedCommittedUsage = useDigitalWalletStore(
    (state) => state.selectedCommittedUsage
  );

  useEffect(() => {
    OnBoardingHttpService.listPricingInfo().then((res) => {
      setPricingInforFromApi(res.data);
    });
  }, []);

  const packageName = () => {
    if (selectedPackageFromPackageSelector === "freetrial") {
      return `FREE TRIAL PACKAGE`;
    } else if (selectedPackageFromPackageSelector === "starter") {
      return "STARTER PACKAGE";
    } else if (selectedPackageFromPackageSelector === "payperuser") {
      return "PAY PER USER PACKAGE";
    } else if (selectedPackageFromPackageSelector === "freetrialverifier") {
      return "FREE TRIAL VERIFIER PACKAGE";
    } else if (selectedPackageFromPackageSelector === "starterverifier") {
      return "STARTER VERIFIER PACKAGE";
    } else if (selectedPackageFromPackageSelector === "premiumverifier") {
      return "PREMIUM VERIFIER PACKAGE";
    }
  };

  const packagePrice = () => {
    if (selectedPackageFromPackageSelector === "freetrial") {
      return `- SEK ${pricingInfoFromApi?.freeTrial?.price}`;
    } else if (selectedPackageFromPackageSelector === "starter") {
      return `- SEK ${pricingInfoFromApi?.starter?.price}`;
    } else if (selectedPackageFromPackageSelector === "payperuser") {
      // instead of price user and time commit will be shown
      return null;
    } else if (selectedPackageFromPackageSelector === "freetrialverifier") {
      return `- SEK ${pricingInfoFromApi?.freeTrialVerifier?.price}`;
    } else if (selectedPackageFromPackageSelector === "starterverifier") {
      return `- SEK ${pricingInfoFromApi?.starterVerifier?.price}`;
    } else if (selectedPackageFromPackageSelector === "premiumverifier") {
      return `- SEK ${pricingInfoFromApi?.premiumVerifier?.price}`;
    }
  };

  const packageValidity = () => {
    if (selectedPackageFromPackageSelector === "freetrial") {
      return `${pricingInfoFromApi?.freeTrial?.validity} Day`;
    } else if (selectedPackageFromPackageSelector === "starter") {
      return `${pricingInfoFromApi?.starter?.validity / 365} Year`;
    } else if (selectedPackageFromPackageSelector === "payperuser") {
      // instead of validity user and time commit will be shown
      return null;
    } else if (selectedPackageFromPackageSelector === "freetrialverifier") {
      return `${pricingInfoFromApi?.freeTrialVerifier?.validity} Days`;
    } else if (selectedPackageFromPackageSelector === "starterverifier") {
      return `${pricingInfoFromApi?.starterVerifier?.validity} Days`;
    } else if (selectedPackageFromPackageSelector === "premiumverifier") {
      return `${pricingInfoFromApi?.premiumVerifier?.validity} Days`;
    }
  };

  const handleSubmit = () => {
    HttpServiceForDigitalWallet.createSubscribePlan(
      selectedPackageFromPackageSelector === "payperuser"
        ? selectedCommittedUsage
        : {},
      selectedPackageFromPackageSelector
    )
      .then(() => {
        setOpenSnackBar(true);
        setSuccessMessage(t("billing.orderSuccessMessage"));
        setShowOkButton(true);
      })
      .catch(() => {
        setOpenSnackBar(true);
        setSuccessMessage(t("billing.orderErrorMessage"));
        setShowOkButton(true);
      });
  };

  return (
    <>
      <Typography variant="subtitle2" color={"black"} mt={2} mb={2}>
        {t("billing.reviewDetailsAndConfirmOrder")}:
      </Typography>
      <DetailsContainer>
        <Typography variant="subtitle1" color={"black"} fontWeight="bold">
          {t("billing.packageDetails")}
        </Typography>

        <Typography variant="subtitle2" color={"black"} mt={2}>
          <span style={{ fontWeight: "bold" }}>{packageName()}</span>{" "}
          {packagePrice()}
        </Typography>

        {selectedPackageFromPackageSelector === "payperuser" ? (
          <>
            <Typography variant="subtitle2" color={"black"} mt={2}>
              {t("billing.committedUsers")}:{" "}
              {selectedCommittedUsage.userCommitment}
            </Typography>{" "}
            <Typography variant="subtitle2" color={"black"}>
              {t("billing.committedTimePeriod")}:{" "}
              {selectedCommittedUsage.timeCommitment}
            </Typography>
          </>
        ) : (
          <Typography variant="subtitle2" color={"black"} mt={2}>
            {t("billing.validity")}: {packageValidity()}
          </Typography>
        )}
      </DetailsContainer>

      <ButtonContainer>
        {showOkButton ? (
          <Button
            variant="outlined"
            style={buttonStyle}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "black",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            }}
            onClick={() => {
              setShowOkButton(false), navigate("/billing");
            }}
          >
            {t("billing.ok")}
          </Button>
        ) : (
          <>
            <Button
              variant="outlined"
              style={buttonStyle}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "black",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              startIcon={<KeyboardArrowLeftIcon />}
              onClick={handleBack}
            >
              <Typography variant="subtitle2">
                {t("onboarding.prev")}
              </Typography>
            </Button>
            <Button
              variant="outlined"
              style={{ ...buttonStyle, width: "auto" }}
              sx={{
                display: "flex",
                alignItems: "center",
                color: "black",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              onClick={handleSubmit}
              endIcon={<KeyboardArrowRightIcon />}
            >
              <Typography variant="subtitle2">
                {t("billing.confimrOrder")}
              </Typography>
            </Button>
          </>
        )}
      </ButtonContainer>
    </>
  );
};

export default ReviewAndConfirmOrder;
