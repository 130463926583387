import Grid from "@mui/material/Grid";

import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";
import { useNavigate } from "react-router-dom";

const buttonStyle = {
  height: 30,
  width: "100%",
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const disabledContainerStyle = {
  display: "grid",
  justifyItems: "center",
  alignContent: "space-between",
  padding: "5px",
  height: "350px",
  borderRadius: "5px",
  backgroundColor: "#fff",
  border: "1px solid #DFDFDF",
  transition: "box-shadow 0.3s",
  "&:hover": {
    boxShadow: "0 1px 5px rgba(0,0,0,.4),0 1px 5px hsla(0,0%,51%,.35)",
  },
};

const unSelectedContainerStyle = {
  display: "grid",
  justifyItems: "center",
  alignContent: "space-between",
  padding: "5px",
  height: "350px",
  borderRadius: "5px",
  backgroundColor: "#fff",
  border: "1px solid #DFDFDF",
  transition: "box-shadow 0.3s",
  "&:hover": {
    boxShadow: "0 1px 5px rgba(0,0,0,.4),0 1px 5px hsla(0,0%,51%,.35)",
  },
};

const selectedContainerStyle = {
  display: "grid",
  justifyItems: "center",
  alignContent: "space-between",
  padding: "5px",
  height: "350px",
  borderRadius: "5px",
  backgroundColor: "#fff",
  border: "1px solid black",
  transition: "box-shadow 0.3s",
  boxShadow: "0 1px 5px rgba(0,0,0,.4), 0 1px 5px hsla(0,0%,51%,.35)",
  "&:hover": {
    boxShadow: "0 1px 5px rgba(0,0,0,.4),0 1px 5px hsla(0,0%,51%,.35)",
  },
};

interface Props {
  selectedPackage: number;
  setSelectedPackage: Dispatch<SetStateAction<number>>;
  pricingInfoFromApi: any;
  setOpenTerminateModal: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string>>;
  setOpenSnackBar: Dispatch<SetStateAction<boolean>>;
}
export default function PackageDetailsContainer(props: Props) {
  const {
    selectedPackage,
    pricingInfoFromApi,
    setOpenTerminateModal,
    setOpenSnackBar,
    setError,
  } = props;
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const packageSelectionDetails = useDigitalWalletStore(
    (state) => state.readExistingBillingDetails.billingInfo
  );

  const committedUsage = useDigitalWalletStore(
    (state) => state.readExistingBillingDetails?.billingInfo?.payPerUserInfo
  );

  console.log("selected", selectedPackage);

  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" spacing={4}>
          {[0, 1, 2].map((value) => (
            <Grid key={value} item xs={12} sm={6} md={4}>
              <Box
                sx={
                  value === 0
                    ? selectedPackage === 0
                      ? selectedContainerStyle
                      : unSelectedContainerStyle
                    : value === 1
                    ? selectedPackage === 1
                      ? selectedContainerStyle
                      : unSelectedContainerStyle
                    : disabledContainerStyle
                }
              >
                <Box
                  sx={{
                    borderBottom: "1px solid #DFDFDF",
                    height: "40px",
                    width: "90%",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="subtitle1" color={"black"}>
                    {value === 0 && t("onboarding.trial")}
                    {value === 1 && t("onboarding.starter")}
                    {value === 2 && t("onboarding.payPerUser")}
                  </Typography>
                </Box>
                <Box>
                  {value === 0 && (
                    <Box textAlign={"center"}>
                      <Typography
                        variant="subtitle1"
                        color={"black"}
                        fontWeight={"bold"}
                      >
                        {pricingInfoFromApi?.freeTrial?.price > 0
                          ? pricingInfoFromApi?.freeTrial?.price
                          : t("onboarding.free")}
                      </Typography>

                      <Typography variant="subtitle1" color={"black"} mt={4}>
                        {t("onboarding.validFor")}{" "}
                        {pricingInfoFromApi?.freeTrial?.validity}{" "}
                        {pricingInfoFromApi?.freeTrial?.validity > 1
                          ? t("onboarding.days")
                          : t("onboarding.day")}
                      </Typography>
                      <Typography variant="subtitle1" color={"black"}>
                        {t("onboarding.upto")}{" "}
                        {pricingInfoFromApi?.freeTrial?.usageLimit?.users}{" "}
                        {t("onboarding.users")}
                      </Typography>
                      <Typography variant="subtitle1" color={"black"}>
                        {t("onboarding.upto")}{" "}
                        {pricingInfoFromApi?.freeTrial?.usageLimit?.api}{" "}
                        {t("onboarding.APIcalls")}
                      </Typography>
                    </Box>
                  )}

                  {value === 1 && (
                    <Box textAlign={"center"}>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="subtitle1"
                          color={"black"}
                          fontWeight={"bold"}
                        >
                          {t("onboarding.sek")}{" "}
                          {pricingInfoFromApi?.starter?.price}{" "}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={"grey"}
                          style={{ marginLeft: "10px" }}
                        >
                          {" "}
                          / {t("onboarding.year")}
                        </Typography>
                      </Box>
                      <Typography variant="subtitle1" color={"black"} mt={4}>
                        {t("onboarding.upto")}{" "}
                        {pricingInfoFromApi?.starter?.usageLimit?.users}{" "}
                        {t("onboarding.users")}
                      </Typography>
                      <Typography variant="subtitle1" color={"black"}>
                        {t("onboarding.upto")}{" "}
                        {pricingInfoFromApi?.starter?.usageLimit?.api}{" "}
                        {t("onboarding.APIcalls")}
                      </Typography>
                    </Box>
                  )}

                  {value === 2 && (
                    <Box textAlign={"center"}>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          color={"black"}
                          fontWeight={"bold"}
                        >
                          {t("onboarding.sek")}{" "}
                          {pricingInfoFromApi?.payPerUser?.basePrice}*
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={"grey"}
                          style={{ marginLeft: "10px" }}
                        >
                          {" "}
                          / {t("onboarding.userPerYear")}
                        </Typography>
                      </Box>

                      <Typography variant="subtitle1" color={"black"} mt={4}>
                        {pricingInfoFromApi?.payPerUser?.usageLimit.users ===
                        "UNLIMITED"
                          ? t("onboarding.unlimitedUsers")
                          : "Upto " +
                            pricingInfoFromApi?.payPerUser?.usageLimit?.users +
                            " users"}{" "}
                      </Typography>
                      <Typography variant="subtitle1" color={"black"}>
                        {t("onboarding.upto")}{" "}
                        {pricingInfoFromApi?.payPerUser?.usageLimit?.api}{" "}
                        {t("onboarding.APIcalls")}
                      </Typography>
                      {selectedPackage === 2 && (
                        <Box
                          style={{
                            display: "grid",
                            borderTop: "1px solid #CFCFCF",
                            marginTop: "20px",
                          }}
                        >
                          <Typography variant="caption" color={"black"} mt={1}>
                            {t("billing.committedUsers")}:{" "}
                            {committedUsage.userCommitment}
                          </Typography>{" "}
                          <Typography variant="caption" color={"black"}>
                            {t("billing.committedTimePeriod")}:{" "}
                            {committedUsage.timeCommitment}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    borderTop: "1px solid #DFDFDF",
                    height: "40px",
                    width: "90%",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {value === 0 &&
                    (packageSelectionDetails.freeTrialExpired === true ? (
                      <Typography
                        variant="subtitle1"
                        color={"black"}
                        fontWeight={"bold"}
                      >
                        {t("billing.expired")}
                      </Typography>
                    ) : (
                      <Button
                        style={buttonStyle}
                        sx={{
                          cursor: "pointer",
                          color: "black",
                          "&:hover": {
                            backgroundColor: "black",
                            color: "white",
                          },
                        }}
                        variant="outlined"
                        onClick={() =>
                          selectedPackage === 0
                            ? setOpenTerminateModal(true)
                            : selectedPackage === null
                            ? (navigate("/orderingwizard"),
                              useDigitalWalletStore
                                .getState()
                                .updateSelectedPackageFromPackageSelector(
                                  "freetrial"
                                ))
                            : (setOpenSnackBar(true),
                              setError(t("billing.errorIfPlanExist")))
                        }
                      >
                        {selectedPackage === 0
                          ? t("billing.terminate")
                          : t("onboarding.select")}{" "}
                      </Button>
                    ))}
                  {value === 1 && (
                    <Button
                      style={buttonStyle}
                      sx={{
                        cursor: "pointer",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "black",
                          color: "white",
                        },
                      }}
                      variant="outlined"
                      onClick={() =>
                        selectedPackage === 1
                          ? setOpenTerminateModal(true)
                          : selectedPackage === null
                          ? (navigate("/orderingwizard"),
                            useDigitalWalletStore
                              .getState()
                              .updateSelectedPackageFromPackageSelector(
                                "starter"
                              ))
                          : (setOpenSnackBar(true),
                            setError(t("billing.errorIfPlanExist")))
                      }
                    >
                      {selectedPackage === 1
                        ? t("billing.terminate")
                        : t("onboarding.select")}{" "}
                    </Button>
                  )}
                  {value === 2 && (
                    <Button
                      style={buttonStyle}
                      sx={{
                        cursor: "pointer",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "black",
                          color: "white",
                        },
                      }}
                      variant="outlined"
                      onClick={() =>
                        selectedPackage === 2
                          ? setOpenTerminateModal(true)
                          : selectedPackage === null
                          ? (navigate("/orderingwizard"),
                            useDigitalWalletStore
                              .getState()
                              .updateSelectedPackageFromPackageSelector(
                                "payperuser"
                              ))
                          : (setOpenSnackBar(true),
                            setError(t("billing.errorIfPlanExist")))
                      }
                    >
                      {selectedPackage === 2
                        ? t("billing.terminate")
                        : t("onboarding.select")}{" "}
                    </Button>
                  )}{" "}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
