import { Typography, Box, Input, Tooltip } from "@mui/material";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { useTranslation } from "react-i18next";

interface Props {
  qrCodeValue: string;
  handleCopy: any;
  showTooltip: boolean;
}
const QRCodeCopyInput = (props: Props) => {
  const { qrCodeValue, handleCopy, showTooltip } = props;
  const { t } = useTranslation("translation");

  return (
    <Box
      style={{
        width: "95%",
        display: "flex",
        justifyContent: "Space-around",
        alignItems: "center",
      }}
    >
      <Input
        value={qrCodeValue}
        readOnly
        sx={{
          width: "80%",
          marginBottom: "10px",
          color: "gray",
          fontSize: "14px",
          fontFamily: "Inter,Helvetica,Arial,sans-serif",
          "&:after": {
            borderBottom: "0px solid transparent",
          },
        }}
      />
      <Tooltip
        title={t("dataAgreements.copied")}
        placement="top"
        open={showTooltip}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleCopy}
        >
          <FileCopyOutlinedIcon style={{ color: "gray" }} fontSize="small" />
          <Typography color={"GrayText"} variant="body2">
            {t("developerAPIs.copy")}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default QRCodeCopyInput;
