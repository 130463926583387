/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useState } from "react";
import {
  List,
  Datagrid,
  Form,
  useRefresh,
  useRecordContext,
} from "react-admin";

import {
  Box,
  Typography,
  Tooltip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from "@mui/material";

import { styled } from "@mui/material/styles";

// icons
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

import BreadCrumb from "../../components/Breadcrumbs";
import GeneralModal from "../../components/modals/generalModal";
import DeleteModal from "../../components/modals/generalModal";
import { useFilterStore } from "../../store/store";
import { getLawfulBasisOfProcessing } from "../../interfaces/DataAgreement";
import { useTranslation } from "react-i18next";
import { TableEmptyMessage } from "../../components/tableEmptyMessage";
import "../../index.css";
import useLanguageChange from "../../utils/translateTableLanguage";

import VersionDropdown from "../../components/dda/versionDropdown";
import PublishToDataMarketPlaceModal from "../../components/modals/publishToDataMarketPlaceModal";
import CreateAndReadDDA from "../../components/modals/createAndReadDDA";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const DataDisclosureAgreement = () => {
  const [openDeleteDataAgreementModal, setOpenDeleteDataAgreementModal] =
    useState(false);
  const [openPublishDataAgreementModal, setOpenPublishDataAgreementModal] =
    useState(false);
  const [openDDAModal, setOpenDDAModal] = useState(false);
  const [openUpdateDDAModal, setOpenUpdateDDAModal] = useState(false);
  const [ddaMode, setDDAMode] = useState("");
  const [openPublishToMarketPlaceModal, setOpenPublishToMarketPlaceModal] =
    useState(false);

  const [
    selectededDataAgreementFromDataAgreement,
    setSelectededDataAgreementFromDataAgreement,
  ] = useState<any>();
  const [handleChangeTriggered, setHandleChangeTriggered] = useState(false);

  const { t } = useTranslation("translation");
  const key = useLanguageChange();

  const [listFilterValue, setListFilterValue] = useState("all");
  const refresh = useRefresh();
  const onRefetch = () => {
    refresh();
  };

  const changefilterDataAgreement = (filterDataAgreement: string) => {
    useFilterStore.getState().updateFilterDataAgreement(filterDataAgreement);
  };

  const handleChange = (value: any) => {
    if (value === "complete") {
      changefilterDataAgreement("complete");
      setListFilterValue("complete");
    } else if (value === "all") {
      changefilterDataAgreement("all");
      setListFilterValue("all");
    }
    setHandleChangeTriggered(!handleChangeTriggered);
  };

  const ColouredField = (props: any) => {
    const record = useRecordContext(props);
    if (!record || !props.source) {
      return null;
    }

    const sourceParts = props.source.split("."); // Split the source string into parts

    // Dynamically access the nested property
    const value = sourceParts.reduce(
      (obj: any, key: any) =>
        obj && obj[key] !== "undefined" ? obj[key] : undefined,
      record
    );
    return (
      <Typography
        variant="body2"
        sx={{
          color: record.publish_flag === "false" ? "#FF0C10" : "black",
        }}
      >
        {value}
      </Typography>
    );
  };

  const LawfulBasisField = (props: any) => {
    const record = useRecordContext(props);

    if (!record || !props.source) {
      return null;
    }

    return (
      <Typography
        variant="body2"
        sx={{
          color: record.publish_flag === "false" ? "#FF0C10" : "black",
        }}
      >
        {getLawfulBasisOfProcessing(
          record.data_disclosure_agreement.lawfulBasis
        )}
      </Typography>
    );
  };

  const IconsFIeld = (props: any) => {
    const record = useRecordContext(props);

    if (!record || !props.source) {
      return null;
    }

    return (
      record[props.source] && (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Tooltip
            title={t("dataDisclosureAgreements.viewDDA")}
            placement="top"
          >
            <RemoveRedEyeOutlinedIcon
              onClick={() => {
                setOpenDDAModal(true);
                setDDAMode("Read");
                setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: record.publish_flag === "false" ? "#FF0C10" : "#4D4D4F",
              }}
            />
          </Tooltip>

          <Tooltip
            title={
              record.publish_flag === "true"
                ? t("dataDisclosureAgreements.publishedDDA")
                : t("dataDisclosureAgreements.publishDDA")
            }
            placement="top"
          >
            <UploadOutlinedIcon
              onClick={() => {
                record.publish_flag !== "true" &&
                  setOpenPublishDataAgreementModal(true);
                setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor:
                  record.publish_flag === "true" ? "not-allowed" : "pointer",
                color: record.publish_flag === "false" ? "#FF0C10" : "#B9B9B9",
              }}
            />
          </Tooltip>

          <Tooltip
            title={t("dataDisclosureAgreements.updateDDA")}
            placement="top"
          >
            <EditOutlinedIcon
              onClick={() => {
                setOpenUpdateDDAModal(true);
                setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: record.publish_flag === "false" ? "#FF0C10" : "#4D4D4F",
              }}
            />
          </Tooltip>

          <Tooltip
            title={t(
              record.marketplacePublished
                ? t("dataDisclosureAgreements.publishedAlreadyToMarketPlace")
                : record.publish_flag === "true"
                ? t("dataDisclosureAgreements.publishToMarketPlace")
                : "Data Disclosure Agreement must be locally published."
            )}
            placement="top"
          >
            <ShoppingCartOutlinedIcon
              onClick={() => {
                !record.marketplacePublished &&
                  record.publish_flag === "true" &&
                  setOpenPublishToMarketPlaceModal(true),
                  setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: record.marketplacePublished
                  ? "not-allowed"
                  : record.publish_flag === "false"
                  ? "not-allowed"
                  : "pointer",
                color:
                  record.publish_flag === "false"
                    ? "#FF0C10"
                    : record.marketplacePublished
                    ? "green"
                    : "#4D4D4F",
              }}
            />
          </Tooltip>

          <Tooltip
            title={t("dataDisclosureAgreements.deleteDDA")}
            placement="top"
          >
            <DeleteOutlineOutlinedIcon
              onClick={() => {
                setOpenDeleteDataAgreementModal(true),
                  setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: record.publish_flag === "false" ? "#FF0C10" : "#4D4D4F",
              }}
            />
          </Tooltip>
        </Box>
      )
    );
  };

  const VersionField = (props: any) => {
    const record = useRecordContext(props);

    if (!record || !props.source) {
      return null;
    }

    return <VersionDropdown record={record} />;
  };

  return (
    <Container>
      <Form>
        <BreadCrumb Link={t("sidebar.dataDisclosureAgreements")} />
        <HeaderContainer>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              {t("sidebar.dataDisclosureAgreements")}
            </Typography>
            <Tooltip
              title={t("dataDisclosureAgreements.createDDA")}
              placement="top"
            >
              <AddCircleOutlineOutlinedIcon
                onClick={() => {
                  setDDAMode("Create");
                  setOpenDDAModal(true);
                }}
                style={{
                  cursor: "pointer",
                  marginLeft: "10px",
                }}
              />
            </Tooltip>
          </Box>

          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="all"
              name="radio-buttons-group"
              row
            >
              <FormControlLabel
                value="all"
                onChange={() => handleChange("all")}
                control={<Radio name="all" color="default" size="small" />}
                label={
                  <Typography variant="body2">{t("common.all")}</Typography>
                }
              />
              <FormControlLabel
                value="complete"
                onChange={() => handleChange("complete")}
                control={<Radio name="complete" color="default" size="small" />}
                label={
                  <Typography variant="body2">
                    {t("dataAgreements.published")}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </HeaderContainer>
        <Typography variant="body2" sx={{ marginTop: "10px" }}>
          {t("dataDisclosureAgreements.pageDescription")}
        </Typography>
      </Form>

      <List
        actions={false}
        sx={{ width: "100%", overflow: "hidden" }}
        empty={<TableEmptyMessage />}
        // filter={{ status: listFilterValue }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              width: "100%",
              "& .RaDatagrid-rowCell:last-child": {
                width: { md: "30%", lg: "19%" },
              },
            }}
            key={key}
          >
            <ColouredField
              source="data_disclosure_agreement.purpose"
              label={t("dataAgreements.usagePurpose")}
              sortable={false}
            />
            <VersionField
              source="id"
              label={t("dataAgreements.version")}
              sortable={false}
              cellClassName="custom-version-column"
            />
            <ColouredField
              source="data_disclosure_agreement.dataSharingRestrictions.industrySector"
              label={t("gettingStarted.sector")}
              sortable={false}
            />
            <ColouredField
              source="data_disclosure_agreement.dataSharingRestrictions.policyUrl"
              label={t("common.policyUrl")}
              sortable={false}
            />
            <ColouredField
              source="id"
              label={t("personalData.dataAgreement")}
              sortable={false}
            />
            <LawfulBasisField
              source="data_disclosure_agreement.lawfulBasis"
              label={t("dataAgreements.lawfulBasisOfProcessing")}
              sortable={false}
            />
            <IconsFIeld
              source="id"
              label={""}
              sortable={false}
              textAlign={"center"}
            />
          </Datagrid>
        </Box>
      </List>

      {/* Modals */}

      <CreateAndReadDDA
        open={openDDAModal}
        setOpen={setOpenDDAModal}
        mode={ddaMode}
        selectedDDA={selectededDataAgreementFromDataAgreement}
        successCallback={onRefetch}
      />

      {/* DeleteDataAgreementModal */}
      <DeleteModal
        open={openDeleteDataAgreementModal}
        setOpen={setOpenDeleteDataAgreementModal}
        headerText={`${t("dataDisclosureAgreements.deleteDDA")}:`}
        confirmText="DELETE"
        confirmButtonText={`${t("common.delete")}`}
        resourceName="dataDisclosureAgreements"
        onRefetch={onRefetch}
        selectededDataAgreementFromDataAgreement={
          selectededDataAgreementFromDataAgreement
        }
        modalDescriptionText={
          <Typography variant="body1">
            {t("dataDisclosureAgreements.deleteDADescription1")}
            <b>DELETE</b>
            {t("dataDisclosureAgreements.deleteDADescription2")}
          </Typography>
        }
      />

      {/* Publish DDA Modal */}
      <GeneralModal
        open={openPublishDataAgreementModal}
        setOpen={setOpenPublishDataAgreementModal}
        headerText={`${t("dataDisclosureAgreements.publishDDA")}:`}
        confirmText="PUBLISH"
        confirmButtonText={`${t("dataAgreements.publish")}`}
        onRefetch={onRefetch}
        resourceName="dataDisclosureAgreements"
        selectededDataAgreementFromDataAgreement={
          selectededDataAgreementFromDataAgreement
        }
        modalDescriptionText={
          <Typography variant="body1">
            {t("dataDisclosureAgreements.publishDDADescription1")}
            <b>PUBLISH</b>
            {t("dataDisclosureAgreements.publishDDADescription2")}
          </Typography>
        }
      />

      {/* Update DDA Modal */}
      <GeneralModal
        open={openUpdateDDAModal}
        setOpen={setOpenUpdateDDAModal}
        headerText={`${t("dataDisclosureAgreements.updateDDA")}:`}
        confirmText="UPDATE"
        confirmButtonText={`${t("dataDisclosureAgreements.update")}`}
        onRefetch={onRefetch}
        resourceName="dataDisclosureAgreements"
        selectededDataAgreementFromDataAgreement={
          selectededDataAgreementFromDataAgreement
        }
        modalDescriptionText={
          <Typography variant="body1">
            {t("dataDisclosureAgreements.updateDDADescription1")}
            <b>UPDATE</b>
            {t("dataDisclosureAgreements.updateDDADescription2")}
          </Typography>
        }
      />

      <PublishToDataMarketPlaceModal
        open={openPublishToMarketPlaceModal}
        setOpen={setOpenPublishToMarketPlaceModal}
        headerText={t("dataDisclosureAgreements.publishToMarketPlace")}
        onRefetch={onRefetch}
        selectedData={selectededDataAgreementFromDataAgreement}
      />
    </Container>
  );
};

export default DataDisclosureAgreement;
