import { RadioGroup, Radio, FormControlLabel, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { Box } from "@mui/system";
import { handleCredentialFormatValueChange } from "../../utils/credentialDefintionUtils";

interface Props {
  control: any;
  name: string;
  credentialFormatsMenuItems: any;
  credentialDefinitionModalMode: string;
  setValue: any;
  watch: any;
}

const EwcCredentialFormatRadio = (props: Props) => {
  const {
    control,
    name,
    credentialDefinitionModalMode,
    credentialFormatsMenuItems,
    setValue,
    watch,
  } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={"space-between"}
      mt={1.5}
    >
      <Typography variant="subtitle1">
        {"Credential Format"}
        <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
      </Typography>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            aria-label={name}
            name={name}
            value={value}
            onChange={(e: any) => {
              onChange(e);
              const value = e.target.value;

              const updatedCredentialFormatValue = (value: any) => {
                if (value === false || value === "false") {
                  return false;
                } else return true;
              };
              handleCredentialFormatValueChange(
                updatedCredentialFormatValue(value),
                setValue,
                watch
              );
            }}
          >
            {credentialFormatsMenuItems?.map((data: any) => (
              <FormControlLabel
                key={data.value}
                style={{
                  cursor:
                    credentialDefinitionModalMode === "View"
                      ? "not-allowed"
                      : "auto",
                }}
                disabled={credentialDefinitionModalMode === "View"}
                value={data.value}
                control={<Radio />}
                label={data.label}
              />
            ))}
          </RadioGroup>
        )}
      />
    </Box>
  );
};

export default EwcCredentialFormatRadio;
