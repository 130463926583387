import {
  Datagrid,
  useRefresh,
  useGetList,
  Pagination,
  ListContextProvider,
  useList,
} from "react-admin";

import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import BreadCrumb from "../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import useLanguageChange from "../../utils/translateTableLanguage";
import { useState } from "react";
import SnackbarComponent from "../../components/notification";

import {
  CredentialFormatFieldForCredential,
  CredentialFormatFieldForVerification,
  DateRecievedField,
  StateField,
  IconsFIeld,
  CredentialTypeFieldForCredential,
  CredentialTypeFieldForVerification,
  OrganisationNameField,
} from "../../components/holder/fields";
import PageHeaderComponent from "../../components/holder/pageHearderComponent";
import DeleteModal from "../../components/modals/generalModal";
import ReceiveCredentialOrPresentationDefinition from "../../components/modals/ewcModals/receiveCredentialOrPresentationDefinition";
import TransactionCodeModal from "../../components/modals/ewcModals/transactionCodeModal";
import ViewSharedCredentialModal from "../../components/modals/ewcModals/viewSharedCredentialModal";
import ViewReceivedCredential from "../../components/modals/ewcModals/viewReceivedCredential";
import SendVerificationsModal from "../../components/modals/ewcModals/sendVerificationsModal";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const Holder = () => {
  const { t } = useTranslation("translation");
  const key = useLanguageChange();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");
  const [openDeleteModalForReceived, setOpenDeleteModalForReceived] =
    useState(false);
  const [openDeleteModalForShared, setOpenDeleteModalForShared] =
    useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  const [openViewReceivedCredentialModal, setOpenViewReceivedCredentialModal] =
    useState(false);
  const [selectedFirstTable, setSelectedFirstTable] = useState(true);
  const [openReceiveCredentialOfferModal, setOpenReceiveCredentialOfferModal] =
    useState(false);
  const [
    openReceivePresentationDefinitionModal,
    setOpenReceivePresentationDefinitionModal,
  ] = useState(false);
  const [openTransactionCodeModal, setOpenTransactionCodeModal] =
    useState(false);
  const [openSharedCredentialModal, setOpenSharedCredentialModal] =
    useState(false);
    const [inputDescriptorData, setInputDescriptorData] = useState<any>();
    const [openSendVerificationsModal, setOpenSendVerificationsModal] =
    useState<any>(false);

  const refresh = useRefresh();

  const onRefetch = () => {
    refresh();
  };

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [page2, setPage2] = useState(1);
  const [perPage2, setPerPage2] = useState(10);

  const { data: receivedCredentialsData, total: receivedCredentialsTotal } =
    useGetList(
      "holder-received-credentials",
      {
        pagination: { page, perPage },
      },
      {
        keepPreviousData: true, // Keep showing previous data while fetching
      }
    );

  const { data: sharedCredentialsData, total: sharedCredentialsTotal } =
    useGetList(
      "holder-shared-credentials",
      {
        pagination: { page: page2, perPage: perPage2 },
      },
      {
        keepPreviousData: true, // Keep showing previous data while fetching
      }
    );

  const listContext = useList({
    data: receivedCredentialsData,
    perPage: perPage,
  });

  const listContext2 = useList({
    data: sharedCredentialsData,
    perPage: perPage2,
  });

  return (
    <Container>
      <SnackbarComponent
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={error}
        topStyle={100}
      />
      <BreadCrumb
        Link={t("sidebar.openIDDigitalWallet")}
        Link2={t("sidebar.holder")}
      />

      <HeaderContainer>
        <PageHeaderComponent
          headerName={t("holder.receivedCredentials")}
          tooltip={t("holder.recieveCredentialOffer")}
          setOpenCreateModal={setOpenReceiveCredentialOfferModal}
          tableNumber={"table1"}
          setSelectedFirstTable={setSelectedFirstTable}
        />
      </HeaderContainer>

      <Typography variant="body2" sx={{ marginTop: "10px" }}>
        {t("holder.tableOneDescription")}
      </Typography>

      <ListContextProvider value={listContext}>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              width: "100%",
              "& .RaDatagrid-rowCell:last-child": {
                width: "20%",
              },
            }}
            key={key}
          >
            <CredentialTypeFieldForCredential
              source="credential.vc.type"
              label={t("issuanceHistory.credentialType")}
              sortable={false}
              tableNumber={"table1"}
            />
            <OrganisationNameField
              source="issuer.name"
              label={t("sidebar.credentials")}
              sortable={false}
              tableNumber={"table1"}
            />
            <DateRecievedField
              source="createdAt"
              label={t("holder.dateRecieved")}
              sortable={false}
              tableNumber={"table1"}
            />
            <CredentialFormatFieldForCredential
              source="credentialFormat"
              label={t("digitalWalletBase.credentialFormat")}
              sortable={false}
            />
            <StateField
              source="credentialStatus"
              label={t("issuanceHistory.state")}
              sortable={false}
              tableNumber={"table1"}
            />
            <IconsFIeld
              source="id"
              sortable={false}
              label={""}
              tableNumber={"table1"}
              setOpenDeleteModal={setOpenDeleteModalForReceived}
              setSelectedData={setSelectedData}
              setOpenViewModal={setOpenViewReceivedCredentialModal}
              setSelectedFirstTable={setSelectedFirstTable}
              setOpenTransactionCodeModal={setOpenTransactionCodeModal}
              onRefetch={onRefetch}
              setOpenSnackBar={setOpenSnackBar}
              setError={setError}
              setInputDescriptorData={setInputDescriptorData}
              setOpenSendVerificationsModal={setOpenSendVerificationsModal}
            />
          </Datagrid>
        </Box>

        <Pagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
          total={receivedCredentialsTotal || 0}
        />
      </ListContextProvider>

      <HeaderContainer>
        <PageHeaderComponent
          headerName={t("holder.sharedCredentials")}
          tooltip={t("holder.receivePresentationDefinition")}
          setOpenCreateModal={setOpenReceivePresentationDefinitionModal}
          setSelectedFirstTable={setSelectedFirstTable}
        />
      </HeaderContainer>

      <Typography variant="body2" sx={{ marginTop: "10px" }}>
        {t("holder.tableTwoDescription")}
      </Typography>

      <ListContextProvider value={listContext2}>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              width: "100%",
              "& .RaDatagrid-rowCell:last-child": {
                width: "11.5%",
              },
            }}
            key={key}
          >
            <CredentialTypeFieldForVerification
              source="id"
              label={t("issuanceHistory.credentialType")}
              sortable={false}
            />
            <OrganisationNameField
              source="clientMetadata.clientName"
              label={t("sidebar.verifications")}
              sortable={false}
            />
            <DateRecievedField
              source="createdAt"
              label={t("holder.dateRecieved")}
              sortable={false}
            />
            <CredentialFormatFieldForVerification
              source="id"
              label={t("digitalWalletBase.credentialFormat")}
              sortable={false}
            />
            <StateField
              source="status"
              label={t("issuanceHistory.state")}
              sortable={false}
            />
            <IconsFIeld
              source="id"
              sortable={false}
              label={""}
              tableNumber={"table2"}
              setOpenDeleteModal={setOpenDeleteModalForShared}
              setSelectedData={setSelectedData}
              setOpenViewModal={setOpenSharedCredentialModal}
              setSelectedFirstTable={setSelectedFirstTable}
            />
          </Datagrid>
        </Box>
        <Pagination
          page={page2}
          perPage={perPage2}
          setPage={setPage2}
          setPerPage={setPerPage2}
          total={sharedCredentialsTotal || 0}
        />
      </ListContextProvider>

      <DeleteModal
        open={
          selectedFirstTable
            ? openDeleteModalForReceived
            : openDeleteModalForShared
        }
        setOpen={
          selectedFirstTable
            ? setOpenDeleteModalForReceived
            : setOpenDeleteModalForShared
        }
        headerText={
          selectedFirstTable
            ? t("holder.deleteReceivedCredential")
            : t("holder.deleteSharedCredential")
        }
        confirmText="DELETE"
        confirmButtonText={`${t("common.delete")}`}
        resourceName={
          selectedFirstTable ? "receivedCredential" : "sharedCredential"
        }
        selectededDataAgreementFromDataAgreement={selectedData}
        onRefetch={onRefetch}
        modalDescriptionText={
          <Typography variant="body1">
            {selectedFirstTable
              ? t("holder.deleteDescription1Received")
              : t("holder.deleteDescription1Shared")}{" "}
            <b>DELETE</b>
            {t("holder.deleteDescription2")}
          </Typography>
        }
      />

      <ViewReceivedCredential
        open={openViewReceivedCredentialModal}
        setOpen={setOpenViewReceivedCredentialModal}
        headerText={
          selectedFirstTable
            ? t("holder.viewReceivedCredential")
            : t("holder.viewSharedCredential")
        }
        selectedData={selectedData}
        selectedFirstTable={selectedFirstTable}
      />

      <ReceiveCredentialOrPresentationDefinition
        open={
          selectedFirstTable
            ? openReceiveCredentialOfferModal
            : openReceivePresentationDefinitionModal
        }
        setOpen={
          selectedFirstTable
            ? setOpenReceiveCredentialOfferModal
            : setOpenReceivePresentationDefinitionModal
        }
        headerText={
          selectedFirstTable
            ? t("holder.recieveCredentialOffer")
            : t("holder.receivePresentationDefinition")
        }
        selectedFirstTable={selectedFirstTable}
        onRefetch={onRefetch}
      />

      <TransactionCodeModal
        open={openTransactionCodeModal}
        setOpen={setOpenTransactionCodeModal}
        headerText={t("holder.receiveCredential")}
        onRefetch={onRefetch}
        selectedData={selectedData}
      />

      {openSharedCredentialModal && (
        <ViewSharedCredentialModal
          open={openSharedCredentialModal}
          setOpen={setOpenSharedCredentialModal}
          headerText={t("holder.viewSharedCredential")}
          selectedData={selectedData}
        />
      )}

      <SendVerificationsModal
        open={openSendVerificationsModal}
        setOpen={setOpenSendVerificationsModal}
        headerText={t("holder.chooseCredentials")}
        onRefetch={onRefetch}
        inputDescriptor={inputDescriptorData}
        selectedData={selectedData}
        selectedFirstTable={true}
      />
    </Container>
  );
};

export default Holder;
