import { Chip } from "@mui/material";
interface Props {
  index: number;
  attrName: string;
}
const ExistingSchamIdChips = (props: Props) => {
  const { index, attrName } = props;
  return (
    <Chip
      key={`${index}`}
      label={attrName}
      sx={{
        margin: 0.5,
        marginBottom: 1,
        color: "black",
        backgroundColor: "#E5E5E5",
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
      }}
    />
  );
};

export default ExistingSchamIdChips;
