import { Box, Typography } from "@mui/material";

const titleAttrRestrictionStyle = (isLastAttribute: boolean) => ({
  fontWeight: "normal",
  margin: "10px 10px 5px 10px",
  borderBottom: isLastAttribute ? "none" : "solid 1px #dee2e6",
  lineHeight: "1.5rem",
});

const tableAttrAdditionalInfoStyle = {
  border: 0,
  width: "100%",
  maxWidth: "100%",
  marginBottom: "0rem",
  backgroundColor: "#FFFF",
};

const blurredTextStyle = {
  filter: "blur(4px)",
};

interface Props {
  selectedData: any;
  blurPersonalData: any;
  ssi: string;
}

export const DataAttributeCard = (props: Props) => {
  const { selectedData, blurPersonalData, ssi } = props;

  console.log("selected ", selectedData)
  // For issuenceHistory Modal values
  const attributesForIssuenceHistory =
    ssi === "issuanceHistory"
      ? selectedData.credentialProposalDict.credentialProposal.attributes
      : null;

  // For VerifiedHistroy Modal values
  const presentation =
    ssi !== "issuanceHistory"
      ? selectedData.presentation.requestedProof
      : null;

  const { revealedAttrs = {}, selfAttestedAttrs = {} } = presentation || {};

  const presentationRequest =
    ssi !== "issuanceHistory"
      ? selectedData.presentationRequest.requestedAttributes
      : null;

  // Populate presentation request attributes with verified (or presented) values.
  if (presentationRequest) {
    Object.keys(presentationRequest).forEach((key) => {
      const attribute = presentationRequest[key];

      if (key in revealedAttrs) {
        // Indy based credential.
        attribute["presentation"] = revealedAttrs[key].raw;
      } else if (key in selfAttestedAttrs) {
        // Self attested credential.
        attribute["presentation"] = selfAttestedAttrs[key];
      } else {
        // No value for this attribute. (Could be a non-revealed attribute as well.)
        attribute["presentation"] = "";
      }
    });
  }

  return (
    <Box
      style={{
        marginTop: "10px",
        border: "1px solid #DFE0E1",
        borderRadius: 5,
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      {ssi === "issuanceHistory"
        ? attributesForIssuenceHistory?.map((attribute: any, index: number) => {
            const isLastAttribute =
              index === (attributesForIssuenceHistory?.length || 0) - 1;

            return (
              <Box
                key={index}
                style={titleAttrRestrictionStyle(isLastAttribute)}
              >
                <table style={tableAttrAdditionalInfoStyle}>
                  <tbody>
                    <tr
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle2">
                        {attribute.name}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={blurPersonalData ? blurredTextStyle : {}}
                      >
                        {attribute.value}
                      </Typography>
                    </tr>
                  </tbody>
                </table>
              </Box>
            );
          })
        : Object.keys(presentationRequest || {}).map((key, index) => {
            const attribute = presentationRequest
              ? presentationRequest[key]
              : null;

            if (!attribute) return null;

            const { name, presentation } = attribute;

            const isLastAttribute =
              index === Object.keys(presentationRequest || {}).length - 1;
            return (
              <Box
                key={index}
                style={titleAttrRestrictionStyle(isLastAttribute)}
              >
                <table style={tableAttrAdditionalInfoStyle}>
                  <tbody>
                    <tr
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle2">{name}</Typography>
                      <Typography
                        variant="subtitle2"
                        style={blurPersonalData ? blurredTextStyle : {}}
                      >
                        {presentation}
                      </Typography>
                    </tr>
                  </tbody>
                </table>
              </Box>
            );
          })}
    </Box>
  );
};
