import { useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const titleAttrRestrictionStyle = (isLastAttribute: boolean) => ({
  fontWeight: "normal",
  margin: "10px 10px 5px 10px",
  borderBottom: isLastAttribute ? "none" : "solid 1px #dee2e6",
  lineHeight: "1.5rem",
});

const tableAttrAdditionalInfoStyle = {
  border: 0,
  width: "100%",
  maxWidth: "100%",
  marginBottom: "0rem",
  backgroundColor: "#FFFF",
};

const blurredTextStyle = {
  filter: "blur(4px)",
};

const valueStyle = {
  wordBreak: "break-word" as const,
  whiteSpace: "nowrap" as const,
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "200px",
  textAlign: "right" as const,
};

const valueContainerStyle = {
  display: "flex",
  justifyContent: "flex-end",
};

interface Props {
  selectedData: Record<string, any>;
  blurPersonalData: boolean;
}

const renderValue = (
  key: string,
  value: any,
  blurPersonalData: boolean,
  parentKey = "",
  isLast = false,
  depth = 0
): JSX.Element[] => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useTranslation("translation");

  if (value === null || value === undefined) {
    return [
      <Box key={key} style={titleAttrRestrictionStyle(isLast)}>
        <table style={tableAttrAdditionalInfoStyle}>
          <tbody>
            <tr>
              <td style={{ verticalAlign: "top", width: "50%" }}>
                <Typography variant="subtitle2">
                  {parentKey ? `${parentKey} ${key}` : key}
                </Typography>
              </td>
              <td style={valueContainerStyle}>
                <Typography variant="subtitle2" style={valueStyle}>
                  {value === null ? "null" : "undefined"}
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>,
    ];
  }

  if (typeof value === "object" && !Array.isArray(value)) {
    const entries = Object.entries(value);
    return entries.flatMap(([subKey, subValue], index) =>
      renderValue(
        subKey,
        subValue,
        blurPersonalData,
        parentKey ? `${parentKey} ${key}` : key,
        index === entries.length - 1 && isLast,
        depth + 1
      )
    );
  }

  if (Array.isArray(value)) {
    return value.flatMap((item, index) =>
      renderValue(
        `${key}[${index}]`,
        item,
        blurPersonalData,
        parentKey,
        index === value.length - 1 && isLast,
        depth + 1
      )
    );
  }

  const displayKey = parentKey ? `${parentKey} ${key}` : key;

  const handleCopy = (value: any) => {
    navigator.clipboard.writeText(value);

    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };

  return [
    <Box
      key={displayKey}
      style={titleAttrRestrictionStyle(isLast && depth === 0)}
    >
      <table style={tableAttrAdditionalInfoStyle}>
        <tbody>
          <tr>
            <td style={{ verticalAlign: "top", width: "50%" }}>
              <Typography variant="subtitle2">{displayKey}</Typography>
            </td>
            <td style={valueContainerStyle}>
              <Tooltip
                title={t("dataAgreements.copied")}
                placement="top"
                open={showTooltip}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    ...valueStyle,
                    ...(blurPersonalData ? blurredTextStyle : {}),
                    cursor: value.length > 25 ? "pointer" : "auto",
                  }}
                  onClick={() => {
                    value.length > 25 && handleCopy(value);
                  }}
                >
                  {value.toString()}
                </Typography>
              </Tooltip>
            </td>
          </tr>
        </tbody>
      </table>
    </Box>,
  ];
};

export const DataAttributeCardHolder = ({
  selectedData,
  blurPersonalData,
}: Props) => {
  const entries = Object?.entries(selectedData);

  return (
    <Box
      style={{
        marginTop: "10px",
        border: "1px solid #DFE0E1",
        borderRadius: 5,
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      {entries.flatMap(([key, value], index) =>
        renderValue(
          key,
          value,
          blurPersonalData,
          "",
          index === entries.length - 1,
          0
        )
      )}
    </Box>
  );
};

export default DataAttributeCardHolder;
