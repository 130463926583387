import BreadCrumb from "../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import BillingAddressInputContainer from "../../components/onboarding/billingAddressInputContainer";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";
import { Box } from "@mui/system";
import { Button } from "react-admin";
import { useNavigate } from "react-router-dom";
import { OnBoardingHttpService } from "../../service/onboardingService";

const buttonStyle = {
  height: 30,
  width: 150,
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const FormContainer = styled("div")(({ theme }) => ({
  display: "grid",
  border: "1px solid #DFE0E1",
  borderRadius: 3,
  padding: "12px",
  width: "50%",
  marginTop: "20px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const BillingAddressPage = () => {
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const { handleSubmit, register, formState, reset, control } = useForm<any>({
    mode: "onChange",
    defaultValues: {},
  });

  const billingAddress = useDigitalWalletStore(
    (state) => state.readExistingBillingDetails?.billingInfo?.address
  );

  useEffect(() => {
    let defaultValues = {
      adminName: billingAddress.name,
      addressLine1: billingAddress.line1,
      addressLine2: billingAddress.line2,
      city: billingAddress.city,
      state: billingAddress.state,
      country: billingAddress.country,
      postalCode: billingAddress.postalCode,
    };

    reset({ ...defaultValues });
  }, []);

  const onSubmit = (createdData: any) => {
    if (formState.isValid) {
      const payload = {
        name: createdData.adminName,
        line1: createdData.addressLine1,
        line2: createdData.addressLine2,
        city: createdData.city,
        state: createdData.state,
        country: createdData.country,
        postalCode: createdData.postalCode,
      };

      OnBoardingHttpService.addBillingAddress(payload).then(() => {
        navigate("/billing");
      });
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BreadCrumb
          Link={t("sidebar.account")}
          Link2={t("sidebar.billing")}
          Link3={t("onboarding.billingAddress")}
        />

        <HeaderContainer>
          <Typography variant="h6" fontWeight="bold">
            {t("onboarding.billingAddress")}
          </Typography>
        </HeaderContainer>
        <Typography variant="body2" mt={1.25}>
          {t("billing.addOrEditBillingAddress")}:
        </Typography>

        <FormContainer>
          <BillingAddressInputContainer
            formState={formState}
            register={register}
          />

          <Box
            style={{
              display: "flex",
              justifyContent: "right",
              marginTop: "20px",
            }}
          >
            <Button
              type="submit"
              variant="outlined"
              style={buttonStyle}
              sx={{
                color: "black",
                fontSize:"14px",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              onClick={() => navigate("/billing")}
            >
              <>{t("common.cancel").toUpperCase()}</>
            </Button>
            <Button
              type="submit"
              variant="outlined"
              style={buttonStyle}
              sx={{
                fontSize:"14px",
                color: formState.isValid ? "black" : "#6D7676",
                cursor: formState.isValid ? "pointer" : "not-allowed",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
            >
              <> {t("common.save").toUpperCase()}</>
            </Button>
          </Box>
        </FormContainer>
      </form>
    </Container>
  );
};

export default BillingAddressPage;
