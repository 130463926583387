import * as React from "react";
import { Dispatch, SetStateAction, useEffect } from "react";

import { Drawer, Typography, Button, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  disabledButtonstyle,
} from "./modalStyle";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ConnectionDropdown from "../dropdowns/ConnectionDropdown";
import LoaderComponent from "../LoaderComponent";
import { HttpServiceForDDA } from "../../service/ddaHttpService";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  onRefetch: any;
  selectedData: any;
}
let defaultValue = {
  connectionId: "",
};
export default function PublishToDataMarketPlaceModal(props: Props) {
  const { t } = useTranslation("translation");
  const { open, setOpen, headerText, onRefetch, selectedData } = props;
  const [openLoader, setOpenLoader] = React.useState(false);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValue,
    },
  });
  const { control } = methods;

  useEffect(() => {
    methods.reset({
      connectionId: "",
    });
  }, [selectedData, open]);

  const onSubmit = (createdData: any) => {
    setOpenLoader(true);
    HttpServiceForDDA.publishDDAtoDataMarketplace("payload", "123id").then(
      () => {
        setOpenLoader(false);
        onRefetch();
        setOpen(false);
      }
    );
  };
  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <HeaderContainer>
              <Box pl={2} style={{ overflow: "hidden" }}>
                <Typography
                  style={{
                    color: "#F3F3F6",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {headerText}:{" "}
                  {selectedData?.data_disclosure_agreement?.purpose}
                </Typography>
                <Typography color="#F3F3F6">
                  {selectedData?.template_id}
                </Typography>
              </Box>
              <CloseIcon
                onClick={() => setOpen(false)}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <DetailsContainer style={{ paddingBottom: "80px" }}>
              <Box p={1.5}>
                <Typography variant="subtitle1">
                  {t("dataDisclosureAgreements.dataMarketplaceConnectionID")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>

                <ConnectionDropdown control={control} dda={"dda"} />
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => setOpen(false)}
                style={buttonStyle}
                sx={{
                  marginLeft: "10px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>
              <Button
                variant="outlined"
                style={
                  methods.formState.isValid ? buttonStyle : disabledButtonstyle
                }
                sx={{
                  cursor: methods.formState.isValid ? "pointer" : "not-allowed",
                  marginRight: "20px",
                  color: methods.formState.isValid ? "black" : "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                type="submit"
              >
                {" "}
                {t("dataAgreements.publish")}
              </Button>
            </FooterContainer>
          </form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
