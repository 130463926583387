/* eslint-disable no-prototype-builtins */
export const getCredentialDefinitionDefaultValues = (
  selectedDropdownValue: any,
  credentialFormat: any
) => {
  if (selectedDropdownValue === "VerifiableLegalPersonalIdentificationData") {
    if (credentialFormat === true) {
      return {
        type: "object",
        properties: {
          identifier: {
            type: "string",
            limitDisclosure: true,
          },
          legalName: {
            type: "string",
            limitDisclosure: true,
          },
        },
        required: ["identifier", "legalName"],
      };
    } else
      return {
        type: "object",
        properties: {
          identifier: {
            type: "string",
            limitDisclosure: false,
          },
          legalName: {
            type: "string",
            limitDisclosure: false,
          },
        },
        required: ["identifier", "legalName"],
      };
  } else if (selectedDropdownValue === "VerifiableCertificateOfRegistration") {
    if (credentialFormat === true) {
      return {
        type: "object",
        properties: {
          name: {
            type: "string",
            limitDisclosure: true,
          },
          legalForm: {
            type: "string",
            limitDisclosure: true,
          },
          activity: {
            type: "string",
            limitDisclosure: true,
          },
          registrationDate: {
            type: "string",
            limitDisclosure: true,
          },
          legalStatus: {
            type: "string",
            limitDisclosure: true,
          },
          registeredAddress: {
            type: "object",
            limitDisclosure: true,
            properties: {
              fullAddress: {
                type: "string",
                limitDisclosure: true,
              },
              thoroughFare: {
                type: "string",
                limitDisclosure: true,
              },
              locatorDesignator: {
                type: "string",
                limitDisclosure: true,
              },
              postCode: {
                type: "string",
                limitDisclosure: true,
              },
              postName: {
                type: "string",
                limitDisclosure: true,
              },
              adminUnitLevel1: {
                type: "string",
                limitDisclosure: true,
              },
            },
            required: [
              "fullAddress",
              "thoroughFare",
              "locatorDesignator",
              "postCode",
              "postName",
              "adminUnitLevel1",
            ],
          },
          orgNumber: {
            type: "string",
            limitDisclosure: true,
          },
        },
        required: [
          "name",
          "legalForm",
          "activity",
          "registrationDate",
          "legalStatus",
          "registeredAddress",
          "orgNumber",
        ],
      };
    } else
      return {
        type: "object",
        properties: {
          name: {
            type: "string",
            limitDisclosure: false,
          },
          legalForm: {
            type: "string",
            limitDisclosure: false,
          },
          activity: {
            type: "string",
            limitDisclosure: false,
          },
          registrationDate: {
            type: "string",
            limitDisclosure: false,
          },
          legalStatus: {
            type: "string",
            limitDisclosure: false,
          },
          registeredAddress: {
            type: "object",
            limitDisclosure: false,
            properties: {
              fullAddress: {
                type: "string",
                limitDisclosure: false,
              },
              thoroughFare: {
                type: "string",
                limitDisclosure: false,
              },
              locatorDesignator: {
                type: "string",
                limitDisclosure: false,
              },
              postCode: {
                type: "string",
                limitDisclosure: false,
              },
              postName: {
                type: "string",
                limitDisclosure: false,
              },
              adminUnitLevel1: {
                type: "string",
                limitDisclosure: false,
              },
            },
            required: [
              "fullAddress",
              "thoroughFare",
              "locatorDesignator",
              "postCode",
              "postName",
              "adminUnitLevel1",
            ],
          },
          orgNumber: {
            type: "string",
            limitDisclosure: false,
          },
        },
        required: [
          "name",
          "legalForm",
          "activity",
          "registrationDate",
          "legalStatus",
          "registeredAddress",
          "orgNumber",
        ],
      };
  } else if (selectedDropdownValue === "VerifiablePortableDocumentA1") {
    if (credentialFormat === true) {
      return {
        type: "object",
        properties: {
          section1: {
            type: "object",
            limitDisclosure: true,
            properties: {
              dateBirth: {
                type: "string",
                limitDisclosure: true,
              },
              forenames: {
                type: "string",
                limitDisclosure: true,
              },
              nationalities: {
                type: "array",
                limitDisclosure: true,
                items: {
                  type: "string",
                },
              },
              personalIdentificationNumber: {
                type: "string",
                limitDisclosure: true,
              },
              placeBirth: {
                type: "object",
                limitDisclosure: true,
                properties: {
                  countryCode: {
                    type: "string",
                    limitDisclosure: true,
                  },
                  region: {
                    type: "string",
                    limitDisclosure: true,
                  },
                  town: {
                    type: "string",
                    limitDisclosure: true,
                  },
                },
                required: ["countryCode", "region", "town"],
              },
              sex: {
                type: "string",
                limitDisclosure: true,
              },
              stateOfResidenceAddress: {
                type: "object",
                limitDisclosure: true,
                properties: {
                  countryCode: {
                    type: "string",
                    limitDisclosure: true,
                  },
                  postCode: {
                    type: "string",
                    limitDisclosure: true,
                  },
                  streetNo: {
                    type: "string",
                    limitDisclosure: true,
                  },
                  town: {
                    type: "string",
                    limitDisclosure: true,
                  },
                },
                required: ["countryCode", "postCode", "streetNo", "town"],
              },
              stateOfStayAddress: {
                type: "object",
                limitDisclosure: true,
                properties: {
                  countryCode: {
                    type: "string",
                    limitDisclosure: true,
                  },
                  postCode: {
                    type: "string",
                    limitDisclosure: true,
                  },
                  streetNo: {
                    type: "string",
                    limitDisclosure: true,
                  },
                  town: {
                    type: "string",
                    limitDisclosure: true,
                  },
                },
                required: ["countryCode", "postCode", "streetNo", "town"],
              },
              surname: {
                type: "string",
                limitDisclosure: true,
              },
              surnameAtBirth: {
                type: "string",
                limitDisclosure: true,
              },
            },
            required: [
              "dateBirth",
              "forenames",
              "nationalities",
              "personalIdentificationNumber",
              "placeBirth",
              "sex",
              "stateOfResidenceAddress",
              "stateOfStayAddress",
              "surname",
              "surnameAtBirth",
            ],
          },
          section2: {
            type: "object",
            limitDisclosure: true,
            properties: {
              certificateForDurationActivity: {
                type: "boolean",
                limitDisclosure: true,
              },
              determinationProvisional: {
                type: "boolean",
                limitDisclosure: true,
              },
              transitionRulesApplyAsEC8832004: {
                type: "boolean",
                limitDisclosure: true,
              },
              endingDate: {
                type: "string",
                limitDisclosure: true,
              },
              memberStateWhichLegislationApplies: {
                type: "string",
                limitDisclosure: true,
              },
              startingDate: {
                type: "string",
                limitDisclosure: true,
              },
            },
            required: [
              "certificateForDurationActivity",
              "determinationProvisional",
              "transitionRulesApplyAsEC8832004",
              "endingDate",
              "memberStateWhichLegislationApplies",
              "startingDate",
            ],
          },
          section3: {
            type: "object",
            limitDisclosure: true,
            properties: {
              civilAndEmployedSelfEmployed: {
                type: "boolean",
                limitDisclosure: true,
              },
              civilServant: {
                type: "boolean",
                limitDisclosure: true,
              },
              contractStaff: {
                type: "boolean",
                limitDisclosure: true,
              },
              employedAndSelfEmployed: {
                type: "boolean",
                limitDisclosure: true,
              },
              employedTwoOrMoreStates: {
                type: "boolean",
                limitDisclosure: true,
              },
              exception: {
                type: "boolean",
                limitDisclosure: true,
              },
              exceptionDescription: {
                type: "string",
                limitDisclosure: true,
              },
              flightCrewMember: {
                type: "boolean",
                limitDisclosure: true,
              },
              mariner: {
                type: "boolean",
                limitDisclosure: true,
              },
              postedEmployedPerson: {
                type: "boolean",
                limitDisclosure: true,
              },
              postedSelfEmployedPerson: {
                type: "boolean",
                limitDisclosure: true,
              },
              selfEmployedTwoOrMoreStates: {
                type: "boolean",
                limitDisclosure: true,
              },
              workingInStateUnder21: {
                type: "boolean",
                limitDisclosure: true,
              },
            },
            required: [
              "civilAndEmployedSelfEmployed",
              "civilServant",
              "contractStaff",
              "employedAndSelfEmployed",
              "employedTwoOrMoreStates",
              "exception",
              "exceptionDescription",
              "flightCrewMember",
              "mariner",
              "postedEmployedPerson",
              "postedSelfEmployedPerson",
              "selfEmployedTwoOrMoreStates",
              "workingInStateUnder21",
            ],
          },
          section4: {
            type: "object",
            limitDisclosure: true,
            properties: {
              employee: {
                type: "boolean",
                limitDisclosure: true,
              },
              selfEmployedActivity: {
                type: "boolean",
                limitDisclosure: true,
              },
              employerSelfEmployedActivityCodes: {
                type: "array",
                limitDisclosure: true,
                items: {
                  type: "string",
                },
              },
              nameBusinessName: {
                type: "string",
                limitDisclosure: true,
              },
              registeredAddress: {
                type: "object",
                limitDisclosure: true,
                properties: {
                  countryCode: {
                    type: "string",
                    limitDisclosure: true,
                  },
                  postCode: {
                    type: "string",
                    limitDisclosure: true,
                  },
                  streetNo: {
                    type: "string",
                    limitDisclosure: true,
                  },
                  town: {
                    type: "string",
                    limitDisclosure: true,
                  },
                },
                required: ["countryCode", "postCode", "streetNo", "town"],
              },
            },
            required: [
              "employee",
              "selfEmployedActivity",
              "employerSelfEmployedActivityCodes",
              "nameBusinessName",
              "registeredAddress",
            ],
          },
          section5: {
            type: "object",
            limitDisclosure: true,
            properties: {
              noFixedAddress: {
                type: "boolean",
                limitDisclosure: true,
              },
              workPlaceAddresses: {
                type: "array",
                limitDisclosure: true,
                items: {
                  type: "object",
                  properties: {
                    address: {
                      type: "object",
                      limitDisclosure: true,
                      properties: {
                        countryCode: {
                          type: "string",
                          limitDisclosure: true,
                        },
                        postCode: {
                          type: "string",
                          limitDisclosure: true,
                        },
                        streetNo: {
                          type: "string",
                          limitDisclosure: true,
                        },
                        town: {
                          type: "string",
                          limitDisclosure: true,
                        },
                      },
                      required: ["countryCode", "postCode", "streetNo", "town"],
                    },
                    seqno: {
                      type: "number",
                      limitDisclosure: true,
                    },
                  },
                  required: ["address", "seqno"],
                  limitDisclosure: true,
                },
              },
              workPlaceNames: {
                type: "array",
                limitDisclosure: true,
                items: {
                  type: "object",
                  properties: {
                    companyNameVesselName: {
                      type: "string",
                      limitDisclosure: true,
                    },
                    seqno: {
                      type: "number",
                      limitDisclosure: true,
                    },
                  },
                  required: ["companyNameVesselName", "seqno"],
                  limitDisclosure: true,
                },
              },
            },
            required: ["noFixedAddress", "workPlaceAddresses", "workPlaceNames"],
          },
          section6: {
            type: "object",
            limitDisclosure: true,
            properties: {
              address: {
                type: "object",
                limitDisclosure: true,
                properties: {
                  countryCode: {
                    type: "string",
                    limitDisclosure: true,
                  },
                  postCode: {
                    type: "string",
                    limitDisclosure: true,
                  },
                  streetNo: {
                    type: "string",
                    limitDisclosure: true,
                  },
                  town: {
                    type: "string",
                    limitDisclosure: true,
                  },
                },
                required: ["countryCode", "postCode", "streetNo", "town"],
              },
              date: {
                type: "string",
                limitDisclosure: true,
              },
              email: {
                type: "string",
                limitDisclosure: true,
              },
              institutionID: {
                type: "string",
                limitDisclosure: true,
              },
              name: {
                type: "string",
                limitDisclosure: true,
              },
              officeFaxNo: {
                type: "string",
                limitDisclosure: true,
              },
              officePhoneNo: {
                type: "string",
                limitDisclosure: true,
              },
              signature: {
                type: "string",
                limitDisclosure: true,
              },
            },
            required: [
              "address",
              "date",
              "email",
              "institutionID",
              "name",
              "officeFaxNo",
              "officePhoneNo",
              "signature",
            ],
          },
        },
        required: [
          "section1",
          "section2",
          "section3",
          "section4",
          "section5",
          "section6",
        ],
      };
    } else
      return {
        type: "object",
        properties: {
          section1: {
            type: "object",
            limitDisclosure: false,
            properties: {
              dateBirth: {
                type: "string",
                limitDisclosure: false,
              },
              forenames: {
                type: "string",
                limitDisclosure: false,
              },
              nationalities: {
                type: "array",
                limitDisclosure: false,
                items: {
                  type: "string",
                },
              },
              personalIdentificationNumber: {
                type: "string",
                limitDisclosure: false,
              },
              placeBirth: {
                type: "object",
                limitDisclosure: false,
                properties: {
                  countryCode: {
                    type: "string",
                    limitDisclosure: false,
                  },
                  region: {
                    type: "string",
                    limitDisclosure: false,
                  },
                  town: {
                    type: "string",
                    limitDisclosure: false,
                  },
                },
                required: ["countryCode", "region", "town"],
              },
              sex: {
                type: "string",
                limitDisclosure: false,
              },
              stateOfResidenceAddress: {
                type: "object",
                limitDisclosure: false,
                properties: {
                  countryCode: {
                    type: "string",
                    limitDisclosure: false,
                  },
                  postCode: {
                    type: "string",
                    limitDisclosure: false,
                  },
                  streetNo: {
                    type: "string",
                    limitDisclosure: false,
                  },
                  town: {
                    type: "string",
                    limitDisclosure: false,
                  },
                },
                required: ["countryCode", "postCode", "streetNo", "town"],
              },
              stateOfStayAddress: {
                type: "object",
                limitDisclosure: false,
                properties: {
                  countryCode: {
                    type: "string",
                    limitDisclosure: false,
                  },
                  postCode: {
                    type: "string",
                    limitDisclosure: false,
                  },
                  streetNo: {
                    type: "string",
                    limitDisclosure: false,
                  },
                  town: {
                    type: "string",
                    limitDisclosure: false,
                  },
                },
                required: ["countryCode", "postCode", "streetNo", "town"],
              },
              surname: {
                type: "string",
                limitDisclosure: false,
              },
              surnameAtBirth: {
                type: "string",
                limitDisclosure: false,
              },
            },
            required: [
              "dateBirth",
              "forenames",
              "nationalities",
              "personalIdentificationNumber",
              "placeBirth",
              "sex",
              "stateOfResidenceAddress",
              "stateOfStayAddress",
              "surname",
              "surnameAtBirth",
            ],
          },
          section2: {
            type: "object",
            limitDisclosure: false,
            properties: {
              certificateForDurationActivity: {
                type: "boolean",
                limitDisclosure: false,
              },
              determinationProvisional: {
                type: "boolean",
                limitDisclosure: false,
              },
              transitionRulesApplyAsEC8832004: {
                type: "boolean",
                limitDisclosure: false,
              },
              endingDate: {
                type: "string",
                limitDisclosure: false,
              },
              memberStateWhichLegislationApplies: {
                type: "string",
                limitDisclosure: false,
              },
              startingDate: {
                type: "string",
                limitDisclosure: false,
              },
            },
            required: [
              "certificateForDurationActivity",
              "determinationProvisional",
              "transitionRulesApplyAsEC8832004",
              "endingDate",
              "memberStateWhichLegislationApplies",
              "startingDate",
            ],
          },
          section3: {
            type: "object",
            limitDisclosure: false,
            properties: {
              civilAndEmployedSelfEmployed: {
                type: "boolean",
                limitDisclosure: false,
              },
              civilServant: {
                type: "boolean",
                limitDisclosure: false,
              },
              contractStaff: {
                type: "boolean",
                limitDisclosure: false,
              },
              employedAndSelfEmployed: {
                type: "boolean",
                limitDisclosure: false,
              },
              employedTwoOrMoreStates: {
                type: "boolean",
                limitDisclosure: false,
              },
              exception: {
                type: "boolean",
                limitDisclosure: false,
              },
              exceptionDescription: {
                type: "string",
                limitDisclosure: false,
              },
              flightCrewMember: {
                type: "boolean",
                limitDisclosure: false,
              },
              mariner: {
                type: "boolean",
                limitDisclosure: false,
              },
              postedEmployedPerson: {
                type: "boolean",
                limitDisclosure: false,
              },
              postedSelfEmployedPerson: {
                type: "boolean",
                limitDisclosure: false,
              },
              selfEmployedTwoOrMoreStates: {
                type: "boolean",
                limitDisclosure: false,
              },
              workingInStateUnder21: {
                type: "boolean",
                limitDisclosure: false,
              },
            },
            required: [
              "civilAndEmployedSelfEmployed",
              "civilServant",
              "contractStaff",
              "employedAndSelfEmployed",
              "employedTwoOrMoreStates",
              "exception",
              "exceptionDescription",
              "flightCrewMember",
              "mariner",
              "postedEmployedPerson",
              "postedSelfEmployedPerson",
              "selfEmployedTwoOrMoreStates",
              "workingInStateUnder21",
            ],
          },
          section4: {
            type: "object",
            limitDisclosure: false,
            properties: {
              employee: {
                type: "boolean",
                limitDisclosure: false,
              },
              selfEmployedActivity: {
                type: "boolean",
                limitDisclosure: false,
              },
              employerSelfEmployedActivityCodes: {
                type: "array",
                limitDisclosure: false,
                items: {
                  type: "string",
                },
              },
              nameBusinessName: {
                type: "string",
                limitDisclosure: false,
              },
              registeredAddress: {
                type: "object",
                limitDisclosure: false,
                properties: {
                  countryCode: {
                    type: "string",
                    limitDisclosure: false,
                  },
                  postCode: {
                    type: "string",
                    limitDisclosure: false,
                  },
                  streetNo: {
                    type: "string",
                    limitDisclosure: false,
                  },
                  town: {
                    type: "string",
                    limitDisclosure: false,
                  },
                },
                required: ["countryCode", "postCode", "streetNo", "town"],
              },
            },
            required: [
              "employee",
              "selfEmployedActivity",
              "employerSelfEmployedActivityCodes",
              "nameBusinessName",
              "registeredAddress",
            ],
          },
          section5: {
            type: "object",
            limitDisclosure: false,
            properties: {
              noFixedAddress: {
                type: "boolean",
                limitDisclosure: false,
              },
              workPlaceAddresses: {
                type: "array",
                limitDisclosure: false,
                items: {
                  type: "object",
                  properties: {
                    address: {
                      type: "object",
                      limitDisclosure: false,
                      properties: {
                        countryCode: {
                          type: "string",
                          limitDisclosure: false,
                        },
                        postCode: {
                          type: "string",
                          limitDisclosure: false,
                        },
                        streetNo: {
                          type: "string",
                          limitDisclosure: false,
                        },
                        town: {
                          type: "string",
                          limitDisclosure: false,
                        },
                      },
                      required: ["countryCode", "postCode", "streetNo", "town"],
                    },
                    seqno: {
                      type: "number",
                      limitDisclosure: false,
                    },
                  },
                  required: ["address", "seqno"],
                  limitDisclosure: false,
                },
              },
              workPlaceNames: {
                type: "array",
                limitDisclosure: false,
                items: {
                  type: "object",
                  properties: {
                    companyNameVesselName: {
                      type: "string",
                      limitDisclosure: false,
                    },
                    seqno: {
                      type: "number",
                      limitDisclosure: false,
                    },
                  },
                  required: ["companyNameVesselName", "seqno"],
                  limitDisclosure: false,
                },
              },
            },
            required: ["noFixedAddress", "workPlaceAddresses", "workPlaceNames"],
          },
          section6: {
            type: "object",
            limitDisclosure: false,
            properties: {
              address: {
                type: "object",
                limitDisclosure: false,
                properties: {
                  countryCode: {
                    type: "string",
                    limitDisclosure: false,
                  },
                  postCode: {
                    type: "string",
                    limitDisclosure: false,
                  },
                  streetNo: {
                    type: "string",
                    limitDisclosure: false,
                  },
                  town: {
                    type: "string",
                    limitDisclosure: false,
                  },
                },
                required: ["countryCode", "postCode", "streetNo", "town"],
              },
              date: {
                type: "string",
                limitDisclosure: false,
              },
              email: {
                type: "string",
                limitDisclosure: false,
              },
              institutionID: {
                type: "string",
                limitDisclosure: false,
              },
              name: {
                type: "string",
                limitDisclosure: false,
              },
              officeFaxNo: {
                type: "string",
                limitDisclosure: false,
              },
              officePhoneNo: {
                type: "string",
                limitDisclosure: false,
              },
              signature: {
                type: "string",
                limitDisclosure: false,
              },
            },
            required: [
              "address",
              "date",
              "email",
              "institutionID",
              "name",
              "officeFaxNo",
              "officePhoneNo",
              "signature",
            ],
          },
        },
        required: [
          "section1",
          "section2",
          "section3",
          "section4",
          "section5",
          "section6",
        ],
      };
  } else
    return {
      type: "object",
      properties: {},
      required: [],
    };
};

export const parseSchema = (schema: any, credentialFormatValue: any): any => {
  const parseProperties = (properties: any, requiredFields: any): any => {
    return Object.keys(properties).map((key: any) => {
      const property = properties[key];
      const parsedProperty: any = {
        name: key,
        type: property.type,
        required: requiredFields.includes(key),
        limitDisclosure:
          property.limitDisclosure !== undefined
            ? property.limitDisclosure
            : credentialFormatValue,
      };

      if (property.type === "object") {
        parsedProperty.properties = parseProperties(
          property.properties || {},
          property.required || []
        );
      } else if (property.type === "array") {
        parsedProperty.items = parseItems(property.items || { type: "string" });
      }

      return parsedProperty;
    });
  };

  const parseItems = (items: any): any => {
    if (Array.isArray(items)) {
      return {
        type: "array",
        items: items.map((item: any) => parseItems(item)),
      };
    }

    if (items.type === "object") {
      return {
        type: "object",
        properties: parseProperties(
          items.properties || {},
          items.required || []
        ),
        limitDisclosure:
          items.limitDisclosure !== undefined
            ? items.limitDisclosure
            : credentialFormatValue,
      };
    }

    return items;
  };

  return Object.keys(schema.properties).map((key: any) => {
    const property = schema.properties[key];
    return {
      name: key,
      type: property.type,
      properties:
        property.type === "object"
          ? parseProperties(property.properties || {}, property.required || [])
          : {},
      items: property.type === "array" ? parseItems(property.items || {}) : {},
      required: schema.required ? schema.required.includes(key) : false,
      limitDisclosure:
        property.limitDisclosure !== undefined
          ? property.limitDisclosure
          : credentialFormatValue,
    };
  });
};

export const generateJsonSchema = (
  sections: any[],
  credentialFormatValue: boolean
): any => {
  const generateProperties = (properties: any[]): any => {
    if (!Array.isArray(properties)) {
      return {};
    }

    return properties.reduce((acc: any, property: any) => {
      acc[property.name] = { type: property.type };

      // Add or update limitDisclosure attribute based on credentialFormat
      if (property.limitDisclosure !== undefined) {
        acc[property.name].limitDisclosure = property.limitDisclosure;
      } else {
        acc[property.name].limitDisclosure = credentialFormatValue;
      }

      if (property.type === "object") {
        acc[property.name].properties = generateProperties(
          property.properties || []
        );
        acc[property.name].required = property.properties
          ? property.properties
              .filter((prop: any) => prop.required)
              .map((prop: any) => prop.name)
          : [];
      } else if (property.type === "array") {
        acc[property.name].items = generateItems(
          property.items || { type: "string" }
        );
      }

      return acc;
    }, {});
  };

  const generateItems = (items: any): any => {
    if (Array.isArray(items)) {
      return {
        type: "array",
        items: items.map((item: any) => generateItems(item)),
      };
    }

    if (items.type === "object") {
      return {
        type: "object",
        properties: generateProperties(items.properties || []),
        required: items.properties
          ? items.properties
              .filter((prop: any) => prop.required)
              .map((prop: any) => prop.name)
          : [],
        limitDisclosure:
          items.limitDisclosure !== undefined
            ? items.limitDisclosure
            : credentialFormatValue,
      };
    }

    return items;
  };

  const schema: any = {
    type: "object",
    properties: {},
    required: [],
  };

  sections.forEach((section: any) => {
    schema.properties[section.name] = {
      type: section.type,
      limitDisclosure:
        section.limitDisclosure !== undefined
          ? section.limitDisclosure
          : credentialFormatValue,
    };

    if (section.type === "object") {
      schema.properties[section.name].properties = generateProperties(
        section.properties || []
      );

      if (section.properties && Array.isArray(section.properties)) {
        schema.properties[section.name].required = section.properties
          .filter((prop: any) => prop.required)
          .map((prop: any) => prop.name);
      }
    } else if (section.type === "array") {
      schema.properties[section.name].items = generateItems(
        section.items || { type: "string" }
      );
    }

    if (section.required) {
      schema.required.push(section.name);
    }
  });

  return schema;
};

function addAdditionalProperties(schemaObj: any): any {
  if (schemaObj.type && schemaObj.type === "object") {
    schemaObj.additionalProperties = false;
  }
  if (schemaObj.properties) {
    Object.values(schemaObj.properties).forEach((property: any) => {
      addAdditionalProperties(property);
    });
  }
  return schemaObj;
}

export function addAdditionalPropertFieldTOJSONSchema(schema: string): string {
  const schemaObj: any = JSON.parse(schema);
  return addAdditionalProperties(schemaObj);
}

export const setJsonSchemaValueOnChange = (
  value: any,
  setValue: any,
  credentialFormat: any
) => {
  const schemaValue = JSON.stringify(
    getCredentialDefinitionDefaultValues(value, credentialFormat),
    null,
    4
  );
  setValue("jsonSchema", schemaValue);

  if (schemaValue.trim() === "") {
    setValue("credentialDefinition", []);
    return;
  }

  try {
    const parsedSchema = JSON.parse(schemaValue);
    const newSections = parseSchema(parsedSchema, credentialFormat);
    setValue("credentialDefinition", newSections);
    updateCredentialFormatValue(parsedSchema, setValue);
  } catch (e) {
    console.error("Invalid JSON schema", e);
  }
};

export const updateCredentialFormatValue = (jsonData: any, setValue: any) => {
  let hasTrueValue = false;
  let hasLimitDisclosure = false;

  function traverse(obj: any) {
    if (typeof obj === "object" && obj !== null) {
      Object.keys(obj).forEach((key) => {
        if (key === "limitDisclosure") {
          hasLimitDisclosure = true;
          if (obj[key] === true) {
            hasTrueValue = true;
          }
        }
        traverse(obj[key]);
      });
    } else if (Array.isArray(obj)) {
      obj.forEach((item) => traverse(item));
    }
  }

  traverse(jsonData);

  // If there are no limitDisclosure properties, keep the current value
  if (!hasLimitDisclosure) {
    return;
  }

  setValue("credentialFormat", hasTrueValue);
};

export const handleCredentialFormatValueChange = (
  event: any,
  setValue: any,
  watch: any
) => {
  const isChecked = event;
  setValue("credentialFormat", isChecked);

  // Get the current JSON schema value
  const currentJsonSchema = JSON.parse(watch("jsonSchema"));

  // If the schema is empty, don't update it
  if (Object.keys(currentJsonSchema.properties).length === 0) {
    return;
  }

  // Update each limitDisclosure property based on the isChecked value
  const updatedJsonSchema = updateLimitDisclosure(currentJsonSchema, isChecked);

  // Set the updated JSON schema value
  setValue("jsonSchema", JSON.stringify(updatedJsonSchema, null, 2));

  updateCredentialFormatValue(updatedJsonSchema, setValue);

  try {
    const newSections = parseSchema(
      updatedJsonSchema,
      watch("credentialFormat")
    );
    setValue("credentialDefinition", newSections);
  } catch (e) {
    console.error("Invalid JSON schema", e);
  }
};

// Function to update limitDisclosure property in the JSON schema
const updateLimitDisclosure = (schema: any, newValue: any) => {
  const traverseAndUpdate = (obj: any) => {
    if (typeof obj === "object" && obj !== null) {
      Object.keys(obj).forEach((key) => {
        if (key === "limitDisclosure") {
          obj[key] = newValue; // Update the limitDisclosure property
        }
        traverseAndUpdate(obj[key]);
      });
    } else if (Array.isArray(obj)) {
      obj.forEach((item) => traverseAndUpdate(item));
    }
  };

  // Deep copy the schema to prevent mutation of the original object
  const updatedSchema = JSON.parse(JSON.stringify(schema));
  traverseAndUpdate(updatedSchema);
  return updatedSchema;
};
