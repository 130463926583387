import VerticalStepper from "../../components/onboarding/stepper";
import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import Footer from "../../components/layout/PageFooter";
import { configStore } from "../../store/configStore";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import SnackbarComponent from "../../components/notification";
import { OnBoardingHttpService } from "../../service/onboardingService";
import { useOnBoardingStore } from "../../store/onBoardingStore";
import ServiceAgreementPage from "../../components/billing/packageSelector/serviceAgreement";
import BreadCrumb from "../../components/Breadcrumbs";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";
import PaymentInformation from "../../components/billing/packageSelector/paymentInformation";
import BillingAddressForm from "../../components/billing/packageSelector/billingAddress";
import CommittedUsage from "../../components/billing/packageSelector/committedUsage";
import ReviewAndConfirmOrder from "../../components/billing/packageSelector/reviewAndConfirmOrder";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const StepperContainer = styled("div")(({ theme }) => ({
  padding: "0px",
  [theme.breakpoints.down("sm")]: {
    margin: "50px 0 10px 0",
  },
}));

const DetailsContainer = styled("div")(() => ({
  display: "grid",
  borderRadius: 3,
  padding: "12px",
  width: "100%",
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const OrderingWizard = () => {
  const version = configStore.appVersion;
  const { t } = useTranslation("translation");

  const selectedPackageFromPackageSelector = useDigitalWalletStore(
    (state) => state.selectedPackageFromPackageSelector
  );

  const [activeStep, setActiveStep] = useState(0);

  const [error, setError] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const steps = [
    t("onboarding.serviceAgreement"),
    t("onboarding.billingAddress"),
    t("onboarding.paymentInformation"),
    t("billing.reviewAndConfirmOrder"),
  ];

  const steps1 = [
    t("billing.committedUsage"),
    t("onboarding.serviceAgreement"),
    t("onboarding.billingAddress"),
    t("onboarding.paymentInformation"),
    t("billing.reviewAndConfirmOrder"),
  ];

  useEffect(() => {
    OnBoardingHttpService.listPricingInfo().then((res) => {
      useOnBoardingStore.getState().updatePricingInfoFromApi(res.data);
    });
  }, []);

  return (
    <Container>
      <SnackbarComponent
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={error}
        topStyle={100}
        successMessage={successMessage}
      />
      <BreadCrumb
        Link={t("sidebar.account")}
        Link2={t("sidebar.billing")}
        Link3={t("billing.packageSelector")}
      />

      <HeaderContainer>
        <Typography variant="h6" fontWeight="bold">
          {t("billing.ordering")}
        </Typography>
      </HeaderContainer>
      <Typography variant="body2" mt={1.25}>
        {t("billing.pageDescription")}:
      </Typography>

      <Grid item lg={3} md={3} sm={12} xs={12}>
        <StepperContainer>
          <VerticalStepper
            steps={
              selectedPackageFromPackageSelector === "payperuser"
                ? steps1
                : steps
            }
            activeStep={activeStep}
            orientation="horizontal"
          />
        </StepperContainer>
      </Grid>
      <Grid
        item
        lg={8}
        md={8}
        sm={12}
        xs={12}
        sx={{ marginTop: { xs: "20px", sm: "20px", md: 0 } }}
      >
        <DetailsContainer>
          {selectedPackageFromPackageSelector === "payperuser" ? (
            <>
              {activeStep === 0 && (
                <CommittedUsage
                  handleNext={handleNext}
                  setError={setError}
                  setOpenSnackBar={setOpenSnackBar}
                />
              )}

              {activeStep === 1 && (
                <ServiceAgreementPage
                  handleNext={handleNext}
                  setError={setError}
                  setOpenSnackBar={setOpenSnackBar}
                  setActiveStep={setActiveStep}
                />
              )}

              {activeStep === 2 && (
                <BillingAddressForm
                  handleBack={handleBack}
                  handleNext={handleNext}
                  setError={setError}
                  setOpenSnackBar={setOpenSnackBar}
                />
              )}

              {activeStep === 3 && (
                <PaymentInformation
                  handleBack={handleBack}
                  handleNext={handleNext}
                  setError={setError}
                  setOpenSnackBar={setOpenSnackBar}
                />
              )}

              {activeStep === 4 && (
                <ReviewAndConfirmOrder
                  handleBack={handleBack}
                  setError={setError}
                  setOpenSnackBar={setOpenSnackBar}
                  setSuccessMessage={setSuccessMessage}
                />
              )}
            </>
          ) : (
            <>
              {activeStep === 0 && (
                <ServiceAgreementPage
                  handleNext={handleNext}
                  setError={setError}
                  setOpenSnackBar={setOpenSnackBar}
                  setActiveStep={setActiveStep}
                />
              )}

              {activeStep === 1 && (
                <BillingAddressForm
                  handleBack={handleBack}
                  handleNext={handleNext}
                  setError={setError}
                  setOpenSnackBar={setOpenSnackBar}
                />
              )}

              {activeStep === 2 && (
                <PaymentInformation
                  handleBack={handleBack}
                  handleNext={handleNext}
                  setError={setError}
                  setOpenSnackBar={setOpenSnackBar}
                />
              )}

              {activeStep === 3 && (
                <ReviewAndConfirmOrder
                  handleBack={handleBack}
                  setError={setError}
                  setOpenSnackBar={setOpenSnackBar}
                  setSuccessMessage={setSuccessMessage}
                />
              )}
            </>
          )}
        </DetailsContainer>
      </Grid>
      <Footer version={version} />
    </Container>
  );
};

export default OrderingWizard;
