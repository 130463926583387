import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  Drawer,
  Typography,
  Button,
  Box,
  TextField,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  disabledButtonstyle,
} from "../modalStyle";

import {
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import LoaderComponent from "../../LoaderComponent";
import QRCodeGenerateModalForEWC from "../QRCodeGenerateModalForEWC";
import { HttpServiceForDigitalWalletEwc } from "../../../service/digitalWalletEwcHttpService";
import SnackbarComponent from "../../notification";

import SelectGrantTypeForEwcIssuance from "../../dropdowns/selectGrantTypeForEwcIssuance";
import { DataAttributeValueInputForOID4VC } from "../../issuanceHistoryForOID4VC/DataAttributeValueInputForOID4VC";
import EwcIssueCredentialManuallybyJson from "./ewcIssueCredentialManuallybyJson";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EwcIssuanceModeRadio from "../../radio/ewcIssuanceModeRadio";
import {
  generateJsonFromSchema,
  validateJson,
  removeLimitDisclosureFromSchema,
} from "../../../utils/issuCredentialUtils";
import DynamicCredentialRequestComponent from "../../issuanceHistoryForOID4VC/dynamicCredentialRequestComponent";
import IssueType from "../../issuanceHistoryForOID4VC/issueType";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  onRefetch: any;
  selectedData?: any;
  ssi: string;
  issuanceModalMode: string;
  selectedRecord?: any;
}

let defaultValue: any = {
  issuanceMode: "InTime",
  userPin: "",
  limitedDisclosure: "false",
  grantTypeDropdown: "pre-authorised",
  CredentialDefintionJSONValue: "",
  credentialDefinition: [],
  presentationDefinitionId: "",
  dynamicCredentialRequest: false,
  credentialOfferEndpoint: "",
  selectCredentialOfferEndpoint: false,
};

export default function EwcIssuenceModal(props: Props) {
  const { t } = useTranslation("translation");
  const {
    open,
    setOpen,
    headerText,
    onRefetch,
    selectedData,
    ssi,
    issuanceModalMode,
    selectedRecord,
  } = props;
  const [openLoader, setOpenLoader] = useState(false);
  const [openQRCodeGenerateModal, setOpenQRCodeGenerateModal] = useState(false);
  const [openCredentialJSONModal, setOpenCredentialJSONModal] = useState(false);

  const [issuedValue, setIssuedValue] = useState<any>();
  const [error, setError] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [isValidJson, setIsValidJson] = useState(true);

  const issuanceModes = [
    { value: "InTime", label: t("credentials.inTime") },
    {
      value: "Deferred",
      label: t("credentials.deferred"),
    },
  ];

  const methods = useForm({
    defaultValues: {
      ...defaultValue,
    },
    mode: "onChange",
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    formState,
    reset,
    setValue,
    resetField,
  } = methods;

  const { fields } = useFieldArray({
    control,
    name: "credentialDefinition",
  });

  const grantTypeDropdownValues = [
    { label: "Pre-Authorised Code Flow", value: "pre-authorised" },
    {
      label: "Authorisation Code Flow",
      value: "authorised",
    },
  ];

  const selectedGrantType = useWatch({
    control: control,
    name: `grantTypeDropdown`,
  });

  const selectedIssuanceMode = useWatch({
    control: control,
    name: `issuanceMode`,
  });

  const CredentialDefintionJSONWatchValue = useWatch({
    control: control,
    name: `CredentialDefintionJSONValue`,
  });

  const selectCredentialOfferEndpointValue = useWatch({
    control: control,
    name: `selectCredentialOfferEndpoint`,
  });

  const dynamicCredentialRequestValue = useWatch({
    control: control,
    name: `dynamicCredentialRequest`,
  });

  const presentationDefinitionValue = useWatch({
    control: control,
    name: `presentationDefinitionId`,
  });

  const grantTypeDropdownWatch = useWatch({
    control: control,
    name: `grantTypeDropdown`,
  });

  useEffect(() => {
    let schemaWithoutLimitDisclore = removeLimitDisclosureFromSchema(
      issuanceModalMode === "Create"
        ? selectedData?.credentialDefinition
        : selectedRecord?.credentialDefinition,
      "limitDisclosure"
    );
    let jsonSchema = JSON.stringify(
      generateJsonFromSchema(schemaWithoutLimitDisclore),
      null,
      2
    );

    const parsedData = JSON.parse(jsonSchema);
    const parsedFields = Object.entries(parsedData).map(([key, value]) => ({
      key,
      value,
      type: Array.isArray(value) ? "array" : typeof value,
    }));

    if (issuanceModalMode === "Update") {
      reset({
        issuanceMode: "InTime",
        userPin: "",
        credentialDefinition: parsedFields,
        grantTypeDropdown: "pre-authorised",
        CredentialDefintionJSONValue: jsonSchema,
      });
    } else if (issuanceModalMode === "Create") {
      reset({
        issuanceMode: "InTime",
        userPin: "",
        credentialDefinition: parsedFields,
        grantTypeDropdown: "pre-authorised",
        CredentialDefintionJSONValue: jsonSchema,
        presentationDefinitionId: "",
        dynamicCredentialRequest: false,
        credentialOfferEndpoint: "",
        selectCredentialOfferEndpoint: false,
      });
    } else reset(defaultValue);
  }, [open, selectedData, reset, issuanceModalMode]);

  useEffect(() => {
    if (fields.length > 0) {
      setValue(
        "CredentialDefintionJSONValue",
        JSON.stringify(
          fields.reduce(
            (acc: any, curr: any) => ({ ...acc, [curr.key]: curr.value }),
            {}
          ),
          null,
          2
        )
      );
    }
  }, [fields]);

  const handleTextAreaChange = (value: any) => {
    setValue("CredentialDefintionJSONValue", value);
    try {
      const parsedData = JSON.parse(value);
      const parsedFields = Object.entries(parsedData).map(([key, value]) => ({
        key,
        value,
        type: Array.isArray(value) ? "array" : typeof value,
      }));
      setValue("credentialDefinition", parsedFields);
    } catch (error) {
      // console.error("Invalid JSON format");
    }
  };

  const handleInputChange = (index: any, value: any) => {
    const updatedFields: any = [...fields];
    updatedFields[index].value = value;
    setValue("credentialDefinition", updatedFields);
  };

  const isSubmitButtonEnabled =
    (issuanceModalMode === "Update" &&
      isValidJson &&
      CredentialDefintionJSONWatchValue.length > 0 &&
      validateJson(CredentialDefintionJSONWatchValue)) ||
    (issuanceModalMode === "Create" &&
      isValidJson &&
      selectedIssuanceMode === "InTime" &&
      CredentialDefintionJSONWatchValue.length > 0 &&
      validateJson(CredentialDefintionJSONWatchValue) &&
      formState.isValid &&
      dynamicCredentialRequestValue === false) ||
    (issuanceModalMode === "Create" &&
      isValidJson &&
      selectedIssuanceMode === "InTime" &&
      CredentialDefintionJSONWatchValue.length > 0 &&
      validateJson(CredentialDefintionJSONWatchValue) &&
      formState.isValid &&
      dynamicCredentialRequestValue === true &&
      presentationDefinitionValue !== "") ||
    (issuanceModalMode === "Create" &&
      selectedIssuanceMode !== "InTime" &&
      dynamicCredentialRequestValue === false) ||
    (issuanceModalMode === "Create" &&
      selectedIssuanceMode !== "InTime" &&
      dynamicCredentialRequestValue === true &&
      presentationDefinitionValue !== "");

  const onSubmit = (createdData: any) => {
    const payloadForCreateIssuance = () => {
      const data: any = {
        issuanceMode: createdData.issuanceMode,
        userPin: createdData.userPin,
        credentialDefinitionId: selectedData.credentialDefinitionId,
      };

      if (createdData.issuanceMode === "InTime") {
        data.credential = {
          credentialSubject: JSON.parse(
            createdData.CredentialDefintionJSONValue.trim()
          ),
        };
      }

      if (createdData.presentationDefinitionId !== "") {
        data.presentationDefinitionId = createdData.presentationDefinitionId;
      }

      if (createdData.credentialOfferEndpoint !== "") {
        data.credentialOfferEndpoint = createdData.credentialOfferEndpoint;
      }

      return data;
    };

    if (
      issuanceModalMode === "Update" &&
      isValidJson &&
      CredentialDefintionJSONWatchValue.length > 0 &&
      validateJson(CredentialDefintionJSONWatchValue)
    ) {
      setOpenLoader(true);

      let payload = {
        credential: {
          credentialSubject: JSON.parse(
            createdData.CredentialDefintionJSONValue.trim()
          ),
        },
      };
      HttpServiceForDigitalWalletEwc.updateIssueCredentialForEWC(
        payload,
        selectedData.CredentialExchangeId
      )
        .then(() => {
          setOpenLoader(false);
          setOpen(false);
          onRefetch();
        })
        .catch((err) => {
          setOpenLoader(false);
          setError(err.response.data.errorDescription);
          setOpenSnackBar(true);
        });
    } else if (
      (issuanceModalMode === "Create" &&
        selectedIssuanceMode === "InTime" &&
        validateJson(CredentialDefintionJSONWatchValue) &&
        formState.isValid &&
        isValidJson &&
        CredentialDefintionJSONWatchValue.length > 0) ||
      (issuanceModalMode === "Create" && selectedIssuanceMode !== "InTime")
    ) {
      setOpenLoader(true);

      HttpServiceForDigitalWalletEwc.issueCredentialForEWC(
        payloadForCreateIssuance()
      )
        .then((res) => {
          selectCredentialOfferEndpointValue
            ? (setOpenLoader(false), setOpen(false))
            : (setIssuedValue(res.data.credentialHistory),
              setOpenQRCodeGenerateModal(true),
              setOpenLoader(false));
        })
        .catch((err) => {
          setOpenLoader(false);
          setError(err.response.data.errorDescription);
          setOpenSnackBar(true);
        });
    }
  };

  const typeOrder: any = {
    string: 1,
    number: 2,
    boolean: 3,
    object: 4,
    array: 5,
  };

  const onCloseModal = () => {
    try {
      JSON.parse(methods.watch("CredentialDefintionJSONValue"));
    } catch (e: any) {
      // setError(e.message);
      // setOpenSnackBar(true);
      // setIsValidJson(false);
    }

    if (error !== "") {
      setOpenSnackBar(true);
      setIsValidJson(false);
    } else {
      setIsValidJson(true);
    }

    setOpenCredentialJSONModal(false);
  };

  return (
    <>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container style={{ overflow: openLoader ? "hidden" : undefined }}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <HeaderContainer>
                <SnackbarComponent
                  open={openSnackBar}
                  setOpen={setOpenSnackBar}
                  message={error}
                />
                <Box pl={2}>
                  <Typography color="#F3F3F6">{headerText}</Typography>
                </Box>
                <CloseIcon
                  onClick={() => {
                    setOpen(false);
                    reset(defaultValue);
                  }}
                  sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
                />
              </HeaderContainer>

              <DetailsContainer style={{ paddingBottom: "80px" }}>
                <Box p={1.5}>
                  {issuanceModalMode === "Create" && (
                    <>
                      <EwcIssuanceModeRadio
                        control={control}
                        modes={issuanceModes}
                        name="issuanceMode"
                      />
                      <SelectGrantTypeForEwcIssuance
                        dropdownValue={grantTypeDropdownValues}
                        control={control}
                        nameOfSelect={"grantTypeDropdown"}
                        resetField={resetField}
                      />

                      <Typography
                        style={{
                          fontSize: "14px",
                          textDecoration: "underline",
                          color: "grey",
                          marginTop: "-7px",
                          cursor: "not-allowed",
                        }}
                        onClick={() => {}}
                      >
                        ({"Configure"})
                      </Typography>

                      <Typography variant="subtitle1" mt={1.5}>
                        {t("credentials.userPin")}
                      </Typography>
                      <TextField
                        variant="standard"
                        autoComplete="off"
                        fullWidth
                        error={!!errors.userPin}
                        sx={{
                          marginBottom: "15px",
                          "& .MuiInput-underline.Mui-error:after": {
                            borderBottom: "2px solid #d32f2f",
                          },
                          "& .MuiInput-underline.Mui-error:before": {
                            borderBottom: "2px solid #d32f2f",
                          },
                          "& .MuiInput-input.Mui-disabled": {
                            cursor: "not-allowed",
                          },
                        }}
                        placeholder={t("credentials.userPinPlaceHolder")}
                        {...register(`userPin`, {
                          pattern: /^[0-9]*$/,
                          minLength: 4,
                          maxLength: 4,
                          disabled: selectedGrantType === "authorised",
                        })}
                      />

                      <DynamicCredentialRequestComponent
                        control={control}
                        setValue={setValue}
                        grantTypeDropdownWatch={grantTypeDropdownWatch}
                      />
                    </>
                  )}
                  {((selectedIssuanceMode === "InTime" &&
                    issuanceModalMode === "Create") ||
                    issuanceModalMode === "Update") && (
                    <>
                      <Box
                        mt={1.5}
                        mb={1.5}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="subtitle1">
                          {t("issuanceHistory.credentialDefinition")}
                          <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>
                            *
                          </span>
                        </Typography>

                        <Tooltip
                          title={"Enter free text in JSON format"}
                          placement="top"
                        >
                          <EditOutlinedIcon
                            onClick={() => {
                              setError("");
                              setOpenCredentialJSONModal(true);
                            }}
                            fontSize="small"
                            style={{
                              cursor: "pointer",
                              color: "black",
                            }}
                          />
                        </Tooltip>
                      </Box>

                      <Typography variant="subtitle1" mb={1}>
                        {t("dataAgreements.dataAttributes")}
                        <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>
                          *
                        </span>
                      </Typography>

                      <table
                        style={{
                          border: "solid 1px #dee2e6",
                          width: "100%",
                          maxWidth: "100%",
                          marginBottom: "1rem",
                          marginTop: ".5rem",
                        }}
                      >
                        {fields
                          .sort((a: any, b: any) => {
                            return typeOrder[a.type] - typeOrder[b.type];
                          })
                          .map(({ key, value, type }: any, index) => (
                            <>
                              <DataAttributeValueInputForOID4VC
                                key={index}
                                index={index}
                                handleInputChange={handleInputChange}
                                attributeName={key}
                                attributeValue={value}
                                attributeType={type}
                              />
                            </>
                          ))}
                      </table>
                    </>
                  )}

                  {issuanceModalMode === "Create" && (
                    <IssueType
                      register={register}
                      setValue={setValue}
                      control={control}
                      selectCredentialOfferEndpointValue={
                        selectCredentialOfferEndpointValue
                      }
                    />
                  )}
                </Box>
              </DetailsContainer>
              <FooterContainer>
                <Button
                  onClick={() => {
                    setOpen(false), reset(defaultValue);
                  }}
                  style={buttonStyle}
                  sx={{
                    marginLeft: "10px",
                    color: "black",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                    },
                  }}
                  variant="outlined"
                >
                  {t("common.close")}
                </Button>
                <Button
                  variant="outlined"
                  style={
                    isSubmitButtonEnabled ? buttonStyle : disabledButtonstyle
                  }
                  sx={{
                    cursor: isSubmitButtonEnabled ? "pointer" : "not-allowed",
                    marginRight: "20px",
                    color: isSubmitButtonEnabled ? "black" : "#6D7676",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                    },
                  }}
                  type="submit"
                >
                  {ssi === "credentials"
                    ? t("credentials.issue")
                    : t("verifications.verify")}
                </Button>
              </FooterContainer>
            </form>
          </FormProvider>

          <QRCodeGenerateModalForEWC
            open={openQRCodeGenerateModal}
            setOpen={setOpenQRCodeGenerateModal}
            setOpenIssuenceModal={setOpen}
            issuedValue={issuedValue}
            onRefetch={onRefetch}
            headerText={t("credentials.credentialOffer")}
          />

          <EwcIssueCredentialManuallybyJson
            open={openCredentialJSONModal}
            onCloseModal={onCloseModal}
            methods={methods}
            headerText={t(
              "issuanceHistory.issueCredentialsCredentialDefinition"
            )}
            handleChange={handleTextAreaChange}
            name={"CredentialDefintionJSONValue"}
            inputFieldName={t("issuanceHistory.credentialDefinition")}
            inputPlaceHolderName={t("issuanceHistory.credentialDefinition")}
            setError={setError}
            schema={
              issuanceModalMode === "Create"
                ? selectedData?.credentialDefinition
                : selectedRecord?.credentialDefinition
            }
            mode={"Issue"}
          />
        </Container>
      </Drawer>
    </>
  );
}
