import React, { useState } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { useOnBoardingStore } from "../../store/onBoardingStore";
import { useNavigate } from "react-router-dom";
import { OnBoardingHttpService } from "../../service/onboardingService";

const ButtonContainer = styled("div")(() => ({
  display: "flex",
  marginTop: "30px",
  justifyContent: "right",
}));

const buttonStyle = {
  height: 30,
  width: 150,
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const CheckoutForm = ({ stripe }: any) => {
  const [valid, setValid] = useState(false);
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const [cardDetail, setCardDetails] = useState<any>();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (valid || cardDetail.card !== undefined) {
      try {
        const { token } = await stripe!.createToken();
        setCardDetails(token);
        OnBoardingHttpService.addBillingPaymentSource({ id: token.id });
        useOnBoardingStore.getState().updateCardDetails(token);
        setValid(false);
        navigate("/billing");
      } catch (error) {
        console.error("Error creating token:", error);
      }
    }
  };

  const handleChange = (event: any) => {
    setValid(event.complete);
  };

  return (
    <>
      <Box style={{ height: "30px" }} display={"grid"} alignItems={"center"}>
        <CardElement onChange={handleChange} />
      </Box>

      <ButtonContainer>
        <Button
          onClick={() => navigate("/billing")}
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
        >
          {t("common.cancel")}
        </Button>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            color: valid ? "black" : "#6D7676",
            cursor: valid ? "pointer" : "not-allowed",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          onClick={(e) => valid && handleSubmit(e)}
        >
          {t("onboarding.addCard")}
        </Button>
      </ButtonContainer>
    </>
  );
};

export default injectStripe(CheckoutForm);
