import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CommonButton from "../buttons/commonButton";
import { Box } from "@mui/system";
import { formatExpiryDate, getBrandLogo } from "../../utils/billingUtils";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";
import { useNavigate } from "react-router-dom";

const PaymentMethod = () => {
  const { t } = useTranslation("translation");
  const navigate = useNavigate();

  const cardDetail = useDigitalWalletStore(
    (state) =>
      state.readExistingBillingDetails?.billingInfo?.defaultPaymentSource
  );

  return (
    <>
      <Typography variant="subtitle1" color={"black"} fontWeight="bold">
        {t("onboarding.paymentMethod")}
      </Typography>
      {cardDetail?.brand !== "" ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src={getBrandLogo(cardDetail?.brand)}
            style={{
              height: 50,
              border: 1,
            }}
            className="pl-3 pt-3"
          />
          <Typography variant="caption" textAlign={"right"}>
            XXXX XXXX XXXX {cardDetail?.last4Digits}
            <br />
            <span style={{ fontSize: "12px", color: "grey" }}>
              Expires{" "}
              {formatExpiryDate({
                month: cardDetail?.expiryMonth,
                year: cardDetail?.expiryYear,
              })}
            </span>
          </Typography>
        </Box>
      ) : (
        <Typography variant="subtitle2" color={"black"} mt={1} mb={1}>
          {t("billing.pleaseAddAPaymentMethod")}
        </Typography>
      )}

      <Box
        style={{
          width: "100%",
          marginLeft: "auto",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <CommonButton
          buttonName={
            cardDetail?.brand !== "" ? t("billing.EDIT") : t("billing.add")
          }
          width={120}
          handleClick={() => navigate("/paymentDetails")}
        />
      </Box>
    </>
  );
};

export default PaymentMethod;
