import { Button, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { OnBoardingHttpService } from "../../service/onboardingService";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useOnBoardingStore } from "../../store/onBoardingStore";
import OtpInput from "react-otp-input";
import { styled } from "@mui/material/styles";

const buttonStyle = {
  height: 30,
  width: 150,
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const disabledButtonstyle = {
  height: 30,
  width: 150,
  borderRadius: 0,
  border: "1px solid #EAEAEA",
};

interface Props {
  handleBack: any;
  handleNext: any;
  setError: Dispatch<SetStateAction<string>>;
  setOpenSnackBar: Dispatch<SetStateAction<boolean>>;
  setSuccessMessage: Dispatch<SetStateAction<string>>;
}

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
  [theme.breakpoints.down("sm")]: {
    display: "grid",
    alignContent: "center",
  },
}));

const MobileVerification = (props: Props) => {
  const { handleBack, handleNext, setError, setOpenSnackBar, setSuccessMessage } = props;
  const { t } = useTranslation("translation");
  const [otp, setOtp] = useState("");
  const numInputs = 6;

  useEffect(() => {
    OnBoardingHttpService.listOrgTypes().then((res) => {
      let types = res.data.organisationTypes;
      useOnBoardingStore.getState().updateorganisationTypesFromApi(
        types.map((type: any) => {
          return {
            id: type.id,
            type: type.type,
            imageId: type.imageId,
            imageUrl: type.imageId,
          };
        })
      );
    });
  }, []);

  const onbordingStore = useOnBoardingStore(
    (state) => state.orgAdminInformation
  );

  const resendOtp = () => {
    OnBoardingHttpService.verifyPhone({ phone: onbordingStore.orgPhone })
      .then((res) => {
        setOpenSnackBar(true);
        setSuccessMessage(res.data.message)
      })
      .catch((error) => {
        setOpenSnackBar(true);
        setError(error.response.data.errorDescription);
      });
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (otp.length === numInputs) {
      OnBoardingHttpService.verifyOtp({
        phone: onbordingStore.orgPhone,
        otp: otp,
      }).then((res) => {
        if (res.data.result === true) {
          handleNext();
        } else {
          setSuccessMessage("")
          setError(res.data.message);
          setOpenSnackBar(true);
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6" color={"black"} fontWeight="bold">
        {t("onboarding.verifyYourMobileNumber")}
      </Typography>

      <Typography variant="subtitle2" mt={2}>
        {t("onboarding.pleaseEnterOtpText")}
      </Typography>

      <Box
        mt={2}
        mb={2}
        style={{ display: "flex", justifyContent: "center", height: "3rem" }}
      >
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={numInputs}
          renderInput={(props: any) => <input {...props} />}
          inputStyle={{
            width: "100%",
            height: "100%",
            maxWidth: "3rem",
            padding: "0 0.5rem",
            margin: "0 0.25rem",
            fontSize: "2rem",
            borderRadius: 4,
            border: "1px solid rgba(0,0,0,0.3)",
          }}
        />
      </Box>

      <ButtonContainer>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          onClick={handleBack}
          startIcon={<KeyboardArrowLeftIcon />}
        >
          <Typography variant="subtitle2">{t("onboarding.prev")}</Typography>
        </Button>

        <Box style={{ display: "flex" }}>
          <Button
            variant="outlined"
            style={buttonStyle}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "black",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            }}
            onClick={() => {
              resendOtp();
            }}
          >
            Resend
          </Button>
          <Button
            variant="outlined"
            style={otp.length < numInputs ? disabledButtonstyle : buttonStyle}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: otp.length < numInputs ? "#6D7676" : "black",
              cursor: otp.length < numInputs ? "not-allowed" : "pointer",

              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            }}
            type="submit"
          >
            Verify
          </Button>
        </Box>
      </ButtonContainer>
    </form>
  );
};

export default MobileVerification;
