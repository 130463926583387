import { Typography } from "@mui/material";
import LanguageSelector from "../dropdowns/languageSelector";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import OrganisationFeatureUpdateModal from "../modals/organisationFeatureUpdateModal";

interface Props {
  version: string;
}

const Container = styled("div")(({ theme }) => ({
  display: "flow",
  color: "black",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "20px",
  position: "fixed",
  right: 0,
  bottom: 10,
  left: 0,
  zIndex: 100,
  textAlign: "center",
  backgroundColor: "white",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    height: "auto",
  },
}));

const Footer = (props: Props) => {
  const { version } = props;
  const { t } = useTranslation("translation");
  const [
    openOrganisationFeatureUpdateModal,
    setOpenOrganisationFeatureUpdateModal,
  ] = useState(false);

  return (
    <Container>
      <LanguageSelector textColor="black" /> &nbsp;| &nbsp;
      <Typography variant="caption">{version}</Typography> &nbsp;| &nbsp;
      <Typography variant="caption">
        2017-{new Date().getFullYear()} {t("login.LCubedABSweden")}
      </Typography>
      &nbsp;| &nbsp;
      <a
        href="https://igrant.io/privacy.html#cookies"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: "none",
          color: "black",
          fontSize: "12px",
        }}
      >
        {t("login.cookiesPolicy")}
      </a>{" "}
      &nbsp;| &nbsp;
      <a
        href="https://igrant.io/terms.html"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: "none",
          color: "black",
          fontSize: "12px",
        }}
      >
        {t("login.termsOfService")}
      </a>{" "}
      &nbsp;| &nbsp;
      <a
        href="https://igrant.io/privacy.html#privacy"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: "none",
          color: "black",
          fontSize: "12px",
        }}
      >
        {t("login.privacyPolicy")}
      </a>
      <div
        style={{
          height: "15px",
          width: "40px",
          backgroundColor: "transparent",
          display: "inline-block",
        }}
        onClick={() => setOpenOrganisationFeatureUpdateModal(true)}
      />
      <OrganisationFeatureUpdateModal
        open={openOrganisationFeatureUpdateModal}
        setOpen={setOpenOrganisationFeatureUpdateModal}
        headerText={t("login.organisationFeatureUpdate")}
      />
    </Container>
  );
};

export default Footer;
