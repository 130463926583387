import {
  TextField,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { styled } from "@mui/material/styles";

import { useForm } from "react-hook-form";
import { useOnBoardingStore } from "../../store/onBoardingStore";
import OrgTypeDropdown from "../dropdowns/orgTypeDropdown";
import { countryData } from "../../utils/countryCodes";
import CountryListDropdown from "../dropdowns/countryListDropdown";
const buttonStyle = {
  height: 30,
  width: 150,
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
}));

interface Props {
  handleNext: any;
  setError: Dispatch<SetStateAction<string>>;
  setOpenSnackBar: Dispatch<SetStateAction<boolean>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
}

const OrganisationDetails = (props: Props) => {
  const { handleNext, setError, setOpenSnackBar, setActiveStep } = props;
  const { handleSubmit, register, formState, reset, control } = useForm<any>({
    mode: "onChange",
    defaultValues: {},
  });

  const countryList = countryData.map((country) => ({
    value: country.Name,
    label: country.Name,
  }));

  const onbordingStore = useOnBoardingStore((state) => state);
  const [authorization, setAuthorization] = useState(false);

  useEffect(() => {
    setAuthorization(onbordingStore.orgDetails.authorization ?? false);

    let defaultValues = {
      orgName: onbordingStore.orgAdminInformation.orgName
        ? onbordingStore.orgAdminInformation.orgName
        : onbordingStore.orgDetails.orgName,
      orgType: onbordingStore?.orgDetails?.orgType
        ? onbordingStore?.orgDetails?.orgType
        : onbordingStore?.organisationTypesFromApi[0]?.type,
      city: onbordingStore.orgDetails.city
        ? onbordingStore.orgDetails.city
        : "",
      country: onbordingStore.orgDetails.country
        ? onbordingStore.orgDetails.country
        : "Sweden",
      orgNumber: onbordingStore.orgDetails.orgNumber
        ? onbordingStore.orgDetails.orgNumber
        : "",
      vat: onbordingStore.orgDetails.vat ? onbordingStore.orgDetails.vat : "",
    };

    reset({ ...defaultValues });
  }, []);

  const { t } = useTranslation("translation");

  if (formState.submitCount > 0 && !authorization) {
    setOpenSnackBar(true);
    setError("Please confirm your authority");
  }

  const onSubmit = (createdData: any) => {
    let orgId = onbordingStore?.organisationTypesFromApi
      .filter((value) => value.type === createdData.orgType)
      .map((filteredValue) => filteredValue.id)[0];

    // update value in store
    useOnBoardingStore.getState().updateOrgDetails({
      orgName: createdData.orgName,
      orgType: createdData.orgType,
      city: createdData.city,
      country: createdData.country,
      orgNumber: createdData.orgNumber,
      vat: createdData.vat,
      authorization: authorization,
      orgId: orgId,
    });
    if (authorization) {
      handleNext();
    } else {
      setOpenSnackBar(true);
      setError("Please confirm your authority");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" color={"black"} fontWeight="bold">
        {t("onboarding.organisationDetails")}
      </Typography>
      <Typography variant="subtitle2" mt={2}>
        {t("gettingStarted.organisationName")}
      </Typography>
      <TextField
        autoComplete="off"
        variant="outlined"
        error={
          formState.submitCount > 0 && (formState.errors.orgName ? true : false)
        }
        sx={{
          width: { xs: "100%", lg: "65%" },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgName
                  ? "#f44336"
                  : "grey",
            },
            "&:hover fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgName
                  ? "#f44336"
                  : "grey",
            },
            "&.Mui-focused fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgName
                  ? "#f44336"
                  : "grey",
            },
          },
        }}
        placeholder={t("gettingStarted.organisationName")}
        {...register("orgName", {
          required: true,
          minLength: 1,
        })}
      />

      <Typography variant="subtitle2" mt={2} mb={1}>
        {t("onboarding.organisationCategory")}
      </Typography>
      <OrgTypeDropdown
        filterValues={onbordingStore?.organisationTypesFromApi}
        control={control}
        nameOfSelect={"orgType"}
      />

      <Typography variant="subtitle2" mt={2}>
        {t("onboarding.city")}
      </Typography>
      <TextField
        autoComplete="off"
        variant="outlined"
        sx={{
          width: { xs: "100%", lg: "65%" },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.city
                  ? "#f44336"
                  : "grey",
            },
            "&:hover fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.city
                  ? "#f44336"
                  : "grey",
            },
            "&.Mui-focused fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.city
                  ? "#f44336"
                  : "grey",
            },
          },
        }}
        placeholder={t("onboarding.city")}
        {...register("city", {
          required: true,
          minLength: 1,
        })}
      />

      <Typography variant="subtitle2" mt={2} mb={1}>
        {t("onboarding.country")}
      </Typography>
      <CountryListDropdown
        filterValues={countryList}
        control={control}
        nameOfSelect={"country"}
      />

      <Typography variant="subtitle2" mt={2}>
        {t("onboarding.organisationNumber")}
      </Typography>
      <TextField
        autoComplete="off"
        variant="outlined"
        sx={{
          width: { xs: "100%", lg: "65%" },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgNumber
                  ? "#f44336"
                  : "grey",
            },
            "&:hover fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgNumber
                  ? "#f44336"
                  : "grey",
            },
            "&.Mui-focused fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.orgNumber
                  ? "#f44336"
                  : "grey",
            },
          },
        }}
        placeholder={t("onboarding.organisationNumber")}
        {...register("orgNumber", {
          required: true,
          minLength: 1,
        })}
      />
      <Typography variant="subtitle2" mt={2}>
        {t("onboarding.vat")}
      </Typography>
      <TextField
        autoComplete="off"
        variant="outlined"
        sx={{
          width: { xs: "100%", lg: "65%" },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.vat
                  ? "#f44336"
                  : "grey",
            },
            "&:hover fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.vat
                  ? "#f44336"
                  : "grey",
            },
            "&.Mui-focused fieldset": {
              borderColor:
                formState.submitCount > 0 && formState.errors.vat
                  ? "#f44336"
                  : "grey",
            },
          },
        }}
        placeholder={t("onboarding.vat")}
        {...register("vat", {
          required: true,
          minLength: 1,
        })}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={authorization}
            onChange={(e) => setAuthorization(e.target.checked)}
          />
        }
        label={
          <Typography variant="subtitle2">
            {t("onboarding.AuthorisationConfirmText")}
          </Typography>
        }
      />

      <ButtonContainer>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          startIcon={<KeyboardArrowLeftIcon />}
          onClick={() => setActiveStep(0)}
        >
          <Typography variant="subtitle2">{t("onboarding.prev")}</Typography>
        </Button>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            color: "black",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          type="submit"
          endIcon={<KeyboardArrowRightIcon />}
        >
          <Typography variant="subtitle2">{t("onboarding.next")}</Typography>
        </Button>
      </ButtonContainer>
    </form>
  );
};

export default OrganisationDetails;
