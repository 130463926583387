import * as React from "react";
import { Dispatch, SetStateAction, useEffect } from "react";

import { Drawer, Typography, Button, Box, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  disabledButtonstyle,
} from "./modalStyle";

import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ConnectionDropdown from "../dropdowns/ConnectionDropdown";
import LoaderComponent from "../LoaderComponent";
import { HttpServiceForDigitalWallet } from "../../service/digitalWalletHttpService";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  onRefetch: any;
  selectedData: any;
  ssi: string;
}
let defaultValue = {
  connectionId: "",
  attributes: [
    {
      name: "",
      value: "",
    },
  ],
};
export default function IssueCredetialOrSendVerificationRequestModal(
  props: Props
) {
  const { t } = useTranslation("translation");
  const { open, setOpen, headerText, onRefetch, selectedData, ssi } = props;
  const [openLoader, setOpenLoader] = React.useState(false);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValue,
    },
  });
  const { control, register } = methods;

  const { fields } = useFieldArray({
    control,
    name: "attributes",
    rules: {
      required: true,
    },
  });

  useEffect(() => {
    methods.reset({
      connectionId: "",
      attributes: selectedData?.dataAttributes?.map((attribute: any) => {
        return {
          name: attribute.name,
          value: "",
          mimeType: "",
        };
      }),
    });
  }, [selectedData, open]);

  const onSubmit = (createdData: any) => {
    setOpenLoader(true);
    if (ssi === "credentials") {
      let payload = {
        comment: selectedData?.purpose,
        autoRemove: false,
        trace: false,
        connectionId: createdData.connectionId,
        credentialPreview: {
          "@type":
            "did:sov:BzCbsNYhMrjHiqZDTUASHg;spec/issue-credential/1.0/credential-preview",
          attributes: createdData.attributes,
        },
        autoIssue: true,
        credDefId: selectedData.dataExchange.credentialDefinitionId,
        dataAgreementId: selectedData?.dataExchange.id,
      };

      HttpServiceForDigitalWallet.sendOfferForCredentials(payload).then(() => {
        setOpenLoader(false);
        onRefetch();
        setOpen(false);
      });
    } else {
      let payload = {
        connectionId: createdData.connectionId,
        dataAgreementId: selectedData?.dataExchange.id,
      };

      HttpServiceForDigitalWallet.OfferForVerificationsRequest(payload).then(
        () => {
          setOpenLoader(false);
          onRefetch();
          setOpen(false);
        }
      );
    }
  };
  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <HeaderContainer>
              <Box pl={2} style={{ overflow: "hidden" }}>
                <Typography
                  style={{
                    color: "#F3F3F6",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {headerText}: {selectedData?.purpose}
                </Typography>
                <Typography color="#F3F3F6">
                  {ssi === "verifications"
                    ? selectedData?.id
                    : selectedData?.dataExchange?.credentialDefinitionId}
                </Typography>
              </Box>
              <CloseIcon
                onClick={() => setOpen(false)}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <DetailsContainer style={{ paddingBottom: "80px" }}>
              <Box p={1.5}>
                <Typography variant="subtitle1" mt={1.5}>
                  {t("credentials.connectionID")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>

                <ConnectionDropdown control={control} />

                {ssi === "credentials" &&
                  fields?.map((attribute: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <Typography variant="subtitle1" mt={1.5}>
                          {attribute.name}{" "}
                          <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>
                            *
                          </span>
                        </Typography>
                        <TextField
                          variant="standard"
                          autoComplete="off"
                          fullWidth
                          placeholder={attribute.name}
                          {...register(`attributes.${index}.value`, {
                            required: true,
                            minLength: 1,
                          })}
                        />
                      </React.Fragment>
                    );
                  })}
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => setOpen(false)}
                style={buttonStyle}
                sx={{
                  marginLeft: "10px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>
              <Button
                variant="outlined"
                style={
                  methods.formState.isValid ? buttonStyle : disabledButtonstyle
                }
                sx={{
                  cursor: methods.formState.isValid ? "pointer" : "not-allowed",
                  marginRight: "20px",
                  color: methods.formState.isValid ? "black" : "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                type="submit"
              >
                {ssi === "credentials"
                  ? t("credentials.issue")
                  : t("verifications.verify")}
              </Button>
            </FooterContainer>
          </form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
