import * as React from "react";
import { Dispatch, SetStateAction, useState, useEffect } from "react";

import { Drawer, Typography, Button, Box, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import ContentTypeDropdown from "../dropdowns/webhookContentTypeDropdown";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  disabledButtonstyle,
} from "./modalStyle";
import CheckboxTree from "../webhooks/checkboxTree";
import { HttpService } from "../../service/HTTPService";
import { useForm } from "react-hook-form";
import { OrganizationDetailsCRUDContext } from "../../contexts/organizationDetailsCrud";
import { useTranslation } from "react-i18next";
import SnackbarComponent from "../notification";
import LoaderComponent from "../LoaderComponent";
import {
  defaultEventTpesCreateForWebooksConvertedForClient,
  defaultUpdateEventTpesForWebooksConvertedForClient,
} from "../../utils/webhookUtils";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  mode: string;
  onRefetch: any;
  webhookDetailsForUpdate: any;
}

export default function EditWebooks(props: Props) {
  const {
    open,
    setOpen,
    headerText,
    mode,
    onRefetch,
    webhookDetailsForUpdate,
  } = props;

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    register,
    formState,
    reset,
    watch,
  } = useForm<any>({
    mode: "onChange",
  });

  const [webhookContentTypes, setWebhookContentTypes] = useState<string[]>([]);
  const [webhookEventTypes, setWebhookEventTypes] = useState<string[]>([]);
  const [checkWebhookIsSelected, setCheckWebhookIsSelected] = useState(false);
  const [
    webhookEventTypesConvertedForUpdate,
    setWebhookEventTypesConvertedForUpdate,
  ] = useState({});
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");
  const [openLoader, setOpenLoader] = useState(false);

  const { organisationDetails } = React.useContext(
    OrganizationDetailsCRUDContext
  );
  const { t } = useTranslation("translation");

  useEffect(() => {
    HttpService.listWebhookContentTypes().then((res) => {
      setWebhookContentTypes(res.ContentTypes);
    });

    HttpService.listWebhookEventTypes().then((resp) => {
      const mergedEventTypes = [
        ...resp.consentManagementWebhookEventTypes,
        ...resp.digitalWalletWebhookEventTypes,
      ].sort();
      setWebhookEventTypes(mergedEventTypes);
    });
  }, [open]);

  useEffect(() => {
    let defaultValues = {
      orgId: organisationDetails.id,
      payloadUrl: "",
      contentType: "application/json",
      secretKey: "",
      radioGroup: "selected",
      disabled: false,
      skipSslVerification: false,
      timestamp: "1693459838",
      webhookEventTypes: defaultEventTpesCreateForWebooksConvertedForClient,
    };

    if (webhookDetailsForUpdate && mode === "Update") {
      let { subscribedEvents, ...otherProps } = webhookDetailsForUpdate;

      const mergedEventTypesForUpdate = [
        ...subscribedEvents.consentManagementWebhook,
        ...subscribedEvents.digitalWalletWebhook,
      ];

      const checkAndUpdateEventTypes = (eventType: any, obj: any) => {
        for (const key in obj) {
          if (typeof obj[key] === "object") {
            checkAndUpdateEventTypes(`${eventType}.${key}`, obj[key]);
          } else {
            if (mergedEventTypesForUpdate.includes(`${eventType}.${key}`)) {
              obj[key] = true;
            } else {
              obj[key] = false;
            }
          }
        }
      };

      for (const category in defaultUpdateEventTpesForWebooksConvertedForClient) {
        checkAndUpdateEventTypes(
          category,
          defaultUpdateEventTpesForWebooksConvertedForClient[category]
        );
      }

      setWebhookEventTypesConvertedForUpdate(
        defaultUpdateEventTpesForWebooksConvertedForClient
      );

      reset({
        webhookEventTypes: defaultUpdateEventTpesForWebooksConvertedForClient,
        radioGroup:
          mergedEventTypesForUpdate.length === 43 ? "all" : "selected",
        ...otherProps,
      });
    } else if (mode === "Create") reset({ ...defaultValues });
  }, [open, webhookDetailsForUpdate]);

  const isFormDataChanged = (formState: any) => {
    const { dirtyFields } = formState;
    const { webhookEventTypes, ...restDirtyFields } = dirtyFields;

    const webhookEventTypesUpdated = watch("webhookEventTypes");
    return (
      Object.keys(restDirtyFields).length > 0 ||
      JSON.stringify(webhookEventTypesConvertedForUpdate) !==
        JSON.stringify(webhookEventTypesUpdated)
    );
  };

  const onSubmit = (createdData: any) => {
    const { webhookEventTypes, radioGroup, ...otherProps } = createdData;

    const consentManagementWebhook: any[] = [];
    const digitalWalletWebhook: any[] = [];

    function checkAndAddEvents(obj: any, prefix: any, targetArray: any) {
      for (const key in obj) {
        if (typeof obj[key] === "object") {
          checkAndAddEvents(obj[key], prefix + "." + key, targetArray);
        } else if (obj[key]) {
          targetArray.push(prefix + "." + key);
        }
      }
    }

    for (const category in webhookEventTypes) {
      console.log("categor", category);
      const targetArray =
        category === "digitalwallet" || category === "openid"
          ? digitalWalletWebhook
          : consentManagementWebhook;
      checkAndAddEvents(webhookEventTypes[category], category, targetArray);
    }

    if (formState.isValid && checkWebhookIsSelected === true) {
      setOpenLoader(true);

      const payload = {
        webhook: {
          ...otherProps,
          subscribedEvents: {
            consentManagementWebhook: consentManagementWebhook,
            digitalWalletWebhook: digitalWalletWebhook,
          },
        },
      };
      if (mode === "Update") {
        if (isFormDataChanged(formState)) {
          HttpService.updateWebhookById(payload, webhookDetailsForUpdate.id)
            .then(() => {
              onRefetch();
              setOpen(false);
              setOpenLoader(false);
            })
            .catch((error) => {
              setOpenLoader(false);
              let errorDescription = error?.response?.data?.errorDescription;
              setError(errorDescription);
              setOpenSnackBar(true);
            });
        }
      } else {
        HttpService.addWebhooks(payload)
          .then(() => {
            onRefetch();
            setOpen(false);
            setOpenLoader(false);
          })
          .catch((error) => {
            setOpenLoader(false);
            let errorDescription = error?.response?.data?.errorDescription;
            setError(errorDescription);
            setOpenSnackBar(true);
          });
      }
    }
  };
  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container
          style={{
            overflow: openLoader ? "hidden" : "auto",
            overflowX: "hidden",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <SnackbarComponent
              open={openSnackBar}
              setOpen={setOpenSnackBar}
              message={error}
            />
            <HeaderContainer>
              <Typography color="#F3F3F6" pl={2}>
                {headerText}
              </Typography>
              <CloseIcon
                onClick={() => {
                  setOpen(false);
                }}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <DetailsContainer style={{ paddingBottom: "50px" }}>
              <Box p={1.5}>
                <Typography variant="subtitle1" mb={0}>
                  {t("webhooks.payloadURL")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>
                <TextField
                  autoFocus
                  variant="standard"
                  fullWidth
                  placeholder={t("webhooks.payloadURLPlaceholder")}
                  {...register("payloadUrl", {
                    required: true,
                    minLength: 3,
                    pattern: {
                      value: /^(ftp|http|https):\/\/[^ "]+$/,
                      message: "",
                    },
                  })}
                />
                <Typography variant="subtitle1" mt={1.5}>
                  {t("webhooks.contentType")}
                </Typography>
                <ContentTypeDropdown
                  filterValues={webhookContentTypes}
                  control={control}
                  nameOfSelect={"contentType"}
                />

                <Typography variant="subtitle1" mt={1.5}>
                  {t("webhooks.secretKey")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>
                <TextField
                  autoFocus
                  variant="standard"
                  fullWidth
                  placeholder={t("webhooks.secretKeyPlaceholder")}
                  {...register("secretKey", {
                    required: true,
                    minLength: 3,
                  })}
                />
                <Typography variant="subtitle1" mt={1.5}>
                  {t("webhooks.events")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>
                <CheckboxTree
                  webhookEventTypes={webhookEventTypes}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  setCheckWebhookIsSelected={setCheckWebhookIsSelected}
                />
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                style={buttonStyle}
                sx={{
                  marginLeft: "15px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>
              <Button
                variant="outlined"
                style={
                  formState.isValid &&
                  checkWebhookIsSelected === true &&
                  isFormDataChanged(formState)
                    ? buttonStyle
                    : disabledButtonstyle
                }
                sx={{
                  cursor:
                    formState.isValid &&
                    checkWebhookIsSelected === true &&
                    isFormDataChanged(formState)
                      ? "pointer"
                      : "not-allowed",
                  marginRight: "20px",
                  color:
                    formState.isValid &&
                    checkWebhookIsSelected === true &&
                    isFormDataChanged(formState)
                      ? "black"
                      : "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                type="submit"
              >
                {t("common.save")}{" "}
              </Button>
            </FooterContainer>
          </form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
