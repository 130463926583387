import { Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import { Box } from "@mui/system";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface SwitchComponentProps {
  covidPackage: boolean;
  setCovidPackage: Dispatch<SetStateAction<boolean>>;
}

const SwitchComponent = ({ covidPackage, setCovidPackage }: SwitchComponentProps) => {
  const { t } = useTranslation("translation");

  const handleToggle = () => {
    setCovidPackage(!covidPackage); 
  };

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="subtitle2"> {t("billing.general")}</Typography>
      <Switch
        color="default"
        checked={covidPackage}
        onChange={handleToggle}
      />
      <Typography variant="subtitle2">{t("billing.covidPackage")}</Typography>
    </Box>
  );
};

export default SwitchComponent;
