import * as React from "react";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import {
  Drawer,
  Typography,
  Button,
  Box,
  FormControlLabel,
  Switch,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  disabledButtonstyle,
} from "./modalStyle";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SnackbarComponent from "../notification";
import LoaderComponent from "../LoaderComponent";
import { OnBoardingHttpService } from "../../service/onboardingService";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
}

export default function OrganisationFeatureUpdateModal(props: Props) {
  const { open, setOpen, headerText } = props;
  const { t } = useTranslation("translation");

  const featureLabels = [
    { name: "gettingStarted", label: t("sidebar.gettingStarted") },
    { name: "dataAgreements", label: t("sidebar.dataAgreements") },
    { name: "managedData", label: t("sidebar.personalData") },
    { name: "digitalWalletAries", label: t("sidebar.ariesDigitalWallet") },
    { name: "digitalWalletOid4vc", label: t("sidebar.openIDDigitalWallet") },
    { name: "manageUsers", label: t("sidebar.manageUsers") },
    { name: "privacyDashboard", label: t("sidebar.privacyDashboard") },
    { name: "account", label: t("sidebar.account") },
    { name: "supportEvents", label: t("sidebar.supportEvents") },
  ];

  const organisationFeatures: any = useDigitalWalletStore(
    (state) => state.organisationFeatures
  );

  const { handleSubmit, formState, reset, setValue, control } = useForm<any>({
    mode: "onChange",
  });

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");
  const [openLoader, setOpenLoader] = useState(false);

  useEffect(() => {
    let defaultValues = {
      gettingStarted: organisationFeatures.gettingStarted,
      dataAgreements: organisationFeatures.dataAgreements,
      managedData: organisationFeatures.managedData,
      digitalWalletAries: organisationFeatures.digitalWalletAries,
      digitalWalletOid4vc: organisationFeatures.digitalWalletOid4vc,
      manageUsers: organisationFeatures.manageUsers,
      privacyDashboard: organisationFeatures.privacyDashboard,
      account: organisationFeatures.account,
      supportEvents: organisationFeatures.supportEvents,
    };

    reset({ ...defaultValues });
  }, [open, reset]);

  const onSubmit = (createdData: any) => {
    setOpenLoader(true);
    OnBoardingHttpService.updateOrganisationFeatures(createdData)
      .then(() => {
        setOpenLoader(false);
        setOpen(false);
      })
      .catch((error: any) => {
        setOpenLoader(false);
        let errorDescription = error.response.data.errorDescription;
        setError(errorDescription);
        setOpenSnackBar(true);
      });
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SnackbarComponent
              open={openSnackBar}
              setOpen={setOpenSnackBar}
              message={error}
            />
            <HeaderContainer>
              <Typography color="#F3F3F6" pl={2}>
                {headerText}
              </Typography>
              <CloseIcon
                onClick={() => {
                  setOpen(false);
                }}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <DetailsContainer style={{ paddingBottom: "50px" }}>
              <Box p={1.5}>
                {featureLabels.map(({ name, label }) => (
                  <>
                    <Box key={name} display="flex" alignItems="center" mb={1}>
                      <Controller
                        name={name}
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                {...field}
                                defaultChecked={organisationFeatures[name]}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  setValue(name, e.target.checked);
                                }}
                              />
                            }
                            label={label}
                          />
                        )}
                      />
                    </Box>
                  </>
                ))}
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                style={buttonStyle}
                sx={{
                  marginLeft: "15px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>

              <Button
                variant="outlined"
                style={formState.isValid ? buttonStyle : disabledButtonstyle}
                sx={{
                  cursor: formState.isValid ? "pointer" : "not-allowed",
                  marginRight: "20px",
                  color: formState.isValid ? "black" : "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                type="submit"
              >
                {t("common.save")}
              </Button>
            </FooterContainer>
          </form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
