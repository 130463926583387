import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DataAgreementPolicyCardInSSI from "../modals/dataAgreementPolicyCardInSSI";
import { Dispatch, SetStateAction, useState } from "react";

const titleAttrRestrictionStyle = {
  fontWeight: "normal",
  marginTop: "20px",
  lineHeight: "1.5rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  border: "1px solid #DFE0E1",
  borderRadius: 5,
  padding: "12px",
};

interface Props {
  selectedData: any;
  setOpenViewExchangeRecordModal: Dispatch<SetStateAction<boolean>>;
  ssi: string;
}

export const DataAgreementPolicyCard = (props: Props) => {
  const { selectedData, setOpenViewExchangeRecordModal, ssi } = props;
  const { t } = useTranslation("translation");
  const [openDataAgreementPolicyModal, setOpenDataAgreementPolicyModal] =
    useState(false);

  const handleCardClick = () => {
    setOpenDataAgreementPolicyModal(true);
  };

  return (
    <>
      <Box style={titleAttrRestrictionStyle} onClick={handleCardClick}>
        <Typography color="grey" variant="subtitle2">
          {t("issuanceHistory.dataAgreementPolicy")}
        </Typography>
        <KeyboardArrowRightIcon style={{ color: "grey" }} />
      </Box>
      <DataAgreementPolicyCardInSSI
        open={openDataAgreementPolicyModal}
        setOpen={setOpenDataAgreementPolicyModal}
        headerText={t("issuanceHistory.dataAgreementPolicy")}
        selectedData={selectedData}
        ssi={ssi}
        setOpenViewExchangeRecordModal={setOpenViewExchangeRecordModal}
      />
    </>
  );
};
