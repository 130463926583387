import Box from "@mui/material/Box";

import { Typography } from "@mui/material";
import { t } from "i18next";
import DataAttributeTableForSendVerification from "./dataAttributeTableForSendVerification";

export const CredentialTable = ({ items, setOpenViewModal }: any) => {
  return (
    <Box>
      {items &&
        items.credentialSubject &&
        Object.entries(items.credentialSubject).map(([key, value]) => (
          <DataAttributeTableForSendVerification
            key={key}
            attributeKey={key}
            attributeValue={value}
          />
        ))}

      <Box display="flex" justifyContent="flex-end" marginTop="10px">
        <Typography
          component="span"
          sx={{
            fontSize: "14px",
            textDecoration: "underline",
            color: "blue",
            cursor: "pointer",
            display: "inline-block",
          }}
          onClick={() => setOpenViewModal(true)}
        >
          {t("holder.viewCredential")}
        </Typography>
      </Box>
    </Box>
  );
};

export default CredentialTable;
