import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const FlexBox = styled(Box)({
  display: "grid",
  alignItems: "center",
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#CFCFCF",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#808080",
  },
}));

interface Props {
  percentage: number;
}

export default function CustomizedProgressBars({ percentage }: Props) {
  return (
    <FlexBox>
      <BorderLinearProgress
        variant="determinate"
        value={percentage}
        sx={{ flex: 1 }}
      />
    </FlexBox>
  );
}
