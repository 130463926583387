import CSS from "csstype";

import { Box } from "@mui/material";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useTranslation } from "react-i18next";
import { useFieldArray } from "react-hook-form";
import { useState } from "react";
import SelectDataType from "../dropdowns/selectDataTypeDropdown";

const titleAttrRestrictionStyle = {
  fontWeight: "normal",
  margin: "10px 10px 5px 10px",
  borderBottom: "solid 1px #dee2e6",
  lineHeight: "1.5rem",
};

const tableAttrAdditionalInfoStyle = {
  border: 0,
  width: "100%",
  maxWidth: "100%",
  marginBottom: "0rem",
  backgroundColor: "#FFFF",
};

const tableAttrAdditionalInfoColumn: CSS.Properties = {
  fontWeight: "normal",
  border: "0px",
};

const inputStyleAttr = {
  width: "85%",
  color: "#495057",
  border: "1",
  borderWidth: 0,
  padding: 0,
  paddingBottom: 1,
  borderRadius: 0,
  fontSize: "14px",
  borderBottomWidth: 1.2,
  backgroundColor: "#FFFF",
  borderBottomColor: "red", //'#DFE0E1',
  marginRight: "10px",
  fontFamily: "Inter,Helvetica,Arial,sans-serif",
};

export interface Props {
  key: any;
  nestIndex: any;
  control: any;
  register: any;
  watch: any;
  remove: any;
  credentialDefinitionModalMode?: string;
}

let dataTypes = [
  { label: "String", value: "string" },
  { label: "Boolean", value: "boolean" },
  { label: "Number", value: "number" },
  { label: "Object", value: "object" },
  { label: "Array", value: "array" },
];
export const DataAttributeForOID4VC = (props: Props) => {
  const { t } = useTranslation("translation");

  const {
    key,
    remove,
    control,
    register,
    watch,
    nestIndex,
    credentialDefinitionModalMode,
  } = props;

  return (
    <>
      <Box
        style={{
          marginBottom: "25px",
          border: "1px solid #DFE0E1",
          borderRadius: 5,
        }}
      >
        <Box style={titleAttrRestrictionStyle}>
          <table style={tableAttrAdditionalInfoStyle}>
            <tbody>
              <tr style={{ display: "flex", alignItems: "center" }}>
                <input
                  {...register(`credentialDefinition.${nestIndex}.name`, {
                    required: true,
                    pattern: {
                      value: /^(?!\s*$).+/,
                      message: "",
                    },
                  })}
                  disabled={credentialDefinitionModalMode === "View"}
                  placeholder={t("issuanceHistory.attributeName")}
                  style={{
                    ...inputStyleAttr,
                    border: "none",
                    outline: "none",
                    width: "100%",
                    cursor:
                      credentialDefinitionModalMode === "View"
                        ? "not-allowed"
                        : "pointer",
                  }}
                  type="text"
                  autoComplete="off"
                />

                <th>
                  <DeleteOutlineOutlinedIcon
                    style={{
                      cursor:
                        credentialDefinitionModalMode !== "View"
                          ? "pointer"
                          : "not-allowed",
                      color:
                        credentialDefinitionModalMode !== "View"
                          ? "black"
                          : "grey",
                    }}
                    onClick={() =>
                      credentialDefinitionModalMode !== "View" &&
                      remove(nestIndex)
                    }
                  />
                </th>
                <th></th>
              </tr>
            </tbody>
          </table>
        </Box>

        <Box style={{ ...titleAttrRestrictionStyle, borderBottom: 0 }}>
          <table style={tableAttrAdditionalInfoStyle}>
            <tbody>
              <tr style={{ display: "flex" }}>
                <td style={{ ...tableAttrAdditionalInfoColumn, flexGrow: 1 }}>
                  <SelectDataType
                    dropdownValue={dataTypes}
                    control={control}
                    nameOfSelect={`credentialDefinition.${nestIndex}.type`}
                    valueKey={"value"}
                    displayKey="label"
                    credentialDefinitionModalMode={
                      credentialDefinitionModalMode
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>
    </>
  );
};
