import React, { Dispatch, SetStateAction, useState, useEffect } from "react";

import { Drawer, Typography, Button, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  disabledButtonstyle,
} from "./modalStyle";
import { useTranslation } from "react-i18next";
import QRCodeCopyInput from "../dataAgreements/InputWithCopyQRCode";
import QRCode from "qrcode";
import LoaderComponent from "../LoaderComponent";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onRefetch?: any;
  setOpenIssuenceModal: Dispatch<SetStateAction<boolean>>;
  issuedValue: any;
  ssi?: string;
  headerText: string;
}

export default function QRCodeGenerateModalForEWC(props: Props) {
  const {
    open,
    setOpen,
    setOpenIssuenceModal,
    issuedValue,
    onRefetch,
    ssi,
    headerText,
  } = props;
  const { t } = useTranslation("translation");
  const [qrCode, setQRCode] = useState<string | null>(null);
  const [qrCodeValue1, setQRCodeValue1] = useState<string>("");
  const [openLoader, setOpenLoader] = useState(false);
  const [showTooltip1, setShowTooltip1] = useState(false);

  const generateQRCode = async () => {
    setOpenLoader(false);
    setQRCodeValue1(
      ssi === "verificationsEwc"
        ? issuedValue?.vpTokenQrCode
        : issuedValue?.credentialOffer
    );
    // setting QRCode
    QRCode.toDataURL(
      ssi === "verificationsEwc"
        ? issuedValue?.vpTokenQrCode
        : issuedValue?.credentialOffer,
      (err: any, url: any) => {
        if (err) {
          // console.error(err);
        }
        setQRCode(url);
      }
    );
  };

  useEffect(() => {
    generateQRCode();
  }, [issuedValue]);

  const downloadQRCode = () => {
    if (qrCode) {
      const link = document.createElement("a");
      link.href = qrCode;
      link.download = "QRCode.png"; // Change the filename as needed
      link.click();
    }
  };

  const handleCopy1 = () => {
    if (qrCode) {
      navigator.clipboard.writeText(qrCodeValue1);

      setShowTooltip1(true);
      setTimeout(() => {
        setShowTooltip1(false);
      }, 1000);
    }
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container sx={{width:"500px"}}>
          <form>
            <HeaderContainer>
              <Box pl={2}>
                <Box display={"flex"}>
                  <ChevronLeftIcon
                    onClick={() => {
                      setOpen(false);
                      setQRCode(null);
                      onRefetch();
                    }}
                    sx={{
                      marginRight: 1,
                      cursor: "pointer",
                      color: "#F3F3F6",
                    }}
                  />
                  <Typography color="#F3F3F6">{headerText}</Typography>
                </Box>
              </Box>

              <CloseIcon
                onClick={() => {
                  setOpen(false);
                  setQRCode(null);
                  setOpenIssuenceModal(false);
                  onRefetch();
                }}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>

            <DetailsContainer
              style={{ display: "grid", justifyItems: "center" }}
            >
              {qrCode && (
                <>
                  <img
                    src={qrCode}
                    alt="QR Code"
                    style={{ marginTop: 10, maxWidth: "100%", width: "250px" }}
                  />
                  <Box
                    style={{
                      width: "100%",
                      display: "grid",
                      justifyItems: "center",
                    }}
                  >
                    <QRCodeCopyInput
                      handleCopy={handleCopy1}
                      qrCodeValue={qrCodeValue1}
                      showTooltip={showTooltip1}
                    />
                  </Box>
                </>
              )}
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => {
                  setOpen(false);
                  setQRCode(null);
                  setOpenIssuenceModal(false);
                  onRefetch();
                }}
                style={buttonStyle}
                sx={{
                  color: "black",
                  marginLeft: "15px",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>
              <Button
                variant="outlined"
                onClick={downloadQRCode}
                style={qrCode ? buttonStyle : disabledButtonstyle}
                sx={{
                  cursor: qrCode ? "pointer" : "not-allowed",
                  marginRight: "20px",
                  color: qrCode ? "black" : "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
              >
                {t("dataAgreements.download")}
              </Button>
            </FooterContainer>
          </form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
