import { Typography, Box, FormControl, Select, MenuItem } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const dropDownStyle = {
  border: "1px solid lightgray",
  outline: "none",
  fontSize: "14px",
  backgroundColor: "#FFFF",
  height: "38px",
  borderRadius: "5px",
  cursor: "pointer",
};

const disabledDropDownStyle = {
  border: "1px solid lightgray",
  outline: "none",
  fontSize: "14px",
  backgroundColor: "#F7F6F6",
  height: "38px",
  borderRadius: "5px",
  cursor: "not-allowed",
};

export const LanguageSelection = (props: any) => {
  const { t } = useTranslation("translation");

  const languages = [{ value: "english", label: "English" }];

  return (
    <>
      <Select
        onChange={(e: any) => {
          props.onChange(e);
        }}
        variant="outlined"
        fullWidth
        value={"english"}
        name="language"
        disabled={true}
        style={{ ...disabledDropDownStyle, width: "200px", marginTop: "-10px" }}
      >
        {languages.map((modes) => (
          <MenuItem
            key={modes.label}
            value={modes.value}
            sx={{
              cursor: "pointer",
            }}
          >
            {modes.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export const LanguageFormControl = (
  props: any
) => {
  const { control } = useFormContext();
  const { t } = useTranslation("translation");

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Typography variant="subtitle1">
          {t("common.language")}
        </Typography>
        <FormControl>
          <Controller
            name="language"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <LanguageSelection
                onChange={onChange}
                value={value}
                mode={props.mode}
                selectededDataAgreementFromDataAgreement={
                  props?.selectededDataAgreementFromDataAgreement
                }
                ssi={props.ssi}
                dataAgreementType={props.dataAgreementType}
              />
            )}
          />
        </FormControl>
      </Box>
    </>
  );
};
