import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";
import { HttpServiceForDigitalWalletEwc } from "../../service/digitalWalletEwcHttpService";

interface Props {
  control: any;
  dynamicCredentialRequestValue: boolean;
}

interface PresentationDefinition {
  presentationDefinitionId: string;
  label: string;
}

const PresentationDefinitionIDDropDown: React.FC<Props> = (props) => {
  const { control, dynamicCredentialRequestValue } = props;
  const [presentationDefinitions, setPresentationDefinitions] = useState<
    PresentationDefinition[]
  >([]);
  const { t } = useTranslation("translation");

  useEffect(() => {
    HttpServiceForDigitalWalletEwc.listIssuePresentationDefinitionForEwc(
      0,
      100
    ).then((data) => {
      setPresentationDefinitions(data.presentationDefinition);
    });
  }, []);

  return (
    <Controller
      name="presentationDefinitionId"
      control={control}
      rules={{
        required: dynamicCredentialRequestValue === true,
      }}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          options={presentationDefinitions}
          getOptionLabel={(option) =>
            `${option.label} (${option.presentationDefinitionId})`
          }
          value={
            value
              ? presentationDefinitions.find(
                  (pd) => pd.presentationDefinitionId === value
                ) || null
              : null
          }
          onChange={(_, newValue) => {
            onChange(newValue ? newValue.presentationDefinitionId : null);
          }}
          disabled={!dynamicCredentialRequestValue}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={t("credentials.selectPresentationDefinitionID")}
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "32px",
                  fontSize: "14px",
                  color: "#495057",
                },
              }}
            />
          )}
          sx={{
            "& .MuiAutocomplete-input": {
              height: "14px",
              cursor: dynamicCredentialRequestValue ? "pointer" : "not-allowed",
            },
          }}
        />
      )}
    />
  );
};

export default PresentationDefinitionIDDropDown;
