import { Typography } from "@mui/material";
import CustomizedProgressBars from "../progress";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

interface Props {
  maxUsers: number | null;
  percentage: number ;
}

const MonthlyUsers = (props: Props) => {
  const { t } = useTranslation("translation");
  const { maxUsers, percentage } = props;
  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography
          color="black"
          variant="subtitle1"
          fontWeight="bold"
          mb={0.5}
        >
          {t("billing.monthlyUsers")}
        </Typography>
        <Typography color="grey" variant="subtitle1" fontWeight="bold" mb={0.5}>
          {maxUsers}
        </Typography>
      </Box>
      <Typography variant="caption" color="textSecondary">
        {t("billing.thisMonth")}
      </Typography>
      <CustomizedProgressBars percentage={percentage} />
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography variant="caption" color="textSecondary">
          {t("billing.lastMonth")}
        </Typography>
        <Typography variant="caption" color="textSecondary" marginLeft={"auto"}>
          {percentage}%
        </Typography>
      </Box>
    </>
  );
};

export default MonthlyUsers;
