import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import Editor, { useMonaco } from "@monaco-editor/react";

type APISchemaType = {
  $schema: string;
  title: string;
  properties: any;
  required: string[];
};

export const createEditorSchema = (schema: string) => {
  try {
    const parsedSchema: APISchemaType = JSON.parse(schema);
    const schemaBase = {
      uri: "/test.json",
      fileMatch: ["test.json"],
      schema: { ...parsedSchema },
    };
    return schemaBase;
  } catch (err) {
    console.error("Invalid JSON schema provided");
  }
};

type EditorProps = {
  schema: string;
  methods: any;
  handleChange: any;
  name: string;
  mode: string;
  setError:Dispatch<SetStateAction<string>>
};

const EditorField = ({
  schema,
  methods,
  handleChange,
  name,
  mode,
  setError
}: EditorProps) => {
  function handleEditorWillMount(monaco: any) {
    // here is the monaco instance
    // do something before editor is mounted
    if (schema) {
      const editorSchema = createEditorSchema(schema);
      monaco?.languages?.json?.jsonDefaults?.setDiagnosticsOptions({
        validate: true,
        schemas: [editorSchema],
      });
    } else {
      console.error("Invalid JSON schema provided");
    }
  }

  const handleValidate = (validation: any) => {
    // console.log(`Validation errors: `, validation);
    if (validation.length > 1) {
      setError("Invalid JSON value");
    } else if (validation.length > 0) {
      const validationObject = validation[0];
      setError(
        `${validationObject.message} at line number ${validationObject.startLineNumber}`
      );
    } else {
      setError("");
    }
  };

  const handleMount = (editor: any, _monaco: any) => {
    if (editor) {
      // Few times document doesn't format, this fixes it.
      setTimeout(() => {
        editor.getAction("editor.action.formatDocument").run();
      }, 300);
    }
  };
  return (
    <Editor
      width="480px"
      height="600px"
      defaultLanguage="json"
      onValidate={handleValidate}
      options={{
        formatOnPaste: true,
        selectOnLineNumbers: true,
        minimap: { enabled: false },
        fontFamily: `'JetBrainsMono', Consolas, 'Courier New', monospace`,
        readOnly: mode === "View" && true,
        readOnlyMessage: {
            value: "Can not update in view mode"
          }
      }}
      className={"jsoneditor"}
      onMount={handleMount}
      path="test.json"
      value={methods.watch(name)}
      onChange={handleChange}
      beforeMount={handleEditorWillMount}
    />
  );
};

export default EditorField;
