import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import LockIcon from "@mui/icons-material/Lock";

interface Props {
  selectedData: any;
}

const VerifiedDABox = ({ selectedData }: Props) => {
  const { t } = useTranslation("translation");

  const verifiedData = [
    {
      title: t("issuanceHistory.controllerDecentralisedIdentifier"),
      value:
        selectedData.dataAgreement.proofChain &&
        selectedData.dataAgreement?.proofChain[0]?.verificationMethod
          ? selectedData.dataAgreement?.proofChain[0]?.verificationMethod
          : selectedData.dataAgreement?.proof?.verificationMethod,
    },
    {
      title: t("issuanceHistory.signature"),
      value:
        selectedData.dataAgreement.proofChain &&
        selectedData.dataAgreement?.proofChain[0]?.proofValue
          ? selectedData.dataAgreement?.proofChain[0]?.proofValue
          : selectedData.dataAgreement?.proof?.proofValue,
    },
  ];

  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <Typography variant="subtitle1">
          {t("issuanceHistory.verifiedDataAgreement")}
        </Typography>
        <LockIcon color="success" fontSize="small" />
      </Box>
      {verifiedData.map((data, index) => (
        <Box
          key={index}
          style={{
            display: "grid",
            padding: "10px",
          }}
        >
          <Typography variant="subtitle2">{data.title}</Typography>
          <Typography
            color="grey"
            variant="subtitle2"
            style={{ overflowWrap: "anywhere" }}
          >
            {data.value}
          </Typography>
        </Box>
      ))}
    </>
  );
};

export default VerifiedDABox;
