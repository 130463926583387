import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import { setJsonSchemaValueOnChange } from "../../utils/credentialDefintionUtils";
import { alpha } from "@mui/material/styles";

const SelectCredentialforCreateCredentialDefinition = (props: any) => {
  const {
    dropdownValue,
    control,
    nameOfSelect,
    selectedValue,
    isFieldMandatory,
    setValue,
    credentialDefinitionModalMode,
    watch,
  } = props;
  const { t } = useTranslation("translation");

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      color: "#495057",
      fontSize: "14px",
      maxHeight: "20px",
      cursor: "pointer",
      boxShadow: "none",
      fontFamily: "Inter,Helvetica,Arial,sans-serif",
      borderRadius: "4px",
      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      "&:hover": {
        border: "1px solid rgba(0, 0, 0, 0.87)",
      },
      "&:focus-within": {
        border: "2px solid #1976d2",
        boxShadow: `${alpha("#1976d2", 0.15)} 0 0 0 1px`,
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "rgba(0, 0, 0, 0.6)",
      fontFamily: "Inter,Helvetica,Arial,sans-serif",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      cursor: "pointer",
      fontFamily: "Inter,Helvetica,Arial,sans-serif",
      fontSize: "14px",
      backgroundColor: state.isSelected
        ? "rgba(25, 118, 210, 0.08)"
        : "transparent",
      color: "rgba(0, 0, 0, 0.87)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      boxShadow:
        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
      borderRadius: "4px",
      margin: "0px",
      padding:"0px"
    }),
  };

  return (
    <Controller
      name={nameOfSelect}
      control={control}
      rules={{
        required: isFieldMandatory,
      }}
      defaultValue={selectedValue || ""}
      render={({ field: { onChange, value } }: any) => {
        const selectedOption =
          dropdownValue.find((option: any) => option.value === value) ||
          (value ? { value: value, label: value } : null);

        return (
          <CreatableSelect
            isClearable
            styles={customStyles}
            value={selectedOption}
            options={dropdownValue}
            isDisabled={credentialDefinitionModalMode === "View"}
            placeholder={t("oid4vc.credentials.select")}
            onChange={(newValue: any) => {
              // Handle both selection and clearing
              const selectedValue = newValue?.value ?? "";
              onChange(selectedValue);
              setJsonSchemaValueOnChange(
                selectedValue,
                setValue,
                watch("credentialFormat"),
              );
            }}
           
          />
        );
      }}
    />
  );
};

export default SelectCredentialforCreateCredentialDefinition;
