import { useState } from "react";
import {
  List,
  Datagrid,
  Form,
  useRefresh,
  useRecordContext,
} from "react-admin";

import { Box, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

// icons
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

import BreadCrumb from "../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";

import { TableEmptyMessage } from "../../components/tableEmptyMessage";
import useLanguageChange from "../../utils/translateTableLanguage";
import DeleteModal from "../../components/modals/generalModal";
import { downloadDAAsJson } from "../../utils/downloadDAasJson";
import RawExchangeModeModal from "../../components/modals/rawExchangeModeModal";
import EwcIssuenceModal from "../../components/modals/ewcModals/ewcIssuenceModal";
import ViewExchangeRecordModal from "../../components/modals/viewExchangeRecordModal";
import { useSSIStore } from "../../store/ssiStore";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const CredentialsHistoryEWC = () => {
  const [openRawExchangeModeModal, setOpenRawExchangeModeModal] =
    useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [
    selectededDataAgreementFromDataAgreement,
    setSelectededDataAgreementFromDataAgreement,
  ] = useState<any>();
  const [openViewExchangeRecordModal, setOpenViewExchangeRecordModal] =
    useState(false);
  const [openIssueCredentialModal, setOpenIssueCredentialModal] =
    useState(false);

  // for controlling issuence modal create and update
  const [issuanceModalMode, setIssuanceModalMode] = useState("Create");

  const selectedRecord: any = useSSIStore(
    (state) => state.dataAgreementForIssuanceHistory
  );

  const { t } = useTranslation("translation");
  const key = useLanguageChange();

  const refresh = useRefresh();

  const onRefetch = () => {
    refresh();
  };

  const IconsFIeld = (props: any) => {
    const record = useRecordContext(props);
    const iconColor =
      record.issuanceMode === "Deferred" && record.status === "Offer Received"
        ? "#FF0C10"
        : "#4D4D4F";

    if (!record || !props.source) {
      return null;
    }

    return (
      record[props.source] && (
        <Box display={"flex"} justifyContent={"space-around"}>
          <Tooltip title={t("issuanceHistory.issueCredential")} placement="top">
            <SendOutlinedIcon
              onClick={() => {
                record.issuanceMode === "Deferred" &&
                  record.status === "Offer Received" &&
                  setOpenIssueCredentialModal(true);
                setSelectededDataAgreementFromDataAgreement(record);
                setIssuanceModalMode("Update");
              }}
              fontSize="small"
              style={{
                cursor:
                  record.issuanceMode === "Deferred" &&
                  record.status === "Offer Received"
                    ? "pointer"
                    : "not-allowed",
                color:
                  record.issuanceMode === "Deferred" &&
                  record.status === "Offer Received"
                    ? "#FF0C10"
                    : "#B9B9B9",
              }}
            />
          </Tooltip>

          {/* <Tooltip
            title={t("issuanceHistory.viewIssuedDataAndDA")}
            placement="top"
          >
            <RemoveRedEyeOutlinedIcon
              onClick={() => {
                record.dataAttributeValues !== null &&
                  setOpenViewExchangeRecordModal(true);
                setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor:
                  record.dataAttributeValues !== null
                    ? "pointer"
                    : "not-allowed",
                color:
                  record.dataAttributeValues !== null ? "#4D4D4F" : "#B9B9B9",
              }}
            />
          </Tooltip> */}

          <Tooltip
            title={t("issuanceHistory.oid4vcDownloadIssuanceRecord")}
            placement="top"
          >
            <CloudDownloadOutlinedIcon
              onClick={() => {
                downloadDAAsJson(record, "credentialHistoryEWC");
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: iconColor,
              }}
            />
          </Tooltip>

          <Tooltip
            title={t(
              "issuanceHistory.viewRawCredentialExchangeRecordForOID4VC"
            )}
            placement="top"
          >
            <IntegrationInstructionsOutlinedIcon
              onClick={() => {
                setOpenRawExchangeModeModal(true);
                setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: iconColor,
              }}
            />
          </Tooltip>
          <Tooltip
            title={t("issuanceHistory.deleteCredentialExchangeRecordForOID4VC")}
            placement="top"
          >
            <DeleteOutlineOutlinedIcon
              onClick={() => {
                setOpenDeleteModal(true),
                  setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: iconColor,
              }}
            />
          </Tooltip>
        </Box>
      )
    );
  };

  const CredentialFormatField = (props: any) => {
    const record = useRecordContext(props);
    const color =
      record.issuanceMode === "Deferred" && record.status === "Offer Received"
        ? "#FF0C10"
        : "#4D4D4F";

    if (!record || !props.source) {
      return null;
    }

    return (
      <Typography
        variant="body2"
        sx={{
          color: color,
        }}
      >
        {record[props.source] === true
          ? t("credentials.sdJwt")
          : t("credentials.jwt")}
      </Typography>
    );
  };

  const ColorField = (props: any) => {
    const record = useRecordContext(props);
    const color =
      record.issuanceMode === "Deferred" && record.status === "Offer Received"
        ? "#FF0C10"
        : "#4D4D4F";

    if (!record || !props.source) {
      return null;
    }

    return (
      <Typography
        variant="body2"
        sx={{
          color: color,
        }}
      >
        {record[props.source]}
      </Typography>
    );
  };

  const TruncateField = (props: any) => {
    const record = useRecordContext();
    const [showTooltip, setShowTooltip] = useState(false);
    const color =
      record.issuanceMode === "Deferred" && record.status === "Offer Received"
        ? "#FF0C10"
        : "#4D4D4F";

    if (!record || !props.source) {
      return null;
    }

    const fullText = record.holder.name;

    const truncatedText =
      fullText.length > 60 ? `${fullText.substring(0, 50)}...` : fullText;

    const handleCopy = () => {
      navigator.clipboard.writeText(fullText);

      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 1000);
    };

    return (
      <Tooltip
        title={t("dataAgreements.copied")}
        placement="top"
        open={showTooltip}
      >
        <Typography
          variant="body2"
          onClick={() => fullText !== "Unknown" && handleCopy()}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: fullText !== "Unknown" ? "pointer" : "auto",
            color: color,
          }}
        >
          {truncatedText}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <Container>
      <Form>
        <BreadCrumb
          Link={t("sidebar.openIDDigitalWallet")}
          Link2={t("sidebar.credentials")}
          Link3={t("issuanceHistory.issuanceHistory")}
        />
        <HeaderContainer>
          <Typography variant="h6" fontWeight="bold">
            {t("issuanceHistory.issuanceHistory")} -{" "}
            {selectedRecord?.label}
          </Typography>
        </HeaderContainer>
        <Typography variant="body2" mt={1.25}>
          {t("issuanceHistory.pageDescription")}
        </Typography>
      </Form>
      <List
        empty={<TableEmptyMessage />}
        actions={false}
        sx={{ width: "100%", overflow: "hidden" }}
        filter={{ id: selectedRecord.credentialDefinitionId }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              overflow: "auto",
              width: "100%",
              "& .RaDatagrid-rowCell:last-child": {
                width: { md: "30%", lg: "19%" },
              },
              "& .RaDatagrid-rowCell:first-child": {
                width: { md: "30%", lg: "19%" },
              },
            }}
            key={key}
          >
            <TruncateField
              source="holder.name"
              sortable={false}
              label={t("sidebar.holder")}
            />

            <ColorField
              source="updatedAt"
              sortable={false}
              label={t("issuanceHistory.issuanceDate")}
            />

            <CredentialFormatField
              source="limitedDisclosure"
              sortable={false}
              label={t("digitalWalletBase.credentialFormat")}
            />
            <ColorField
              source="issuanceMode"
              sortable={false}
              label={t("credentials.issuanceMode")}
            />
            <ColorField
              source="status"
              sortable={false}
              label={t("issuanceHistory.state")}
            />

            <IconsFIeld source="id" sortable={false} label={""} />
          </Datagrid>
        </Box>
      </List>

      {/* Modals */}

      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        headerText={`${t(
          "issuanceHistory.deleteCredentialExchangeRecordForOID4VC"
        )}`}
        confirmText="DELETE"
        confirmButtonText={`${t("common.delete")}`}
        resourceName="issuanceHistoryEWC"
        onRefetch={onRefetch}
        selectededDataAgreementFromDataAgreement={
          selectededDataAgreementFromDataAgreement
        }
        modalDescriptionText={
          <Typography variant="body1">
            {t("issuanceHistory.deleteDescription1")}
            <b>DELETE</b>
            {t("issuanceHistory.deleteDescription2")}
          </Typography>
        }
      />

      <RawExchangeModeModal
        open={openRawExchangeModeModal}
        setOpen={setOpenRawExchangeModeModal}
        headerText={t("issuanceHistory.rawExchangeRecord")}
        selectedData={selectededDataAgreementFromDataAgreement}
        ssi={"issuanceHistoryEWC"}
      />

      <ViewExchangeRecordModal
        open={openViewExchangeRecordModal}
        setOpen={setOpenViewExchangeRecordModal}
        headerText={t("issuanceHistory.viewExchangeRecord")}
        selectedData={selectededDataAgreementFromDataAgreement}
        ssi={"issuanceHistoryEWC"}
      />

      <EwcIssuenceModal
        open={openIssueCredentialModal}
        setOpen={setOpenIssueCredentialModal}
        headerText={t("credentials.issueCredentials")}
        onRefetch={onRefetch}
        ssi={"credentials"}
        issuanceModalMode={issuanceModalMode}
        selectedData={selectededDataAgreementFromDataAgreement}
        selectedRecord={selectedRecord}
      />
    </Container>
  );
};

export default CredentialsHistoryEWC;
