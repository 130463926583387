import {
  List,
  Datagrid,
  TextField,
  useRefresh,
  useRecordContext,
} from "react-admin";

import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import BreadCrumb from "../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import { TableEmptyMessage } from "../../components/tableEmptyMessage";
import useLanguageChange from "../../utils/translateTableLanguage";
import { useEffect, useState } from "react";
import DataRequestStatusDropdown from "../../components/dropdowns/dataRequestStatusDropdown";
import SnackbarComponent from "../../components/notification";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const UserRequests = () => {
  const { t } = useTranslation("translation");
  const key = useLanguageChange();
  const [selectedStatus, setSelectedStatus] = useState<any>({});
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const refresh = useRefresh();

  const onRefetch = () => {
    refresh();
  };

  const DataRequestStatusField = (props: any) => {
    const record = useRecordContext(props);

    useEffect(() => {
      if (record && props.source) {
        setSelectedStatus(record[props.source]); // Set initial value
      }
    }, [record, props.source]);

    if (!record || !props.source) {
      return null;
    }

    return (
      <DataRequestStatusDropdown
        record={record}
        setSelectedValue={setSelectedStatus}
        selectedValue={selectedStatus}
        key={record.id}
        onRefetch={onRefetch}
        setOpenSnackBar={setOpenSnackBar}
        setSuccessMessage={setSuccessMessage}
        setError={setError}
      />
    );
  };

  return (
    <Container>
      <SnackbarComponent
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        message={error}
        topStyle={100}
        successMessage={successMessage}
      />
      <BreadCrumb
        Link={t("sidebar.supportEvents")}
        Link2={t("sidebar.userRequests")}
      />
      <HeaderContainer>
        <Typography variant="h6" fontWeight="bold">
          {t("userRequests.activeRequests")}
        </Typography>
      </HeaderContainer>

      <Typography variant="body2" mt={1.25}>
        {t("userRequests.pageDescription")}
      </Typography>
      <List
        actions={false}
        sx={{ width: "100%", overflow: "hidden" }}
        filter={{ status: "open" }}
        empty={<TableEmptyMessage />}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              width: "100%",

              "& .RaDatagrid-rowCell:last-child": {
                width: "20%",
              },
            }}
            key={key}
          >
            <TextField
              source="userId"
              label={t("userRequests.consumerID")}
              sortable={false}
            />
            <TextField
              source="userName"
              label={t("userRequests.consumerName")}
              sortable={false}
            />
            <TextField
              source="requestedDate"
              label={t("userRequests.dateRequested")}
              sortable={false}
            />
            <TextField
              source="typeStr"
              label={t("userRequests.request")}
              sortable={false}
            />
            <DataRequestStatusField
              source="stateStr"
              label={t("common.status")}
              sortable={false}
            />
          </Datagrid>
        </Box>
      </List>

      <HeaderContainer>
        <Typography variant="h6" fontWeight="bold">
          {t("userRequests.closedRequests")}
        </Typography>
      </HeaderContainer>
      <List
        actions={false}
        sx={{ width: "100%", overflow: "hidden" }}
        filter={{ status: "closed" }}
        empty={<TableEmptyMessage />}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              overflow: "auto",
              width: "100%",
            }}
            key={key}
          >
            <TextField
              source="userId"
              label={t("userRequests.consumerID")}
              sortable={false}
            />
            <TextField
              source="userName"
              label={t("userRequests.consumerName")}
              sortable={false}
            />
            <TextField
              source="requestedDate"
              label={t("userRequests.dateRequested")}
              sortable={false}
            />
            <TextField
              source="typeStr"
              label={t("userRequests.request")}
              sortable={false}
            />
            <TextField
              source="closedDate"
              label={t("userRequests.dateProcessed")}
              sortable={false}
            />
            <TextField
              source="stateStr"
              label={t("common.status")}
              sortable={false}
            />
          </Datagrid>
        </Box>
      </List>
    </Container>
  );
};

export default UserRequests;
