import { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import BreadCrumb from "../../components/Breadcrumbs";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import CopyButtonWithToolTip from "../../components/copyButtonWithToolTip";
import { Datagrid, List, TextField } from "react-admin";
import { TableEmptyMessage } from "../../components/tableEmptyMessage";
import useLanguageChange from "../../utils/translateTableLanguage";
import { HttpServiceForDigitalWalletEwc } from "../../service/digitalWalletEwcHttpService";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";
import ConfigureEwcDigitalWalletBase from "../../components/modals/configureEWCDigitalWalletBaseModal";
import KeyManagementModalForOrgId from "../../components/modals/keyManagementModalForOrgId";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const Item = styled("div")({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#fff",
  padding: "25px 30px 25px 30px",
  marginTop: "15px",
  justifyContent: "space-between",
  height: "auto",
  borderRadius: 3,
  border: "1px solid #E1E1E1",
});

const ItemGrid = styled("div")({
  display: "Grid",
  backgroundColor: "#fff",
  padding: "15px 30px 15px 30px",
  marginTop: "13px",
  height: "auto",
  borderRadius: 3,
  border: "1px solid #E1E1E1",
});

const OrgItemGrid = styled("div")({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#fff",
  marginTop: "15px",
  justifyContent: "space-between",
  height: "auto",
});

const buttonStyle = {
  height: 35,
  borderRadius: 1,
  border: "1px solid #DFDFDF",
  width: "auto",
  paddingLeft: "50px",
  paddingRight: "50px",
};

const DigitalWalletBaseEWC = () => {
  const [openConfigurationModal, setOpenConfigurationModal] = useState(false);
  const [openKeyManagementModal, setOpenKEyManagementModal] = useState(false);
  const { t } = useTranslation("translation");
  const key = useLanguageChange();
  const [digitalWallet, setDigitalWallet] = useState<any>();
  const [organisationalIdentifier, setOrganisationalIdentifier] = useState<any>(
    { "did:ebsi": "", "did:key": "" }
  );
  // const digitalWalletDeploymentStatus = useDigitalWalletStore(
  //   (state) => state.deploymentStatus
  // );
  const [digitalWalletDeploymentStatus, setDigitalWalletDeploymentStatus] =
    useState<string>("Not Configured");

  const ledgerNetworks = [
    {
      label: "European Blockchain Service Infrastructure",
      value: "ebsi",
      disabled: false,
    },
  ];

  const infrastructureProvider = [
    { label: "iGrant.io", value: "iGrant.io", disabled: false },
    {
      label: "Google Cloud Platform",
      value: "Google Cloud Platform",
      disabled: true,
    },
    {
      label: "Amazon Web Service",
      value: "Amazon Web Service",
      disabled: true,
    },
  ];

  useEffect(() => {
    HttpServiceForDigitalWalletEwc.getDigitalWallet().then((response) => {
      setDigitalWallet(response);
      setDigitalWalletDeploymentStatus(response.statusStr);

      useDigitalWalletStore
        .getState()
        .updateDigitalWalletDeploymentStatusForEWC(response.statusStr);
    });
  }, [openConfigurationModal]);

  useEffect(() => {
    if (digitalWalletDeploymentStatus === "Deployed") {
      HttpServiceForDigitalWalletEwc.getOrganisationalIdentifier().then(
        (data) => {
          setOrganisationalIdentifier(data["organisationIdentifier"]);
        }
      );
    }
  }, [digitalWalletDeploymentStatus]);

  return (
    <Container>
      <BreadCrumb
        Link={t("sidebar.openIDDigitalWallet")}
        Link2={t("sidebar.digitalWalletBase")}
      />
      <HeaderContainer>
        <Typography variant="h6" fontWeight="bold">
          {t("sidebar.digitalWalletBase")}
        </Typography>
      </HeaderContainer>
      <Typography variant="body2" mt={1.25}>
        {t("digitalWalletBase.pageDescription")}
      </Typography>

      <Item>
        <Typography color="black" variant="subtitle1" fontWeight="bold">
          {t("privacyDashboard.currentStatus")}
        </Typography>
        <Box sx={{ display: { xs: "grid", sm: "flex" }, alignItems: "center" }}>
          <Typography color="black" variant="subtitle1" fontWeight="bold">
            {digitalWallet?.statusStr?.toUpperCase()}
          </Typography>
          <Button
            style={buttonStyle}
            variant="outlined"
            sx={{
              marginLeft: { sx: 0, md: "20px" },
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
              color: "black",
            }}
            onClick={() => setOpenConfigurationModal(true)}
          >
            {t("privacyDashboard.configure")}
          </Button>
        </Box>
      </Item>

      <ItemGrid>
        <OrgItemGrid>
          <Typography color="black" variant="subtitle1" fontWeight="bold">
            {t("digitalWalletBase.organisationalIdentifier")}
          </Typography>
          <Box
            sx={{
              display: { xs: "grid", sm: "flex" },
              alignOrgItemGrids: "center",
            }}
          >
            <Typography color="black" variant="subtitle1" fontWeight="bold">
              {t("digitalWalletBase.configured")}
            </Typography>
            <Button
              style={buttonStyle}
              variant="outlined"
              sx={{
                marginLeft: { sx: 0, md: "20px" },
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
                color: "black",
              }}
              onClick={() => setOpenKEyManagementModal(true)}
            >
              {t("digitalWalletBase.keyManagement")}
            </Button>
          </Box>
        </OrgItemGrid>

        <Grid container mt={1.5}>
          <Grid item lg={3} md={5} sm={5} xs={12}>
            <Typography color="black" variant="body2">
              {t("digitalWalletBase.didEbsi")}:
            </Typography>
          </Grid>
          <Grid item lg={5} md={7} sm={7} xs={12} display={"flex"}>
            <Typography
              color="grey"
              variant="body2"
              style={{ overflowWrap: "anywhere" }}
            >
              {organisationalIdentifier["did:ebsi"]}
            </Typography>
            {organisationalIdentifier["did:ebsi"] && (
              <Box style={{ marginLeft: "10px" }}>
                <CopyButtonWithToolTip
                  copytext={organisationalIdentifier["did:ebsi"]}
                />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={3} md={5} sm={5} xs={12}>
            <Typography
              color="black"
              variant="body2"
              style={{ overflowWrap: "anywhere" }}
            >
              {t("digitalWalletBase.didKey")}:
            </Typography>
          </Grid>
          <Grid item lg={5} md={7} sm={7} xs={12} display={"flex"}>
            <Typography
              color="grey"
              variant="body2"
              style={{ overflowWrap: "anywhere" }}
            >
              {organisationalIdentifier["did:key"]}
            </Typography>
            {organisationalIdentifier["did:key"] && (
              <Box style={{ marginLeft: "10px" }}>
                <CopyButtonWithToolTip
                  copytext={organisationalIdentifier["did:key"]}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </ItemGrid>

      <ItemGrid>
        <Typography color="black" variant="subtitle1" fontWeight="bold">
          {t("appbar.settings")}
        </Typography>
        <Grid container mt={1.5}>
          <Grid item lg={3} md={5} sm={5} xs={12}>
            <Typography
              color="black"
              variant="body2"
              style={{ overflowWrap: "anywhere" }}
            >
              {t("credentials.walletEndpoint")}:
            </Typography>
          </Grid>
          <Grid item lg={7} md={7} sm={7} xs={12} display={"flex"}>
            {digitalWallet?.statusStr === "Deployed" ? (
              <>
                <a
                  style={{
                    color: "blue",
                    fontSize: "14px",
                    textDecoration: "none",
                    overflowWrap: "anywhere",
                  }}
                  href={digitalWallet?.credentialOfferEndpoint}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {digitalWallet?.credentialOfferEndpoint}
                </a>
                <Box style={{ marginLeft: "10px" }}>
                  <CopyButtonWithToolTip
                    copytext={digitalWallet?.credentialOfferEndpoint}
                  />
                </Box>
              </>
            ) : (
              <Typography
                color="grey"
                variant="body2"
                style={{ overflowWrap: "anywhere" }}
              >
                {`<${t("digitalWalletBase.notDeployed")}>`}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={3} md={5} sm={5} xs={12}>
            <Typography color="black" variant="body2">
              {t("digitalWalletBase.defaultTrustAnchorURL")}:
            </Typography>
          </Grid>
          <Grid
            item
            lg={7}
            md={7}
            sm={7}
            xs={12}
            sx={{ display: { xs: "grid", sm: "flex" } }}
          >
            {digitalWallet?.statusStr === "Not Configured" ? (
              <Typography
                color="grey"
                variant="body2"
                style={{ overflowWrap: "anywhere" }}
              >
                {`<${t("digitalWalletBase.notDeployed")}>`}
              </Typography>
            ) : (
              <>
                {" "}
                <Typography color="grey" variant="body2" sx={{ mr: 1 }}>
                  {digitalWallet?.statusStr === "Deployed" && (
                    <>
                      {digitalWallet.ledgerName}{" "}
                      <a
                        style={{
                          color: "blue",
                          fontSize: "14px",
                          textDecoration: "none",
                        }}
                        href={digitalWallet.ledgerURL}
                        target="_blank"
                      >
                        ({digitalWallet.ledgerURL})
                      </a>
                    </>
                  )}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={3} md={5} sm={5} xs={12}>
            <Typography color="black" variant="body2">
              {t("digitalWalletBase.infrastructureProvider")}:
            </Typography>
          </Grid>
          <Grid item lg={7} md={7} sm={7} xs={12} display={"flex"}>
            <Typography
              color="grey"
              variant="body2"
              style={{ overflowWrap: "anywhere" }}
            >
              {digitalWallet?.statusStr === "Deployed"
                ? digitalWallet?.infrastructureProvider
                : `<${t("digitalWalletBase.notDeployed")}>`}
            </Typography>
          </Grid>
        </Grid>{" "}
        <Grid container>
          <Grid item lg={3} md={5} sm={5} xs={12}>
            <Typography color="black" variant="body2">
              {t("digitalWalletBase.deploymentRegion")}:
            </Typography>
          </Grid>
          <Grid item lg={7} md={7} sm={7} xs={12} display={"flex"}>
            <Typography
              color="grey"
              variant="body2"
              style={{ overflowWrap: "anywhere" }}
            >
              {digitalWallet?.statusStr === "Deployed"
                ? digitalWallet?.deploymentRegion
                : `<${t("digitalWalletBase.notDeployed")}>`}
            </Typography>
          </Grid>
        </Grid>
      </ItemGrid>

      <Typography variant="subtitle1" fontWeight="bold" mt={1.5}>
        {t("digitalWalletBase.supportedDataExchangeProfile")}
      </Typography>

      <List
        actions={false}
        sx={{ width: "100%", overflow: "hidden" }}
        empty={<TableEmptyMessage />}
        pagination={false}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              overflow: "auto",
              width: "100%",
            }}
            key={key}
          >
            <TextField
              source="dexProfileName"
              label={t("digitalWalletBase.dexProfileName")}
              sortable={false}
            />
            <TextField
              source="credentialFormat"
              label={t("digitalWalletBase.credentialFormat")}
              sortable={false}
            />
            <TextField
              source="signingAlgorithm"
              label={t("digitalWalletBase.signingAlgorithm")}
              sortable={false}
            />
            <TextField
              source="revocationAlgorithm"
              label={t("digitalWalletBase.revocationAlgorithm")}
              sortable={false}
            />
            <TextField
              source="keyManagementIssuer"
              label={t("digitalWalletBase.keyManagementIssuer")}
              sortable={false}
            />
            <TextField
              source="keyManagementHolder"
              label={t("digitalWalletBase.keyManagementHolder")}
              sortable={false}
            />{" "}
            <TextField
              source="trustManagement"
              label={t("digitalWalletBase.trustManagement")}
              sortable={false}
            />
          </Datagrid>
        </Box>
      </List>

      {/* Modals */}

      <ConfigureEwcDigitalWalletBase
        ledgerNetworks={ledgerNetworks}
        open={openConfigurationModal}
        setOpen={setOpenConfigurationModal}
        headerText={t("digitalWalletBase.modalHeaderText")}
        infrastructureProvider={infrastructureProvider}
        digitalWallet={digitalWallet}
      />

      <KeyManagementModalForOrgId
        ledgerNetworks={openKeyManagementModal}
        open={openKeyManagementModal}
        setOpen={setOpenKEyManagementModal}
        headerText={t("digitalWalletBase.keyManagement")}
        infrastructureProvider={infrastructureProvider}
        digitalWallet={digitalWallet}
      />
    </Container>
  );
};

export default DigitalWalletBaseEWC;
