import { Controller } from "react-hook-form";

function ReusableCheckbox({
  control,
  name,
  checkBoxlabel,
  disabledValue,
}: any) {
  return (
    <label
      style={{
        fontSize: 14,
        marginLeft: "20px",
        fontFamily: "Inter,Helvetica,Arial,sans-serif",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <input
              style={{ cursor: disabledValue ? "not-allowed" : "pointer" }}
              type="checkbox"
              disabled={disabledValue}
              checked={value === true}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
            />
            {checkBoxlabel}
          </>
        )}
      />
    </label>
  );
}

export default ReusableCheckbox