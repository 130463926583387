import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const titleAttrRestrictionStyle = {
  fontWeight: "normal",
  marginTop: "10px",
  lineHeight: "1.5rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "not-allowed",
  border: "1px solid #DFE0E1",
  borderRadius: 5,
  padding: "12px",
  marginBottom:"70px"
};

export const DataAgreementPolicyCardForEWC = () => {
  const { t } = useTranslation("translation");

  return (
    <>
      <Box style={titleAttrRestrictionStyle}>
        <Typography color="grey" variant="subtitle2">
          {t("issuanceHistory.dataAgreementPolicy")}
        </Typography>
        <KeyboardArrowRightIcon style={{ color: "grey", }} />
      </Box>
    </>
  );
};
