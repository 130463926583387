import { TextField, Typography, TextareaAutosize } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SubmitButtons from "./buttonsContainer";
import { styled } from "@mui/material/styles";

const InputWithTypoFlexBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "80%",
  [theme.breakpoints.down("sm")]: {
    display: "grid",
  },
}));

const InputWithTypoGridBox = styled("div")(({ theme }) => ({
  display: "grid",
  width: "100%",
}));

let defaultValues = {
  natureOfPersonalDataBreach: "",
  approximateNumberOfDataSubjects: null,
  dpoContact: "",
  consequencesOfPersonalDataBreach: "",
  measuresToBeTaken: "",
};

const DataBreaches = () => {
  const { t } = useTranslation("translation");

  const { handleSubmit, formState, reset, register } = useForm<any>({
    mode: "onChange",
  });

  useEffect(() => {
    let defaultValues = {
      natureOfPersonalDataBreach: "",
      approximateNumberOfDataSubjects: null,
      dpoContact: "",
      consequencesOfPersonalDataBreach: "",
      measuresToBeTaken: "",
    };

    reset({ ...defaultValues });
  }, []);

  const onSubmit = (createdData: any) => {
    console.log("createdData", createdData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography color="black" variant="subtitle1" fontWeight="bold">
        {t("notifications.dataBreaches")}
      </Typography>
      <InputWithTypoFlexBox>
        <Typography color="black" variant="subtitle2">
          {t("notifications.natureOfPersonalDataBreach")}:
        </Typography>
        <TextField
          variant="standard"
          sx={{
            width: { xs: "100%", sm: "50%" },
          }}
          placeholder={t("notifications.pleaseTypeAtleastFiveCharacters")}
          {...register("natureOfPersonalDataBreach", {
            required: true,
            minLength: 5,
          })}
        />
      </InputWithTypoFlexBox>

      <InputWithTypoFlexBox>
        <Typography color="black" variant="subtitle2">
          {t("notifications.approximateNumberOfDataSubjects")}:
        </Typography>
        <TextField
          variant="standard"
          sx={{
            width: { xs: "100%", sm: "50%" },
          }}
          type="number"
          placeholder={t("notifications.pleaseTypeAtleastADigit")}
          {...register("approximateNumberOfDataSubjects", {
            required: true,
            minLength: 1,
            pattern: /^[0-9]+$/,
          })}
        />
      </InputWithTypoFlexBox>

      <InputWithTypoFlexBox>
        <Typography color="black" variant="subtitle2">
          {t("notifications.dpoContact")}:
        </Typography>
        <TextField
          variant="standard"
          sx={{
            width: { xs: "100%", sm: "50%" },
          }}
          placeholder={t("notifications.pleaseTypeAValidEmail")}
          {...register("dpoContact", {
            required: true,
            minLength: 1,
            pattern: /^\S+@\S+\.\S+$/,
          })}
        />
      </InputWithTypoFlexBox>

      <InputWithTypoGridBox>
        <Typography color="black" variant="subtitle2" mb={1} mt={1}>
          {t("notifications.consequencesOfPersonalDataBreach")}:
        </Typography>
        <TextareaAutosize
          aria-label="empty textarea"
          placeholder={t("notifications.pleaseTypeAtleastFiveCharacters")}
          style={{
            border: "none",
            borderBottom: "1px solid #bdbdbd",
            resize: "vertical",
            width: "100%",
            outline: "none",
            height: "100px",
            fontFamily: "Inter,Helvetica,Arial,sans-serif",
            fontSize:"14px"
          }}
          {...register("consequencesOfPersonalDataBreach", {
            required: true,
            minLength: 5,
          })}
        />
      </InputWithTypoGridBox>

      <InputWithTypoGridBox>
        <Typography color="black" variant="subtitle2" mb={1} mt={2}>
          {t("notifications.measuresToBeTaken")}:
        </Typography>
        <TextareaAutosize
          aria-label="empty textarea"
          placeholder={t("notifications.pleaseTypeAtleastFiveCharacters")}
          style={{
            border: "none",
            borderBottom: "1px solid #bdbdbd",
            resize: "vertical",
            width: "100%",
            outline: "none",
            height: "100px",
            fontFamily: "Inter,Helvetica,Arial,sans-serif",
            fontSize:"14px"
          }}
          {...register("measuresToBeTaken", {
            required: true,
            minLength: 5,
          })}
        />
      </InputWithTypoGridBox>
      <SubmitButtons
        handleSubmit={handleSubmit(onSubmit)}
        clearValues={() => reset({ ...defaultValues })}
        disable={!formState.isValid}
        buttonName1={t("notifications.clear")}
        buttonName2={t("notifications.notify")}
      />
    </form>
  );
};

export default DataBreaches;
