import * as React from "react";
import { Dispatch, SetStateAction, useState } from "react";

import { Drawer, Typography, Button, Box, Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  BannerContainer,
} from "../modalStyle";
import { useTranslation } from "react-i18next";
import unknownOrgLogo from "../../../assets/unknownOrgLogo.png";
import CustomTabs from "../../holder/tabs";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ViewReceivedCredential from "./viewReceivedCredential";
import CredentialTable from "../../holder/credentialTableForViewSharedCredential";
import DefaultBanner from "../../../assets/OrganisationDefaultBanner.jpeg";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;

  selectedData: any;
}

export default function ViewSharedCredentialModal(props: Props) {
  const { open, setOpen, headerText, selectedData } = props;
  const { t } = useTranslation("translation");

  const [activeTab, setActiveTab] = useState<number>(0);
  const [openViewModal, setOpenViewModal] = useState(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <Container>
          <form>
            <HeaderContainer>
              <Box pl={2} sx={{ display: "flex", alignItems: "center" }}>
                <ChevronLeftIcon
                  onClick={() => {
                    setOpen(false);
                  }}
                  sx={{
                    marginRight: 1,
                    cursor: "pointer",
                    color: "#F3F3F6",
                  }}
                />
                <Typography color="#F3F3F6">{headerText}</Typography>
              </Box>
              <CloseIcon
                onClick={() => {
                  setOpen(false);
                }}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>

            <BannerContainer>
              <Box
                style={{ height: "150px", width: "100%" }}
                component="img"
                alt="Banner"
                src={
                  selectedData?.clientMetadata?.coverUri
                    ? selectedData?.clientMetadata?.coverUri
                    : DefaultBanner
                }
              />
            </BannerContainer>
            <Box sx={{ marginBottom: "60px" }}>
              <Avatar
                src={
                  selectedData?.clientMetadata?.logoUri
                    ? selectedData?.clientMetadata?.logoUri
                    : unknownOrgLogo
                }
                style={{
                  position: "absolute",
                  marginLeft: 50,
                  marginTop: "-65px",
                  width: "110px",
                  height: "110px",
                  border: "solid white 6px",
                }}
              />
            </Box>
            <DetailsContainer sx={{ paddingBottom: "100px" }}>
              <Box p={1.5}>
                <Typography variant="h6" fontWeight="bold" mb={2}>
                  {selectedData?.clientMetadata?.clientName
                    ? selectedData?.clientMetadata?.clientName
                    : t("holder.Unknown")}
                </Typography>
                {/* <Typography color="#9F9F9F" mb={1}>
                  {selectedData?.clientMetadata?.location
                    ? selectedData?.clientMetadata?.location
                    : t("holder.notDiscoverable")}
                </Typography> */}

                <CustomTabs
                  tabs={selectedData?.presentation}
                  activeTab={activeTab}
                  onTabChange={handleTabChange}
                  mode={"View"}
                />

                <CredentialTable
                  items={selectedData?.presentation[activeTab]}
                  setOpenViewModal={setOpenViewModal}
                />
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                style={buttonStyle}
                sx={{
                  marginLeft: "15px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                variant="outlined"
              >
                {t("common.close")}
              </Button>

              <Button
                variant="outlined"
                style={buttonStyle}
                sx={{
                  cursor: "not-allowed",
                  marginRight: "20px",
                  color: "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
              >
                {t("onboarding.submit")}
              </Button>
            </FooterContainer>
          </form>

          <ViewReceivedCredential
            open={openViewModal}
            setOpen={setOpenViewModal}
            headerText={t("holder.viewStoredCredential")}
            selectedFirstTable={"not-table"}
            credentialId={selectedData?.presentation[activeTab]?.credentialId}
            closeSendVerificationModal={setOpen}
          />
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
