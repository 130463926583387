import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const inputDataConfigStyle = {
  color: "#495057",
  border: "none",
  outline: "none",
  fontSize: "14px",
  width: "100%",
  backgroundColor: "#FFFF",
  fontFamily: "Inter,Helvetica,Arial,sans-serif",
};

const dropDownStyle = {
  border: "none",
  outline: "none",
  fontSize: "14px",
  width: "100%",
  height: "30px",
  color: "#495057",
  backgroundColor: "#FFFF",
};

const AttributeValueInputAccordingToType = ({
  type,
  attributeName,
  index,
  handleInputChange,
  attributeValue,
}: any) => {
  if (type === "string") {
    return (
      <input
        autoComplete="off"
        type="text"
        style={{
          ...inputDataConfigStyle,
        }}
        placeholder={`${attributeName} (${type})`}
        value={attributeValue}
        onChange={(e) => handleInputChange(index, e.target.value)}
      />
    );
  } else if (type === "number") {
    return (
      <input
        autoComplete="off"
        type="number"
        style={{
          ...inputDataConfigStyle,
        }}
        placeholder={`${attributeName} (${type})`}
        value={attributeValue}
        onChange={(e) => {
          const value = parseFloat(e.target.value);
          handleInputChange(index, value);
        }}
      />
    );
  } else if (type === "boolean") {
    return (
      <Select
        style={dropDownStyle}
        variant="outlined"
        fullWidth
        defaultValue="true"
        value={attributeValue}
        onChange={(e) => {
          const value = e.target.value;
          handleInputChange(index, value === "true" ? true : false);
        }}
      >
        <MenuItem value="true">True</MenuItem>
        <MenuItem value="false">False</MenuItem>
      </Select>
    );
  }
  if (type === "object") {
    return null;
  } else if (type === "array") {
    return null;
  } else {
    return null;
  }
};

export default AttributeValueInputAccordingToType;
