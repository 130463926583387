import { useState } from "react";
import {
  List,
  Datagrid,
  Form,
  useRefresh,
  useRecordContext,
  TextField,
} from "react-admin";

import { Box, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

// icons
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import BreadCrumb from "../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";

import { TableEmptyMessage } from "../../components/tableEmptyMessage";
import useLanguageChange from "../../utils/translateTableLanguage";
import DeleteModal from "../../components/modals/generalModal";
import { downloadDAAsJson } from "../../utils/downloadDAasJson";
import RawExchangeModeModal from "../../components/modals/rawExchangeModeModal";
import ViewExchangeRecordModal from "../../components/modals/viewExchangeRecordModal";
import { useSSIStore } from "../../store/ssiStore";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const VerificationsHistoryEwc = () => {
  const [openRawExchangeModeModal, setOpenRawExchangeModeModal] =
    useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [
    selectededDataAgreementFromDataAgreement,
    setSelectededDataAgreementFromDataAgreement,
  ] = useState<any>();
  const [openViewExchangeRecordModal, setOpenViewExchangeRecordModal] =
    useState(false);

  const { t } = useTranslation("translation");
  const key = useLanguageChange();

  const refresh = useRefresh();
  const selectedRecord: any = useSSIStore(
    (state) => state.dataAgreementForVerificationsHistory
  );

  const onRefetch = () => {
    refresh();
  };

  const IconsFIeld = (props: any) => {
    const record = useRecordContext(props);

    if (!record || !props.source) {
      return null;
    }

    return (
      record[props.source] && (
        <Box display={"flex"} justifyContent={"space-around"}>
          {record.verified === true ? (
            <Tooltip
              title={t("verificationsHistory.verificationSuccessful")}
              placement="top"
            >
              <DoneOutlinedIcon
                fontSize="small"
                style={{
                  color: "green",
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip
              title={t("verificationsHistory.verificationFailed")}
              placement="top"
            >
              <CloseOutlinedIcon
                fontSize="small"
                style={{
                  color: "red",
                }}
              />
            </Tooltip>
          )}

          {/* <Tooltip
            title={t("verificationsHistory.viewVerifiedDataAndDA")}
            placement="top"
          >
            <RemoveRedEyeOutlinedIcon
              onClick={() => {
                setOpenViewExchangeRecordModal(true);
                setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip> */}

          <Tooltip
            title={t("issuanceHistory.oid4vcDownloadVerificationRecord")}
            placement="top"
          >
            <CloudDownloadOutlinedIcon
              onClick={() => {
                downloadDAAsJson(record, "verificationHistoryEWC");
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>

          <Tooltip
            title={t(
              "verificationsHistory.viewRawPresentationExchangeRecordForOID4VC"
            )}
            placement="top"
          >
            <IntegrationInstructionsOutlinedIcon
              onClick={() => {
                setOpenRawExchangeModeModal(true);
                setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>
          <Tooltip
            title={t(
              "verificationsHistory.deleteCredentialExchangeRecordForOID4VC"
            )}
            placement="top"
          >
            <DeleteOutlineOutlinedIcon
              onClick={() => {
                setOpenDeleteModal(true),
                  setSelectededDataAgreementFromDataAgreement(record);
              }}
              fontSize="small"
              style={{
                cursor: "pointer",
                color: "#4D4D4F",
              }}
            />
          </Tooltip>
        </Box>
      )
    );
  };

  const TruncateField = (props: any) => {
    const record = useRecordContext();
    const [showTooltip, setShowTooltip] = useState(false);
    const color = "#4D4D4F";

    if (!record || !props.source) {
      return null;
    }

    const fullText = record.holder.name;

    const truncatedText =
      fullText.length > 60 ? `${fullText.substring(0, 50)}...` : fullText;

    const handleCopy = () => {
      navigator.clipboard.writeText(fullText);

      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 1000);
    };

    return (
      <Tooltip
        title={t("dataAgreements.copied")}
        placement="top"
        open={showTooltip}
      >
        <Typography
          variant="body2"
          onClick={() => fullText !== "Unknown" && handleCopy()}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: fullText !== "Unknown" ? "pointer" : "auto",
            color: color,
          }}
        >
          {truncatedText}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <Container>
      <Form>
        <BreadCrumb
          Link={t("sidebar.openIDDigitalWallet")}
          Link2={t("sidebar.verifications")}
          Link3={t("verificationsHistory.verificationHistory")}
        />

        <HeaderContainer>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              {t("verificationsHistory.verificationHistory")} -{" "}
              {selectedRecord?.label}
            </Typography>
          </Box>
        </HeaderContainer>
        <Typography variant="body2" mt={1.25}>
          {t("verificationsHistory.pageDescription")}
        </Typography>
      </Form>
      <List
        empty={<TableEmptyMessage />}
        actions={false}
        sx={{ width: "100%", overflow: "hidden" }}
        filter={{ id: selectedRecord.presentationDefinitionId }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              overflow: "auto",
              width: "100%",
              "& .RaDatagrid-rowCell:last-child": {
                width: { md: "30%", lg: "19%" },
              },
            }}
            key={key}
          >
            <TruncateField
              source="holder.name"
              sortable={false}
              label={t("sidebar.holder")}
            />
            <TextField
              source="updatedAt"
              sortable={false}
              label={t("verificationsHistory.verificationDate")}
            />

            <TextField
              source="presentationExchangeId"
              sortable={false}
              label={t("verificationsHistory.presentationExchangeID")}
            />
            <TextField
              source="status"
              sortable={false}
              label={t("issuanceHistory.state")}
            />

            <IconsFIeld source="id" sortable={false} label={""} />
          </Datagrid>
        </Box>
      </List>

      {/* Modals */}

      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        headerText={`${t(
          "verificationsHistory.deleteCredentialExchangeRecordForOID4VC"
        )}`}
        confirmText="DELETE"
        confirmButtonText={`${t("common.delete")}`}
        resourceName="verificationHistoryEWC"
        onRefetch={onRefetch}
        selectededDataAgreementFromDataAgreement={
          selectededDataAgreementFromDataAgreement
        }
        modalDescriptionText={
          <Typography variant="body1">
            {t("verificationsHistory.deleteDescription1")}
            <b>DELETE</b>
            {t("verificationsHistory.deleteDescription2")}
          </Typography>
        }
      />

      <RawExchangeModeModal
        open={openRawExchangeModeModal}
        setOpen={setOpenRawExchangeModeModal}
        headerText={t("issuanceHistory.rawExchangeRecord")}
        selectedData={selectededDataAgreementFromDataAgreement}
        ssi={"verificationHistoryEwc"}
      />

      {/* <ViewExchangeRecordModal
        open={openViewExchangeRecordModal}
        setOpen={setOpenViewExchangeRecordModal}
        headerText={t("verificationsHistory.verificationHistory")}
        selectedData={selectededDataAgreementFromDataAgreement}
        ssi={"verificationHistoryEwc"}
      /> */}
    </Container>
  );
};

export default VerificationsHistoryEwc;
