import { useContext, useEffect, useState } from "react";

import { Form } from "react-admin";
import { styled } from "@mui/material/styles";

import BreadCrumb from "../../components/Breadcrumbs";
import OrgCoverImageUpload from "../../components/OrganisationDetails/OrgCoverImageUpload";

import OrganisationDetailsContainer from "../../components/OrganisationDetails/OrgDetailsContainer";
import { OrganizationDetailsCRUDContext } from "../../contexts/organizationDetailsCrud";
import { useTranslation } from "react-i18next";
import { HttpServiceForDigitalWalletEwc } from "../../service/digitalWalletEwcHttpService";
import QuickActionCards from "../../components/OrganisationDetails/quickActionCards";
import StatsPage from "../../components/OrganisationDetails/statsPage";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const DetailsContainer = styled("div")({
  height: "auto",
  width: "100%",
  borderRadius: 2,
  backgroundColor: "#FFFFF",
  padding: 10,
  paddingLeft:0,
  paddingRight:0
});

const GettingStarted = () => {
  const [editMode, setEditMode] = useState(false);
  const { t } = useTranslation("translation");
  const [performanceStatistics, setPerformanceStatistics] = useState<object>({})

  const {
    organisationDetails,
    logoImageBase64,
    coverImageBase64,
    setOrganisationDetails,
    setCoverImageBase64,
    setLogoImageBase64,
  } = useContext(OrganizationDetailsCRUDContext);

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    HttpServiceForDigitalWalletEwc.getStatistics().then((res) => {
      setPerformanceStatistics(res.performanceStats)
    });
  }, []);

  return (
    <Form>
      <Container>
        <BreadCrumb Link={t("sidebar.gettingStarted")} />

        <DetailsContainer sx={{ flexGrow: 1 }}>
          <StatsPage performanceStatistics={performanceStatistics} />
        </DetailsContainer>

        <OrgCoverImageUpload
          editMode={editMode}
          coverImageBase64={coverImageBase64}
          setCoverImageBase64={setCoverImageBase64}
        />
        <OrganisationDetailsContainer
          editMode={editMode}
          logoImageBase64={logoImageBase64}
          organisationDetails={organisationDetails}
          handleEdit={handleEdit}
          setOganisationDetails={setOrganisationDetails}
          setLogoImageBase64={setLogoImageBase64}
        />

        <DetailsContainer sx={{ flexGrow: 1, marginTop:"7px" }}>
          <QuickActionCards />
        </DetailsContainer>
      </Container>
    </Form>
  );
};

export default GettingStarted;
