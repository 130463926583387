import * as React from "react";
import { useEffect, useState } from "react";

import { MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { HttpServiceForDigitalWallet } from "../../service/digitalWalletHttpService";

const dropDownStyle = {
  border: "1px solid lightgray",
  outline: "none",
  fontSize: "14px",
  backgroundColor: "#FFFF",
  height: "38px",
  borderRadius: "5px",
  cursor: "pointer",
};

interface DataSchemaTemplateProps {
  mode: string;
  onChange: (e: React.SyntheticEvent) => void;
  value: any;
  setSelectedTemplates: React.Dispatch<React.SetStateAction<any>>;
}

interface Option {
  value: string;
  label: string;
  data: Record<string, string>;
}

export const DataSchemaTemplate = (props: DataSchemaTemplateProps) => {
  const { t } = useTranslation("translation");
  const [dataSchemaValues, setDataSchemaValues] = useState(null)
  const selectOptionFunc = (data: any): Option[] => {
    const options: Option[] = [];

    for (const category in data) {
      options.push({ value: category, label: category, data: data[category] });
    }

    return options;
  };

  useEffect(() => {
    HttpServiceForDigitalWallet.getPredefinedSchemaValues().then((data) => {
     setDataSchemaValues(data.data)
    })
  }, []);

  const selectOptions: Option[] = selectOptionFunc(dataSchemaValues);

  const handleSelectChange = (event: any) => {
    const selectedOption: any = selectOptions.find(
      (option) => option.value === event.target.value
    );
    props.onChange(selectedOption);
    props.setSelectedTemplates(selectedOption.data);
  };

  return (
    <>
      <Select
        onChange={handleSelectChange}
        variant="outlined"
        fullWidth
        value={props.value ? props.value : t("common.select")}
        name="dataSchemaValue"
        style={{
          ...dropDownStyle,
          width: "100%",
          marginTop: "-10px",
        }}
        renderValue={(selected) => {
          if (!selected || !selected.label || selected.label === "Select...") {
            return <em>{t("common.select")}</em>;
          }
          return selected.label;
        }}
      >
        {selectOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
