import { Controller } from "react-hook-form";

function VerificationFormatCheckbox({
  control,
  mode,
}: any) {
  return (
    <div style={{ width: "105px" }}>
      <label
        style={{
          fontSize: 14,
          marginLeft: "20px",
          fontFamily: "Inter,Helvetica,Arial,sans-serif",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Controller
          name={"limitDisclosure"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <>
              <input
                type="checkbox"
                disabled={mode === "View"}
                style={{ cursor: mode === "View" ? "not-allowed" : "pointer" }}
                checked={value}
                onChange={onChange}
              />
              SD-JWT
            </>
          )}
        />
      </label>
    </div>
  );
}

export default VerificationFormatCheckbox;
