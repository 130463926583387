import { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import BreadCrumb from "../../components/Breadcrumbs";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import CopyButtonWithToolTip from "../../components/copyButtonWithToolTip";
import { Datagrid, List, TextField } from "react-admin";
import { TableEmptyMessage } from "../../components/tableEmptyMessage";
import useLanguageChange from "../../utils/translateTableLanguage";
import ConfigureDigitalWalletBase from "../../components/modals/configureDigitalWalletBaseModal";
import { HttpServiceForDigitalWallet } from "../../service/digitalWalletHttpService";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";

const Container = styled("div")(({ theme }) => ({
  margin: "58px 15px 0px 15px",
  paddingBottom: "50px",
  [theme.breakpoints.down("sm")]: {
    margin: "52px 0 10px 0",
  },
}));

const HeaderContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  marginTop: "10px",
});

const Item = styled("div")({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#fff",
  padding: "25px 30px 25px 30px",
  marginTop: "15px",
  justifyContent: "space-between",
  height: "auto",
  borderRadius: 3,
  border: "1px solid #E1E1E1",
});

const ItemGrid = styled("div")({
  display: "Grid",
  backgroundColor: "#fff",
  padding: "15px 30px 15px 30px",
  marginTop: "13px",
  height: "auto",
  borderRadius: 3,
  border: "1px solid #E1E1E1",
});

const buttonStyle = {
  height: 35,
  borderRadius: 1,
  border: "1px solid #DFDFDF",
  width: "auto",
  paddingLeft: "50px",
  paddingRight: "50px",
};

const DigitalWalletBase = () => {
  const [openConfigurationModal, setOpenConfigurationModal] = useState(false);
  const { t } = useTranslation("translation");
  const key = useLanguageChange();
  const [versions, setVersions] = useState();
  const [ledgerNetworks, setLedgerNetworks] = useState();
  const [digitalWallet, setDigitalWallet] = useState<any>();
  const [organisationalIdentifier, setOrganisationalIdentifier] =
    useState<any>();
  const digitalWalletDeploymentStatus = useDigitalWalletStore(
    (state) => state.deploymentStatus
  );

  const infrastructureProvider = [
    { label: "iGrant.io", value: "iGrant.io", disabled: false },
    {
      label: "Google Cloud Platform",
      value: "Google Cloud Platform",
      disabled: true,
    },
    {
      label: "Amazon Web Service",
      value: "Amazon Web Service",
      disabled: true,
    },
  ];

  useEffect(() => {
    HttpServiceForDigitalWallet.getDigitalWalletVersions().then((res) => {
      setVersions(res.versions);
    });

    HttpServiceForDigitalWallet.getLedgerNetworks().then((resp) => {
      setLedgerNetworks(resp.ledgerNetworks);
    });

    HttpServiceForDigitalWallet.getDigitalWallet().then((response) => {
      setDigitalWallet(response);

      useDigitalWalletStore
        .getState()
        .updateDigitalWalletDeploymentStatus(response.statusStr);
    });
  }, [openConfigurationModal]);

  useEffect(() => {
    if (digitalWalletDeploymentStatus === "Deployed") {
      HttpServiceForDigitalWallet.getOrganisationalIdentifier().then((data) => {
        setOrganisationalIdentifier(data.result);
      });
    }
  }, [digitalWalletDeploymentStatus]);

  return (
    <Container>
      <BreadCrumb
        Link={t("sidebar.ariesDigitalWallet")}
        Link2={t("sidebar.digitalWalletBase")}
      />
      <HeaderContainer>
        <Typography variant="h6" fontWeight="bold">
          {t("sidebar.digitalWalletBase")}
        </Typography>
      </HeaderContainer>
      <Typography variant="body2" mt={1.25}>
        {t("digitalWalletBase.pageDescription")}
      </Typography>

      <Item>
        <Typography color="black" variant="subtitle1" fontWeight="bold">
          {t("privacyDashboard.currentStatus")}
        </Typography>
        <Box sx={{ display: { xs: "grid", sm: "flex" }, alignItems: "center" }}>
          <Typography color="black" variant="subtitle1" fontWeight="bold">
            {digitalWallet?.statusStr?.toUpperCase()}
          </Typography>
          <Button
            style={buttonStyle}
            variant="outlined"
            sx={{
              marginLeft: { sx: 0, md: "20px" },
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
              color: "black",
            }}
            onClick={() => setOpenConfigurationModal(true)}
          >
            {t("privacyDashboard.configure")}
          </Button>
        </Box>
      </Item>

      <ItemGrid>
        <Typography color="black" variant="subtitle1" fontWeight="bold">
          {t("digitalWalletBase.organisationalIdentifier")}
        </Typography>
        <Grid container mt={1.5}>
          <Grid item lg={3} md={5} sm={5} xs={12}>
            <Typography color="black" variant="body2">
              {t("digitalWalletBase.publicDID")}:
            </Typography>
          </Grid>
          <Grid item lg={5} md={7} sm={7} xs={12} display={"flex"}>
            <Typography
              color="grey"
              variant="body2"
              style={{ overflowWrap: "anywhere" }}
            >
              {organisationalIdentifier?.did}
            </Typography>
            {organisationalIdentifier?.did && (
              <Box style={{ marginLeft: "10px" }}>
                <CopyButtonWithToolTip
                  copytext={organisationalIdentifier?.did}
                />
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={3} md={5} sm={5} xs={12}>
            <Typography
              color="black"
              variant="body2"
              style={{ overflowWrap: "anywhere" }}
            >
              {t("digitalWalletBase.publicVerkey")}:
            </Typography>
          </Grid>
          <Grid item lg={5} md={7} sm={7} xs={12} display={"flex"}>
            <Typography
              color="grey"
              variant="body2"
              style={{ overflowWrap: "anywhere" }}
            >
              {organisationalIdentifier?.verkey}
            </Typography>
            {organisationalIdentifier?.verkey && (
              <Box style={{ marginLeft: "10px" }}>
                <CopyButtonWithToolTip
                  copytext={organisationalIdentifier?.verkey}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </ItemGrid>

      <ItemGrid>
        <Typography color="black" variant="subtitle1" fontWeight="bold">
          {t("appbar.settings")}
        </Typography>
        <Grid container mt={1.5}>
          <Grid item lg={3} md={5} sm={5} xs={12}>
            <Typography color="black" variant="body2">
              {t("privacyDashboard.deployedVersion")}:
            </Typography>
          </Grid>
          <Grid item lg={5} md={7} sm={7} xs={12} display={"flex"}>
            <Typography
              color="grey"
              variant="body2"
              style={{ overflowWrap: "anywhere" }}
            >
              {digitalWallet?.statusStr === "Deployed"
                ? digitalWallet?.version
                : `<${t("digitalWalletBase.notDeployed")}>`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={3} md={5} sm={5} xs={12}>
            <Typography
              color="black"
              variant="body2"
              style={{ overflowWrap: "anywhere" }}
            >
              {t("digitalWalletBase.agentServiceEndpoint")}:
            </Typography>
          </Grid>
          <Grid item lg={5} md={7} sm={7} xs={12} display={"flex"}>
            {digitalWallet?.statusStr === "Deployed" ? (
              <a
                style={{
                  color: "blue",
                  fontSize: "14px",
                  textDecoration: "none",
                  overflowWrap: "anywhere",
                }}
                href={digitalWallet?.agentServiceEndpoint}
                target="_blank"
                rel="noopener noreferrer"
              >
                {digitalWallet?.agentServiceEndpoint}
              </a>
            ) : (
              <Typography
                color="grey"
                variant="body2"
                style={{ overflowWrap: "anywhere" }}
              >
                {`<${t("digitalWalletBase.notDeployed")}>`}
              </Typography>
            )}
          </Grid>
          <Grid container>
            <Grid item lg={3} md={5} sm={5} xs={12}>
              <Typography
                color="black"
                variant="body2"
                style={{ overflowWrap: "anywhere" }}
              >
                {t("digitalWalletBase.adminEndpoint")}:
              </Typography>
            </Grid>
            <Grid item lg={5} md={7} sm={7} xs={12} display={"flex"}>
              {digitalWallet?.statusStr === "Deployed" ? (
                <a
                  style={{
                    color: "blue",
                    fontSize: "14px",
                    textDecoration: "none",
                    overflowWrap: "anywhere",
                  }}
                  href={digitalWallet?.adminEndpoint}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {digitalWallet?.adminEndpoint}
                </a>
              ) : (
                <Typography
                  color="grey"
                  variant="body2"
                  style={{ overflowWrap: "anywhere" }}
                >
                  {`<${t("digitalWalletBase.notDeployed")}>`}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={3} md={5} sm={5} xs={12}>
            <Typography color="black" variant="body2">
              {t("digitalWalletBase.defaultTrustAnchorURL")}:
            </Typography>
          </Grid>
          <Grid
            item
            lg={5}
            md={7}
            sm={7}
            xs={12}
            sx={{ display: { xs: "grid", sm: "flex" } }}
          >
            {digitalWallet?.statusStr === "Not Configured" ? (
              <Typography
                color="grey"
                variant="body2"
                style={{ overflowWrap: "anywhere" }}
              >
                {`<${t("digitalWalletBase.notDeployed")}>`}
              </Typography>
            ) : (
              <>
                {" "}
                <Typography color="grey" variant="body2" sx={{ mr: 1 }}>
                  {digitalWallet?.statusStr === "Deployed" && (
                    <>
                      {digitalWallet.ledgerName}{" "}
                      <a
                        style={{
                          color: "blue",
                          fontSize: "14px",
                          textDecoration: "none",
                        }}
                        href={digitalWallet.ledgerURL}
                        target="_blank"
                      >
                        ({digitalWallet.ledgerURL})
                      </a>
                    </>
                  )}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={3} md={5} sm={5} xs={12}>
            <Typography color="black" variant="body2">
              {t("digitalWalletBase.infrastructureProvider")}:
            </Typography>
          </Grid>
          <Grid item lg={5} md={7} sm={7} xs={12} display={"flex"}>
            <Typography
              color="grey"
              variant="body2"
              style={{ overflowWrap: "anywhere" }}
            >
              {digitalWallet?.statusStr === "Deployed"
                ? digitalWallet?.infrastructureProvider
                : `<${t("digitalWalletBase.notDeployed")}>`}
            </Typography>
          </Grid>
        </Grid>{" "}
        <Grid container>
          <Grid item lg={3} md={5} sm={5} xs={12}>
            <Typography color="black" variant="body2">
              {t("digitalWalletBase.deploymentRegion")}:
            </Typography>
          </Grid>
          <Grid item lg={5} md={7} sm={7} xs={12} display={"flex"}>
            <Typography
              color="grey"
              variant="body2"
              style={{ overflowWrap: "anywhere" }}
            >
              {digitalWallet?.statusStr === "Deployed"
                ? "Europe: Frankfurt"
                : `<${t("digitalWalletBase.notDeployed")}>`}
            </Typography>
          </Grid>
        </Grid>
      </ItemGrid>

      <Typography variant="subtitle1" fontWeight="bold" mt={1.5}>
        {t("digitalWalletBase.supportedDataExchangeProfile")}
      </Typography>

      <List
        actions={false}
        sx={{ width: "100%", overflow: "hidden" }}
        empty={<TableEmptyMessage />}
        pagination={false}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15px",
          }}
        >
          <Datagrid
            bulkActionButtons={false}
            sx={{
              overflow: "auto",
              width: "100%",
            }}
            key={key}
          >
            <TextField
              source="dexProfileName"
              label={t("digitalWalletBase.dexProfileName")}
              sortable={false}
            />
            <TextField
              source="credentialFormat"
              label={t("digitalWalletBase.credentialFormat")}
              sortable={false}
            />
            <TextField
              source="signingAlgorithm"
              label={t("digitalWalletBase.signingAlgorithm")}
              sortable={false}
            />
            <TextField
              source="revocationAlgorithm"
              label={t("digitalWalletBase.revocationAlgorithm")}
              sortable={false}
            />
            <TextField
              source="keyManagementIssuer"
              label={t("digitalWalletBase.keyManagementIssuer")}
              sortable={false}
            />
            <TextField
              source="keyManagementHolder"
              label={t("digitalWalletBase.keyManagementHolder")}
              sortable={false}
            />{" "}
            <TextField
              source="trustManagement"
              label={t("digitalWalletBase.trustManagement")}
              sortable={false}
            />
          </Datagrid>
        </Box>
      </List>

      {/* Modals */}

      <ConfigureDigitalWalletBase
        versions={versions}
        ledgerNetworks={ledgerNetworks}
        open={openConfigurationModal}
        setOpen={setOpenConfigurationModal}
        headerText={t("digitalWalletBase.modalHeaderText")}
        infrastructureProvider={infrastructureProvider}
        digitalWallet={digitalWallet}
      />
    </Container>
  );
};

export default DigitalWalletBase;
