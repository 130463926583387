import { Typography } from "@mui/material";
import LanguageSelector from "../dropdowns/languageSelector";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

interface Props {
  version: string;
}

const Container = styled("div")(({ theme }) => ({
  display: "flow",
  color: "white",
  alignItems: "center",
  marginRight: "20px",
  justifyContent: "right",
  [theme.breakpoints.down("sm")]: {
    padding: "10px",
    marginRight: "0px",
    textAlign: "center",
  },
}));

const Footer = (props: Props) => {
  const { version } = props;
  const { t } = useTranslation("translation");

  return (
    <Container>
      <LanguageSelector textColor="white" /> &nbsp;| &nbsp;
      <Typography variant="caption">{version}</Typography> &nbsp;| &nbsp;
      <a
        href="https://igrant.io/privacy.html#cookies"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: "none",
          color: "white",
          fontSize: "12px",
        }}
      >
        {t("login.cookiesPolicy")}
      </a>{" "}
      &nbsp;| &nbsp;
      <a
        href="https://igrant.io/terms.html"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: "none",
          color: "white",
          fontSize: "12px",
        }}
      >
        {t("login.termsOfService")}
      </a>{" "}
      &nbsp;| &nbsp;
      <a
        href="https://igrant.io/privacy.html#privacy"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: "none",
          color: "white",
          fontSize: "12px",
        }}
      >
        {t("login.privacyPolicy")}
      </a>
      <Typography variant="caption">
        &nbsp;&nbsp;&nbsp;&nbsp;&copy;&nbsp;2017-{new Date().getFullYear()}{" "}
        {t("login.LCubedABSweden")}
      </Typography>
    </Container>
  );
};

export default Footer;
