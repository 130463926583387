import { Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { HttpServiceForDigitalWallet } from "../../service/digitalWalletHttpService";

interface Props {
  control: any;
  dda?: string;
}

const dropDownStyle = {
  color: "#495057",
  border: "none",
  outline: "none",
  fontSize: "14px",
  width: "100%",
  backgroundColor: "transparent",
};

const ConnectionDropdown = (props: Props) => {
  const { control, dda } = props;
  const [connections, setConnections] = useState<any>();
  const { t } = useTranslation("translation");

  useEffect(() => {
    if (dda === "dda") {
      setConnections([
        {
          state: "active",
          their_did: "36jkKZPsyKVYnq9S8YuB6X",
          connection_id: "fe4b7da4-da63-4fe0-aaa9-5b297ba7f4ac",
          updated_at: "2024-04-01 19:47:28.149905Z",
          initiator: "external",
          routing_state: "none",
          invitation_mode: "once",
          request_id: "7ab4f6c8-418f-4e0b-8f90-f4a91daf406c",
          invitation_key: "bh7bME6TQnUtRxUzkdS3qgUt3LWZRgzRLxPLR4swPYK",
          my_did: "2tm2JsdZBqpJFDJHxohrdr",
          accept: "auto",
          created_at: "2024-04-01 19:47:27.902762Z",
          their_label: "Health Dataspace",
          org_flag: true,
          controller_details: {
            organisation_did: "did:sov:F8pf39N29qjgmc2NPP4x4X",
            organisation_name: "Health Dataspace",
            cover_image_url:
              "https://demo-api.igrant.io/v1/organizations/6609f0860c7d8c0001aea929/image/6609f13c0c7d8c0001aea92b/web",
            logo_image_url:
              "https://demo-api.igrant.io/v1/organizations/6609f0860c7d8c0001aea929/image/6609f1360c7d8c0001aea92a/web",
            location: "Sweden",
            organisation_type: "Healthcare",
            description:
              "For queries about how we are managing your data please contact the Data Protection Officer.",
            policy_url: "https://igrant.io/policy.html",
            eula_url: "",
          },
          marketplace_flag: true,
        },
      ]);
    } else {
      HttpServiceForDigitalWallet.listConnections(0, 100).then(
        (connections) => {
          setConnections(connections.connections);
        }
      );
    }
  }, []);

  return (
    <Controller
      name={"connectionId"}
      
      control={control}
      rules={{
        required: true,
      }}
      render={({ field: { onChange, value } }) => (
        <Select
          onChange={(e: any) => {
            onChange(e);
          }}
          variant="outlined"
          fullWidth
          name={"connectionId"}
          value={value ? value : t("credentials.selectAConnection")}
          style={{
            ...dropDownStyle,
            height: "32px",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200, // Adjust the maximum height of the panel
                overflowY: "auto",
              },
            },
          }}
          renderValue={(selected) => {
            if (!selected) {
              return <em>{t("credentials.selectAConnection")}</em>;
            }
            return selected;
          }}
        >
          {connections?.map((connection: any) =>
            dda === "dda" ? (
              <MenuItem
                key={connection.connection_id}
                value={connection.connection_id}
              >
                {connection.connection_id} ({connection.their_label})
              </MenuItem>
            ) : (
              <MenuItem
                key={connection.connectionId}
                value={connection.connectionId}
              >
                {connection.id} ({connection.theirLabel})
              </MenuItem>
            )
          )}
        </Select>
      )}
    />
  );
};

export default ConnectionDropdown;
