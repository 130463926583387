import * as React from "react";
import { Dispatch, SetStateAction } from "react";

import { Drawer, Typography, Button, Box, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
} from "./modalStyle";

import { useTranslation } from "react-i18next";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import {
  getStateForCredentialHistoryForEWCAsInAPI,
  getStateForVerificationForEWCAsInAPI,
} from "../../utils/downloadDAasJson";
import i18n from "i18next";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  selectedData: any;
  ssi?: string;
  dda?: string;
}

export default function RawExchangeModeModal(props: Props) {
  const { t } = useTranslation("translation");
  const { open, setOpen, headerText, selectedData, ssi, dda } = props;
  const [showTooltip, setShowTooltip] = React.useState(false);

  const getStateForCredentialHistory = (stateTranslation: string) => {
    switch (stateTranslation) {
      case t("issuanceHistory.proposalSent"):
        return "proposal_sent";
      case t("issuanceHistory.proposalReceived"):
        return "proposal_received";
      case t("issuanceHistory.offerSent"):
        return "offer_sent";
      case t("issuanceHistory.offerReceived"):
        return "offer_received";
      case t("issuanceHistory.requestSent"):
        return "request_sent";
      case t("issuanceHistory.requestReceived"):
        return "request_received";
      case t("issuanceHistory.credentialIssued"):
        return "credential_issued";
      case t("issuanceHistory.credentialReceived"):
        return "credential_received";
      case t("issuanceHistory.credentialAcked"):
        return "credential_acked";
      default:
        return null;
    }
  };

  const displayData = (selectedData: any) => {
    if (!selectedData) {
      return null;
    }

    const { state, status, updatedAt, ...otherProps } = selectedData;

    const dataToDisplay = {
      state:
        ssi === "issuanceHistory" ? getStateForCredentialHistory(state) : state,
      updatedAt: updatedAt,
      ...otherProps,
    };

    const dataToDisplayEwc10 = {
      status:
        ssi === "issuanceHistoryEWC"
          ? getStateForCredentialHistoryForEWCAsInAPI(status, i18n.t)
          : getStateForVerificationForEWCAsInAPI(status, i18n.t),
      updatedAt: updatedAt,
      ...otherProps,
    };

    if (dda) {
      return selectedData;
    } else {
      if (ssi === "issuanceHistory" || ssi === "verificationsHistory") {
        return dataToDisplay;
      } else if (
        ssi === "issuanceHistoryEWC" ||
        ssi === "verificationHistoryEwc"
      ) {
        return dataToDisplayEwc10;
      }
    }
  };

  const handleCopy = () => {
    if (selectedData) {
      navigator.clipboard.writeText(
        JSON.stringify(displayData(selectedData), null, 4)
      );

      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 1000);
    }
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <Container>
          <HeaderContainer>
            <Box pl={2} style={{ overflow: "hidden" }}>
              <Typography
                style={{
                  color: "#F3F3F6",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {dda
                  ? headerText
                  : `${headerText} ${
                      selectedData?.dataAgreement?.purpose
                        ? `:${selectedData?.dataAgreement?.purpose}`
                        : ""
                    }`}
              </Typography>
              <Typography color="#F3F3F6">
                {dda ? selectedData?.dda_instance_id : selectedData?.id}
              </Typography>
            </Box>
            <CloseIcon
              onClick={() => setOpen(false)}
              sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
            />
          </HeaderContainer>
          <DetailsContainer>
            <Box>
              <SyntaxHighlighter
                customStyle={{
                  paddingBottom: "60px",
                  marginBottom: "0px",
                  background: "#fff",
                  lineHeight: "1.7",
                  fontSize: "14px",
                }}
                showLineNumbers={true}
                language="json"
                style={docco}
                codeTagProps={{
                  style: {
                    fontFamily: "'Inter', 'Helvetica', 'Arial', sans-serif",
                  },
                }}
              >
                {JSON.stringify(displayData(selectedData), null, 4)}
              </SyntaxHighlighter>
            </Box>
          </DetailsContainer>
          <FooterContainer>
            <Button
              onClick={() => setOpen(false)}
              style={buttonStyle}
              sx={{
                marginLeft: "15px",
                color: "black",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              variant="outlined"
            >
              {t("common.close")}
            </Button>
            <Tooltip
              title={t("dataAgreements.copied")}
              placement="top"
              open={showTooltip}
            >
              <Button
                variant="outlined"
                style={buttonStyle}
                sx={{
                  cursor: "pointer",
                  marginRight: "20px",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                onClick={handleCopy}
              >
                {t("developerAPIs.copy")}
              </Button>
            </Tooltip>
          </FooterContainer>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
