export const ONBOARDINGENDPOINTS = {
  verifyEmail: () => {
    return `/onboard/validate/email`;
  },
  validatePhone: () => {
    return `/onboard/validate/phone`;
  },
  verifyPhone: () => {
    return `/onboard/verify/phone`;
  },
  verifyOtp: () => {
    return `/onboard/verify/otp`;
  },
  listOrgTypes: () => {
    return `/onboard/organisation/types`;
  },
  listPricingInfo: () => {
    return `onboard/organisation/billing/plans`;
  },
  getServiceAgreement: () => {
    return `/onboard/billing/serviceagreement`;
  },
  registerOrganisationAdmin: () => {
    return `/onboard/admin/register`;
  },
  addOrganisation: () => {
    return `/onboard/organisation`;
  },
  registerBilling: () => {
    return `/onboard/organisation/billing`;
  },
  addBillingAddress: () => {
    return `/onboard/organisation/billing/address`;
  },
  addBillingPaymentSource: () => {
    return `/onboard/organisation/billing/paymentsource`;
  },
  addBillingSubscribePlan: (planId: string | undefined) => {
    return `/onboard/organisation/billing/plan/${planId}`;
  },
  getOrganisationFeatures:  () => {
    return `/onboard/organisation/features`;
  },
  updateOrganisationFeatures:  () => {
    return `/onboard/organisation/features`;
  },
};