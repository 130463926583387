import { MenuItem, Select } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HttpServiceForDigitalWallet } from "../../service/digitalWalletHttpService";

const dropDownStyle = {
  border: "1px solid lightgray",
  outline: "none",
  fontSize: "14px",
  backgroundColor: "#FFFF",
  height: "28px",
  borderRadius: "5px",
  cursor: "pointer",
  padding: 0,
  margin: 0,
};

interface Props {
  record: any;
  selectedValue: any;
  setSelectedValue: any;
  onRefetch: any;
  setOpenSnackBar: Dispatch<SetStateAction<boolean>>;
  setSuccessMessage: Dispatch<SetStateAction<string>>;
  setError: Dispatch<SetStateAction<string>>;
}

const DataRequestStatusDropdown = (props: Props) => {
  const {
    record,
    selectedValue,
    setSelectedValue,
    onRefetch,
    setError,
    setOpenSnackBar,
    setSuccessMessage,
  } = props;
  const { t } = useTranslation("translation");

  const statustype = [
    t("userRequests.requestInitiated"),
    t("userRequests.requestAcknowledged"),
    t("userRequests.requestProcessedWithoutAction"),
    t("userRequests.requestProcessedWithAction"),
  ];

  const getStatus = (selectedStatus: string | undefined) => {
    if (selectedStatus === "Request initiated") {
      return 1;
    } else if (selectedStatus === "Request acknowledged") {
      return 2;
    } else if (selectedStatus === "Request processed without action") {
      return 6;
    } else if (selectedStatus === "Request processed with action") {
      return 7;
    }
  };

  const [defaultSelectedValue, setDefaultSelectedValueNew] = useState(
    record.status
  );

  useEffect(() => {
    setDefaultSelectedValueNew(record.status);
  }, [record]);

  const handleChange = (event: any) => {
    setSelectedValue((selectedValue: any) => {
      return { ...selectedValue, [record.id]: event.target.value };
    });

    const selectedStatus = statustype?.find(
      (status: any) => status === event.target.value
    );

    record.status = selectedStatus;

    let payload = {
      state: getStatus(selectedStatus),
    };
    HttpServiceForDigitalWallet.updateDataRequest(payload, record.id)
      .then(() => {
        setOpenSnackBar(true);
        setError("");
        setSuccessMessage(t("userRequests.successMessageForUpdateDataRequests"));
        onRefetch();
      })
      .catch((err) => {
        setOpenSnackBar(true);
        setSuccessMessage("");
        setError(err.response.data.Message);
        onRefetch();
        console.log("error");
      });
  };

  const newSelectedValue = selectedValue[record.id] || defaultSelectedValue;

  return (
    <>
      <Select
        onChange={(e) => handleChange(e)}
        variant="outlined"
        value={newSelectedValue ? newSelectedValue : record.stateStr}
        fullWidth
        style={{
          ...dropDownStyle,
          width: "100%",
        }}
        renderValue={(value) => <span>{value}</span>}
      >
        {statustype?.map((status: any, index: number) => (
          <MenuItem key={index} value={status}>
            {status}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default DataRequestStatusDropdown;
