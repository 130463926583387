import { Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  copytext: string;
}

const CopyButtonWithToolTip = (props: Props) => {
  const { copytext } = props;
  const { t } = useTranslation("translation");

  const [showTooltip, setShowTooltip] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(copytext);

    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };
  return (
    <Tooltip
      title={t("dataAgreements.copied")}
      placement="top"
      open={showTooltip}
    >
      <Box
        onClick={handleCopy}
        style={{
          display: "flex",
          cursor: "pointer",
        }}
      >
        <FileCopyOutlinedIcon style={{ color: "gray" }} fontSize="small" />

        <Typography color="grey" variant="body2">
          Copy
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default CopyButtonWithToolTip;
