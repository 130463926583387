import Grid from "@mui/material/Grid";

import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useOnBoardingStore } from "../../store/onBoardingStore";
import { Dispatch, SetStateAction } from "react";

const buttonStyle = {
  height: 30,
  width: "100%",
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const disabledContainerStyle = {
  display: "grid",
  justifyItems: "center",
  alignContent: "space-between",
  padding: "5px",
  height: "350px",
  borderRadius: "5px",
  backgroundColor: "#fff",
  border: "1px solid #DFDFDF",
  transition: "box-shadow 0.3s",
  "&:hover": {
    boxShadow: "0 1px 5px rgba(0,0,0,.4),0 1px 5px hsla(0,0%,51%,.35)",
  },
};

const unSelectedContainerStyle = {
  display: "grid",
  justifyItems: "center",
  alignContent: "space-between",
  padding: "5px",
  height: "350px",
  borderRadius: "5px",
  backgroundColor: "#fff",
  border: "1px solid #DFDFDF",
  transition: "box-shadow 0.3s",
  "&:hover": {
    boxShadow: "0 1px 5px rgba(0,0,0,.4),0 1px 5px hsla(0,0%,51%,.35)",
  },
};

const selectedContainerStyle = {
  display: "grid",
  justifyItems: "center",
  alignContent: "space-between",
  padding: "5px",
  height: "350px",
  borderRadius: "5px",
  backgroundColor: "#fff",
  border: "1px solid black",
  transition: "box-shadow 0.3s",
  boxShadow: "0 1px 5px rgba(0,0,0,.4), 0 1px 5px hsla(0,0%,51%,.35)",
  "&:hover": {
    boxShadow: "0 1px 5px rgba(0,0,0,.4),0 1px 5px hsla(0,0%,51%,.35)",
  },
};

interface Props {
  selectedPackage: number;
  setSelectedPackage: Dispatch<SetStateAction<number>>;
}
export default function PackageDetailsContainer(props: Props) {
  const { selectedPackage, setSelectedPackage } = props;
  const { t } = useTranslation("translation");
  const onbordingStore: any = useOnBoardingStore(
    (state) => state.pricingInfoFromApi
  );

  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" spacing={4}>
          {[0, 1, 2].map((value) => (
            <Grid key={value} item xs={12} sm={6} md={4}>
              <Box
                sx={
                  value === 0
                    ? selectedPackage === 0
                      ? selectedContainerStyle
                      : unSelectedContainerStyle
                    : value === 1
                    ? selectedPackage === 1
                      ? selectedContainerStyle
                      : unSelectedContainerStyle
                    : disabledContainerStyle
                }
              >
                <Box
                  sx={{
                    borderBottom: "1px solid #DFDFDF",
                    height: "40px",
                    width: "90%",
                    textAlign: "center",
                  }}
                >
                  <Typography variant="subtitle1" color={"black"}>
                    {value === 0 && t("onboarding.trial")}
                    {value === 1 && t("onboarding.starter")}
                    {value === 2 && t("onboarding.payPerUser")}
                  </Typography>
                </Box>
                <Box>
                  {value === 0 && (
                    <Box textAlign={"center"}>
                      <Typography
                        variant="subtitle1"
                        color={"black"}
                        fontWeight={"bold"}
                      >
                        {onbordingStore?.freeTrial?.price > 0
                          ? onbordingStore?.freeTrial?.price
                          : t("onboarding.free")}
                      </Typography>

                      <Typography variant="subtitle1" color={"black"} mt={4}>
                        {t("onboarding.validFor")}{" "}
                        {onbordingStore.freeTrial.validity}{" "}
                        {onbordingStore.freeTrial.validity > 1
                          ? t("onboarding.days")
                          : t("onboarding.day")}
                      </Typography>
                      <Typography variant="subtitle1" color={"black"}>
                        {t("onboarding.upto")}{" "}
                        {onbordingStore.freeTrial.usageLimit.users}{" "}
                        {t("onboarding.users")}
                      </Typography>
                      <Typography variant="subtitle1" color={"black"}>
                        {t("onboarding.upto")}{" "}
                        {onbordingStore.freeTrial.usageLimit.api}{" "}
                        {t("onboarding.APIcalls")}
                      </Typography>
                    </Box>
                  )}

                  {value === 1 && (
                    <Box textAlign={"center"}>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="subtitle1"
                          color={"black"}
                          fontWeight={"bold"}
                        >
                          {t("onboarding.sek")} {onbordingStore.starter.price}{" "}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={"grey"}
                          style={{ marginLeft: "10px" }}
                        >
                          {" "}
                          / {t("onboarding.year")}
                        </Typography>
                      </Box>
                      <Typography variant="subtitle1" color={"black"} mt={4}>
                        {t("onboarding.upto")}{" "}
                        {onbordingStore.starter.usageLimit.users}{" "}
                        {t("onboarding.users")}
                      </Typography>
                      <Typography variant="subtitle1" color={"black"}>
                        {t("onboarding.upto")}{" "}
                        {onbordingStore.starter.usageLimit.api}{" "}
                        {t("onboarding.APIcalls")}
                      </Typography>
                    </Box>
                  )}

                  {value === 2 && (
                    <Box textAlign={"center"}>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="subtitle1"
                          color={"black"}
                          fontWeight={"bold"}
                        >
                          {t("onboarding.sek")}{" "}
                          {onbordingStore.payPerUser.basePrice}*
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color={"grey"}
                          style={{ marginLeft: "10px" }}
                        >
                          {" "}
                          / {t("onboarding.userPerYear")}
                        </Typography>
                      </Box>

                      <Typography variant="subtitle1" color={"black"} mt={4}>
                        {onbordingStore.payPerUser.usageLimit.users ===
                        "UNLIMITED"
                          ? t("onboarding.unlimitedUsers")
                          : "Upto " +
                            onbordingStore.payPerUser.usageLimit.users +
                            " users"}{" "}
                      </Typography>
                      <Typography variant="subtitle1" color={"black"}>
                        {t("onboarding.upto")}{" "}
                        {onbordingStore.payPerUser.usageLimit.api}{" "}
                        {t("onboarding.APIcalls")}
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    borderTop: "1px solid #DFDFDF",
                    height: "40px",
                    width: "90%",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {value === 0 &&
                    (selectedPackage === 0 ? (
                      <Typography
                        variant="subtitle1"
                        color={"black"}
                        fontWeight={"bold"}
                      >
                        {t("onboarding.selected")}
                      </Typography>
                    ) : (
                      <Button
                        style={buttonStyle}
                        sx={{
                          cursor: "pointer",
                          color: "black",
                          "&:hover": {
                            backgroundColor: "black",
                            color: "white",
                          },
                        }}
                        variant="outlined"
                        onClick={() => setSelectedPackage(0)}
                      >
                        {t("onboarding.select")}
                      </Button>
                    ))}
                  {value === 1 &&
                    (selectedPackage === 1 ? (
                      <Typography
                        variant="subtitle1"
                        color={"black"}
                        fontWeight={"bold"}
                      >
                        {t("onboarding.selected")}
                      </Typography>
                    ) : (
                      <Button
                        style={buttonStyle}
                        sx={{
                          cursor: "pointer",
                          color: "black",
                          "&:hover": {
                            backgroundColor: "black",
                            color: "white",
                          },
                        }}
                        variant="outlined"
                        onClick={() => setSelectedPackage(1)}
                      >
                        {t("onboarding.select")}
                      </Button>
                    ))}
                  {value === 2 && (
                    <Button
                      sx={{
                        cursor: "not-allowed",
                        color: "#6D7676",
                        "&:hover": {
                          backgroundColor: "black",
                          color: "white",
                        },
                      }}
                      variant="outlined"
                      style={buttonStyle}
                    >
                      {t("onboarding.select")}
                    </Button>
                  )}{" "}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
