import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Typography, Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDigitalWalletStore } from "../../../store/digitalWalletStore";
import BillingAddressInputContainer from "../../../components/onboarding/billingAddressInputContainer";
import { OnBoardingHttpService } from "../../../service/onboardingService";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { HttpServiceForDigitalWallet } from "../../../service/digitalWalletHttpService";

const buttonStyle = {
  height: 30,
  width: 150,
  borderRadius: 0,
  border: "1px solid #DFDFDF",
};

const FormContainer = styled("div")(({ theme }) => ({
  display: "grid",
  border: "1px solid #DFE0E1",
  borderRadius: 3,
  padding: "12px",
  width: "50%",
  marginTop: "20px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

const ButtonContainer = styled("div")(() => ({
  display: "flex",
  marginTop: "20px",
  justifyContent: "space-between",
}));

interface Props {
  setError: Dispatch<SetStateAction<string>>;
  setOpenSnackBar: Dispatch<SetStateAction<boolean>>;
  handleNext: any;
  handleBack: any;
}

const BillingAddressForm = (props: Props) => {
  const { handleBack, handleNext } = props;
  const { t } = useTranslation("translation");
  const { handleSubmit, register, formState, reset } = useForm<any>({
    mode: "onChange",
    defaultValues: {},
  });

  const billingAddress = useDigitalWalletStore(
    (state) => state.readExistingBillingDetails?.billingInfo?.address
  );
  const [editmode, setEditMode] = useState(true);

  useEffect(() => {
    setEditMode(billingAddress.name === "" ? true : false);
  }, []);

  useEffect(() => {
    let defaultValues = {
      adminName: billingAddress.name,
      addressLine1: billingAddress.line1,
      addressLine2: billingAddress.line2,
      city: billingAddress.city,
      state: billingAddress.state,
      country: billingAddress.country,
      postalCode: billingAddress.postalCode,
    };

    reset({ ...defaultValues });
  }, []);

  const onSubmit = (createdData: any) => {
    if (formState.isValid) {
      const payload = {
        name: createdData.adminName,
        line1: createdData.addressLine1,
        line2: createdData.addressLine2,
        city: createdData.city,
        state: createdData.state,
        country: createdData.country,
        postalCode: createdData.postalCode,
      };

      OnBoardingHttpService.addBillingAddress(payload).then(() => {
        HttpServiceForDigitalWallet.readBilling().then((res) => {
          useDigitalWalletStore
            .getState()
            .updateReadExistingBillingDetails(res.organisation);
          setEditMode(false);
        });
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="body2" mt={1.25}>
        {t("billing.addOrEditBillingAddress")}:
      </Typography>

      <FormContainer>
        {editmode ? (
          <BillingAddressInputContainer
            formState={formState}
            register={register}
          />
        ) : (
          <Box style={{ height: "260px" }}>
            <Box p={4}>
              <Typography variant="subtitle1" color={"black"} fontWeight="bold">
                {t("onboarding.billingAddress")}
              </Typography>
              <Typography variant="subtitle2" mt={2} mb={1}>
                <span style={{ fontWeight: "bold" }}>
                  {t("onboarding.billingAdmin")}:
                </span>{" "}
                <span>{billingAddress.name}</span>
              </Typography>

              <Typography variant="subtitle2">
                {billingAddress.line1}
              </Typography>
              <Typography variant="subtitle2">
                {billingAddress.line2}
              </Typography>
              <Typography variant="subtitle2">
                <span>{billingAddress.city}</span>,{" "}
                <span>{billingAddress.state}</span>,{" "}
                <span>{billingAddress.postalCode}</span>
              </Typography>
            </Box>
          </Box>
        )}

        <Box
          style={{
            display: "flex",
            justifyContent: "right",
            marginTop: "20px",
            alignItems: "end",
          }}
        >
          {editmode ? (
            <>
              {" "}
              <Button
                type="submit"
                variant="outlined"
                style={buttonStyle}
                sx={{
                  color: "black",
                  fontSize: "14px",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                onClick={() => setEditMode(false)}
              >
                <>{t("common.cancel").toUpperCase()}</>
              </Button>
              <Button
                type="submit"
                variant="outlined"
                style={buttonStyle}
                sx={{
                  fontSize: "14px",
                  color: formState.isValid ? "black" : "#6D7676",
                  cursor: formState.isValid ? "pointer" : "not-allowed",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
              >
                <> {t("common.save").toUpperCase()}</>
              </Button>{" "}
            </>
          ) : (
            <Button
              type="submit"
              variant="outlined"
              style={buttonStyle}
              sx={{
                color: "black",
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
              onClick={() => setEditMode(true)}
            >
              <>{t("common.edit").toUpperCase()}</>
            </Button>
          )}
        </Box>
      </FormContainer>

      <ButtonContainer>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            color: "black",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          onClick={handleBack}
          startIcon={<KeyboardArrowLeftIcon />}
        >
          <span>{t("onboarding.prev")}</span>
        </Button>
        <Button
          variant="outlined"
          style={buttonStyle}
          sx={{
            display: "flex",
            alignItems: "center",
            color: "black",
            "&:hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
          onClick={handleNext}
          endIcon={<KeyboardArrowRightIcon />}
        >
          <span>{t("onboarding.next")}</span>
        </Button>
      </ButtonContainer>
    </form>
  );
};

export default BillingAddressForm;
