import { Select, MenuItem, FormControl } from "@mui/material";
import { Controller } from "react-hook-form";
import "../../index.css";

interface Props {
  dropdownValue: any;
  control: any;
  nameOfSelect: string;
  valueKey: string; // Key to use as the value for MenuItem
  displayKey: string; // Key to use as the display text for MenuItem
  digitalWallet?: any;
  credentialDefinitionModalMode?:string
}

const dropDownStyle = {
  color: "#495057",
  border: "none",
  outline: "none",
  fontSize: "14px",
  width: "100%",
  backgroundColor: "transparent",
};

const SelectDataType = (props: Props) => {
  const { dropdownValue, control, nameOfSelect, valueKey, displayKey, credentialDefinitionModalMode } = props;

  return (
    <Controller
      name={nameOfSelect}
      control={control}
      rules={{
        required: true,
      }}
      render={({ field: { onChange, value } }) => (
        <FormControl variant="outlined" fullWidth>
          <Select
            value={value}
            disabled={credentialDefinitionModalMode === "View"}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                cursor: "not-allowed",
              },
              "& .MuiAutocomplete-inputRoot.Mui-disabled .MuiAutocomplete-clearIndicator": {
                display: "none",
              },
            }}
            onChange={onChange}
            name={nameOfSelect}
            displayEmpty
            style={{
              ...dropDownStyle,
              height: "32px",
            }}
            inputProps={{ "aria-label": "Without label" }}
            renderValue={(selected) => {
              const selectedItem = dropdownValue?.find(
                (item: any) => item[valueKey] === selected
              );
              return selectedItem ? (
                selectedItem[displayKey]
              ) : (
                <>Data type</>
              );
            }}
          >
            {dropdownValue?.map((option: any) => (
              <MenuItem key={option[displayKey]} value={option[valueKey]}>
                {option[displayKey]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default SelectDataType;
