import { Select, MenuItem, FormControl } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "../../index.css";
import {
  getPresentationRequestValue,
  handleChangeForExistingSchemaDropdown,
} from "../../utils/verificationRequestUtils";
interface Props {
  dropdownValue: any;
  control: any;
  nameOfSelect: string;
  valueKey: string; // Key to use as the value for MenuItem
  displayKey: string; // Key to use as the display text for MenuItem
  digitalWallet?: any;
  selectedValue: string;
  disabled?: boolean;
  isFieldMandatory?: boolean;
  setValue: any;
  watchLimitDisclosure?: any;
  resetField: any;
  mode: string;
  presentationDefinitionValue: any;
}

const dropDownStyle = {
  color: "#495057",
  border: "none",
  outline: "none",
  fontSize: "14px",
  width: "100%",
  backgroundColor: "transparent",
};

const SelectDefaultValueFOrEwcVerification = (props: Props) => {
  const {
    dropdownValue,
    control,
    nameOfSelect,
    valueKey,
    displayKey,
    selectedValue,
    setValue,
    watchLimitDisclosure,
    resetField,
    mode,
    presentationDefinitionValue,
  } = props;
  const { t } = useTranslation("translation");

  return (
    <Controller
      name={nameOfSelect}
      control={control}
      rules={{
        required: false,
      }}
      defaultValue={selectedValue || ""}
      render={({ field: { onChange, value } }) => (
        <FormControl variant="outlined" fullWidth>
          <Select
            value={value}
            onChange={(e) => {
              resetField("fields");
              setValue(
                "presentationDefinition",
                JSON.stringify(
                  {
                    constraints: {
                      fields: [],
                    },
                  },
                  null,
                  2
                )
              );
              onChange(e.target.value);

              const presentationDefinition = JSON.stringify(
                getPresentationRequestValue(
                  watchLimitDisclosure,
                  e.target.value,
                  presentationDefinitionValue
                )
              );

              handleChangeForExistingSchemaDropdown(
                presentationDefinition,
                setValue,
                watchLimitDisclosure
              );
            }}
            disabled={mode === "View"}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                cursor: "not-allowed",
              },
              "& .MuiAutocomplete-inputRoot.Mui-disabled .MuiAutocomplete-clearIndicator":
                {
                  display: "none",
                },
            }}
            name={nameOfSelect}
            displayEmpty
            style={{
              ...dropDownStyle,
              height: "32px",
            }}
            inputProps={{ "aria-label": "Without label" }}
            renderValue={(selected) => {
              const selectedItem = dropdownValue?.find(
                (item: any) => item[valueKey] === selected
              );

              return selectedItem ? (
                selectedItem[displayKey]
              ) : (
                <>{t("dataAgreements.chooseExistingSchemas")}</>
              );
            }}
          >
            {dropdownValue?.map((option: any) => (
              <MenuItem key={option[displayKey]} value={option[valueKey]}>
                {option[displayKey]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default SelectDefaultValueFOrEwcVerification;
