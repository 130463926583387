/* eslint-disable no-prototype-builtins */
import * as React from "react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  Drawer,
  Typography,
  Button,
  Box,
  Avatar,
  TextField,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import {
  Container,
  HeaderContainer,
  DetailsContainer,
  FooterContainer,
  buttonStyle,
  disabledButtonstyle,
  BannerContainer,
} from "../modalStyle";

import {
  useForm,
  useFieldArray,
  FieldValues,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import LoaderComponent from "../../LoaderComponent";
import SnackbarComponent from "../../notification";
import {
  CREDENTIAL_TYPE_LABELS,
  CREDENTIAL_TYPES,
} from "../../../utils/credentialsHistoryUtils";
import DefaultBanner from "../../../assets/OrganisationDefaultBanner.jpeg";
import DefaultLogo from "../../../assets/OrganisationDefaultLogo.png";
import { OrganizationDetailsCRUDContext } from "../../../contexts/organizationDetailsCrud";
import SelectCredentialforCreateCredentialDefinition from "../../dropdowns/selectCredentialforCreateCredentialDefinition";
import { DataAttributeForOID4VC } from "../../issuanceHistoryForOID4VC/DataAttribute";
import { DataAttributeAppendHeaderForOID4VC } from "../../issuanceHistoryForOID4VC/DataAttributeAppendHeader";
import { HttpServiceForDigitalWalletEwc } from "../../../service/digitalWalletEwcHttpService";
import { isFormDataChanged } from "../../../utils/isFormDataChanged";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EwcIssueCredentialManuallybyJson from "./ewcIssueCredentialManuallybyJson";
import {
  addAdditionalPropertFieldTOJSONSchema,
  generateJsonSchema,
  parseSchema,
  updateCredentialFormatValue,
} from "../../../utils/credentialDefintionUtils";
import EwcCredentialFormatRadio from "../../radio/ewcCredentialFormatRadio";
import { credentialDefinitionJsonSchema } from "../../../utils/jsonSchema";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  headerText: string;
  onRefetch: any;
  selectedData: any;
  ssi: string;
  credentialDefinitionModalMode: string;
}

let defaultJsonSchemaValue = JSON.stringify(
  {
    type: "object",
    properties: {},
    required: [],
  },
  null,
  4
);

let defaultValue = {
  label: "",
  credentialDefinitionId: "",
  credentialFormat: true,
  credentialtype: "",
  jsonSchema: defaultJsonSchemaValue,
  credentialDefinition: [],
};

export default function EwcCreateCredentialDefinitionModal(props: Props) {
  const { organisationDetails, logoImageBase64, coverImageBase64 }: any =
    React.useContext(OrganizationDetailsCRUDContext);
  const [openCredentialJSONModal, setOpenCredentialJSONModal] = useState(false);

  const { t } = useTranslation("translation");
  const {
    open,
    setOpen,
    headerText,
    onRefetch,
    credentialDefinitionModalMode,
    selectedData,
  } = props;
  const [openLoader, setOpenLoader] = useState(false);
  const [error, setError] = useState<any>("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [credentialDefinitionUpdateValue, setCredentialDefinitionUpdateValue] =
    useState([]);
  const [isValidJson, setIsValidJson] = useState(true);

  const methods = useForm<FieldValues>({
    defaultValues: {
      ...defaultValue,
    },
    mode: "onChange",
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { isValid },
    watch,
    reset,
    setValue,
    formState,
  } = methods;

  const { fields, append, remove } = useFieldArray<
    FieldValues,
    "credentialDefinition"
  >({
    control,
    name: "credentialDefinition",
  });

  const credentialFormatsMenuItems = [
    { value: true, label: "SD-JWT" },
    {
      value: false,
      label: "JWT",
    },
  ];

  const credntialDropdownValues = [
    {
      label:
        CREDENTIAL_TYPE_LABELS.VERIFIABLE_LEGAL_PERSONAL_IDENTIFICATION_DATA,
      value:
        CREDENTIAL_TYPES.VERIFIABLE_LEGAL_PERSONAL_IDENTIFICATION_DATA_SDJWT,
    },
    {
      label: CREDENTIAL_TYPE_LABELS.VERIFIABLE_CERTIFICATE_OF_REGISTRATION,
      value: CREDENTIAL_TYPES.VERIFIABLE_CERTIFICATE_OF_REGISTRATION_SDJWT,
    },
    {
      label: CREDENTIAL_TYPE_LABELS.VERIFIABLE_PORTABLE_DOCUMENT_A1,
      value: CREDENTIAL_TYPES.VERIFIABLE_PORTABLE_DOCUMENT_A1_SDJWT,
    },
  ];

  const credentialDropdownSelectedValues = watch(`credentialtype`);

  useEffect(() => {
    if (credentialDefinitionModalMode === "Create") {
      reset({
        label: "",
        credentialtype: credentialDropdownSelectedValues,
        credentialFormat: true,
        jsonSchema: defaultJsonSchemaValue,
        credentialDefinition: [],
      });
    } else if (
      credentialDefinitionModalMode === "Update" ||
      credentialDefinitionModalMode === "View"
    ) {
      reset({
        label: selectedData.label,
        credentialDefinitionId: selectedData.credentialDefinitionId,
        credentialtype: selectedData.credentialType[0],
        credentialFormat: false,
        jsonSchema: defaultJsonSchemaValue,
        credentialDefinition: [],
      });

      const schemaValueForUpdate = JSON.stringify(
        selectedData.credentialDefinition,
        null,
        4
      );

      // to updated credentialFormat
      updateCredentialFormatValue(selectedData?.credentialDefinition, setValue);

      setValue("jsonSchema", schemaValueForUpdate);

      const parsedSchema = JSON.parse(schemaValueForUpdate);
      setCredentialDefinitionUpdateValue(
        parseSchema(parsedSchema, watch("credentialFormat"))
      );
    }
  }, [open]);

  // this useEffect is used to set initialvalue credentialDefinition during update
  useEffect(() => {
    setValue("credentialDefinition", credentialDefinitionUpdateValue);
  }, [credentialDefinitionUpdateValue]);

  const handleCredentialManuallyOnChange = (value: any) => {
    const inputValue = value;
    setValue("jsonSchema", inputValue, {
      shouldDirty: true,
    });

    if (inputValue.trim() === "") {
      setValue("credentialDefinition", []);
      return;
    }
  };

  const onCloseModal = () => {
    try {
      const parsedSchema = JSON.parse(watch("jsonSchema"));
      const newSections = parseSchema(parsedSchema, watch("credentialFormat"));
      setValue("credentialDefinition", newSections);

      updateCredentialFormatValue(parsedSchema, setValue);

      // setIsValidJson(true);
    } catch (e: any) {
      // setError(e.message);
      // setOpenSnackBar(true);
      // setIsValidJson(false);
    }

    if (error !== "") {
      setOpenSnackBar(true);
      setIsValidJson(false);
    } else {
      setIsValidJson(true);
    }
    setOpenCredentialJSONModal(false);
  };

  const handleTypeChange = (index: any, value: any) => {
    setValue(`credentialDefinition.${index}.type`, value);
  };

  const credentialDefinitionValues = watch("credentialDefinition");

  const lastJsonSchema = React.useRef<any>(null);

  useEffect(() => {
    const newJsonSchema: any = generateJsonSchema(
      credentialDefinitionValues,
      watch("credentialFormat")
    );

    const newJsonSchemaString = JSON.stringify(newJsonSchema, null, 2);

    if (newJsonSchemaString !== lastJsonSchema.current) {
      lastJsonSchema.current = newJsonSchemaString;
      setValue("jsonSchema", newJsonSchemaString);

      updateCredentialFormatValue(newJsonSchema, setValue);
    }
  }, [credentialDefinitionValues, setValue, handleTypeChange]);

  const isSubmitButtonEnabled =
    isValid &&
    credentialDefinitionValues.length > 0 &&
    isFormDataChanged(formState) &&
    isValidJson &&
    credentialDefinitionModalMode !== "View";

  const onSubmit = (data: any) => {
    if (isSubmitButtonEnabled) {
      let credentialDefinitionUpdated: any =
        addAdditionalPropertFieldTOJSONSchema(data.jsonSchema);

      const payload = {
        label: data.label,
        credentialType: [data.credentialtype],
        credentialDefinition: credentialDefinitionUpdated,
      };
      setOpenLoader(true);

      if (credentialDefinitionModalMode === "Create") {
        HttpServiceForDigitalWalletEwc.createCredentialDefinitin(payload)
          .then(() => {
            setOpenLoader(false);
            setOpen(false);
            onRefetch();
            reset({ ...defaultValue });
          })
          .catch((err: any) => {
            setOpenLoader(false);
            setError(err.response.data.errorDescription);
            setOpenSnackBar(true);
          });
      } else if (credentialDefinitionModalMode === "Update") {
        HttpServiceForDigitalWalletEwc.updateCredentialDefinitin(
          payload,
          selectedData.credentialDefinitionId
        )
          .then(() => {
            setOpenLoader(false);
            setOpen(false);
            onRefetch();
            reset({ ...defaultValue });
          })
          .catch((err: any) => {
            setOpenLoader(false);
            setError(err.response.data.errorDescription);
            setOpenSnackBar(true);
          });
      }
    }
  };

  return (
    <React.Fragment>
      <Drawer anchor="right" open={open}>
        <LoaderComponent openLoader={openLoader} />
        <Container style={{ overflow: openLoader ? "hidden" : undefined }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <HeaderContainer>
              <SnackbarComponent
                open={openSnackBar}
                setOpen={setOpenSnackBar}
                message={error}
              />
              <Box pl={2}>
                <Typography color="#F3F3F6">{headerText}</Typography>
              </Box>
              <CloseIcon
                onClick={() => {
                  setOpen(false);
                  reset({ ...defaultValue });
                }}
                sx={{ paddingRight: 2, cursor: "pointer", color: "#F3F3F6" }}
              />
            </HeaderContainer>
            <BannerContainer>
              <Box
                style={{ height: "150px", width: "100%" }}
                component="img"
                alt="Banner"
                src={
                  coverImageBase64
                    ? `data:image/jpeg;charset=utf-8;base64,${coverImageBase64}`
                    : DefaultBanner
                }
              />
            </BannerContainer>
            <Box sx={{ marginBottom: "60px" }}>
              <Avatar
                src={
                  logoImageBase64
                    ? `data:image/jpeg;charset=utf-8;base64,${logoImageBase64}`
                    : DefaultLogo
                }
                style={{
                  position: "absolute",
                  marginLeft: 50,
                  marginTop: "-65px",
                  width: "110px",
                  height: "110px",
                  border: "solid white 6px",
                }}
              />
            </Box>
            <DetailsContainer style={{ paddingBottom: "80px" }}>
              <Box p={1.5}>
                <Typography variant="h6" fontWeight="bold">
                  {organisationDetails.name}
                </Typography>

                <Typography variant="subtitle1" mb={0} mt={1}>
                  {t("dataAgreements.usagePurpose")}
                  <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                </Typography>
                <TextField
                  autoFocus
                  variant="standard"
                  fullWidth
                  autoComplete="off"
                  disabled={credentialDefinitionModalMode === "View"}
                  placeholder={t("credentials.usagePurposePlaceHolder")}
                  {...register("label", {
                    required: true,
                    validate: (value) => {
                      const trimmedValue = value.trim();
                      return trimmedValue.length >= 3;
                    },
                    pattern: {
                      value: /^(?=.*\S).+$/,
                      message: "",
                    },
                  })}
                  sx={{
                    "& .MuiInputBase-input.Mui-disabled": {
                      cursor: "not-allowed",
                    },
                    "& .MuiAutocomplete-inputRoot.Mui-disabled .MuiAutocomplete-clearIndicator":
                      {
                        display: "none",
                      },
                  }}
                />

                <Box mt={1.5}>
                  <Box
                    mt={1.5}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    mb={1.5}
                  >
                    <Typography variant="subtitle1" mb={-1}>
                      {t("issuanceHistory.credentialDefinitionTemplate")}
                      <span style={{ color: "rgba(224, 7, 7, 0.986)" }}>*</span>
                    </Typography>

                    <Tooltip
                      title={"Enter free text in JSON format"}
                      placement="top"
                    >
                      <EditOutlinedIcon
                        onClick={() => {
                          credentialDropdownSelectedValues !== "" &&
                            setOpenCredentialJSONModal(true);
                          setError("");
                        }}
                        fontSize="small"
                        style={{
                          cursor:
                            credentialDropdownSelectedValues !== ""
                              ? "pointer"
                              : "not-allowed",
                          color: "black",
                        }}
                      />
                    </Tooltip>
                  </Box>

                  <SelectCredentialforCreateCredentialDefinition
                    dropdownValue={credntialDropdownValues}
                    control={control}
                    nameOfSelect={"credentialtype"}
                    valueKey={"value"}
                    displayKey={"label"}
                    selectedValue={credentialDropdownSelectedValues}
                    isFieldMandatory={true}
                    setValue={setValue}
                    credentialDefinitionModalMode={
                      credentialDefinitionModalMode
                    }
                    watch={watch}
                  />

                  <EwcCredentialFormatRadio
                    control={control}
                    name="credentialFormat"
                    credentialFormatsMenuItems={credentialFormatsMenuItems}
                    credentialDefinitionModalMode={
                      credentialDefinitionModalMode
                    }
                    setValue={setValue}
                    watch={watch}
                  />

                  <DataAttributeAppendHeaderForOID4VC
                    append={append}
                    credentialDefinitionModalMode={
                      credentialDefinitionModalMode
                    }
                    watch={watch}
                  />

                  {fields.map((item, index) => (
                    <DataAttributeForOID4VC
                      key={item.id}
                      nestIndex={index}
                      control={control}
                      register={register}
                      watch={watch}
                      remove={remove}
                      credentialDefinitionModalMode={
                        credentialDefinitionModalMode
                      }
                    />
                  ))}
                </Box>
              </Box>
            </DetailsContainer>
            <FooterContainer>
              <Button
                style={disabledButtonstyle}
                sx={{
                  marginLeft: "10px",
                  color: "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                  cursor: "not-allowed",
                }}
                variant="outlined"
              >
                {t("dataAgreements.publish")}
              </Button>
              <Button
                variant="outlined"
                style={
                  isSubmitButtonEnabled ? buttonStyle : disabledButtonstyle
                }
                sx={{
                  cursor: isSubmitButtonEnabled ? "pointer" : "not-allowed",
                  marginRight: "20px",
                  color: isSubmitButtonEnabled ? "black" : "#6D7676",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                type="submit"
              >
                {t("common.save")}
              </Button>
            </FooterContainer>
          </form>

          <EwcIssueCredentialManuallybyJson
            open={openCredentialJSONModal}
            onCloseModal={onCloseModal}
            methods={methods}
            headerText={t(headerText)}
            handleChange={handleCredentialManuallyOnChange}
            name={"jsonSchema"}
            inputFieldName={t("issuanceHistory.credentialDefinition")}
            inputPlaceHolderName={t("issuanceHistory.credentialDefinition")}
            credentialDefinitionModalMode={credentialDefinitionModalMode}
            schema={credentialDefinitionJsonSchema}
            mode={credentialDefinitionModalMode}
            setError={setError}
          />
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
