import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Box } from "@mui/system";
import { useOnBoardingStore } from "../../store/onBoardingStore";
import PackageDetailsContainer from "./packagedetailsContainer";
import SwitchComponent from "../switchComponent";
import CovidPackageDetailsContainer from "./covidPackagedetailsContainer";
import { useDigitalWalletStore } from "../../store/digitalWalletStore";

interface Props {
  setError: Dispatch<SetStateAction<string>>;
  setOpenSnackBar: Dispatch<SetStateAction<boolean>>;
  pricingInfoFromApi: any;
  setOpenTerminateModal: Dispatch<SetStateAction<boolean>>;
}

const PackageSelector = (props: Props) => {
  const { pricingInfoFromApi, setOpenTerminateModal, setOpenSnackBar, setError } = props;
  const subscibedPackageDetails = useDigitalWalletStore(
    (state) => state.selectedPackage
  );

  const onbordingStore: any = useOnBoardingStore((state) => state);

  const [selectedPackage, setSelectedPackage] = useState<any>();
  const { t } = useTranslation("translation");
  const [covidPackage, setCovidPackage] = useState(false);

  useEffect(() => {
    if (
      subscibedPackageDetails.planCategory === "normalplan" ||
      subscibedPackageDetails.planCategory === ""
    ) {
      setCovidPackage(false);
    } else {
      setCovidPackage(true);
    }

    if (subscibedPackageDetails.planType === "notactive") {
      setSelectedPackage(null);
    } else if (subscibedPackageDetails.planType !== "notactive") {
      subscibedPackageDetails.planType === "freetrial" && setSelectedPackage(0);
      subscibedPackageDetails.planType === "starter" && setSelectedPackage(1);
      subscibedPackageDetails.planType === "payperuser" &&
        setSelectedPackage(2);
      subscibedPackageDetails.planType === "freetrialverifier" &&
        setSelectedPackage(3);
      subscibedPackageDetails.planType === "starterverifier" &&
        setSelectedPackage(4);
      subscibedPackageDetails.planType === "premiumverifier" &&
        setSelectedPackage(5);
    }
  }, [subscibedPackageDetails.planType]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (selectedPackage === 0) {
      useOnBoardingStore.getState().updateSelectedPackage({
        packageNumber: selectedPackage,
        packageDetails: onbordingStore.pricingInfoFromApi.freeTrial,
      });
    } else {
      useOnBoardingStore.getState().updateSelectedPackage({
        packageNumber: selectedPackage,
        packageDetails: onbordingStore.pricingInfoFromApi.starter,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" color={"black"} fontWeight="bold">
          {t("onboarding.packageSelection")}
        </Typography>

        <SwitchComponent
          covidPackage={covidPackage}
          setCovidPackage={setCovidPackage}
        />
      </Box>

      <Typography variant="subtitle2" color={"black"} mt={2} mb={2}>
        {t("onboarding.packageSelectionPageDescription")}
      </Typography>
      {covidPackage ? (
        <CovidPackageDetailsContainer
          setSelectedPackage={setSelectedPackage}
          selectedPackage={selectedPackage}
          pricingInfoFromApi={pricingInfoFromApi}
          setOpenTerminateModal={setOpenTerminateModal}
          setError={setError}
          setOpenSnackBar={setOpenSnackBar}
        />
      ) : (
        <PackageDetailsContainer
          setSelectedPackage={setSelectedPackage}
          selectedPackage={selectedPackage}
          pricingInfoFromApi={pricingInfoFromApi}
          setOpenTerminateModal={setOpenTerminateModal}
          setError={setError}
          setOpenSnackBar={setOpenSnackBar}
        />
      )}

      <Box textAlign={"right"} mt={1}>
        <Typography variant="caption">
          *{t("onboarding.discountText")}
        </Typography>
      </Box>
    </form>
  );
};

export default PackageSelector;
