import { Select, MenuItem, FormControl } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "../../index.css";
import { Box } from "@mui/system";

interface Props {
  dropdownValue: any;
  control: any;
  nameOfSelect: string;
  valueKey: string; // Key to use as the value for MenuItem
  displayKey: string; // Key to use as the display text for MenuItem
  selectedValue: string;
  label?: string;
  setSelectedDAforViewDA: any;
  mode: string;
}

const dropDownStyle = {
  color: "#495057",
  border: "none",
  outline: "none",
  fontSize: "14px",
  width: "100%",
  backgroundColor: "transparent",
};

const SelectDAforDDA = (props: Props) => {
  const {
    dropdownValue,
    control,
    nameOfSelect,
    valueKey,
    displayKey,
    selectedValue,
    label,
    setSelectedDAforViewDA,
    mode,
  } = props;
  const { t } = useTranslation("translation");
  const { setValue } = useFormContext();

  const handleSelectChange = (selected: any) => {
    const selectedItem = dropdownValue?.find(
      (item: any) => item[valueKey] === selected
    );
    if (selectedItem) {
      setSelectedDAforViewDA(selectedItem);
      setValue("url", selectedItem.policy?.url);
      setValue("jurisdiction", selectedItem.policy?.jurisdiction);
      setValue("industrySector", selectedItem.policy?.industrySector);
      setValue(
        "dataRetentionPeriodDays",
        Math.floor(selectedItem.policy?.dataRetentionPeriodDays / 365)
      );
      setValue(
        "geographicRestriction",
        selectedItem.policy?.geographicRestriction
      );
      setValue("storageLocation", selectedItem.policy?.storageLocation);
    }
  };

  return (
    <Controller
      name={nameOfSelect}
      control={control}
      rules={{
        required: true,
      }}
      defaultValue={selectedValue || ""}
      render={({ field: { onChange, value } }) => (
        <FormControl variant="outlined" fullWidth>
          <Select
            value={value}
            onChange={(e) => {
              onChange(e); // Handle field change
              handleSelectChange(e.target.value); // Handle other field change
            }}
            name={nameOfSelect}
            disabled={mode === "Read"}
            displayEmpty
            style={{
              ...dropDownStyle,
              height: "32px",
            }}
            inputProps={{ "aria-label": "Without label" }}
            renderValue={(selected) => {
              const selectedItem = dropdownValue?.find(
                (item: any) => item[valueKey] === selected
              );
              return selectedItem ? (
                selectedItem[displayKey]
              ) : (
                <em>{label ? label : t("common.select")}</em>
              );
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Adjust the maximum height of the panel
                  overflowY: "auto",
                },
              },
            }}
          >
            {dropdownValue?.map((option: any) =>
              option?.disabled ? (
                <Box sx={{ cursor: "not-allowed" }}>
                  <MenuItem
                    key={option[displayKey]}
                    value={option[valueKey]}
                    disabled={option?.disabled}
                    className={
                      "css-j2ut2o-MuiButtonBase-root-MuiMenuItem-root Mui-disabled"
                    }
                    sx={{ cursor: "not-allowed" }}
                  >
                    {option[displayKey]}
                  </MenuItem>
                </Box>
              ) : (
                <MenuItem
                  key={option[displayKey]}
                  value={option[valueKey]}
                  disabled={option?.disabled}
                >
                  {option[displayKey]}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default SelectDAforDDA;
