import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const inputStyle = {
  border: "1",
  outline: "none",
  fontSize: "14px",
  color: "#495057",
  borderWidth: 0,
  borderRadius: 0,
  backgroundColor: "#FFFF",
  borderBottom: "2px solid #DFE0E1",
};

const IssueType = ({
  register,
  control,
  setValue,
  selectCredentialOfferEndpointValue,
}: any) => {
  const { t } = useTranslation("translation");

  const issuanceTypes = [
    { value: false, label: t("credentials.qrCode") },
    {
      value: true,
      label: t("credentials.walletEndpoint"),
    },
  ];

  return (
    <>
      <Controller
        name={"selectCredentialOfferEndpoint"}
        control={control}
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            aria-label={"selectCredentialOfferEndpoint"}
            name={"selectCredentialOfferEndpoint"}
            value={value}
            onChange={(e) => {
              const value = e.target.value;
              onChange(value === "true" ? true : false);
              if (value === "false") {
                setValue("credentialOfferEndpoint", "");
              }
            }}
          >
            {issuanceTypes?.map((data: any) => (
              <FormControlLabel
                key={data.value}
                value={data.value}
                control={<Radio />}
                label={data.label}
              />
            ))}
          </RadioGroup>
        )}
      />

      <textarea
        disabled={selectCredentialOfferEndpointValue === false}
        placeholder={t("credentials.walletEndpointPlaceholderText")}
        style={{
          ...inputStyle,
          cursor:
            selectCredentialOfferEndpointValue === false
              ? "not-allowed"
              : "auto",
          height: "120px",
          fontFamily: "Inter,Helvetica,Arial,sans-serif",
          marginLeft: "30px",
          width: "93%",
        }}
        {...register("credentialOfferEndpoint", {
          required: selectCredentialOfferEndpointValue === true,
          minLength: 1,
          maxLength: 500,
        })}
        rows={5}
        cols={25}
        maxLength={500}
      />
    </>
  );
};

export default IssueType;
