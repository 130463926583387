import * as React from "react";
import { Dispatch, SetStateAction, Fragment } from "react";
import { Form } from "react-admin";

import { Drawer, Typography, Box } from "@mui/material";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { Container, HeaderContainer, DetailsContainer } from "./modalStyle";
import { DataAttributeForRestrictions } from "../dataAgreements/DataAttributeForRestrcitions";
import { useTranslation } from "react-i18next";
interface Props {
  openRestrictionModal: boolean;
  setOpenRestrictionModal: Dispatch<SetStateAction<boolean>>;
  mode: string;
  fields: any;
  register: any;
  remove: any;
  formController: any;
  addDataAttributeField: any;
  methods:any
}

export default function RestrictionModal(props: Props) {
  const {
    openRestrictionModal,
    setOpenRestrictionModal,
    mode,
    fields,
    register,
    remove,
    formController,
    addDataAttributeField,
    methods
  } = props;
  const { t } = useTranslation("translation");

  return (
    <React.Fragment>
      <Drawer anchor="right" open={openRestrictionModal}>
        <Container sx={{ width: "510px" }}>
          <Form>
            <HeaderContainer>
              <Box pl={2} style={{ display: "flex", alignItems: "center" }}>
                <ChevronLeftIcon
                  onClick={() => setOpenRestrictionModal(false)}
                  sx={{ marginRight: 1, cursor: "pointer", color: "#F3F3F6" }}
                />
                <Typography color="#F3F3F6">{t("dataAgreements.restrictions")}</Typography>
              </Box>
            </HeaderContainer>
            <DetailsContainer>
              <Box p={1.5}>
                {fields?.map((item: any, index: number) => {
                  return (
                    <Fragment key={item.id}>
                      <DataAttributeForRestrictions
                        index={index}
                        mode={mode}
                        remove={remove}
                        formController={formController}
                        fields={fields}
                        register={register}
                        methods={methods}
                      />
                    </Fragment>
                  );
                })}
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                    marginTop: "-10px",
                    justifyContent: "right",
                    cursor:"pointer"
                  }}
                  onClick={mode === "Read" ? () => {} : addDataAttributeField}

                >
                  <Typography variant="subtitle1">{t("dataAgreements.addMore")}</Typography>
                  <AddCircleOutlineOutlinedIcon
                    style={{
                      cursor: mode === "Read" ? "not-allowed" : "pointer",
                      marginLeft: "5px",
                    }}
                  />
                </Box>
              </Box>
            </DetailsContainer>
          </Form>
        </Container>
      </Drawer>
    </React.Fragment>
  );
}
